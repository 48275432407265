<template>
  <div class="chart">
    <v-overlay contained :model-value="loading" class="progress" z-index="101" persistent>
      <v-progress-linear indeterminate position="absolute" />
    </v-overlay>
    <v-chart :option="option" autoresize :class="{ 'opacity-50': loading }" />
  </div>
</template>

<script setup lang="ts">
import { use, graphic, ComposeOption } from "echarts/core";
import { CanvasRenderer } from "echarts/renderers";
import { LineChart, LineSeriesOption } from "echarts/charts";
import {
  TitleComponentOption,
  TitleComponent,
  TooltipComponent,
  TooltipComponentOption,
  LegendComponent,
  LegendComponentOption,
  GridComponent,
  GridComponentOption,
} from "echarts/components";
import VChart from "vue-echarts";

import moment from "moment";
import statsResource from "@/resources/StatsResource";
import axios, { CancelTokenSource } from "axios";
import { useTheme } from "vuetify";
import { ref, watch, computed, onActivated, onDeactivated } from "vue";

use([CanvasRenderer, LineChart, TitleComponent, TooltipComponent, LegendComponent, GridComponent]);
type ECOption = ComposeOption<
  LineSeriesOption | TitleComponentOption | TooltipComponentOption | LegendComponentOption | GridComponentOption
>;

let timer = 0;
let cancelToken: CancelTokenSource | undefined = undefined;
const loading = ref(false);
const theme = useTheme();
const isDark = computed(() => theme?.global?.current?.value?.dark);

const getData = () => {
  // Cancel existing request
  if (cancelToken) {
    cancelToken.cancel();
  }

  setTimeout(() => {
    // Timeout is workaround for finaly() being executed after request was canceled and new request already began
    loading.value = true;
    cancelToken = axios.CancelToken.source();

    statsResource
      .getActiveDevicesHourly(cancelToken)
      .then((resp) => {
        const data = resp.data;

        (option.value.xAxis as any).data = data.current.map(({ timestamp }) => moment(timestamp).format("D.MMM HH:mm"));

        (option.value.series as LineSeriesOption[])[0].data = data.current.map(({ numberOfDevices }) => numberOfDevices);
        (option.value.series as LineSeriesOption[])[1].data = data.lastYear.map(({ numberOfDevices }) => numberOfDevices);
      })
      .catch(statsResource.defaultErrorHandler)
      .finally(() => {
        loading.value = false;
        cancelToken = undefined;
      });
  }, 10);
};

onActivated(() => {
  getData();
  timer = setInterval(() => getData(), 60 * 1000);
});

onDeactivated(() => {
  if (timer) {
    const cloneTimer = timer;
    clearInterval(cloneTimer);
    timer = 0;
  }
});

const textColor = computed(() => (isDark.value ? "#fff" : "#333"));
const secondaryColor = computed(() => (isDark.value ? "#333" : "#ccc"));

const option = ref<ECOption>({
  textStyle: {
    fontFamily: "Roboto, sans-serif",
  },

  grid: {
    top: 40,
  },

  tooltip: {
    trigger: "axis",
  },

  title: {
    text: "Active devices (hourly)",
    left: "center",
    textStyle: {
      fontSize: 20,
      fontWeight: 400,
      color: textColor.value,
    },
  },

  xAxis: {
    boundaryGap: false,
    data: [],
    axisLine: {
      lineStyle: {
        color: secondaryColor.value,
      },
    },
    axisLabel: {
      color: textColor.value,
    },
    splitLine: {
      show: true,
      lineStyle: { color: secondaryColor.value },
    },
  },
  yAxis: {
    type: "value",
    axisLabel: {
      color: textColor.value,
      formatter: (val: number) => `${val / 1000}K`,
    },
    splitLine: {
      show: true,
      lineStyle: { color: secondaryColor.value },
    },
  },

  series: [
    {
      name: "Active devices",
      type: "line",
      data: [],
      smooth: true,
      symbolSize: 0,
      itemStyle: { color: theme.current.value.colors.primary },
      areaStyle: {
        color: new graphic.LinearGradient(0, 0, 0, 1, [
          {
            offset: 0,
            color: "rgba(254,221,16, 0.5)",
          },
          {
            offset: 1,
            color: "rgba(254,221,16, 0.1)",
          },
        ]),
      },
    },
    {
      name: "Active devices last year",
      type: "line",
      data: [],
      smooth: true,
      symbolSize: 0,
      itemStyle: { color: "#f24911" },
      areaStyle: {
        color: new graphic.LinearGradient(0, 0, 0, 1, [
          { offset: 0, color: "rgba(244,66,54, 0.5)" },
          { offset: 1, color: "rgba(25,25,25, 0.1)" },
        ]),
      },
    },
  ],
});

watch(isDark, () => {
  if (!Array.isArray(option.value.title) && option.value.title?.textStyle) {
    option.value.title.textStyle.color = textColor.value;
  }

  if (!Array.isArray(option.value.xAxis)) {
    if (option.value.xAxis?.axisLabel) {
      option.value.xAxis.axisLabel.color = textColor.value;
    }

    if (option.value.xAxis?.axisLine?.lineStyle) {
      option.value.xAxis.axisLine.lineStyle.color = secondaryColor.value;
    }

    if (option.value.xAxis?.splitLine?.lineStyle) {
      option.value.xAxis.splitLine.lineStyle.color = secondaryColor.value;
    }
  }

  if (!Array.isArray(option.value.yAxis)) {
    if (option.value.yAxis?.axisLabel) {
      option.value.yAxis.axisLabel.color = textColor.value;
    }

    if (option.value.yAxis?.splitLine?.lineStyle) {
      option.value.yAxis.splitLine.lineStyle.color = secondaryColor.value;
    }
  }
});
</script>

<style scoped>
.chart {
  height: 100%;
  width: 100%;
  flex: 1;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
}

.progress {
  display: flex;
  align-items: center;
  justify-content: center;
}

.progress :deep(.v-overlay__content) {
  width: 25%;
  margin-bottom: 55px;
}
</style>
