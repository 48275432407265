<template>
  <div>
    <div class="d-block mt-4">
      <v-select
        attach
        v-model="period"
        :items="periodOptions"
        variant="outlined"
        density="compact"
        class="flex-grow-0"
        style="min-width: 150px"
        hide-details
        label="Time window"
      />
    </div>
    <div class="d-block pt-4">
      <div>Studio API requests</div>
      <div class="user-activity-resp-status-chart-wrap">
        <div v-if="showChart || loading" class="user-activity-resp-status-chart">
          <Overlay :show="loading" :loading="loading" />
          <v-chart
            ref="chart"
            :key="option.series && (option.series as []).length"
            :option="option"
            autoresize
            :class="{ 'opacity-50': loading }"
          />
        </div>

        <div v-else class="text-center text-body-2 text--disabled mt-2">No data available</div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import User from "@/types/User";
import axios, { CancelTokenSource } from "axios";
import { CanvasRenderer } from "echarts/renderers";
import {
  TitleComponentOption,
  TitleComponent,
  TooltipComponent,
  TooltipComponentOption,
  LegendComponent,
  LegendComponentOption,
  GridComponent,
  GridComponentOption,
} from "echarts/components";
import VChart from "vue-echarts";
import { LineChart, LineSeriesOption } from "echarts/charts";
import { use, ComposeOption } from "echarts/core";
import moment from "moment";
import userResource from "@/resources/UserResource";
import { useTheme } from "vuetify";
import { ref, computed, watch, onMounted, onUnmounted } from "vue";
import Overlay from "@/components/common/Overlay.vue";

use([CanvasRenderer, LineChart, TitleComponent, TooltipComponent, LegendComponent, GridComponent]);
type ECOption = ComposeOption<
  LineSeriesOption | TitleComponentOption | TooltipComponentOption | LegendComponentOption | GridComponentOption
>;

const props = withDefaults(defineProps<{ readonly modelValue: User | null }>(), {
  modelValue: null,
});

const primaryColor = useTheme().current.value.colors.primary;
const isDark = useTheme().global?.current?.value?.dark;

const showChart = ref(false);
let cancelToken: CancelTokenSource | undefined = undefined;
const loading = ref(false);
const period = ref(7);

const periodOptions = [
  { title: "Last 7 days", value: 7 },
  { title: "Last 30 days", value: 30 },
  { title: "Last 90 days", value: 90 },
];

const textColor = computed(() => (isDark ? "#fff" : "#333"));

const barTextColor = computed(() => (isDark ? "#fedd10" : "#333"));

const secondaryColor = computed(() => (isDark ? "#333" : "#ccc"));

const option = ref<ECOption>({
  textStyle: {
    fontFamily: "Roboto, sans-serif",
  },

  grid: { top: 30, bottom: 30 },

  tooltip: {
    trigger: "axis",
    confine: true,
    formatter: (args: any) => {
      let tooltip = `<div>${moment(args[0].axisValue).format("D MMM, YYYY")}</div> `;

      args.forEach(({ value }: any) => {
        tooltip += `<div class="w-100 text-right">
                        <b>${value[1] || 0}</b>
                      </div>`;
      });

      return tooltip;
    },
    axisPointer: {
      type: "line",
    },
  },

  xAxis: {
    type: "time",
    axisLine: {
      lineStyle: {
        color: secondaryColor.value,
      },
    },
    axisLabel: {
      color: textColor.value,
    },
    splitLine: {
      show: true,
      lineStyle: { color: secondaryColor.value },
    },
  },

  yAxis: {
    type: "value",
    alignTicks: true,
    minInterval: 1,
    axisLabel: { color: textColor.value },
    splitLine: {
      show: true,
      lineStyle: { color: secondaryColor.value },
    },
  },

  series: [],
  animation: false,
});

watch(period, () => {
  if (period.value) getData();
});

onMounted(() => {
  getData();
});

onUnmounted(() => {
  // Cancel existing request
  if (cancelToken) {
    cancelToken.cancel();
  }
});

const getData = () => {
  // Cancel existing request
  if (cancelToken) {
    cancelToken.cancel();
  }

  setTimeout(() => {
    // Timeout is workaround for finaly() being executed after request was canceled and new request already began
    loading.value = true;
    cancelToken = axios.CancelToken.source();

    if (!props.modelValue) return;

    userResource
      .getApiUserActivity(props.modelValue.userId, period.value, cancelToken)
      .then((resp) => {
        const data = resp.data;
        showChart.value = Object.values(data).length > 0;

        option.value.series = [
          {
            type: "line",
            areaStyle: { color: primaryColor },
            lineStyle: { color: primaryColor },
            showSymbol: false,
            dimensions: [{ type: "time" }, { type: "number" }],
            data: Object.entries(data),
          },
        ];
      })
      .catch(userResource.defaultErrorHandler)
      .finally(() => {
        loading.value = false;
        cancelToken = undefined;
      });
  }, 10);
};
</script>

<style scoped>
.user-activity-resp-status-chart-wrap {
  width: 100%;
  flex: 1;
}
.user-activity-resp-status-chart {
  height: 500px;
  max-height: 70dvh;
  width: 100%;
  flex: 1;
  position: relative;
  align-items: center;
  justify-content: center;
}
</style>
