<template>
  <v-container fluid>
    <v-card>
      <v-card-title class="d-block d-sm-flex"> Global advert settings </v-card-title>
      <v-card-subtitle> The settings below are global and affect all ads and all devices. </v-card-subtitle>
      <v-card-text v-if="settings">
        <v-sheet max-width="500">
          <PropEditor
            name="Message of the day"
            desc="ID of the advert that will be used as the message of the day."
            titleSize="large"
          >
            <v-text-field
              v-model="settings.messageOfTheDayId"
              density="compact"
              variant="outlined"
              :width="150"
            ></v-text-field>
          </PropEditor>
          <PropEditor
            name="Maximum adverts per day"
            desc="Sets the maximum number of adverts each device can display per day."
            titleSize="large"
          >
            <v-text-field
              v-model="settings.maxAdvertsPerDay"
              density="compact"
              variant="outlined"
              :width="150"
            ></v-text-field>
          </PropEditor>
          <PropEditor
            name="Minimum time between adverts"
            desc="Sets the minimum number of minutes between adverts."
            titleSize="large"
          >
            <v-text-field
              v-model="settings.minTimeBetweenAdverts"
              density="compact"
              variant="outlined"
              :width="150"
            ></v-text-field>
          </PropEditor>

          <v-btn color="primary" class="mt-8" @click="saveSettings" :loading="loading" :disabled="loading"
            >Save settings
          </v-btn>
        </v-sheet>
      </v-card-text>
    </v-card>
  </v-container>
</template>

<script setup lang="ts">
import AdvertSettings from "@/types/AdvertSettings";
import PropEditor from "../components/layout/PropEditor.vue";
import advertResource from "@/resources/AdvertResource";
import infoMessageService from "@/services/InfoMessageService";
import { InfoMessageType } from "@/types/InfoMessageType";
import { ref, onMounted } from "vue";

const settings = ref<AdvertSettings | null>(null);
const loading = ref(false);

const getSettings = () => {
  loading.value = true;

  advertResource
    .getAdvertSettings()
    .then((resp) => {
      settings.value = resp.data;
    })
    .catch(advertResource.defaultErrorHandler)
    .finally(() => {
      loading.value = false;
    });
};

const saveSettings = () => {
  if (!settings.value) return;

  loading.value = true;

  advertResource
    .updateAdvertSettings(settings.value)
    .then(() => {
      infoMessageService.show(InfoMessageType.Success, `Global advert settings updated`);
      getSettings();
    })
    .catch(advertResource.defaultErrorHandler);
};

onMounted(() => {
  getSettings();
});
</script>
