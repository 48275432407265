<template>
  <form @submit.prevent="verify" class="form-auth" method="post">
    <h3 class="form-auth-heading"><img src="/favicon.png" alt="Safedrive" />Safedrive</h3>

    <p v-if="isEmailVerification">Please enter the code we sent to your email.</p>
    <p v-else>Please enter the code from the SMS we just sent you.</p>

    <p v-if="showLockoutWarning" class="text-red">
      This is your last attempt. Your account will be locked if you fail to provide correct verification code.
    </p>

    <div class="input-field">
      <v-text-field v-model="code" type="text" label="Code" required autocomplete="off" />
    </div>
    <v-btn class="submit-button mb-5" type="submit" :loading="loading" :disabled="loading"> Verify </v-btn>

    <p v-if="isEmailVerification" class="link-container">
      Didn't get the code?<br />Check SPAM folder or try <a class="link-btn" @click.prevent="resendCode">sending again</a>.
    </p>
    <p v-else class="link-container">
      Didn't get the code? Try
      <a class="link-btn" @click.prevent="resendCode">sending again</a>.
    </p>

    <p class="link-container">
      <router-link class="link-btn" to="/login">Back to login page</router-link>
    </p>
  </form>
</template>

<script setup lang="ts">
import authService from "@/services/AuthService";
import { MfaType } from "@/types/MfaType";
import authResource from "@/resources/AuthResource";
import router from "@/router/router";
import infoMessageService from "@/services/InfoMessageService";
import { InfoMessageType } from "@/types/InfoMessageType";
import { useRoute } from "vue-router";
import { ref, onMounted } from "vue";

const showLockoutWarning = ref(false);
const loading = ref(false);

const isEmailVerification = Number(useRoute().query.type) === MfaType.email;

const code = ref("");

const username = ref("");

onMounted(() => {
  const authInfo = authService.authInfo;
  if (!authInfo.username) {
    router.push("/login");
  } else {
    username.value = authInfo.username;
  }
});

const resendCode = () => {
  loading.value = true;
  authResource
    .resendCode(username.value)
    .then(() => {
      infoMessageService.show(InfoMessageType.Success, "Verification code sent");
    })
    .catch(authResource.defaultErrorHandler)
    .finally(() => (loading.value = false));
};

const verify = () => {
  if (!code.value) return;

  loading.value = true;
  authResource
    .verify(code.value, username.value)
    .then((resp) => {
      if (resp.data.token) {
        // Sign in
        authService.signIn(resp.data.token, resp.data.expires!);
      } else {
        // Wrong code
        infoMessageService.show(InfoMessageType.Error, "Oops, wrong code!");
        if (resp.data.isLastAttempt) {
          showLockoutWarning.value = true;
        }
      }
    })
    .catch(authResource.defaultErrorHandler)
    .finally(() => (loading.value = false));
};
</script>

<style scoped></style>
