<template>
  <div>
    <div v-if="canAddComment">
      <div class="text-subtitle-1 mb-2">Add comment</div>
      <AddIssueComment v-on:created="addComment" />
    </div>

    <div v-if="comments && comments.length">
      <div class="text-subtitle-1 mt-6 mb-2">Comments</div>

      <div v-for="comment in comments" v-bind:key="comment.commentId">
        <IssueComment :comment="comment" v-on:delete="deleteComment" v-on:update="updateComment" />
      </div>

      <div class="d-flex justify-center">
        <v-btn v-if="!loading && moreAvailable" class="my-2" variant="text" size="small" @click="getComments">
          <v-icon start>mdi-arrow-down-bold</v-icon>
          Load more
        </v-btn>

        <v-progress-circular v-if="loading" indeterminate color="primary" size="30" class="my-2" />
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import Comment from "@/types/Comment";
import { UserPermissionType } from "@/types/UserPermissionType";
import AddIssueComment from "@/components/issues/AddIssueComment.vue";
import IssueComment from "@/components/issues/IssueComment.vue";
import userProfileService from "@/services/UserProfileService";
import issueResource from "@/resources/IssueResource";
import { useRoute } from "vue-router";
import { ref, computed, onMounted } from "vue";

const route = useRoute();

const comments = ref<Comment[]>([]);
const loading = ref(false);
const moreAvailable = ref(false);

const canAddComment = computed(() => userProfileService.hasPermission(UserPermissionType.ViewIssues));

const getComments = () => {
  if (comments.value.length && !moreAvailable.value) {
    return;
  }
  loading.value = true;
  let createdBefore;

  if (comments.value.length) {
    const minDateAsMilliseconds = Math.min(...comments.value.map(({ createdAt }) => new Date(createdAt).getTime()));
    createdBefore = new Date(minDateAsMilliseconds);
  }

  issueResource
    .getIssueComments(Number(route.params.id), undefined, createdBefore)
    .then((resp) => {
      comments.value = [...comments.value, ...resp.data.comments];
      moreAvailable.value = resp.data.moreAvailable;
    })
    .catch(issueResource.defaultErrorHandler)
    .finally(() => {
      loading.value = false;
    });
};

const addComment = (newComment: Comment) => {
  comments.value.unshift(newComment);
};

const updateComment = (updComment: Comment) => {
  comments.value = comments.value.map((comment) => (comment.commentId === updComment.commentId ? updComment : comment));
};

const deleteComment = (deleteCommentId: number) => {
  comments.value = comments.value.filter((comment) => comment.commentId !== deleteCommentId);
};

onMounted(() => {
  getComments();
});
</script>

<style scoped></style>
