<template>
  <v-card @click="open" class="mr-4 mt-4 preview-card" max-width="102">
    <v-img
      :cover="true"
      v-if="FileHelper.isImage(mime) && !isFailedLoad"
      class="img"
      height="100"
      :src="imgUrl"
      @error="showErrIcon"
    />
    <div v-else-if="FileHelper.isImage(mime) && isFailedLoad" class="icon-container">
      <v-icon color="#8e8f90" size="27">mdi-download-off </v-icon>
    </div>

    <div v-else-if="isIcon" class="icon-container">
      <v-icon v-if="FileHelper.isVideo(mime) || FileHelper.isAudio(mime)" color="primary" size="50">mdi-play-circle</v-icon>
    </div>
    <div v-else class="icon-container">
      <div class="text-icon mt-1">{{ textIcon }}</div>
    </div>

    <v-btn @click.stop.prevent="removeFile" class="close-btn" icon v-if="!props.disabled">
      <v-icon size="17"> mdi-close-circle </v-icon>
    </v-btn>

    <div class="tools">
      <v-tooltip location="bottom" color="black" transition="fade" z-index="999999">
        <template v-slot:activator="{ props }">
          <div v-bind="props" class="text-caption text-no-wrap text-truncate text-right px-1">
            {{ name }}
          </div>
        </template>
        <div>Filename: {{ name }}</div>
        <div v-if="!localFileUrl">
          Created: {{ moment(props.file?.createdAt).format("lll") }} by {{ props.file.createdBy }}
        </div>
      </v-tooltip>
    </div>
  </v-card>
</template>

<script setup lang="ts">
import moment from "moment";
import AttachedFile from "@/types/AttachedFile";
import commonHelper from "@/helpers/commonHelper";
import FileHelper from "@/helpers/fileHelper";
import { ref, computed, watch, onUnmounted } from "vue";

const emit = defineEmits(["remove", "open"]);
const props = withDefaults(
  defineProps<{
    readonly file: AttachedFile | (File & { createdAt?: Date; createdBy?: string });
    readonly index: number;
    readonly disabled: boolean;
  }>(),
  { disabled: false }
);

const localFileUrl = ref("");
const isFailedLoad = ref(false);

onUnmounted(() => {
  if (localFileUrl.value) {
    URL.revokeObjectURL(localFileUrl.value);
  }
});

watch(
  () => props.file,
  function onChangeFile() {
    if (localFileUrl.value) {
      URL.revokeObjectURL(localFileUrl.value);
      localFileUrl.value = "";
    }
    if (props.file && props.file instanceof File) {
      localFileUrl.value = URL.createObjectURL(props.file);
    }
    isFailedLoad.value = false;
  },
  { immediate: true }
);

const name = computed(() => {
  return props.file instanceof File ? props.file.name : props.file?.filename;
});

const mime = computed(() => {
  return props.file instanceof File ? props.file.type : props.file?.mime;
});

const isIcon = computed(() => {
  return FileHelper.isVideo(mime.value) || FileHelper.isAudio(mime.value);
});

const textIcon = computed(() => {
  return name.value.substring(name.value.lastIndexOf(".") + 1).toUpperCase();
});

const open = computed(() => {
  if (isFailedLoad.value) return () => {};
  return FileHelper.isAvailableForViewer(mime.value) ? () => emit("open", props.file) : download;
});

const imgUrl = computed(() => {
  return props.file instanceof File
    ? localFileUrl.value
    : `${commonHelper.apiHost}${props.file.url}?width=200&height=200&quality=90`;
});

const showErrIcon = () => {
  isFailedLoad.value = true;
};

const removeFile = () => {
  emit("remove", props.index);
};

const download = () => {
  const a = document.createElement("a");
  if (props.file instanceof File) {
    a.href = localFileUrl.value;
    a.download = name.value;
  } else {
    a.href = `${commonHelper.apiHost}${props.file.downloadUrl}`;
  }

  document.body.appendChild(a);
  a.click();
  document.body.removeChild(a);
};
</script>

<style scoped>
.icon-container {
  width: 100px;
  height: 100px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  padding-bottom: 18px;
  box-sizing: border-box;
}

.close-btn {
  position: absolute;
  right: 0;
  top: 0;
  max-height: 15px;
  max-width: 15px;
  margin: 3px;
}

:root[theme="dark"] .close-btn {
  background: rgba(0, 0, 0, 0.6);
}

:root[theme="light"] .close-btn {
  background: rgba(255, 255, 255, 0.6);
}

.img {
  width: 100px;
  height: 100px;
  object-fit: cover;
}

.preview-card {
  width: 102px;
  height: 102px;
  cursor: pointer;
  position: relative;
}

.preview-card:hover {
  border: 1px solid #fedd10 !important;
}
.preview-card {
  border: 1px solid rgb(var(--v-theme-dark-lighten3));
}

.text-icon {
  font-size: 25px;
  font-weight: 500;
  color: #8e8f90;
}

.tools {
  position: absolute;
  bottom: 0px;
  width: 100%;
  display: flex;
  justify-content: space-between;
  border-radius: 0 3px;
}

:root[theme="dark"] .tools {
  background: rgba(0, 0, 0, 0.6);
}

:root[theme="light"] .tools {
  background: rgba(255, 255, 255, 0.6);
}
</style>
