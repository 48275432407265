<template>
  <div v-bind:model-value="userToEdit">
    <side-sheet
      v-if="userToEdit"
      :modelValue="showDialog"
      @update:modelValue="close"
      @click-outside="close"
      :heading="dialogHeading"
      :noClickAnimation="true"
    >
      <template v-slot:tabs>
        <v-tabs
          v-if="userToEdit && userToEdit.userId"
          v-model="tab"
          grow
          :show-arrows="false"
          color="primary"
          slider-color="primary"
        >
          <v-tab value="user">User</v-tab>
          <v-tab value="activity">Activity</v-tab>
        </v-tabs>
      </template>

      <v-window v-model="tab" :touch="false" class="full-height-tabs-wrap tabs-w-100">
        <!-- USER -->
        <v-tabs-window-item :reverse-transition="false" value="user" transition="none">
          <v-form ref="userForm" v-model="valid" lazy-validation>
            <PropEditor v-if="userToEdit.userId" name="Info">
              <div class="text-subtitle-2">
                <div><span class="info-label">User ID:</span> {{ userToEdit.userId }}</div>
                <div v-if="userToEdit.createdDate">
                  <span class="info-label">Created:</span> {{ moment(userToEdit.createdDate).format("lll") }} ({{
                    userToEdit.createdBy
                  }})
                </div>
                <div v-if="userToEdit.updatedDate">
                  <span class="info-label">Updated:</span> {{ moment(userToEdit.updatedDate).format("lll") }} ({{
                    userToEdit.updatedBy
                  }})
                </div>
                <div v-if="userToEdit.lastLoginDate">
                  <span class="info-label">Last sign in:</span> {{ moment(userToEdit.lastLoginDate).format("lll") }}
                </div>
                <div><span class="info-label">Last sign in IP:</span> {{ userToEdit.lastLoginIp }}</div>
              </div>
              <div>
                <v-btn
                  size="small"
                  color="secondary"
                  class="mt-2"
                  :loading="signOutEverywhereLoading"
                  @click="signOutEverywhere()"
                  >Sign out everywhere</v-btn
                >
              </div>
            </PropEditor>
            <PropEditor
              name="Username"
              desc="Allowed characters: letters, numbers, dash(-), underscore(_)."
              test-id="username-field"
            >
              <v-text-field
                density="compact"
                variant="outlined"
                v-model="userToEdit.username"
                :rules="usernameRules"
              ></v-text-field>
            </PropEditor>
            <PropEditor
              name="Password"
              :desc="
                (userToEdit.userId ? 'Leave empty to keep current password. ' : '') +
                'Minimum 8 chars, at least one lower case char, upper case char and a number (and/or special char).'
              "
            >
              <div class="d-flex">
                <v-text-field
                  density="compact"
                  variant="outlined"
                  v-model="userToEdit.password"
                  :rules="passwordRules"
                  class="mr-4"
                ></v-text-field>
                <v-btn color="secondary" class="align-self-center" @click="generatePassword()">Generate</v-btn>
              </div>
            </PropEditor>
            <PropEditor
              v-if="userToEdit.userId"
              name="Force password update"
              desc="Force user to update their password on next sign in."
            >
              <v-switch v-model="userToEdit.isPasswordUpdateRequired"></v-switch>
            </PropEditor>
            <PropEditor name="Email">
              <v-text-field
                density="compact"
                variant="outlined"
                v-model="userToEdit.email"
                :rules="emailRules"
              ></v-text-field>
            </PropEditor>
            <PropEditor name="Phone number" desc="Phone number with country code. Ex. 4745012345">
              <v-text-field
                density="compact"
                variant="outlined"
                v-model="userToEdit.phoneNumber"
                :rules="phoneRules"
              ></v-text-field>
            </PropEditor>
            <PropEditor name="Active" desc="Active users are able to sign in and use the app.">
              <v-switch v-model="userToEdit.isActive"></v-switch>
            </PropEditor>
            <PropEditor name="Multifactor authentication">
              <v-select
                :list-props="{ density: 'compact' }"
                attach
                v-model="userToEdit.mfaType"
                :items="mfaItems"
                variant="outlined"
                density="compact"
              ></v-select>
            </PropEditor>
            <PropEditor
              name="Administrator"
              desc="Adminisrtators are allowed to access any part of the application and do any changes. User group permissions are ignored."
            >
              <v-switch v-model="userToEdit.isAdministrator"></v-switch>
            </PropEditor>
            <PropEditor v-if="!userToEdit.isAdministrator" name="User groups">
              <v-combobox
                v-model="selectedUserGroups"
                :items="userGroups"
                multiple
                variant="outlined"
                density="compact"
              ></v-combobox>
            </PropEditor>
            <PropEditor
              v-if="userToEdit.userId === 0"
              name="Notify by email"
              desc="Send email notification to new user with link and login credentials."
            >
              <v-switch v-model="notifyNewUserByEmail"></v-switch>
            </PropEditor>
          </v-form>
        </v-tabs-window-item>
        <!-- ACTIVITY -->
        <v-tabs-window-item :reverse-transition="false" value="activity" transition="none">
          <UserActivity v-if="tab === 'activity' && userToEdit" v-model="props.modelValue" />
        </v-tabs-window-item>
      </v-window>

      <template v-slot:actions>
        <v-btn
          v-if="allowDeleteUser && userToEdit.userId"
          color="secondary"
          @click="deleteUserConfirm"
          :loading="deleting"
          :disabled="deleting"
          >Delete</v-btn
        >
        <v-spacer></v-spacer>
        <v-btn variant="text" @click="showDialog = false">Cancel</v-btn>
        <v-btn
          color="primary"
          class="ml-4"
          @click="submit"
          :loading="loading"
          :disabled="loading || disabledSubmitBtn"
          test-id="submit"
          >Submit</v-btn
        >
      </template>
    </side-sheet>
  </div>
</template>

<script setup lang="ts">
import SideSheet from "@/components/layout/SideSheet.vue";
import PropEditor from "@/components/layout/PropEditor.vue";
import User from "@/types/User";
import UserGroup from "@/types/UserGroup";
import userGroupResource from "@/resources/UserGroupResource";
import userResource from "@/resources/UserResource";
import infoMessageService from "@/services/InfoMessageService";
import { InfoMessageType } from "@/types/InfoMessageType";
import userProfileService from "@/services/UserProfileService";
import moment from "moment";
import { MultiFactorAuthType } from "@/types/MultiFactorAuthType";
import UserHelper from "@/helpers/userHelper";
import ChangeManager from "@/services/ChangeManager";
import UserActivity from "@/components/users/UserActivity.vue";
import { VForm } from "vuetify/components";
import { ref, computed, watch, onBeforeMount } from "vue";
import { useComponentQuery } from "@/globalProperties";
import { useConfirm } from "@/services/ConfirmService";

const { setComponentQuery } = useComponentQuery();

const confirm = useConfirm();

const emit = defineEmits(["update:modelValue", "updated"]);

interface ComboboxItem {
  title: string;
  value: string;
}

const changesControl = ref<ChangeManager | null>(null);
const tab = ref<string | null>(null);
const props = withDefaults(defineProps<{ readonly initTab: string | null; modelValue: User | null }>(), {
  initTab: null,
  modelValue: null,
});

const userToEdit = computed(() => {
  return props.modelValue;
});
watch(tab, (val: string | null) => {
  if (userToEdit?.value?.userId) {
    setComponentQuery("userTab", val);
  }
});

watch(
  () => userToEdit.value,
  (newValue: User | null) => {
    if (newValue !== null) {
      tab.value = props.initTab || null;
    } else {
      tab.value = null;
      setComponentQuery("userTab", null);
    }
    setComponentQuery("userId", userToEdit?.value?.userId ? userToEdit.value.userId : null);
  }
);

// begin change management
watch(
  () => userToEdit.value,
  (val: User | null, oldValue: User | null) => {
    changesControl.value = ChangeManager.modalController({
      controller: changesControl.value,
      isNewValue: val && oldValue === null,
      isDestroy: oldValue && val === null,
      isUpdateValue: oldValue && val && oldValue.userId !== val.userId,
      data: { user: val },
      message: "You have unsaved User changes.",
      target: `user_${val?.userId}`,
      onLeave: () => {
        showDialog.value = false;
      },
      onSave: submit,
    });
  }
);

const setChangesStatus = () => {
  if (!userToEdit.value) {
    return;
  }

  const origUser = { ...changesControl.value?.data?.origData?.user };

  if (!changesControl.value || !origUser) return;

  //Password and phone number can be null or an empty string. Set the same type
  if (!origUser.password && !userToEdit.value?.password && userToEdit.value?.hasOwnProperty("password"))
    origUser.password = userToEdit.value?.password;
  if (!origUser.phoneNumber && !userToEdit.value?.phoneNumber && userToEdit.value?.hasOwnProperty("phoneNumber"))
    origUser.phoneNumber = userToEdit.value?.phoneNumber;

  if (!ChangeManager.isObjectEqual(origUser, userToEdit.value || {}, { isOrigPartial: true })) {
    changesControl.value?.activate();
    return;
  }

  changesControl?.value.deactivate();
};

watch(() => userToEdit.value, setChangesStatus, { deep: true });
// end change management

const disabledSubmitBtn = computed(() => !ChangeManager.state().isChanged);

const showDialog = computed({
  get() {
    return userToEdit.value != null;
  },
  set(value) {
    emit("update:modelValue", null);
  },
});

const dialogHeading = computed(() => {
  let heading = "";
  if (userToEdit.value) {
    heading = userToEdit.value?.userId ? `${userToEdit.value.username} (ID: ${userToEdit.value.userId})` : "New user";
  }
  return heading;
});

const valid = ref(true);
const loading = ref(false);

const notifyNewUserByEmail = ref(true);

const selectedUserGroups = computed({
  get() {
    if (userToEdit.value != null) {
      return userGroupsToCompoboxItems(userToEdit.value.userGroups);
    } else {
      return [];
    }
  },
  set(value: ComboboxItem[]) {
    if (userToEdit.value != null) {
      userToEdit.value.userGroups = comboboxItemsToUserGroups(value);
    }
  },
});

const userGroups = ref<ComboboxItem[]>([]);

const mfaItems = [
  { title: UserHelper.getMfaTypeDisplayName(MultiFactorAuthType.None), value: MultiFactorAuthType.None },
  { title: UserHelper.getMfaTypeDisplayName(MultiFactorAuthType.Email), value: MultiFactorAuthType.Email },
  { title: UserHelper.getMfaTypeDisplayName(MultiFactorAuthType.Sms), value: MultiFactorAuthType.Sms },
];

const usernameRules = [
  (v: any) => !!v || "Username is required",
  (v: any) => v.length > 2 || "Username must be at least 3 characters long",
  (v: any) => /^[a-zA-Z0-9-_]+$/.test(v) || "Username must be valid",
];
const phoneRules = [(v: string) => !v || /^\d+$/.test(v) || "Phone number should contain digits only."];
const emailRules = [(v: any) => !!v || "E-mail is required", (v: any) => /^\S+@\S+\.\S+$/.test(v) || "E-mail must be valid"];
const passwordRules = ref<((v: any) => any)[]>([]);

const allowDeleteUser = computed(() => userProfileService.currentUser?.userId !== userToEdit.value?.userId);
const deleting = ref(false);

const userForm = ref<InstanceType<typeof VForm> | null>(null);
onBeforeMount(() => {
  passwordRules.value = [(v: any) => !!(userToEdit.value?.userId || v) || "Password is required"];
  userGroupResource
    .getAllUserGroups()
    .then((resp) => {
      userGroups.value = userGroupsToCompoboxItems(resp.data);
    })
    .catch(userGroupResource.defaultErrorHandler);
});

const generatePassword = () => {
  userResource
    .generatePassword(12)
    .then((resp) => {
      userToEdit.value!.password = resp.data;
    })
    .catch(userResource.defaultErrorHandler);
};

const submit = async () => {
  if (userToEdit.value === null) {
    return;
  }

  // Validate form
  const { valid } = await (userForm.value as InstanceType<typeof VForm>).validate();

  if (valid) {
    if (userToEdit.value.userId) {
      // Update user
      loading.value = true;
      userResource
        .updateUser(userToEdit.value)
        .then((resp) => {
          showDialog.value = false;
          emit("updated");
        })
        .catch(userResource.defaultErrorHandler)
        .finally(() => {
          loading.value = false;
        });
    } else {
      // New user
      loading.value = true;

      userResource
        .addUser(userToEdit.value, notifyNewUserByEmail.value)
        .then((resp) => {
          if (resp.data && resp.data.warn) {
            infoMessageService.show(InfoMessageType.Warning, resp.data.warn);
          } else {
            infoMessageService.show(InfoMessageType.Success, "New user created");
          }
          showDialog.value = false;
          emit("updated");
        })
        .catch(userResource.defaultErrorHandler)
        .finally(() => {
          loading.value = false;
        });
    }
  }
};

const deleteUserConfirm = () => {
  if (!allowDeleteUser.value || userToEdit.value == null) {
    return;
  }

  confirm.show(`Delete user '${userToEdit.value.username}'?`).then((confirmed) => {
    if (confirmed) {
      deleteUser();
    }
  });
};

const deleteUser = () => {
  if (!allowDeleteUser.value || userToEdit.value == null) {
    return;
  }

  deleting.value = true;
  userResource
    .deleteUser(userToEdit.value.userId)
    .then((resp) => {
      showDialog.value = false;
      emit("updated");
    })
    .catch(userResource.defaultErrorHandler)
    .finally(() => {
      deleting.value = false;
    });
};

const signOutEverywhereLoading = ref(false);
const signOutEverywhere = () => {
  confirm.show("This user will be forcibly signed out on all devices.").then((resp) => {
    if (resp) {
      signOutEverywhereLoading.value = true;
      userResource
        .signOutEverywhere(userToEdit.value!.userId)
        .then((resp) => {
          infoMessageService.show(
            InfoMessageType.Success,
            `User '${userToEdit.value!.username}' will be singed out on all devices`
          );
        })
        .catch(userResource.defaultErrorHandler)
        .finally(() => {
          signOutEverywhereLoading.value = false;
        });
    }
  });
};

const close = (value: boolean) => {
  if (!value && ChangeManager.state().isChanged) {
    ChangeManager.show();
    return;
  }

  showDialog.value = value;
};

const userGroupsToCompoboxItems = (userGroups: UserGroup[]): ComboboxItem[] => {
  return userGroups.map((userGroup) => {
    return { title: userGroup.name, value: userGroup.userGroupId?.toString() };
  });
};

const comboboxItemsToUserGroups = (items: ComboboxItem[]): UserGroup[] => {
  return items.map((item) => {
    return { userGroupId: parseInt(item.value), name: item.title } as UserGroup;
  });
};
</script>
