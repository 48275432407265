<template>
  <v-btn
    class="mr-2 btn"
    :class="btnStyles"
    :color="`${isThemeDark ? 'secondary' : undefined}`"
    :style="{ width: data.title.length * 7 + 45 + 'px' }"
    size="small"
    @click="setActive"
  >
    <span v-if="!isActive">
      <v-icon class="mr-1" size="16">{{ data.icon }}</v-icon>
      <span> {{ data.title }} </span>
    </span>

    <v-autocomplete
      :loading="loading"
      :modelValue="data.disableMultiple ? [data.selected[0]] : data.selected"
      @update:modelValue="onInput"
      v-if="isActive"
      ref="filterNameRef"
      :items="suggestedItems"
      item-title="text"
      item-value="value"
      return-object
      density="compact"
      :placeholder="data.title"
      class="btn-field"
      hide-details
      :hide-no-data="loading || !isLoaded"
      :multiple="data.disableMultiple ? false : true"
      @keyup.space.stop.prevent
      @blur="blur"
      v-model:search="search"
      :menu-props="{
        modelValue: isOpenMenu,
        contentClass: isDropListFrozen ? 's-custom-disabled-menu-content-filter' : 's-custom-menu-content-filter',
      }"
      @click:append.stop.prevent="blur"
      color="primary"
      variant="underlined"
    >
      <template v-slot:prepend-inner>
        <v-icon :color="isThemeDark ? 'white' : 'dark darken-1'" center class="ml-1 labe-icon" size="18">
          {{ data.icon }}
        </v-icon>
      </template>
      <template v-slot:no-data>
        <v-list-item class="no-data"> {{ `No ${data.title} available` }} </v-list-item>
      </template>
      <template v-slot:selection=""> </template>
      <template v-slot:item="{ item, props }">
        <v-list-item
          :disabled="isDropListFrozen"
          density="compact"
          :ripple="false"
          v-bind="{ ...props, title: undefined }"
          class="list-item"
          :class="{
            'list-item-mobile': breakpoint.xs.value,
            delimiterItem: (data.dividerValues || []).includes(item.value) && !search,
          }"
          active-class="active-item"
        >
          <template v-slot:prepend="{ isActive }">
            <v-list-item-action class="mr-2 my-0">
              <v-checkbox-btn density="compact" hide-details color="primary" :model-value="isActive"></v-checkbox-btn>
            </v-list-item-action>
          </template>
          <v-list-item-title density="compact" class="text-body-2">{{ item.title }}</v-list-item-title>

          <v-divider v-if="(data.dividerValues || []).includes(item.value) && !search" class="delimiter" />
        </v-list-item>
      </template>
      <template v-slot:append-item="">
        <div class="footer">
          <v-divider></v-divider>
          <v-list-item :disabled="isDropListFrozen" :ripple="false" density="compact" @click="blur" class="close-menu-btn">
            <v-list-item-title class="text-body-2">Close</v-list-item-title>
          </v-list-item>
        </div>
      </template>
    </v-autocomplete>
  </v-btn>
</template>

<script setup lang="ts">
import axios, { CancelTokenSource } from "axios";
import TableFilter from "@/types/TableFilter";
import { useDisplay, useTheme } from "vuetify";
import { ref, computed, watch, onActivated, onDeactivated } from "vue";
import { VCombobox } from "vuetify/lib/components/index.mjs";

const props = withDefaults(
  defineProps<{
    readonly data: TableFilter;
    readonly isDropListFrozen: boolean;
  }>(),
  {
    isDropListFrozen: false,
  }
);

const search = ref<string | undefined>(undefined);
const isActive = ref(false);
const suggestedItems = ref<TableFilter["selected"][]>([]);
let searchThrottleTimer = 0;
let cancelToken: CancelTokenSource | undefined = undefined;
const loading = ref(false);
const isAnimationCompleted = ref(false);
const isLoaded = ref(false);
let openMenuTimeout = 0;
const componentActive = ref(true);

onActivated(() => {
  componentActive.value = true;
});

onDeactivated(() => {
  componentActive.value = false;
});

const breakpoint = useDisplay();
const theme = useTheme();

const isThemeDark = computed(() => theme?.global?.current?.value?.dark);

const btnStyles = computed(() => ({
  "btn-active": isActive.value,
  "btn-active-mobile": breakpoint.xs.value,
  "btn-transition": !breakpoint.xs.value,
}));

const isOpenMenu = computed(() => componentActive.value && isAnimationCompleted.value && !loading.value && isLoaded.value);

const filterNameRef = ref<InstanceType<typeof VCombobox> | null>(null);
watch(search, function onSearchChanged() {
  if (!search.value?.trim() && search.value && filterNameRef.value) {
    (filterNameRef.value as any).internalSearch = "";
    return;
  }

  if (!props.data.searchable) {
    return getFastData();
  }

  clearSearchTimeout();
  loading.value = true;

  searchThrottleTimer = setTimeout(() => getData(), 1000);
});

const onInput = (value: { text: string; value: any }[] | { text: string; value: any } | null) => {
  if (value === null) return
  if (Array.isArray(value)) {
    props.data.selected = value;
  } else {
    if (props.data.selected.find((item) => item.value === value.value)) {
      props.data.selected = [];
      return;
    }

    props.data.selected = value ? [value] : [];
  }
};
const clearSearchTimeout = () => {
  if (searchThrottleTimer) {
    clearTimeout(searchThrottleTimer);
    searchThrottleTimer = 0;
  }
};

const getFastData = async () => {
  const searchName = search.value === null ? undefined : search.value;
  const data = await props.data.itemsCallback(searchName, cancelToken);
  if (data) {
    suggestedItems.value = data;
    if (!isLoaded.value) isLoaded.value = true;
  }
  if (!data) {
    suggestedItems.value = [];
  }
};

const getData = () => {
  // Cancel existing request
  if (cancelToken) {
    cancelToken.cancel();
  }

  setTimeout(async () => {
    // Timeout is workaround for finally() being executed after request was canceled and new request already began
    cancelToken = axios.CancelToken.source();
    const searchName = search.value;
    const data = await props.data.itemsCallback(searchName, cancelToken);

    if (data) {
      suggestedItems.value = data;
      if (!isLoaded.value) isLoaded.value = true;
    }
    if (!data) {
      suggestedItems.value = [];
    }
    loading.value = false;
    cancelToken = undefined;
  }, 10);
};

const blur = () => {
  if (props.isDropListFrozen) {
    if (filterNameRef.value?.focus) {
      filterNameRef.value.focus();
    }
    return;
  }
  if (openMenuTimeout) {
    clearTimeout(openMenuTimeout);
  }
  isAnimationCompleted.value = false;
  setTimeout(() => (isActive.value = false), 100);
};

const setActive = async () => {
  if (isActive.value) return;
  isActive.value = true;
  if (!suggestedItems.value.length) {
    getData();
  }

  setTimeout(() => {
    if (filterNameRef.value) {
      filterNameRef.value.click();

      //@ts-ignore
      filterNameRef.value.menu = true;
    }
  }, 300);
  openMenuTimeout = setTimeout(() => (isAnimationCompleted.value = true), 400);
};
</script>

<style scoped>
.btn {
  margin: 5px;
  text-transform: none;
  max-width: 100%;
  overflow: hidden;
  display: flex;
}

.btn:focus:before {
  opacity: 0 !important;
}

.btn-transition {
  transition: all 0.3s ease;
}

.btn-active {
  width: 250px !important;
  max-width: 100%;
  padding: 0 !important;
}

.btn-active :deep(.v-btn__content) {
  width: 250px !important;
  max-width: 100%;
  padding: 0 !important;
  flex: 1;
}

.btn-active-mobile {
  margin-left: 0 !important;
  margin-right: 0 !important;
  width: 100% !important;
}

.btn-field {
  width: 100% !important;
}

.btn-field :deep(input) {
  width: 100%;
  font-size: 13px !important;
  padding: 0 !important;
  padding-bottom: 4px !important;
}

.btn-field :deep(.v-field) {
  padding-left: 0;
  padding-right: 0;
}

.btn-field:deep(.v-field__append-inner) {
  display: none;
}

.labe-icon {
  margin-bottom: 3px;
}

.no-data {
  font-size: 13px;
  min-height: 20px;
}

.list-item {
  font-size: 14px;
  font-weight: 500;
  max-width: 239px;

  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.list-item-mobile {
  max-width: 100% !important;
}

.close-menu-btn {
  text-align: right;
  font-size: 13px;
  margin-top: 5px;
  min-height: 40px !important;
  min-width: 150px;
  color: rgb(var(--v-theme-primary)) !important;
  cursor: pointer;
}

:root[theme="light"] .close-menu-btn {
  color: #ffaa03 !important;
  font-weight: 700 !important;
}

.active-item.v-list-item--active:before,
.v-list-item--highlighted:before {
  opacity: 0.07 !important;
}

.footer {
  position: sticky;
  bottom: -8px;
  background: #2d2d2d;
  width: 100%;
}

:root[theme="dark"] .v-menu > .v-overlay__content > .v-card,
.v-menu > .v-overlay__content > .v-sheet,
.v-menu > .v-overlay__content > .v-theme--dark.v-list {
  background: #2d2d2d !important;
}

:root[theme="light"] .footer {
  background: #fff;
}
</style>

<style>
.s-custom-menu-content-filter,
.s-custom-menu-content-filter .v-theme--dark.v-list {
  background: #2d2d2d;
}

:root[theme="light"] .s-custom-menu-content-filter .v-list {
  background: #fff;
}

.delimiter {
  position: absolute;
  left: 5px;
  right: 5px;
  bottom: -5px;
}

.delimiterItem {
  position: relative;
  margin-bottom: 10px;
  overflow: visible !important;
}
</style>
