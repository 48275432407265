<template>
  <v-container>
    <div v-if="issue" class="issue-container flex-column flex-md-row-reverse">
      <!-- SIDEBAR SECTION -->
      <div class="mt-0 mb-3 ml-md-8 mt-md-12">
        <IssueSideBar :issue="issue" @remove="changesControl.deactivate()" />
      </div>

      <!-- MAIN SECTION -->
      <v-form ref="issueForm" class="issue-form">
        <!-- BACK (desktop only) -->
        <div class="mb-3 d-none d-md-block">
          <v-btn icon variant="text" density="compact" color="primary" @click="back" :disabled="loading">
            <v-icon>mdi-arrow-left-thick</v-icon>
          </v-btn>
        </div>

        <!-- ISSUE -->
        <v-card :loading="loading" :disabled="loading">
          <v-card-text>
            <v-sheet class="d-flex align-center mb-3">
              <div class="issue-title font-weight-normal mr-2">{{ issue.issueId }}</div>

              <v-textarea
                :readonly="!canEditIssues"
                :rules="rules"
                v-model="issue.name"
                @update:modelValue="onUpdateLocalData"
                placeholder="Issue title"
                class="issue-title w-hover title"
                density="compact"
                variant="plain"
                flat
                :hide-details="!!issue.name.trim()"
                single-line
                auto-grow
                rows="1"
                maxlength="120"
                no-resize
              ></v-textarea>
              <v-btn
                variant="text"
                density="compact"
                color="primary"
                class="ml-4"
                icon
                size="large"
                title="Copy id and name to clipboard"
                @click="copyToClipboard"
              >
                <v-icon>mdi-content-copy</v-icon>
              </v-btn>
            </v-sheet>

            <TagsField
              :tags="tags"
              @update="updateTags"
              :canCreate="canAddTags"
              :disabled="!canEditIssues"
              :type="tagType"
              orderBy="issue_count"
              :orderByDesc="true"
            />

            <div class="text-subtitle-2 mt-5">Description</div>
            <v-sheet class="mt-1">
              <RichText
                v-model="issue.description"
                @update:modelValue="onUpdateLocalData"
                :disabled="!canEditIssues"
                :size="true"
                placeholder="Write issue description here..."
              />
            </v-sheet>
            <Attachments
              ref="attachmentsRef"
              :isActionByCommand="true"
              :files="files"
              :targetType="targetType"
              :targetId="issueId"
              v-on:update="updateFiles"
              v-on:updateLocalFiles="updateLocalFiles"
              :disabled="!canEditIssues"
            />

            <div class="text-right mt-4">
              <v-btn
                v-if="canEditIssues"
                color="primary"
                @click="submit"
                class="ml-4"
                :loading="loading"
                :disabled="loading || !changesControl.data || !changesControl.data.isChanged || !isReadyForm"
                size="small"
                >Save</v-btn
              >
            </div>
          </v-card-text>
        </v-card>

        <!-- COMMENTS -->
        <div class="mt-6">
          <IssueComments />
        </div>
      </v-form>
    </div>
  </v-container>
</template>

<script setup lang="ts">
import RichText from "@/components/common/RichText.vue";
import Attachments from "@/components/common/Attachments.vue";
import Issue from "@/types/Issue";
import AttachedFile from "@/types/AttachedFile";
import { AttachmentTargetType } from "@/types/AttachmentTargetType";
import Tag from "@/types/Tag";
import { TagType } from "@/types/TagType";
import { UserPermissionType } from "@/types/UserPermissionType";
import axios, { CancelTokenSource } from "axios";
import issueResource from "@/resources/IssueResource";
import userProfileService from "@/services/UserProfileService";
import infoMessageService from "@/services/InfoMessageService";
import { InfoMessageType } from "@/types/InfoMessageType";
import ChangeManager from "@/services/ChangeManager";
import TagsField from "@/components/common/TagsField.vue";
import IssueComments from "@/components/issues/IssueComments.vue";
import IssueSideBar from "@/components/issues/IssueSideBar.vue";
import { VForm } from "vuetify/components";
import { useRoute, useRouter } from "vue-router";
import { ref, computed, watch } from "vue";

const router = useRouter();

const targetType = AttachmentTargetType.Issue;
let cancelToken: CancelTokenSource | undefined = undefined;
const loading = ref(false);
const issue = ref<Issue | null>(null);
const tagType = TagType.Issue;
const localFiles = ref<File[]>([]);
const changesControl = ref<ChangeManager>(new ChangeManager());

const canEditIssues = computed(
  () => !issue.value?.isArchived && userProfileService.hasPermission(UserPermissionType.EditIssues)
);

const canAddTags = computed(() => userProfileService.hasPermission(UserPermissionType.ViewIssues));

const files = computed(() => issue.value?.attachments || []);

const tags = computed(() => issue.value?.tags || []);
const issueForm = ref<InstanceType<typeof VForm> | null>(null);

const isReadyForm = ref(false);
const issueId = ref(Number(useRoute().params.id));
const attachmentsRef = ref<null | typeof Attachments>(null);

const updated = () => {
  checkForm();
};

const checkForm = async () => {
  if (!issueForm.value) return;
  const { valid } = await issueForm.value.validate();
  isReadyForm.value = valid;
};

const rules = [(v: string) => !!v.trim() || "Title is required"];

const back = () => {
  router.back();
};

const getIssueData = () => {
  // Cancel existing request
  if (cancelToken) {
    cancelToken.cancel();
  }

  if (!issueId.value) {
    return;
  }

  loading.value = true;
  cancelToken = axios.CancelToken.source();
  issueResource
    .getIssueById(issueId.value, cancelToken)
    .then((resp) => {
      const issueResp = resp.data;
      issue.value = issueResp;

      changesControl.value.init({
        target: `issue_${issueResp?.issueId}`,
        onSave: submit,
        message: "You have unsaved Issue changes.",
        isChanged: false,
      });
    })
    .catch(issueResource.defaultErrorHandler)
    .finally(() => {
      loading.value = false;
      cancelToken = undefined;
    });
};

const updateLocalFiles = (files: File[]) => {
  localFiles.value = files;
  checkForm();
  changesControl.value.activate();
};

const updateFiles = (newFiles: AttachedFile[]) => {
  if (issue.value) {
    issue.value.attachments = newFiles;
    checkForm();
    changesControl.value.activate();
  }
};
const submit = () => {
  if (!issue.value) {
    return;
  }
  if (!canEditIssues.value) {
    return;
  }

  if (isReadyForm.value) {
    loading.value = true;
    issueResource
      .updateIssue(issue.value)
      .then(() => attachmentsRef.value?.uploadLocalFiles())
      .then(() => attachmentsRef.value?.removeFiles())
      .then(() => {
        changesControl.value.deactivate();
      })
      .catch(issueResource.defaultErrorHandler)
      .finally(() => {
        loading.value = false;
      });
  }
};

const updateTags = (tags: Tag[]) => {
  // Cancel existing request
  if (cancelToken) {
    cancelToken.cancel();
  }
  if (!canEditIssues.value || !tags || !issue.value) {
    return;
  }
  checkForm();
  changesControl.value.activate();
  issue.value.tags = tags;
};

const copyToClipboard = () => {
  if (issue.value) {
    navigator.clipboard
      .writeText(`${issue.value.issueId}: ${issue.value.name}`)
      .then(() => {
        infoMessageService.show(InfoMessageType.Success, "Copied to clipboard");
      })
      .catch((e) => {
        infoMessageService.show(InfoMessageType.Error, "Failed to copy content");
      });
  }
};

const onUpdateLocalData = () => {
  checkForm();
  changesControl.value.activate();
};

watch(
  issueId,
  function onIssueIdChanged() {
    getIssueData();
  },
  { immediate: true }
);
</script>

<style scoped>
.issue-container {
  display: flex;
  justify-content: center;
  min-height: 100%;
  height: 100%;
  overflow-x: visible;
}

.issue-form {
  width: 100%;
  max-width: 1000px;
}

.issue-title {
  font-weight: 500;
  font-size: 1.2rem;
}

:deep() .v-field__input .v-textarea__sizer {
  display: none;
}

.title :deep(.v-field__input) {
  padding-left: 12px;
  padding-bottom: 3px;
  padding-top: 6px;
  margin-bottom: 3px;
}

:deep() .v-field textarea {
  font-size: 1.2rem;
}
</style>
