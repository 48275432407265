<template>
  <div
    :class="{
      'd-flex d-md-block align-start justify-space-between': true,
      'issue-side-bar-min-width': isSmAndUp,
    }"
  >
    <div class="d-block d-md-none">
      <div class="d-block d-md-none">
        <v-btn density="compact" icon variant="text" color="primary" @click="back">
          <v-icon>mdi-arrow-left-thick</v-icon>
        </v-btn>
      </div>
    </div>

    <div class="d-flex d-md-block align-center flex-row">
      <!-- STATUS -->
      <div class="sb-row">
        <div class="sb-row-title d-none d-md-block">Status</div>
        <div v-if="!props.issue?.isArchived" class="sb-row-content">
          <v-menu offset-y>
            <template v-slot:activator="{ props }">
              <v-btn v-bind="props" :class="statusColor" size="small">
                {{ getIssueStatusName() }}
                <v-icon class="d-none d-md-block ml-1" v-if="canEditIssues && !props.issue?.isArchived" size="small" end
                  >mdi-chevron-down</v-icon
                >
              </v-btn>
            </template>
            <v-list v-if="canEditIssues && !props.issue?.isArchived">
              <v-list-item
                @click="changeStatusConfirm(item.value)"
                v-for="(item, index) in statusList"
                :key="index"
                density="compact"
              >
                <v-list-item-title class="text-uppercase" :class="IssueHelper.getIssueStatusColor(item.value, true)">{{
                  item.text
                }}</v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>
        </div>
        <div v-if="props.issue?.isArchived" class="sb-row-content">
          <v-btn color="primary" size="small" prepend-icon="mdi-alert" readonly> Archived </v-btn>
        </div>
      </div>

      <!-- PRIORITY -->
      <div class="sb-row ml-3 mt-md-4 ml-md-0">
        <div class="sb-row-title d-none d-md-block">Priority</div>
        <div class="sb-row-content">
          <v-menu offset-y>
            <template v-slot:activator="{ props: btnProps }">
              <v-btn variant="text" class="priority px-0 pr-0 pr-md-2" v-bind="btnProps" size="small">
                <v-icon :color="props.issue ? IssueHelper.getIssuePriorityColor(props.issue?.priority) : undefined">
                  {{ props.issue ? IssueHelper.getIssuePriorityIcon(props.issue.priority) : undefined }}
                </v-icon>
                <span class="d-none d-md-block">{{
                  props.issue ? IssueHelper.getIssuePriorityName(props.issue.priority) : ""
                }}</span>
                <v-icon class="d-none d-md-block ml-1" v-if="canEditIssues && !props.issue?.isArchived" size="small" end
                  >mdi-chevron-down</v-icon
                >
              </v-btn>
            </template>

            <v-list v-if="canEditIssues && !props.issue?.isArchived">
              <v-list-item
                class="pl-1"
                @click="setPriority(item.value)"
                v-for="(item, index) in priorityList"
                :key="index"
                density="compact"
              >
                <v-list-item-title class="text-uppercase">
                  <v-icon :color="IssueHelper.getIssuePriorityColor(item.value)">
                    {{ IssueHelper.getIssuePriorityIcon(item.value) }}
                  </v-icon>
                  {{ item.text }}
                </v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>
        </div>
      </div>

      <!-- AFFECTED DEVICES -->
      <div class="sb-row ml-3 mt-md-4 ml-md-0" :class="{ 'mb-md-4': !statusSummary.length }">
        <div class="sb-row-title d-none d-md-block">Affected devices</div>
        <div class="sb-row-content">
          <v-btn
            :loading="totalDeviceLoading"
            color="secondary"
            class="text-primary"
            @click="showAffectedDevices = true"
            size="small"
          >
            {{ totalDevices }} device/s
          </v-btn>
        </div>
      </div>

      <!-- OTHER (will be moved to popup on mobile) -->
      <div class="relative" v-click-outside="() => (showOther ? (showOther = false) : undefined)">
        <v-btn
          icon
          variant="text"
          color="primary"
          class="d-block d-md-none ml-3"
          @click="showOther = !showOther"
          density="compact"
        >
          <v-icon size="28">mdi-dots-vertical</v-icon>
        </v-btn>

        <div
          :class="{
            'sb-other d-none d-md-block': true,
            'show-other v-card v-sheet v-theme--dark pa-4': showOther,
          }"
        >
          <div v-if="statusSummary.length" class="sb-row mb-4 mt-md-2">
            <div class="sb-row-title align-self-start"><span v-if="showOther">Device Statuses</span></div>
            <div class="sb-row-content">
              <div>
                <v-tooltip location="bottom" transition="fade-transition" v-for="sum in statusSummary" :key="sum.status">
                  <template v-slot:activator="{ props }">
                    <span v-bind="props">
                      <v-badge
                        :color="(IssueHelper.getDeviceStatusColor(sum.status, true) as string)"
                        :content="sum.count"
                        inline
                        class="cursor-default"
                      ></v-badge>
                    </span>
                  </template>
                  <span>{{ getDeviceStatusName(sum.status) }}</span>
                </v-tooltip>
              </div>
              <div class="mt-2" @click.capture="showOther = false">
                <DeviceStats :issue="props.issue" />
              </div>
            </div>
          </div>

          <div class="sb-row mb-4">
            <div class="sb-row-title">Reporter</div>
            <div class="sb-row-content">
              {{ props.issue?.createdBy }}
            </div>
          </div>

          <v-divider />

          <div class="text-grey text-caption mt-2">
            <span class="font-weight-bold mr-2">Created</span>
            {{ `${moment(props.issue?.createdAt).format("lll")} by ${props.issue?.createdBy}` }}

            <div v-if="props.issue?.lastModifiedAt">
              <span class="font-weight-bold mr-2 mt-2">Updated</span>
              {{ `${moment(props.issue.lastModifiedAt).format("lll")} by ${props.issue.lastModifiedBy}` }}
            </div>
          </div>

          <div class="text-right">
            <v-btn
              v-if="canEditIssues && canDeleteIssue && !props.issue?.isArchived"
              @click="archiveIssueConfirm"
              :loading="archiving"
              :disabled="archiving"
              variant="text"
              size="small"
              color="primary"
              class="mt-4 mr-2"
            >
              Archive issue
            </v-btn>
            <v-btn
              v-if="canEditIssues && props.issue?.isArchived"
              @click="restoreIssueConfirm"
              :loading="archiving"
              :disabled="archiving"
              variant="text"
              size="small"
              color="primary"
              class="mt-4 mr-2"
            >
              Restore issue
            </v-btn>
            <v-btn
              v-if="canDeleteIssue"
              @click="deleteIssueConfirm"
              :loading="deleting"
              :disabled="deleting"
              variant="text"
              size="small"
              color="red"
              class="mt-4"
              test-id="delete-issue-btn"
            >
              Delete issue
            </v-btn>
          </div>
          <IssueAffectedDevices
            :statusSummary="statusSummary"
            v-model="showAffectedDevices"
            :issue="props.issue || undefined"
            @changeTotalDevices="updateDeviceTotal"
            @changeStatus="getTotalDevices"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import Issue from "@/types/Issue";
import IssueAffectedDevices from "@/components/issues/IssueAffectedDevices.vue";
import DeviceStats from "@/components/issues/DeviceStats.vue";
import { UserPermissionType } from "@/types/UserPermissionType";
import userProfileService from "@/services/UserProfileService";
import { IssueStatus } from "@/types/IssueStatus";
import { IssueDeviceStatus } from "@/types/IssueDeviceStatus";
import { IssuePriority } from "@/types/IssuePriority";
import issueResource from "@/resources/IssueResource";
import infoMessageService from "@/services/InfoMessageService";
import { InfoMessageType } from "@/types/InfoMessageType";
import axios, { CancelTokenSource } from "axios";
import IssueHelper from "@/helpers/issueHelper";
import moment from "moment";
import { useDisplay } from "vuetify";
import { useRouter } from "vue-router";
import { useConfirm } from "@/services/ConfirmService";
import { ref, computed, watch } from "vue";

const emit = defineEmits(["remove"]);
const props = withDefaults(defineProps<{ issue: Issue | null }>(), {
  issue: null,
});

let cancelToken: CancelTokenSource | undefined = undefined;
let cancelArchiveToken: CancelTokenSource | undefined = undefined;
let cancelStatusToken: CancelTokenSource | undefined = undefined;
let cancelPriorityToken: CancelTokenSource | undefined = undefined;
const showAffectedDevices = ref(false);
const deleting = ref(false);
const archiving = ref(false);
const totalDeviceLoading = ref(false);
const statusSummary = ref<{ status: IssueDeviceStatus; count: number }[]>([]);
const totalDevices = ref(0);
const showOther = ref(false);

const isSmAndUp = useDisplay().smAndUp;
const router = useRouter();
const confirm = useConfirm();

const back = () => {
  router.back();
};

const priorityList = computed(() => {
  return Object.values(IssuePriority)
    .filter((v) => !isNaN(Number(v)))
    .map((v) => ({
      text: IssueHelper.getIssuePriorityName(v as number),
      value: v as number,
    }));
});

const statusList = computed(() => {
  return Object.values(IssueStatus)
    .filter((v) => !isNaN(Number(v)))
    .map((v) => ({
      text: IssueHelper.getIssueStatusDisplayName(v as number),
      value: v as number,
    }));
});

const statusColor = computed(() => {
  return IssueHelper.getIssueStatusColor(props.issue?.status);
});

const canEditIssues = computed(() => {
  return userProfileService.hasPermission(UserPermissionType.EditIssues);
});

const canDeleteIssue = computed(() => {
  return userProfileService.hasPermission(UserPermissionType.DeleteIssues) && props.issue;
});

const getIssueStatusName = () => {
  if (!props.issue) return;
  return IssueHelper.getIssueStatusDisplayName(props.issue?.status);
};

const getDeviceStatusName = (status: IssueDeviceStatus) => {
  return IssueHelper.getDeviceStatusDisplayName(status);
};

const updateDeviceTotal = (difference: number) => {
  totalDevices.value = totalDevices.value + difference;
  getTotalDevices();
};

const getTotalDevices = () => {
  if (!props.issue) {
    return;
  }

  totalDeviceLoading.value = true;

  issueResource
    .getIssueDevicesSummary(props.issue.issueId)
    .then((resp) => {
      totalDevices.value = resp.data.totalAffectedDevices;
      statusSummary.value = resp.data.statusSummary.filter((v) => v.count).sort((a, b) => a.status - b.status);
    })
    .catch(issueResource.defaultErrorHandler)
    .finally(() => {
      totalDeviceLoading.value = false;
    });
};

const setPriority = (newPriority: IssuePriority) => {
  if (!props.issue) return;
  const previousStatus = props.issue?.status;
  props.issue.priority = newPriority;

  // Cancel existing request
  if (cancelPriorityToken) {
    cancelPriorityToken.cancel();
  }

  if (!canEditIssues.value || !props.issue) {
    return;
  }

  setTimeout(() => {
    if (!props.issue) return;
    // Timeout is workaround for finaly() being executed after request was canceled and new request already began
    cancelPriorityToken = axios.CancelToken.source();
    issueResource
      .patchIssue(props.issue?.issueId, { priority: newPriority }, cancelPriorityToken)
      .catch((e) => {
        issueResource.defaultErrorHandler(e);
        if (!props.issue) return;
        props.issue.status = previousStatus;
      })
      .finally(() => {
        cancelPriorityToken = undefined;
      });
  }, 10);
};

const changeStatusConfirm = (newStatus: IssueStatus) => {
  if (!canEditIssues.value || !props.issue) {
    return;
  }

  const isAllDevicesResolved = statusSummary.value.every(
    ({ status, count }) => status === IssueDeviceStatus.Resolved || !count
  );
  if (!statusSummary.value.length || isAllDevicesResolved) {
    if (newStatus === props.issue.status) {
      return;
    }
    return setStatus(newStatus);
  }

  if (newStatus !== IssueStatus.Closed) {
    return setStatus(newStatus);
  }

  confirm
    .show(`Do you want to update status of all linked devices to Resolved?`, {
      width: 525,
      persistent: true,
      buttonTrueText: "Yes",
      buttonFalseText: "No",
      buttonCancelText: "Cancel",
    })
    .then((result) => {
      if (result === null) {
        return;
      }

      if (result) {
        setStatus(newStatus, true);
        return;
      }
      setStatus(newStatus, false);
    });
};
const setStatus = (newStatus: IssueStatus, resolveLinkedDevices: boolean = false) => {
  // Cancel existing request
  if (cancelStatusToken) {
    cancelStatusToken.cancel();
  }

  if (!canEditIssues.value || !props.issue) {
    return;
  }

  const previousStatus = props.issue.status;
  props.issue.status = newStatus;

  setTimeout(() => {
    if (!props.issue) return;
    // Timeout is workaround for finaly() being executed after request was canceled and new request already began
    cancelStatusToken = axios.CancelToken.source();
    issueResource
      .patchIssue(
        props.issue?.issueId,
        { status: newStatus },
        cancelStatusToken,
        resolveLinkedDevices ? { resolveLinkedDevices } : undefined
      )
      .then(() => {
        if (resolveLinkedDevices) {
          getTotalDevices();
        }
      })
      .catch((e) => {
        issueResource.defaultErrorHandler(e);
        if (!props.issue) return;
        props.issue.status = previousStatus;
      })
      .finally(() => {
        cancelStatusToken = undefined;
      });
  }, 10);
};

const archiveIssueConfirm = () => {
  if (!props.issue) return;
  if (!canDeleteIssue.value || !canEditIssues.value || props.issue == null) {
    return;
  }

  confirm.show(`Archive issue  '${props.issue.name}'? `).then((confirmed) => {
    if (confirmed) {
      archiveIssue();
    }
  });
};

const archiveIssue = () => {
  if (!canDeleteIssue.value || !canEditIssues.value || props.issue?.isArchived) {
    return;
  }
  // Cancel existing request
  if (cancelArchiveToken) {
    cancelArchiveToken.cancel();
  }
  archiving.value = true;
  cancelArchiveToken = axios.CancelToken.source();

  setTimeout(() => {
    if (!props.issue) return;
    // Timeout is workaround for finaly() being executed after request was canceled and new request already began
    cancelStatusToken = axios.CancelToken.source();
    issueResource
      .archiveIssue(props.issue?.issueId, cancelArchiveToken)
      .then(() => {
        if (!props.issue) return;
        props.issue.isArchived = true;
      })
      .catch((e) => {
        issueResource.defaultErrorHandler(e);
      })
      .finally(() => {
        archiving.value = false;
        cancelArchiveToken = undefined;
      });
  }, 10);
};

const restoreIssueConfirm = () => {
  if (!props.issue?.isArchived) return;
  if (!canDeleteIssue.value || !canEditIssues.value || props.issue == null) {
    return;
  }

  confirm.show(`Restore issue  '${props.issue.name}'? `).then((confirmed) => {
    if (confirmed) {
      restoreIssue();
    }
  });
};

const restoreIssue = () => {
  if (!canDeleteIssue.value || !canEditIssues.value || !props.issue?.isArchived) {
    return;
  }
  // Cancel existing request
  if (cancelArchiveToken) {
    cancelArchiveToken.cancel();
  }
  archiving.value = true;
  cancelArchiveToken = axios.CancelToken.source();

  setTimeout(() => {
    if (!props.issue) return;
    // Timeout is workaround for finaly() being executed after request was canceled and new request already began
    cancelStatusToken = axios.CancelToken.source();
    issueResource
      .restoreIssue(props.issue.issueId, cancelArchiveToken)
      .then(() => {
        if (!props.issue) return;
        props.issue.isArchived = false;
      })
      .catch((e) => {
        issueResource.defaultErrorHandler(e);
      })
      .finally(() => {
        archiving.value = false;
        cancelArchiveToken = undefined;
      });
  }, 10);
};

const deleteIssueConfirm = () => {
  if (!canDeleteIssue.value || props.issue == null) {
    return;
  }

  confirm.show(`Delete '${props.issue.name}' issue?`).then((confirmed) => {
    if (confirmed) {
      deleteIssue();
    }
  });
};

const deleteIssue = () => {
  if (!canDeleteIssue.value || !props.issue) {
    return;
  }

  // Cancel existing request
  if (cancelToken) {
    cancelToken.cancel();
  }
  deleting.value = true;
  cancelToken = axios.CancelToken.source();
  issueResource
    .deleteIssue(props.issue?.issueId, cancelToken)
    .then(() => {
      infoMessageService.clear();
      infoMessageService.show(InfoMessageType.Success, `Issue '${props.issue?.name}' deleted`);
      emit("remove");
      router.push(`/support/issues`);
    })
    .catch(issueResource.defaultErrorHandler)
    .finally(() => {
      deleting.value = false;
      cancelToken = undefined;
    });
};

watch(
  () => props.issue,
  function onIssueChanged() {
    if (props.issue) {
      getTotalDevices();
    }
  },
  { immediate: true }
);
</script>

<style scoped>
.issue-side-bar-min-width {
  min-width: 320px;
}

.sb-row {
  display: flex;
  align-items: center;
  font-size: 0.9em;
}

.sb-row-title {
  width: 150px;
}

.sb-row-content {
  flex-grow: 1;
}

.show-other {
  position: absolute;
  right: 0.75rem;
  display: block !important;
  z-index: 1;
  margin-top: 0.4rem;
  background-color: rgb(var(--v-theme-darkgrey-darken1));
}

.title-line-height {
  line-height: 1;
}

.priority {
  min-width: 0 !important;
}

.stats {
  margin-left: 2px;
  text-transform: none;
  font-size: 14px;
}
</style>
