<template>
  <v-autocomplete
    v-model="tagModel"
    clear-on-select
    :items="suggestion"
    :hide-no-data="!search && !creating"
    :loading="loading || creating || isProcessing"
    :disabled="creating || disabled"
    item-title="name"
    multiple
    v-model:search="search"
    ref="tagsRef"
    hide-details
    solo
    flat
    variant="plain"
    density="compact"
    class="w-hover combobox"
    prepend-icon="mdi-tag-multiple"
    placeholder="No tags selected"
    id="combobox-tags"
    @focus="onFocus"
    color="primary"
  >
    <template v-slot:no-data>
      <v-list-item v-if="creating" class="justify-center text-primary text-center"> ...Creating </v-list-item>
      <v-list-item v-else class="justify-center text-center">Searching...</v-list-item>
    </template>
    <template v-slot:selection="{ item, index }">
      <v-chip size="small" :closable="!disabled" @click:close="removeTags(index)" class="my-1">
        {{ item.raw.name }}
      </v-chip>
    </template>

    <template v-slot:item="{ item, props }">
      <v-list-item
        v-bind="{
          ...props,
          title: '',
          active: item.raw.tagId ? Boolean(props.active) : false,
        }"
      >
        <span v-if="item.raw.tagId">{{ item.raw.name }}</span>

        <v-container v-else-if="item.raw.name" class="text-center w-100" color="secondary">
          <span class="subheading mr-2">Create Tag</span>
          <v-chip label size="small"> {{ item.raw.name }}</v-chip>
        </v-container>
      </v-list-item>
    </template>
  </v-autocomplete>
</template>

<script setup lang="ts">
import axios, { CancelTokenSource } from "axios";
import Tag from "@/types/Tag";
import { TagType } from "@/types/TagType";
import tagResource from "@/resources/TagResource";
import { ref, nextTick, watch } from "vue";

const emit = defineEmits(["update"]);
const props = withDefaults(
  defineProps<{
    readonly tags?: Tag[];
    readonly type: number;
    readonly orderBy?: string;
    readonly orderByDesc?: boolean;
    readonly deleting?: boolean;
    readonly canCreate?: boolean;
    readonly enableCreateOnServer?: boolean;
    readonly disabled?: boolean;
    readonly isProcessing?: boolean;
  }>(),
  {
    type: TagType.Default,
    deleting: false,
    canCreate: false,
    enableCreateOnServer: false,
    disabled: false,
    isProcessing: false,
  }
);

let cancelToken: CancelTokenSource | undefined = undefined;
const tagModel = ref<Tag[]>([]);
const loading = ref(false);
const creating = ref(false);
const search = ref<string | undefined>(undefined);
let searchThrottleTimer = 0;
const suggestion = ref<Tag[]>([]);
const tagsRef = ref(null);

watch(
  () => props.tags,
  function onChangeTags() {
    tagModel.value = [...(props.tags || [])];
  },
  { deep: true, immediate: true }
);

watch(tagModel, function onChangeModelTags(val: Tag[], prev: Tag[]) {
  if (val.length === prev.length) return;
  suggestion.value = suggestion.value.filter((v) => v.tagId);
  //Normalize data. When the enter key is pressed, added a string to the combobox
  tagModel.value = val.map((tag) => (typeof tag === "string" ? { tagId: 0, name: tag, type: props.type } : tag));

  const newTag = val.find((v) => v.name && !v.tagId);
  if (!props.enableCreateOnServer) {
    emit("update", tagModel.value);
    if (suggestion.value.length === 0) {
      getData();
    }
  } else {
    //Add new tag after upload to database
    tagModel.value = val.filter((v) => v.tagId);
    if (newTag) {
      createTag(newTag);
    }

    if (!newTag && props.tags?.length !== tagModel.value.length) {
      emit("update", tagModel.value);
      if (suggestion.value.length === 0) {
        getData();
      }
    }
  }
});

watch(search, function onSearchChanged() {
  if (creating.value || !search.value) return;
  suggestion.value = suggestion.value.filter((v) => v.tagId);
  clearSearchTimeout();
  searchThrottleTimer = setTimeout(() => {
    getData();
  }, 1000);
});

const onFocus = () => {
  // Initial tags load on focus
  if (creating.value) return;
  if (suggestion.value?.length === 0) {
    getData();
  }
};

const clearSearchTimeout = () => {
  if (searchThrottleTimer) {
    clearTimeout(searchThrottleTimer);
    searchThrottleTimer = 0;
  }
};

const getData = () => {
  // Cancel existing request
  if (cancelToken) {
    cancelToken.cancel();
  }

  setTimeout(() => {
    // Timeout is workaround for finally() being executed after request was canceled and new request already began
    loading.value = true;
    cancelToken = axios.CancelToken.source();
    const searchName = search.value === null ? undefined : search.value;
    const typeOf = props.type ? [props.type] : undefined;
    tagResource
      .getTagsPaged(10, 1, searchName, typeOf, props.orderBy, props.orderByDesc, cancelToken)
      .then((resp) => {
        suggestion.value = resp.data.items;

        if (!props.canCreate || !searchName) return;
        const newTag = tagModel.value.find((v) => v.name.toLowerCase() === searchName?.toLowerCase());
        const isCreateOption = suggestion.value.every((v) => v.name.toLowerCase() !== searchName?.toLowerCase());
        if (isCreateOption && !newTag) {
          suggestion.value.push({
            tagId: 0,
            name: searchName,
            type: props.type,
          });
        }
      })
      .catch(tagResource.defaultErrorHandler)
      .finally(() => {
        loading.value = false;
        cancelToken = undefined;
      });
  }, 10);
};

const createTag = (newTag: Tag) => {
  if (!props.canCreate || !newTag) {
    return;
  }

  suggestion.value = [];
  creating.value = true;
  clearSearchTimeout();
  if (cancelToken) {
    cancelToken.cancel();
  }

  tagResource
    .addTag(newTag)
    .then((resp) => tagModel.value.push(resp.data))
    .catch(tagResource.defaultErrorHandler)
    .finally(() => {
      creating.value = false;
      nextTick(() => {
        if (!tagsRef.value) return;
        (tagsRef.value as HTMLInputElement).focus();
      });
    });
};

const removeTags = (index: number) => {
  tagModel.value = [...tagModel.value.slice(0, index), ...tagModel.value.slice(index + 1)];
};
</script>

<style scoped>
.combobox {
  position: relative;
}
.combobox :deep(.v-field__input) {
  padding: 8px 10px;
  border-radius: 5px;
}

:deep() .tags-menu {
  top: 40px !important;
}
</style>
