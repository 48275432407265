<template>
  <side-sheet v-model="showFilters" heading="Filter POI" contentClass="filters">
    <h4>Filter presets</h4>
    <v-select
      attach
      v-model="c_filterPresetsSelected"
      :items="filterPresetsItems"
      density="compact"
      hide-details
      class="filter-item"
      @update:modelValue="applyFilterPreset()"
    />

    <h4 class="mt-6">POI Types</h4>
    <div>
      <v-btn size="x-small" link @click="selectAllPoiTypes()">Select all</v-btn> /
      <v-btn size="x-small" link @click="deselectAllPoiTypes()">Deselect all</v-btn>
    </div>
    <div v-for="type in typeItems" :key="type.value">
      <v-checkbox
        v-model="c_typeSelected"
        :label="type.text"
        :value="type.value"
        density="compact"
        class="type"
        hide-details
        @click="setCustomFilterPreset()"
      />
    </div>

    <h4 class="mt-6">Active status</h4>
    <v-select
      v-model="c_activeSelected"
      :items="activeItems"
      density="compact"
      hide-details
      class="filter-item"
      @update:model-value="setCustomFilterPreset()"
    />

    <template v-slot:actions>
      <v-spacer />
      <v-btn variant="text" @click="resetFilters()">Reset</v-btn>
      <v-btn color="primary" class="ml-4" @click="applyFilters(true)">Apply</v-btn>
    </template>
  </side-sheet>
</template>

<script setup lang="ts">
import { PoiType } from "@/types/PoiType";
import PoiHelper from "@/helpers/poiHelper";
import SideSheet from "@/components/layout/SideSheet.vue";
import { ref, computed, onMounted, watch } from "vue";

const emit = defineEmits(["update:modelValue", "update"]);

const props = withDefaults(
  defineProps<{
    readonly modelValue: boolean;
    readonly filterPresetsSelected: string;
    readonly typeSelected: PoiType[];
    readonly activeSelected: string;
  }>(),
  {
    modelValue: false,
    filterPresetsSelected: "",
    typeSelected: () => [],
    activeSelected: "",
  }
);

const c_activeSelected = ref(props.activeSelected);
const activeItems = [
  { title: "Any", value: "any" },
  { title: "Active only", value: "active" },
  { title: "Inactive only", value: "inactive" },
];

const c_filterPresetsSelected = ref(props.filterPresetsSelected);
const filterPresetsItems = [
  { title: "All POI", value: "all" },
  { title: "Active non-static POI only", value: "active" },
  { title: "Static POI only", value: "static" },
  { title: "Custom", value: "custom" },
];
const c_typeSelected = ref<PoiType[]>(props.typeSelected);

const getPoiTypeDisplayName = (type: PoiType) => {
  return PoiHelper.getPoiTypeDisplayName(type);
};

const typeItems = [
  { text: getPoiTypeDisplayName(PoiType.Checkpoint), value: PoiType.Checkpoint },
  { text: getPoiTypeDisplayName(PoiType.SpeedControl), value: PoiType.SpeedControl },
  { text: getPoiTypeDisplayName(PoiType.SeatBeltControl), value: PoiType.SeatBeltControl },
  { text: getPoiTypeDisplayName(PoiType.TechnicalControl), value: PoiType.TechnicalControl },
  { text: getPoiTypeDisplayName(PoiType.CustomsControl), value: PoiType.CustomsControl },
  { text: getPoiTypeDisplayName(PoiType.RoadCondition), value: PoiType.RoadCondition },
  { text: getPoiTypeDisplayName(PoiType.Animal), value: PoiType.Animal },
  { text: getPoiTypeDisplayName(PoiType.SpeedCamera), value: PoiType.SpeedCamera },
  { text: getPoiTypeDisplayName(PoiType.AverageCamera), value: PoiType.AverageCamera },
  { text: getPoiTypeDisplayName(PoiType.AverageCameraEnd), value: PoiType.AverageCameraEnd },
];

const showFilters = computed({
  get() {
    return props.modelValue;
  },
  set(value: boolean) {
    emit("update:modelValue", value);
  },
});

onMounted(() => {
  applyFilterPreset();
  applyFilters(false);
});

const resetFilters = () => {
  c_filterPresetsSelected.value = "active";
  applyFilterPreset();
  applyFilters(true);
};

const applyFilters = (reloadPoi: boolean) => {
  const filterPresetsName = filterPresetsItems.find((item) => item.value === c_filterPresetsSelected.value)?.title;
  emit(
    "update",
    c_filterPresetsSelected.value,
    [...c_typeSelected.value],
    c_activeSelected.value,
    filterPresetsName,
    reloadPoi
  );

  if (reloadPoi) {
    showFilters.value = false;
  }
};

const setCustomFilterPreset = () => {
  c_filterPresetsSelected.value = "custom";
};

const selectAllPoiTypes = () => {
  c_typeSelected.value = typeItems.map((el) => el.value);
  setCustomFilterPreset();
};

const deselectAllPoiTypes = () => {
  c_typeSelected.value = [];
  setCustomFilterPreset();
};

const applyFilterPreset = () => {
  if (c_filterPresetsSelected.value === "all") {
    c_typeSelected.value = typeItems.map((el) => el.value);
    c_activeSelected.value = "any";
  } else if (c_filterPresetsSelected.value === "active") {
    c_typeSelected.value = PoiHelper.getNonPermanentPoiTypes();
    c_activeSelected.value = "active";
  } else if (c_filterPresetsSelected.value === "static") {
    c_typeSelected.value = PoiHelper.getPermanentPoiTypes();
    c_activeSelected.value = "any";
  }
};

watch(showFilters, function showFiltersData() {
  if (showFilters.value) {
    c_filterPresetsSelected.value = props.filterPresetsSelected;
    c_typeSelected.value = [...props.typeSelected];
    c_activeSelected.value = props.activeSelected;
  }
});
</script>

<style scoped>
.type {
  font-size: 13px;
}
</style>
