<template>
  <v-menu location="bottom">
    <template v-slot:activator="{ props }">
      <v-btn
        icon
        v-bind="props"
        :ripple="false"
        :x-small="isXSmall"
        :small="isSmall"
        :large="isLarge"
        :x-large="isXLarge"
        :plain="plain"
        variant="text"
        density="compact"
      >
        <v-icon :color="dark ? 'black' : ''">mdi-dots-vertical</v-icon>
      </v-btn>
    </template>
    <v-list light density="compact" class="py-0">
      <v-list-item @click="menuItemCopyId(props.modelValue)">
        <template v-slot:prepend>
          <v-icon class="mr-2">mdi-content-copy</v-icon>
        </template>
        <v-list-item-title>Copy ID</v-list-item-title>
      </v-list-item>
      <v-list-item v-if="canViewDeviceLocation" @click="menuItemOpenLocationHistory(props.modelValue)">
        <template v-slot:prepend>
          <v-icon class="mr-2">mdi-map-marker-path</v-icon>
        </template>
        <v-list-item-title>Open in Location History</v-list-item-title>
      </v-list-item>
      <v-list-item v-if="canViewDeviceLogs" @click="menuItemOpenInLogs(props.modelValue)">
        <template v-slot:prepend>
          <v-icon class="mr-2">mdi-text-search</v-icon>
        </template>
        <v-list-item-title>Open in Logs</v-list-item-title>
      </v-list-item>
    </v-list>
  </v-menu>
</template>

<script setup lang="ts">
import infoMessageService from "@/services/InfoMessageService";
import { InfoMessageType } from "@/types/InfoMessageType";
import router from "@/router/router";
import userProfileService from "@/services/UserProfileService";
import { UserPermissionType } from "@/types/UserPermissionType";
import { computed } from "vue";

const props = withDefaults(
  defineProps<{
    readonly modelValue: number | null;
    readonly dark?: boolean;
    readonly size?: string;
    readonly plain?: boolean;
  }>(),
  {
    modelValue: null,
    dark: false,
    size: "",
    plain: false,
  }
);

const isXSmall = computed(() => props.size === "x-small");

const isSmall = computed(() => props.size === "small");

const isXLarge = computed(() => props.size === "x-large");

const isLarge = computed(() => props.size === "large");

const canViewDeviceLogs = computed(() => userProfileService.hasPermission(UserPermissionType.ViewDeviceLogs));

const canViewDeviceLocation = computed(() => userProfileService.currentUser?.isAdministrator);

const menuItemCopyId = (deviceId: number | null) => {
  if (!deviceId) return;
  navigator.clipboard.writeText(deviceId.toString()).catch((e) => {
    infoMessageService.show(InfoMessageType.Error, "Failed to copy text");
  });
};

const menuItemOpenInLogs = (deviceId: number | null) => {
  if (!deviceId) return;
  router.push({ name: "Device Logs", query: { deviceId: deviceId.toString() } }).catch((e) => {
    if (e.message.includes("Navigation aborted")) return;
    console.error(e);
  });
};

const menuItemOpenLocationHistory = (deviceId: number | null) => {
  if (!deviceId) return
  router.push({ name: "Device Location History", query: { deviceId: deviceId.toString() } }).catch((e) => {
    if (e.message.includes("Navigation aborted")) return;
    console.error(e);
  });
};
</script>
