<template>
  <div>
    <div class="text-subtitle-1 mb-1">Usage (current billing cycle)</div>

    <v-progress-linear v-if="!status && loading" indeterminate></v-progress-linear>

    <table v-if="status" class="text-subtitle-2 mt-4 info-table">
      <tr>
        <td class="text-grey">Data usage:</td>
        <td>{{ (status.ctdDataUsage / 1024).toFixed(2) }} KB</td>
      </tr>
      <tr>
        <td class="text-grey">SMS usage:</td>
        <td>{{ status.ctdSmsUsage }}</td>
      </tr>
      <tr>
        <td class="text-grey">Voice usage:</td>
        <td>{{ status.ctdVoiceUsage }}</td>
      </tr>
      <tr>
        <td class="text-grey">Session count:</td>
        <td>{{ status.ctdSessionCount || "N/A" }}</td>
      </tr>
      <tr>
        <td class="text-grey">Data limit reached rate plan:</td>
        <td>{{ status.overageLimitReached }}</td>
      </tr>
    </table>

    <div class="mt-4">
      <v-btn :disabled="loading" color="secondary" class="mr-4" @click="refresh">Refresh</v-btn>
    </div>
  </div>
</template>

<script setup lang="ts">
import deviceResource from "@/resources/DeviceResource";
import MobileNetworkUsageType from "@/types/MobileNetworkUsageType";
import { ref, watch } from "vue";

const props = withDefaults(defineProps<{ readonly deviceId: number | null }>(), { deviceId: null });

const loading = ref(false);
const status = ref<MobileNetworkUsageType | null>(null);

const getData = () => {
  if (!props.deviceId) {
    return;
  }

  loading.value = true;

  deviceResource
    .getMobileNetworkUsage(props.deviceId)
    .then((resp) => {
      status.value = resp.data;
    })
    .catch(deviceResource.defaultErrorHandler)
    .finally(() => {
      loading.value = false;
    });
};

watch(
  () => props.deviceId,
  function onValueChanged() {
    getData();
  },
  { immediate: true }
);

const refresh = () => {
  status.value = null;
  getData();
};
</script>

<style scoped>
.info-table td:first-child {
  padding-right: 2rem;
}
</style>
