<template>
  <v-container fluid>
    <v-card>
      <v-card-title class="d-block d-sm-flex">
        <div class="d-flex justify-space-between flex-grow-1">
          <div class="d-flex flex-grow-1">
            <v-text-field
              v-model="searchTerm"
              append-inner-icon="mdi-magnify"
              variant="underlined"
              color="primary"
              label="Search"
              hide-details
              clearable
              @click:clear="search()"
              style="max-width"
              v-on:input="search()"
              v-on:keypress.enter="search(true)"
            ></v-text-field>
            <v-btn
              size="x-small"
              variant="text"
              icon
              class="align-self-end ml-4"
              @click="reload"
              :disabled="loading"
              title="Refresh"
            >
              <v-icon size="24">mdi-reload</v-icon>
            </v-btn>
            <TableConfiguration :allHeaders="headers" v-model="selectedHeaders" tableKey="devicesTableColumns" />
            <v-tooltip location="bottom" :disabled="!searchTerm">
              <template v-slot:activator="{ props }">
                <span class="align-self-end ml-2 p-relative" v-bind="props">
                  <v-btn
                    size="x-small"
                    variant="text"
                    icon
                    class="align-self-end"
                    @click="showFilter = !showFilter"
                    :color="showFilter ? 'primary' : undefined"
                    :disabled="loading || Boolean(searchTerm)"
                    title="Filters"
                  >
                    <v-icon size="24" v-if="searchTerm">mdi-filter-off</v-icon>

                    <v-icon size="24" v-if="!searchTerm">mdi-filter-variant</v-icon>
                    <v-badge
                      color="primary"
                      v-if="numberOfFilter && !searchTerm"
                      transition="v-fade-transition"
                      dot
                      bordered
                      offset-x="-1"
                      offset-y="-10"
                    />
                  </v-btn>
                </span>
              </template>
              Filters ignored during search
            </v-tooltip>
          </div>

          <v-spacer class="d-none d-sm-block"></v-spacer>
          <div class="text-right align-self-end mt-2 mt-sm-0">
            <v-menu offset-y v-if="isAdmin" location="bottom left">
              <template v-slot:activator="{ props }">
                <v-btn icon v-bind="props" class="ml-2" variant="text" density="compact">
                  <v-icon>mdi-dots-vertical</v-icon>
                </v-btn>
              </template>

              <v-list>
                <v-list-item @click="showExport = true" link>
                  <v-list-item-title>Export devices</v-list-item-title>
                </v-list-item>
              </v-list>
            </v-menu>
          </div>
        </div>
      </v-card-title>

      <Filters :show="showFilter" :filter="filter" @close="showFilter = false" @update="updateFilter" :disabled="loading" />

      <v-data-table-server
        density="compact"
        :row-props="rowClass"
        :headers="selectedHeaders"
        :items="items"
        :items-length="total || 0"
        :loading="loading && 'primary'"
        :mobile-breakpoint="0"
        @click:row="(_ : any, { item }:any) => rowClick(item)"
        @contextmenu:row="openContenxMenu"
        v-model:sort-by="sortBy"
        :must-sort="true"
        hover
        :mobile="false"
      >
        <template v-slot:[`item.deviceId`]="{ item }">
          <div class="no-wrap">
            {{ item.deviceId }}
            <DeviceMenu v-model="item.deviceId" size="small" />
          </div>
        </template>
        <template v-slot:[`item.type`]="{ item }">
          {{ getDeviceTypeName(item.type) }}
        </template>
        <template v-slot:[`item.isBlocked`]="{ item }">
          <v-icon size="small" color="green" v-if="item.isBlocked">mdi-check</v-icon>
        </template>
        <template v-slot:[`item.isDebugMode`]="{ item }">
          <v-icon size="small" color="green" v-if="item.isDebugMode">mdi-check</v-icon>
        </template>
        <template v-slot:[`item.isUpdateAllowed`]="{ item }">
          <v-icon size="small" color="green" v-if="item.isUpdateAllowed">mdi-check</v-icon>
        </template>
        <template v-slot:[`item.customerId`]="{ item }">
          <span>{{ item.customerId }}</span>
          <v-btn
            v-if="canViewCustomers && item.customerId"
            @click="editCustomer(item.customerId, $event)"
            icon
            size="small"
            variant="text"
            density="compact"
          >
            <v-icon size="small">mdi-open-in-new</v-icon>
          </v-btn>
        </template>
        <template v-slot:[`item.created`]="{ item }">
          {{ moment(item.createdDate).format("lll") }}
        </template>
        <template v-slot:[`item.updatedDate`]="{ item }">
          {{ item.updatedDate ? moment(item.updatedDate).format("lll") : "" }}
        </template>
        <template v-slot:[`item.subscriptionEndDate`]="{ item }">
          {{ moment(item.subscriptionEndDate).format("lll") }}
        </template>
        <template v-slot:[`item.lastSeenDate`]="{ item }">
          {{ item.lastSeenDate != undefined ? moment(item.lastSeenDate).format("lll") : "" }}
        </template>
        <template v-slot:[`item.notes`]="{ item }">
          <v-tooltip v-if="item.notes && item.notes.length > 15" location="bottom" color="secondary" max-width="500">
            <template v-slot:activator="{ props }">
              <div class="no-wrap" v-bind="props">
                {{ item.notes.substring(0, 15) + "..." }}
              </div>
            </template>
            <span class="pre-wrap">{{ item.notes }}</span>
          </v-tooltip>
          <span v-else>{{ item.notes }}</span>
        </template>

        <template v-slot:[`item.status`]="{ item }">
          <div>{{ getDeviceStatusName(item.status) }}</div>
        </template>
        <template v-slot:bottom>
          <DataTableFooter
            v-model:page="page"
            :items="items"
            :itemsLength="total"
            v-model:itemsPerPage="itemsPerPage"
            :itemsPerPageOptions="[15, 25, 50]"
          />
        </template>
      </v-data-table-server>

      <v-overlay contained :model-value="loading" opacity="0" persistent style="z-index: 999 !important" />
    </v-card>

    <EditDevice v-model="deviceToEdit" v-on:updated="reload" :deviceInitTab="deviceInitTab" />
    <EditCustomer
      v-model="customerToEdit"
      v-on:updated="reload"
      :initData="props.initData"
      :customerInitTab="customerInitTab"
    />
    <ExportDevices v-model="showExport" />
    <DataTableContextMenu v-model="contextMenuEventItem" />
  </v-container>
</template>

<script setup lang="ts">
import axios, { CancelTokenSource } from "axios";
import moment from "moment";
import userProfileService from "@/services/UserProfileService";
import { UserPermissionType } from "@/types/UserPermissionType";
import EditDevice from "@/components/devices/EditDevice.vue";
import EditCustomer from "@/components/customers/EditCustomer.vue";
import deviceResource from "@/resources/DeviceResource";
import customerResource from "@/resources/CustomerResource";
import Device from "@/types/Device";
import Customer from "@/types/Customer";
import { DeviceType } from "@/types/DeviceType";
import DeviceHelper from "@/helpers/deviceHelper";
import DataTableContextMenu from "@/components/common/DataTableContextMenu.vue";
import userStorage from "@/services/UserStorageService";
import DeviceMenu from "@/components/devices/DeviceMenu.vue";
import ExportDevices from "@/components/devices/ExportDevices.vue";
import TableConfiguration from "@/components/common/TableConfiguration.vue";
import infoMessageService from "@/services/InfoMessageService";
import { InfoMessageType } from "@/types/InfoMessageType";
import TableFilter from "@/types/TableFilter";
import Filters from "@/components/common/Filters/Filters.vue";
import DataTableFooter from "@/components/common/DataTableFooter.vue";
import { DeviceStatus } from "@/types/DeviceStatus";
import { ref, computed, watch, nextTick, onActivated, onDeactivated } from "vue";

const props = withDefaults(
  defineProps<{
    readonly initFilter: { [key: string]: TableFilter["selected"] } | null;
    readonly initData?: { customerId?: number; customerTab?: string; deviceId?: number; deviceTab?: string } | null;
  }>(),
  {
    initFilter: null,
    initData: null,
  }
);

const showFilter = ref(Boolean(Object.values(props.initFilter || {}).length));
const filter = ref<TableFilter[]>([
  {
    title: "Type",
    icon: "mdi-format-list-bulleted-type",
    filterName: "type",
    searchable: false,
    selected: props.initFilter?.type || [],
    itemsCallback: (search?: string) => {
      if (!search) return DeviceHelper.getDeviceFilterByType();
      return DeviceHelper.getDeviceFilterByType().filter(({ text }) => text.toLowerCase().includes(search.toLowerCase()));
    },
  },
  {
    title: "Blocked",
    icon: "mdi-cancel",
    filterName: "blocked",
    searchable: false,
    selected: props.initFilter?.blocked || [],
    disableMultiple: true,
    itemsCallback: (search?: string) => {
      const data = [
        { text: "Yes", value: true },
        { text: "No", value: false },
      ];
      if (!search) return data;
      return data.filter(({ text }) => text.toLowerCase().includes(search.toLowerCase()));
    },
  },
  {
    title: "Allow updates",
    icon: "mdi-upload",
    filterName: "updateAllowed",
    searchable: false,
    selected: props.initFilter?.updateAllowed || [],
    disableMultiple: true,
    itemsCallback: (search?: string) => {
      const data = [
        { text: "Yes", value: true },
        { text: "No", value: false },
      ];
      if (!search) return data;
      return data.filter(({ text }) => text.toLowerCase().includes(search.toLowerCase()));
    },
  },
  {
    title: "Status",
    icon: "mdi-checkbox-multiple-marked",
    filterName: "status",
    searchable: false,
    selected: props.initFilter?.status || [],
    itemsCallback: (search?: string) => {
      if (!search) return DeviceHelper.getDeviceFilterByStatus();
      return DeviceHelper.getDeviceFilterByStatus().filter(({ text }) => text.toLowerCase().includes(search.toLowerCase()));
    },
  },
]);

const numberOfFilter = computed(() =>
  Object.values(filter.value).reduce((acc, { selected }) => (acc += selected.length), 0)
);

const total = ref<number | undefined>(0);
const items = ref<Device[]>([]);
const loading = ref(false);

const optionsStorageKey = "devicesTable";
const itemsPerPage = ref(userStorage.get(optionsStorageKey)?.itemsPerPage ?? 15);
const sortBy = ref<{ key: string; order: boolean | "asc" | "desc" }[]>(
  userStorage.get(optionsStorageKey)?.sortBy?.[0]?.key
    ? userStorage.get(optionsStorageKey)?.sortBy
    : [{ key: "deviceId", order: true }]
);

const page = ref(userStorage.get(optionsStorageKey)?.page ?? 1);

const searchTermStorageKey = "devicesTableSearchTerm";
const searchTerm = ref(userStorage.get(searchTermStorageKey) ?? "");
let searchThrottleTimer = 0;
let cancelToken: CancelTokenSource | undefined = undefined;
let cancelTokenArr: CancelTokenSource[] = [];

const deviceToEdit = ref<Device | null>(null);
const deviceInitTab = ref<string | null>(null);
const showExport = ref(false);

const ignoreOptionsChange = ref(false);

const customerToEdit = ref<Customer | null>(null);
const customerInitTab = ref<string | null>(null);
let dataReloadTimeoutId: number | null = null;
const dataReloadIntervalSeconds = 180;
const componentActive = ref(false);
const contextMenuEventItem: any = ref(null);

const openContenxMenu = (e: any) => {
  contextMenuEventItem.value = e;
};

const restartDataReloadTimeout = () => {
  if (dataReloadTimeoutId) {
    clearTimeout(dataReloadTimeoutId);
  }

  dataReloadTimeoutId = setTimeout(() => {
    dataReloadTimeoutId = 0;
    if (componentActive.value) {
      getData();
    }
  }, dataReloadIntervalSeconds * 1000);
};

const cancelExistingRequests = () => {
  // Cancel existing request
  if (cancelToken) {
    cancelToken.cancel();
    cancelToken = undefined;
  }

  // Cancel existing requests when making a search by IDs
  if (cancelTokenArr.length) {
    cancelTokenArr.forEach((t) => t?.cancel());
    cancelTokenArr = [];
  }
};

const getData = (resetPagination: boolean = false) => {
  cancelExistingRequests();

  // Reset pagination
  if (resetPagination) {
    ignoreOptionsChange.value = true;
    page.value = 1;
  }

  // Save sorting, filters and search terms
  userStorage.set(optionsStorageKey, {
    page: page.value,
    itemsPerPage: itemsPerPage.value,
    sortBy: sortBy.value,
  });
  userStorage.set(searchTermStorageKey, searchTerm.value);

  // Restart data reload timeout
  restartDataReloadTimeout();

  const deviceIds = getSearchTermAsIds();

  if (deviceIds) {
    if (deviceIds?.length > 300) {
      return infoMessageService.show(
        InfoMessageType.Info,
        "Too many device IDs. The maximum available number of IDs is 300."
      );
    }
    return getDevicesByIds(deviceIds);
  }
  setTimeout(() => {
    // Timeout is workaround for finaly() being executed after request was canceled and new request already began
    loading.value = true;
    cancelToken = axios.CancelToken.source();

    let typeFilter = undefined;
    let blockedFilter = undefined;
    let statusFilter = undefined;
    let updateAllowedFilter = undefined;
    if (!searchTerm.value) {
      typeFilter = filter.value.find(({ filterName }) => filterName === "type")?.selected.map(({ value }) => value);
      blockedFilter = filter.value.find(({ filterName }) => filterName === "blocked")?.selected.map(({ value }) => value)[0];
      statusFilter = filter.value.find(({ filterName }) => filterName === "status")?.selected.map(({ value }) => value);
      updateAllowedFilter = filter.value
        .find(({ filterName }) => filterName === "updateAllowed")
        ?.selected.map(({ value }) => value);
    }

    const orderDesc =
      typeof sortBy.value[0].order === "boolean" ? sortBy.value[0].order : sortBy.value[0].order.toString() === "desc";
    deviceResource
      .getDevicesPaged(
        itemsPerPage.value,
        page.value,
        searchTerm.value,
        sortBy.value[0].key,
        orderDesc,
        typeFilter,
        blockedFilter,
        statusFilter,
        updateAllowedFilter?.[1] ? undefined : updateAllowedFilter?.[0],
        cancelToken
      )
      .then((resp) => {
        items.value = resp.data.items;
        total.value = resp.data.totalItems;
      })
      .catch(deviceResource.defaultErrorHandler)
      .finally(() => {
        loading.value = false;
        cancelToken = undefined;
        ignoreOptionsChange.value = false;
      });
  }, 10);
};

watch([itemsPerPage, sortBy, page], async () => {
  if (!ignoreOptionsChange.value) {
    await nextTick();
    getData();
  }
});

watch(customerToEdit, function onChangeCustomerToEdit() {
  if (!customerToEdit.value) {
    customerInitTab.value = null;
    deviceInitTab.value = null;
  }
});

watch(deviceToEdit, function onChangeDeviceToEdit() {
  if (!deviceToEdit.value) {
    deviceInitTab.value = null;
  }
});

const selectedHeaders = ref([]);
const headers = [
  { title: "ID", align: "start", key: "deviceId" },
  { title: "Name", key: "deviceName" },
  { title: "Type", key: "type" },
  { title: "Customer ID", key: "customerId" },
  { title: "Blocked", key: "isBlocked" },
  { title: "Debug mode", key: "isDebugMode" },
  { title: "Allow updates", key: "isUpdateAllowed" },
  { title: "Notes", key: "notes", sortable: false },
  { title: "Created", key: "created" },
  { title: "Subscription end", key: "subscriptionEndDate" },
  { title: "Last seen", key: "lastSeenDate" },
  { title: "Status", key: "status" },
  { title: "Updated", key: "updatedDate" },
];

const canViewCustomers = computed(() => userProfileService.hasPermission(UserPermissionType.ViewCustomers));
const isAdmin = () => userProfileService.currentUser && userProfileService.currentUser.isAdministrator;

const getDeviceTypeName = (type: DeviceType) => {
  return DeviceHelper.getDeviceTypeDisplayName(type);
};

const getDeviceStatusName = (status: DeviceStatus) => {
  return DeviceHelper.getDeviceStatusDisplayName(status);
};

const getDeviceById = (deviceId: number, isInitData = false) => {
  deviceResource
    .getDeviceById(deviceId)
    .then((resp) => {
      deviceToEdit.value = resp.data;
      if (isInitData) {
        deviceInitTab.value = props.initData?.deviceTab || null;
      }
    })
    .catch(deviceResource.defaultErrorHandler);
};

const getDevicesByIds = (deviceIds: number[]) => {
  cancelExistingRequests();

  if (!deviceIds?.length) return;

  setTimeout(() => {
    // Timeout is workaround for finaly() being executed after request was canceled and new request already began
    ignoreOptionsChange.value = true;
    loading.value = true;
    cancelToken = axios.CancelToken.source();

    const promise = Promise.all(
      deviceIds.map((id) => {
        const token = axios.CancelToken.source();
        cancelTokenArr.push(token);
        return deviceResource
          .getDeviceById(id, token)
          .then((resp) => resp.data)
          .catch((err) => {
            if (axios.isCancel(err)) return err;
            if (err.response.status === 404) return;
            deviceResource.defaultErrorHandler(err);
          });
      })
    );

    promise
      .then((resp) => {
        if (resp.some((v) => axios.isCancel(v))) return;
        items.value = resp.filter((v) => v) as Device[];
        total.value = undefined;
      })
      .finally(() => {
        loading.value = false;
        cancelTokenArr = [];
      });
  }, 10);
};

const getSearchTermAsIds = (): number[] | null => {
  const dividers = [" ", ",", ";"];
  let result: number[] | null = null;

  dividers.some((divider) => {
    const termArr = searchTerm.value
      ?.split(divider)
      .filter((v: string) => v.trim())
      .map((v: string) => v.trim())
      .filter((v: string, ind: number, arr: string[]) => arr.indexOf(v) === ind);

    if (termArr?.every((v: string) => !isNaN(Number(v))) && termArr.length > 1) {
      result = termArr;
    }
    return result;
  });
  return result;
};

const search = (noTheshold: boolean = false) => {
  if (searchThrottleTimer) {
    clearTimeout(searchThrottleTimer);
    searchThrottleTimer = 0;
  }

  if (noTheshold || !searchTerm.value) {
    getData(true);
  } else {
    searchThrottleTimer = setTimeout(() => {
      getData(true);
    }, 1000);
  }
};

const reload = () => getData();

const rowClick = (item: Device) => {
  if (!contextMenuEventItem.value) {
    deviceToEdit.value = Object.assign({}, item);
  }
};

const rowClass = ({ item }: { item: Device }) => {
  if (item.isBlocked) {
    return { class: { "text-red cursor-default": true } };
  } else if (item.isDebugMode) {
    return { class: { "text-blue cursor-default": true } };
  }
  return { class: { "cursor-default": true } };
};

const editCustomer = (customerId: number, e: Event) => {
  e.stopPropagation();

  if (customerId) {
    loading.value = true;

    customerResource
      .getCustomerById(customerId)
      .then((resp) => {
        customerToEdit.value = resp.data;
        if (e.type === "init") {
          customerInitTab.value = props.initData?.customerTab || null;
        }
      })
      .catch(customerResource.defaultErrorHandler)
      .finally(() => {
        loading.value = false;
      });
  }
};

const updateFilter = ({ newFilter, eventOptions }: { newFilter: TableFilter[]; eventOptions: any }) => {
  if (searchThrottleTimer) {
    clearTimeout(searchThrottleTimer);
    searchThrottleTimer = 0;
  }

  searchThrottleTimer = setTimeout(
    () => {
      filter.value = newFilter;
      if (page.value > 1) {
        page.value = 1;
        return;
      }
      getData();
    },
    eventOptions.byRemoveBtn ? 0 : 1000
  );
};

onActivated(() => {
  componentActive.value = true;

  if (props.initData?.customerId) {
    editCustomer(props.initData?.customerId, new Event("init"));
    return;
  }
  if (props.initData?.deviceId) {
    getDeviceById(props.initData?.deviceId, true);
  }

  // reload data (user haven't been on the page logner than dataReloadIntervalSeconds)
  if (dataReloadTimeoutId === 0 || dataReloadTimeoutId === null) {
    getData();
  }
});

onDeactivated(() => {
  componentActive.value = false;
});
</script>

<style scoped></style>
