<template>
  <v-combobox
    v-model="selectedIssue"
    :filter="filter"
    :items="props.isLoading || (isTyping && props.noFilter) ? [] : suggestion"
    :hide-no-data="!search || props.isLoading"
    :loading="loading || props.isLoading"
    :disabled="props.isLoading"
    v-model:search="search"
    clearable
    @click:clear="onClear"
    item-value="issueId"
    item-title="name"
    ref="issue"
    :label="props.label"
    persistent-hint
    :hint="props.hint"
    @focus="onFocus"
    @click:append="onAppendClick"
    @blur="onBlur"
    :menu-props="props.menuProps"
    :no-filter="noFilter"
    variant="underlined"
  >
    <template v-slot:no-data>
      <v-list-item v-if="loading" class="justify-center text-center">Searching...</v-list-item>
      <v-list-item v-else-if="!canAddIssue" class="text-disabled subheading text-center">No data available</v-list-item>
      <v-list-item v-else class="d-none" />
    </template>
    <template v-slot:selection="{ item }">
      <span small class="text-no-wrap text-truncate selection">
        {{ item.raw.name || item }}
      </span>
    </template>
    <template v-slot:item="{ item, props }">
      <v-list-item v-bind="{ ...props, title: '' }">
        <div class="d-flex flex-grow-1 text-left">
          <div class="flex-srink-1">{{ item.raw.issueId }}</div>
          <div class="flex-grow-1 mx-4">{{ item.raw.name }}</div>
          <div :class="IssueHelper.getIssueStatusColor(item.raw.status, true)" class="flex-srink-1">
            {{ IssueHelper.getIssueStatusDisplayName(item.raw.status) }}
          </div>
        </div>
      </v-list-item>
    </template>
    <template v-slot:append-item>
      <v-list-item
        v-if="search && !loading && !props.isLoading && canAddIssue"
        class="d-flex justify-center flex-wrap"
        @click="onCreate"
      >
        <span class="subheading mr-2">Create Issue: </span>
        {{ search }}
      </v-list-item>
    </template>
  </v-combobox>
</template>

<script setup lang="ts">
import Issue from "@/types/Issue";
import issueResource from "@/resources/IssueResource";
import axios, { CancelTokenSource } from "axios";
import IssueHelper from "@/helpers/issueHelper";
import userProfileService from "@/services/UserProfileService";
import { UserPermissionType } from "@/types/UserPermissionType";
import { ref, watch, computed } from "vue";

const emit = defineEmits(["change", "update:title", "create"]);

const props = withDefaults(
  defineProps<{
    readonly hasInitLoad?: boolean;
    readonly isLoading?: boolean;
    readonly hint: string;
    readonly label: string;
    readonly menuProps: Object;
    readonly noFilter: boolean;
  }>(),
  { hasInitLoad: true, isLoading: false, hint: "", label: "", menuProps: () => ({}), noFilter: false }
);

const suggestion = ref<Issue[]>([]);
const loading = ref(false);
//The props are needed to disable clearing by v-combobox when it loses focus.
const newIssueName = ref("");
const search = ref<string | null>(null);
let searchThrottleTimer = 0;
let cancelToken: CancelTokenSource | undefined = undefined;
const isTyping = ref(false);
const selectedIssue = ref<Issue | null>(null);

const canAddIssue = computed(() => userProfileService.hasPermission(UserPermissionType.AddIssues));

const canViewIssues = computed(() => userProfileService.hasPermission(UserPermissionType.ViewIssues));
const issue = ref(null);
watch(selectedIssue, function onSelectedIssue() {
  if (typeof selectedIssue.value === "string") {
    selectedIssue.value = null;
    // @ts-ignore
    issue.value.reset();
    // @ts-ignore
    issue.value.search = newIssueName.value;

    emit("change", null);
    return;
  }
  emit("change", selectedIssue.value);
});

watch(search, function onSearchChanged() {
  if (search.value !== null) {
    newIssueName.value = search.value;
  }
  emit("update:title", search.value || "");

  if (!props.hasInitLoad && !search.value) {
    clearSearchTimeout();
    return (suggestion.value = []);
  }

  if (props.isLoading || !search.value) return;

  selectedIssue.value = null;
  suggestion.value = suggestion.value.filter((v) => v.issueId);

  clearSearchTimeout();
  isTyping.value = true;
  searchThrottleTimer = setTimeout(() => {
    isTyping.value = false;
    if (suggestion.value.length && !search.value) return;
    if (props.noFilter) {
      suggestion.value = [];
    }
    getData();
  }, 800);
});

const onClear = () => {
  searchThrottleTimer = setTimeout(() => {
    if (suggestion.value.length && !search.value) return;
    if (props.noFilter) {
      suggestion.value = [];
    }
    getData();
  }, 800);
};
const clearSearchTimeout = () => {
  if (searchThrottleTimer) {
    isTyping.value = false;
    clearTimeout(searchThrottleTimer);
    searchThrottleTimer = 0;
  }
};

const onBlur = () => {
  if (search.value && !selectedIssue.value) {
    isTyping.value = false;
    newIssueName.value = search.value;
  }
};
const onFocus = () => {
  // Initial tags load on focus
  if (props.isLoading) return;
  if (suggestion.value?.length === 0 && props.hasInitLoad) {
    getData();
  }
};

const onAppendClick = () => {
  //@ts-ignore
  if (issue.value?.blur) {
    //@ts-ignore
    issue.value.blur();
  }
};
const getData = () => {
  if (!canViewIssues.value) return;
  // Cancel existing request
  if (cancelToken) {
    cancelToken.cancel();
  }

  setTimeout(() => {
    // Timeout is workaround for finaly() being executed after request was canceled and new request already began
    loading.value = true;
    cancelToken = axios.CancelToken.source();

    issueResource
      .getIssuesPaged(
        10,
        1,
        search.value || "",
        undefined,
        true,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        cancelToken
      )
      .then((resp) => {
        suggestion.value = resp.data.items;
      })
      .catch(issueResource.defaultErrorHandler)
      .finally(() => {
        loading.value = false;
        cancelToken = undefined;
      });
  }, 10);
};
const filter = (item: Issue, queryText: string) => {
  const query = queryText || "";
  return `${item.issueId} ${item.name.toLowerCase()} 
    ${item.tags?.map(({ name }) => name?.toLowerCase())?.join(" ")}`.includes(query.toString().toLowerCase());
};

const onCreate = () => {
  emit("create", search.value);
};
</script>

<style scoped></style>
