<template>
  <div>
    <div class="text-subtitle-1 mb-1">Mobile network provider information</div>

    <v-progress-linear v-if="!status && loading" indeterminate></v-progress-linear>

    <table v-if="status" class="text-subtitle-2 mt-4 info-table">
      <tr>
        <td class="text-grey">Iccid:</td>
        <td>{{ status.iccid }}</td>
      </tr>
      <tr>
        <td class="text-grey">Imsi:</td>
        <td>{{ status.imsi }}</td>
      </tr>
      <tr>
        <td class="text-grey">Msisdn:</td>
        <td>{{ status.msisdn }}</td>
      </tr>
      <tr>
        <td class="text-grey">Imei:</td>
        <td>{{ status.imei }}</td>
      </tr>
      <tr>
        <td class="text-grey">Rate plan:</td>
        <td>{{ status.ratePlan }}</td>
      </tr>
      <tr>
        <td class="text-grey">Communication plan:</td>
        <td>{{ status.communicationPlan }}</td>
      </tr>
      <tr>
        <td class="text-grey">Added on:</td>
        <td>{{ moment(status.dateAdded).format("lll") }}</td>
      </tr>
      <tr v-if="status.dateActivated">
        <td class="text-grey">Activated on:</td>
        <td>{{ moment(status.dateActivated).format("lll") }}</td>
      </tr>
      <tr v-if="status.dateUpdated">
        <td class="text-grey">Updated on:</td>
        <td>{{ moment(status.dateUpdated).format("lll") }}</td>
      </tr>
      <tr>
        <td class="text-grey">Status:</td>
        <td :class="statusClass()">{{ status.status }}</td>
      </tr>
    </table>

    <div class="mt-4">
      <v-btn :disabled="loading" color="secondary" class="mr-4" @click="refresh">Refresh</v-btn>
      <v-btn v-if="canEditDevice && isActivated" @click="deactivateConfirmation" :loading="loading" color="red" class="mr-4"
        >Deactivate</v-btn
      >
      <v-btn v-if="canEditDevice && isDeactivated" @click="activate" :loading="loading" color="green" class="mr-4"
        >Activate</v-btn
      >
    </div>
  </div>
</template>

<script setup lang="ts">
import moment from "moment";
import deviceResource from "@/resources/DeviceResource";
import MobileNetworkProviderStatus from "@/types/MobileNetworkProviderStatus";
import userProfileService from "@/services/UserProfileService";
import { UserPermissionType } from "@/types/UserPermissionType";
import { ref, computed, watch } from "vue";
import { useConfirm } from "@/services/ConfirmService";

const confirm = useConfirm();
const props = withDefaults(defineProps<{ readonly deviceId: number | null }>(), { deviceId: null });

const loading = ref(false);
const status = ref<MobileNetworkProviderStatus | null>(null);

const getData = () => {
  if (!props.deviceId) {
    return;
  }

  loading.value = true;

  deviceResource
    .getMobileNetworkProviderStatus(props.deviceId)
    .then((resp) => {
      status.value = resp.data;
    })
    .catch(deviceResource.defaultErrorHandler)
    .finally(() => {
      loading.value = false;
    });
};

watch(
  () => props.deviceId,
  function onValueChanged() {
    getData();
  },
  { immediate: true }
);

const canEditDevice = computed(() => userProfileService.hasPermission(UserPermissionType.EditDevices));

const isActivated = computed(() => status.value?.status === "ACTIVATED" || status.value?.status === "TEST_READY");

const isDeactivated = computed(() => status.value?.status === "DEACTIVATED" || status.value?.status === "TEST_READY");

const deactivateConfirmation = () => {
  confirm
    .show(`Device will no longer be able to connect to any services until activated again.`, {
      width: 360,
    })
    .then((confirmed) => {
      if (confirmed) {
        deactivate();
      }
    });
};

const deactivate = () => {
  if (!canEditDevice.value) {
    return;
  }

  loading.value = true;

  deviceResource
    .deactivateSimCard(props.deviceId!)
    .then(() => {
      status.value = null;
      getData();
    })
    .catch(deviceResource.defaultErrorHandler)
    .finally(() => {
      loading.value = false;
    });
};

const activate = () => {
  if (!canEditDevice.value) {
    return;
  }

  loading.value = true;

  deviceResource
    .activateSimCard(props.deviceId!)
    .then(() => {
      status.value = null;
      getData();
    })
    .catch(deviceResource.defaultErrorHandler)
    .finally(() => {
      loading.value = false;
    });
};

const refresh = () => {
  status.value = null;
  getData();
};

const statusClass = () => {
  if (status.value!.status === "ACTIVATED") {
    return "text-green";
  } else if (status.value!.status === "DEACTIVATED") {
    return "text-red";
  } else {
    return "";
  }
};
</script>

<style scoped>
.info-table td:first-child {
  padding-right: 2rem;
}
</style>
