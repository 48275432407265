<template>
  <div>
    <div class="d-flex align-center">
      <v-autocomplete
        class="customer-select"
        attach
        v-model="selectedItem"
        @update:model-value="customerChanged"
        @blur="onBlur"
        :items="customers"
        :loading="loading"
        v-model:search="searchTerm"
        :clearable="props.canEdit"
        @click:clear="onClear"
        no-filter
        return-object
        item-value="customerId"
        placeholder="Start typing to search..."
        density="compact"
        :variant="props.canEdit ? 'outlined' : 'solo'"
        :append-icon="!canEdit ? null : undefined"
        :flat="!props.canEdit"
        :readonly="!props.canEdit"
        :disabled="disabled"
        hide-details
      >
        <template v-slot:selection="{ item }">
          <span v-if="!searchTerm">
            <span>{{ `${item.raw.firstName} ${item.raw.lastName}` }}</span>
          </span>
        </template>
        <template v-slot:item="{ item, props }">
          <v-list-item v-bind="{ ...props, title: '' }">
            <div>{{ `${item.raw.firstName} ${item.raw.lastName}` }}</div>
            <div class="text-caption">
              <span class="mr-3">ID {{ item.raw.customerId }} </span>
              <span class="mr-3 icon-label">
                <v-icon class="mr-1" size="small">mdi-phone-outline</v-icon>
                <span>{{ item.raw.phoneNumber }}</span>
              </span>
              <span v-if="item.raw.companyName" class="icon-label">
                <v-icon class="mr-1" size="small">mdi-briefcase-outline</v-icon>
                <span>{{ item.raw.companyName }}</span>
              </span>
            </div>
          </v-list-item>
        </template>
      </v-autocomplete>
      <v-btn
        v-if="props.canEdit"
        icon
        variant="text"
        density="compact"
        class="ml-2"
        :disabled="initCustomerId === props.modelValue"
        @click="undo"
        title="Undo"
      >
        <v-icon>mdi-arrow-u-left-top</v-icon>
      </v-btn>
    </div>
    <div class="text-caption ml-3 mt-1">
      <div v-if="selectedItem">
        <span class="mr-3">ID {{ selectedItem.customerId }} </span>
        <span class="mr-3 icon-label">
          <v-icon class="mr-1" size="small">mdi-phone-outline</v-icon>
          <span>{{ selectedItem.phoneNumber }}</span>
        </span>
        <span v-if="selectedItem.companyName" class="icon-label">
          <v-icon class="mr-1" size="small">mdi-briefcase-outline</v-icon>
          <span>{{ selectedItem.companyName }}</span>
        </span>
      </div>
      <div v-else>No customer linked to device</div>
    </div>
  </div>
</template>

<script setup lang="ts">
import customerResource from "@/resources/CustomerResource";
import Customer from "@/types/Customer";
import axios, { CancelTokenSource } from "axios";
import { ref, watch, onMounted } from "vue";

const emit = defineEmits(["update:modelValue"]);
const props = withDefaults(defineProps<{ readonly canEdit: boolean; modelValue: number | null }>(), {
  canEdit: true,
  modelValue: null,
});

const loading = ref(false);
let cancelToken: CancelTokenSource | undefined = undefined;
const selectedItem = ref<Customer | null>(null);
const customers = ref<Customer[]>([]);
const searchTerm = ref<string | undefined>();
let searchThrottleTimer = 0;
const disabled = ref(false);
const initCustomerId = ref<number | null>(null);

const onClear = () => {
  searchThrottleTimer = setTimeout(() => {
    getCustomers();
  }, 1000);
};

onMounted(() => {
  initCustomerId.value = props.modelValue;
});

const customerChanged = () => {
  searchTerm.value = undefined;
  emit("update:modelValue", selectedItem.value?.customerId);
};

const onBlur = () => {
  searchTerm.value = "";
};

const getCustomerById = () => {
  if (props.modelValue) {
    loading.value = true;
    disabled.value = true;
    customerResource
      .getCustomerById(props.modelValue)
      .then((resp) => {
        customers.value = [resp.data];
        selectedItem.value = resp.data;
      })
      .catch(customerResource.defaultErrorHandler)
      .finally(() => {
        loading.value = false;
        disabled.value = false;
      });
  }
};

const getCustomers = () => {
  // Cancel existing request
  if (cancelToken) {
    cancelToken.cancel();
  }
  setTimeout(() => {
    // Timeout is workaround for finaly() being executed after request was canceled and new request already began
    loading.value = true;
    cancelToken = axios.CancelToken.source();

    customerResource
      .getCustomersPaged(10, 1, searchTerm.value ?? undefined, "customerId", false, undefined, cancelToken)
      .then(async (resp) => {
        // Try to get customer by exact ID
        const customerById = await tryGetCustomerById(searchTerm.value, cancelToken);

        const newCustomers: Customer[] = [];

        if (selectedItem.value) {
          newCustomers.push(selectedItem.value);
        }
        if (customerById) {
          newCustomers.push(customerById);
        }
        if (resp.data.items) {
          newCustomers.push(...resp.data.items.filter((v) => v.customerId !== customerById?.customerId));
        }

        customers.value = newCustomers;
      })
      .catch(customerResource.defaultErrorHandler)
      .finally(() => {
        loading.value = false;
        cancelToken = undefined;
      });
  }, 10);
};

const undo = () => {
  emit("update:modelValue", initCustomerId.value);
};

const tryGetCustomerById = async (searchTerm?: string, cancel?: CancelTokenSource): Promise<Customer | null> => {
  if (!searchTerm) return null;

  if (/^\d+$/.test(searchTerm)) {
    return new Promise((resolve, reject) => {
      customerResource
        .getCustomerById(parseInt(searchTerm), cancel)
        .then((resp) => {
          resolve(resp.data);
        })
        .catch((err) => resolve(null));
    });
  }

  return null;
};

watch(
  () => props.modelValue,
  function onValueChanged() {
    if (!props.modelValue) {
      selectedItem.value = null;
    } else if (props.modelValue && props.modelValue !== selectedItem.value?.customerId) {
      getCustomerById();
    }
  },
  { immediate: true }
);

watch(searchTerm, function onSearchTermChanged(newValue: any, oldValue: any) {
  if (!newValue) return;

  if (oldValue !== newValue) {
    if (searchThrottleTimer) {
      clearTimeout(searchThrottleTimer);
      searchThrottleTimer = 0;
    }
    searchThrottleTimer = setTimeout(() => {
      getCustomers();
    }, 1000);
  }
});
</script>

<style scoped>
.customer-select :deep(.v-text-field__details) {
  position: static;
  margin-bottom: 0;
  padding-left: 0;
}

.icon-label > * {
  vertical-align: middle;
}
</style>
