<template>
  <v-container fluid style="min-height: 100%; height: 100%" class="position-relative">
    <v-row class="d-flex flex-column" style="height: 100%">
      <v-card class="d-flex flex-grow-1" style="min-height: 400px">
        <div class="map-wrap">
          <l-map
            ref="mapRef"
            class="map-container"
            :options="options"
            :zoom.sync="mapZoom"
            :center="mapCenter"
            :noBlockingAnimations="true"
            @contextmenu="openMapContextMenu"
            @update:bounds="mapBoundsUpdated"
            @ready="onReadyMap"
          >
            <l-tile-layer :url="leaflet.url" :attribution="leaflet.attribution"></l-tile-layer>
            <l-control-scale position="bottomleft" :imperial="false" :metric="true"></l-control-scale>
            <l-control position="topright">
              <div class="text-right">
                <div class="d-flex no-wrap mb-4 mt-2 justify-end">
                  <v-autocomplete
                    v-model="geocodeSelectedItem"
                    :items="geocodeItems"
                    :loading="geocodeLoading"
                    v-model:search="geocodeSearchTerm"
                    color="dark"
                    base-color="dark"
                    density="compact"
                    flat
                    clearable
                    light
                    hide-no-data
                    no-filter
                    label="Address search"
                    item-title="formatted_address"
                    :loader-height="2"
                    return-object
                    hide-details
                    :disabled="loading"
                    class="poi-map-search-input flex-grow-0"
                  ></v-autocomplete>
                </div>

                <div class="d-flex no-wrap poi-filter">
                  <v-btn
                    density="compact"
                    size="small"
                    variant="text"
                    icon
                    color="secondary"
                    @click="reload"
                    :disabled="loading"
                    title="Reload POI"
                  >
                    <v-icon size="24">mdi-reload</v-icon>
                  </v-btn>

                  <v-btn
                    variant="text"
                    @click="showFilters = true"
                    title="Show filters"
                    color="secondary"
                    class="d-flex flex-grow-1 px-1 ml-1 justify-start"
                    density="compact"
                    :disabled="loading"
                  >
                    <v-icon size="x-large">mdi-filter-variant</v-icon>

                    <span class="filter-preset-label flex-grow-1 text-truncate">{{ filterPresetsName }}</span>
                  </v-btn>
                </div>

                <div class="d-flex no-wrap justify-end mt-2">
                  <v-btn
                    v-if="canAddPoi"
                    size="small"
                    light
                    variant="outlined"
                    color="secondary"
                    @click="newPoi()"
                    :disabled="loading"
                    ><b>New POI</b></v-btn
                  >
                </div>
              </div>
            </l-control>
          </l-map>
          <v-menu
            v-model="showMapContextMenu"
            :target="[mapContextMenuX, mapContextMenuY]"
            position="absolute"
            offset-y
            z-index="999"
          >
            <v-list density="compact" class="py-0">
              <v-list-item @click="newPoi(lastMapContextMenuLatLng.lat, lastMapContextMenuLatLng.lng)">
                <template v-slot:prepend>
                  <v-icon class="mr-2">mdi-map-marker</v-icon>
                </template>
                <v-list-item-title>New POI here</v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>
        </div>
      </v-card>
    </v-row>
    <div v-if="hasMorePoi" class="partial-data-alert">
      <v-alert density="compact" type="error" :icon="false" class="d-inline-block text-caption px-1 py-0">{{
        `Showing max ${maxPoi} POI. Zoom in to see all POI in the area.`
      }}</v-alert>
    </div>
    <PoiFilter
      v-model="showFilters"
      :filterPresetsSelected="filterPresetsSelected"
      :typeSelected="typeSelected"
      :activeSelected="activeSelected"
      @update="updateFilter"
    />
    <EditPoi v-model="poiToEdit" v-on:updated="reload()" @edit-another-poi="editPoi" :poiInitTab="poiInitTab" />

    <v-overlay :model-value="loading" class="progress" opacity="0" z-index="101" contained persistent>
      <v-progress-linear indeterminate color="primary-darken-1" :height="3" />
    </v-overlay>
  </v-container>
</template>

<script setup lang="ts">
import userProfileService from "@/services/UserProfileService";
import { UserPermissionType } from "@/types/UserPermissionType";
import L from "leaflet";
import { LMap, LTileLayer, LControlScale, LControl } from "@vue-leaflet/vue-leaflet";
import Poi from "@/types/Poi";
import SimplePoi from "@/types/SimplePoi";
import { PoiType } from "@/types/PoiType";
import poiResource from "@/resources/PoiResource";
import PoiHelper from "@/helpers/poiHelper";
import MapHelper from "@/helpers/mapHelper";
import EditPoi from "@/components/poi/EditPoi.vue";
import PoiFilter from "@/components/poi/PoiFilter.vue";
import userStorage from "@/services/UserStorageService";
import googleMapsResource from "@/resources/GoogleMapsResource";
import "@/components/common/leaflet/leaflet-markers-canvas.js";
import { ref, computed, watch, onActivated, onDeactivated } from "vue";

const props = withDefaults(
  defineProps<{
    readonly initData: { poiTab: string; poiId: number } | null;
  }>(),
  {
    initData: null,
  }
);

const markersCanvas = ref(new L.MarkersCanvas({ resetOnBoundingBoxChange: false }));

const maxPoi = ref(1000);
const loading = ref(false);
const poiLoading = ref(false);

const options = ref({
  preferCanvas: true,
});

const poiToEdit = ref<Poi | null>(null);
const poiInitTab = ref<string | null>(null);
const mapCenter = ref([64, 19]);
const mapZoom = ref(5);
const bounds = ref(
  {} as {
    ne: { lat: number; lng: number };
    sw: { lat: number; lng: number };
  }
);
const leaflet = {
  url: MapHelper.defaultMapTilesUrl,
  attribution: MapHelper.defaultMapAttr,
};

const filterPresetStorageKey = "poiMapFilterPreset";
const filterPresetsSelected = ref(userStorage.get(filterPresetStorageKey) ?? "active");
const filterPresetsName = ref("init");

const activeSelectedStorageKey = "poiMapActiveSelected";
const activeSelected = ref(userStorage.get(activeSelectedStorageKey) ?? "any");

const typeSelectedStorageKey = "poiMapTypeSelected";
const typeSelected = ref((userStorage.get(typeSelectedStorageKey) ?? []) as PoiType[]);

const showFilters = ref(false);

const hasMorePoi = ref(false);
const partialDataMessage = ref("");

let mapBoundsUpdateThresholdTimer = 0;
const mapBoundsUpdated = (newBounds: any) => {
  if (mapBoundsUpdateThresholdTimer) {
    clearTimeout(mapBoundsUpdateThresholdTimer);
  }

  mapBoundsUpdateThresholdTimer = setTimeout(() => {
    bounds.value = {
      ne: { lat: newBounds._northEast.wrap().lat, lng: newBounds._northEast.wrap().lng },
      sw: { lat: newBounds._southWest.wrap().lat, lng: newBounds._southWest.wrap().lng },
    };
    reload();
  }, 100);
};
watch(poiToEdit, function onChangeCustomerToEdit() {
  if (!poiToEdit.value) {
    poiInitTab.value = null;
  }
});

const canAddPoi = computed(() => userProfileService.hasPermission(UserPermissionType.AddPoi));

const mapRef = ref(null);
const onReadyMap = () => {
  const map = mapRef.value as any;
  markersCanvas.value.addTo(map.leafletObject);
  const newBounds = map.leafletObject.getBounds();
  bounds.value = {
    ne: { lat: newBounds._northEast.wrap().lat, lng: newBounds._northEast.wrap().lng },
    sw: { lat: newBounds._southWest.wrap().lat, lng: newBounds._southWest.wrap().lng },
  };
  reload();

  if (props.initData?.poiId) {
    getInitPoiById(props.initData?.poiId);
  }
};

let dataReloadTimeoutId: number | undefined = undefined;
const dataReloadIntervalSeconds = 60;
const componentActive = ref(false);

onActivated(() => {
  componentActive.value = true;

  // reload data (user haven't been on the page longer than dataReloadIntervalSeconds)
  if (dataReloadTimeoutId === 0) {
    reload();
  }
});
onDeactivated(() => {
  componentActive.value = false;
});

const getInitPoiById = (poiId: number) => {
  poiResource
    .getPoiById(poiId)
    .then((resp) => {
      editPoi(resp.data);
      poiInitTab.value = props.initData?.poiTab || null;
    })
    .catch(poiResource.defaultErrorHandler);
};

const restartDataReloadTimeout = () => {
  if (dataReloadTimeoutId) {
    clearTimeout(dataReloadTimeoutId);
  }

  dataReloadTimeoutId = setTimeout(() => {
    dataReloadTimeoutId = 0;
    if (componentActive.value) {
      reload();
    }
  }, dataReloadIntervalSeconds * 1000);
};

const editPoi = (poi: Poi) => {
  poiToEdit.value = null;

  // use timeout to ensure poi is reset
  setTimeout(() => {
    poiToEdit.value = Object.assign({}, poi);
  }, 100);
};

const showMapContextMenu = ref(false);
const mapContextMenuX = ref(0);
const mapContextMenuY = ref(0);
const lastMapContextMenuLatLng = ref({ lat: 0, lng: 0 });
const openMapContextMenu = (e: any) => {
  lastMapContextMenuLatLng.value = e.latlng;
  mapContextMenuX.value = e.originalEvent.clientX;
  mapContextMenuY.value = e.originalEvent.clientY;
  showMapContextMenu.value = true;
};

// Geocoding
const geocodeSearchTerm = ref("");
const geocodeLoading = ref(false);
const geocodeSelectedItem = ref<google.maps.GeocoderResult | null>(null);
const geocodeItems = ref<google.maps.GeocoderResult[]>([]);
let geocodeTimeout = 0;

const geocode = () => {
  geocodeLoading.value = true;
  googleMapsResource
    .geocodeAddress(geocodeSearchTerm.value)
    .then((resp) => {
      if (resp.results) {
        geocodeItems.value = resp.results;
      }
    })
    .catch(googleMapsResource.defaultErrorHandler)
    .finally(() => {
      geocodeLoading.value = false;
    });
};
// Geocoding end

const getPoiTypeName = (type: PoiType) => {
  return PoiHelper.getPoiTypeDisplayName(type);
};

const newPoi = (lat: number | undefined = undefined, lng: number | undefined = undefined) => {
  let newPoi = PoiHelper.getNewPoiTemplate();
  if (lat) {
    newPoi.latitude = Number(lat.toFixed(6));
  }
  if (lng) {
    newPoi.longitude = Number(lng.toFixed(6));
  }

  editPoi(newPoi);
};

const getPoiTooltip = (poi: SimplePoi) => {
  return `<div class="poi-tooltip"><h4> ${getPoiTypeName(poi.type)} ID: ${poi.poiId}</h4></div>`;
};

const updateMarkers = (markerData: SimplePoi[]) => {
  const markers: any[] = [];
  markerData
    .sort((a, b) => (a.isActive === b.isActive ? 0 : a.isActive ? 1 : -1))
    .forEach((item) => {
      const iconData = PoiHelper.getMapIconByType(item.type);
      const icon = L.icon({
        ...iconData,
        rotationAngle: iconData.roatationOrigin ? item.direction : 0,
        tooltipAnchor: [20, -20],
        popupAnchor: [0, -40],
        isActive: item.isActive,
        // isTransparent: true,
      });
      const marker = L.marker(L.latLng(item.latitude, item.longitude), { icon, data: item })
        .bindTooltip(getPoiTooltip(item))
        .on({ click: () => poiClick(item) });

      markers.push(marker);
    });

  setTimeout(() => markersCanvas.value.updateMarkers(markers), 0);
};

const getPoiInBounds = () => {
  if (!bounds.value.ne || !typeSelected.value.length) return;

  // Save sorting, filters and search terms
  userStorage.set(filterPresetStorageKey, filterPresetsSelected.value);
  userStorage.set(typeSelectedStorageKey, typeSelected.value);
  userStorage.set(activeSelectedStorageKey, activeSelected.value);

  // Restart data reload timeout
  restartDataReloadTimeout();

  // Filters
  let isActive: boolean | undefined = undefined;

  // isActive
  if (activeSelected.value === "active") {
    isActive = true;
  } else if (activeSelected.value === "inactive") {
    isActive = false;
  }

  loading.value = true;
  poiResource
    .getPoiInBounds(
      bounds.value.ne.lat,
      bounds.value.ne.lng,
      bounds.value.sw.lat,
      bounds.value.sw.lng,
      isActive,
      typeSelected.value,
      maxPoi.value
    )
    .then((resp) => {
      hasMorePoi.value = resp.data.length >= maxPoi.value;
      updateMarkers(resp.data);
    })
    .catch(poiResource.defaultErrorHandler)
    .finally(() => {
      loading.value = false;
    });
};

const poiClick = (poi: SimplePoi) => {
  if (!poi?.poiId) return;
  poiLoading.value = true;

  poiResource
    .getPoiById(poi.poiId)
    .then((resp) => editPoi(resp.data))
    .catch(poiResource.defaultErrorHandler)
    .finally(() => {
      poiLoading.value = false;
    });
};

const reload = () => {
  if (!typeSelected.value.length) {
    return markersCanvas.value.clear();
  }
  getPoiInBounds();

  // Restart data reload timeout
  restartDataReloadTimeout();
};

const updateFilter = (
  newFilterPresetsSelected: string,
  newTypeSelected: PoiType[],
  newActiveSelected: string,
  newFilterPresetsName: string,
  reloadPoi: boolean
) => {
  filterPresetsSelected.value = newFilterPresetsSelected;
  typeSelected.value = newTypeSelected;
  activeSelected.value = newActiveSelected;
  filterPresetsName.value = newFilterPresetsName;

  if (reloadPoi) {
    showFilters.value = false;
    reload();
  }
};

watch(geocodeSearchTerm, function onGeocodeSearch(term: string) {
  // Search threshold
  if (geocodeTimeout) {
    clearTimeout(geocodeTimeout);
    geocodeTimeout = 0;
  }

  if (!term) {
    geocodeSelectedItem.value = null;
    geocodeItems.value = [];
    geocodeLoading.value = false;
    return;
  }

  if (geocodeSelectedItem.value != null) return;

  geocodeItems.value = [];
  geocodeLoading.value = true;
  geocodeTimeout = setTimeout(() => {
    geocode();
  }, 2000);
});

watch(geocodeSelectedItem, function onGeocodeItemSelected() {
  if (geocodeSelectedItem.value) {
    mapZoom.value = 15;
    mapCenter.value = [
      geocodeSelectedItem.value!.geometry.location.lat(),
      geocodeSelectedItem.value!.geometry.location.lng(),
    ];
  }
});
</script>

<style scoped>
.map-wrap {
  width: 100%;
  height: 100%;
}

.poi-filter,
.poi-map-search-input {
  width: 220px;

  color: rgb(var(--v-theme-secondary));
}

.poi-map-search-input :deep(input) {
  font-size: 14px !important;
}
.filter-preset-label {
  width: 160px;
  overflow: hidden;
  text-overflow: ellipsis;
  text-transform: none;
  text-align: left;
  font-size: 12px;
}

.zoom-message {
  color: rgba(0, 0, 0, 0.6);
  margin-top: 5px;
}

.progress {
  align-items: center;
  justify-content: center;
}
.progress :deep(.v-overlay__content) {
  width: 20%;
  margin-bottom: 55px;
}

.partial-data-alert {
  position: absolute;
  bottom: 5px;
  left: 0;
  right: 0;
  padding: 0 1rem;
  z-index: 99;

  text-align: center;
}
</style>
<style>
.poi-map-search-input .v-label {
  font-size: 14px;
}
</style>
