<template>
  <v-overlay scrim="rgba(33, 33, 33)" v-model="show" opacity=".33" z-index="1000">
    <v-dialog
      data-side-sheet=""
      ref="sideSheet"
      v-bind:value="props.modelValue"
      v-bind:heading="props.heading"
      v-model="show"
      :no-click-animation="props.noClickAnimation"
      @click:outside="clickOutside"
      origin="top center"
      transition="slide-x-reverse-transition"
      class="side-sheet-dialog"
      :content-class="'side-sheet ' + props.contentClass"
      :opacity="0"
      @keydown.esc="show = false"
    >
      <v-card flat rounded="0">
        <div class="d-flex flex-column" style="height: 100%">
          <v-toolbar density="compact" flat color="primary text-dark" class="flex-grow-0">
            <v-toolbar-title>{{ heading }}</v-toolbar-title>

            <v-toolbar-items>
              <slot name="heading-menu" />
              <v-btn icon @click="show = false">
                <v-icon color="dark">mdi-close</v-icon>
              </v-btn>
            </v-toolbar-items>
          </v-toolbar>

          <div class="flex-grow-0">
            <slot name="tabs"></slot>
          </div>
          <div class="pa-4 flex-grow-1 overflow-auto" style="overflow-x: hidden !important">
            <slot />
          </div>
          <div class="side-sheet-actions pa-4 flex-grow-0 text-right d-flex">
            <slot name="actions" />
          </div>
        </div>
      </v-card>
    </v-dialog>
  </v-overlay>
</template>

<script setup lang="ts">
import { ref, computed, onActivated, onDeactivated } from "vue";

const emit = defineEmits(["update:modelValue"]);

const props = withDefaults(
  defineProps<{
    readonly modelValue?: boolean;
    readonly heading: string;
    readonly contentClass?: string;
    noClickAnimation?: boolean;
  }>(),
  {
    noClickAnimation: false,
  }
);

const isMounted = ref(false);

const clickOutside = (e: PointerEvent) => {
  const target = e.target as HTMLDivElement;
  if (target?.classList.contains("v-overlay__scrim")) {
    emit("update:modelValue", false);
  }
};

onActivated(() => {
  if (!isMounted.value) return (isMounted.value = true);
  if (props.modelValue) show.value = true;
});

onDeactivated(() => {
  show.value = false;
});

const show = computed({
  get() {
    return props.modelValue;
  },

  set(value: boolean) {
    emit("update:modelValue", value);
  },
});
</script>
