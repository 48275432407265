<template>
  <v-dialog scrim="rgba(33, 33, 33)" v-model="showDialog" width="1700" @keydown.esc="showDialog = false" scrollable>
    <template v-slot:activator="{ props }">
      <v-btn variant="text" class="px-0 pr-0" size="small" v-bind="props">
        <v-icon>mdi-chart-box-outline</v-icon>
        <span class="stats-label">Device stats</span>
      </v-btn>
    </template>
    <v-card>
      <v-toolbar flat color="primary" :height="4"> </v-toolbar>
      <v-card-title class="d-flex mt-2">
        Device statistics
        <v-spacer></v-spacer>
        <v-btn icon size="small" class="ma-2" variant="text" density="compact" @click="showDialog = false">
          <v-icon size="large"> mdi-close </v-icon>
        </v-btn>
      </v-card-title>
      <v-card-text>
        <v-container fluid class="dialog-content">
          <v-row v-if="!loading && !isError && totalDevicesAnalysed">
            <v-col
              cols="12"
              sm="6"
              md="3"
              class="pa-md-6 pa-3"
              v-for="([name, value], ind) in Object.entries(data)"
              :key="ind"
            >
              <div class="text-subtitle-1 mb-2">{{ name }}</div>
              <div v-for="([title, quantity], ind) in Object.entries(value)" :key="ind" class="d-flex mt-2 string">
                <div class="flex-grow-1 text-caption text-truncate">{{ title }}</div>
                <div class="text-caption mx-1 quantity">{{ quantity }}</div>
                <div class="text-caption mx-1 quantity">{{ getPercent(quantity) }}%</div>
                <div class="percent-bar" :style="{ width: `${getPercent(quantity)}%` }" />
              </div>
              <div v-if="getUnknownValue(value)" class="d-flex mt-2 string">
                <div class="flex-grow-1 text-caption text-truncate">Unknown</div>
                <div class="text-caption mx-1 quantity">{{ getUnknownValue(value) }}</div>
                <div class="text-caption mx-1 quantity">{{ getPercent(getUnknownValue(value)) }}%</div>
                <div class="percent-bar" :style="{ width: `${getPercent(getUnknownValue(value))}%` }" />
              </div>
            </v-col>
          </v-row>

          <div v-if="!loading && !isError && !totalDevicesAnalysed" class="d-flex w-100 justify-center text-h6">
            No data available
          </div>

          <div class="progress" v-if="loading">
            <v-progress-linear class="w-50" indeterminate color="primary" />
          </div>
          <div v-if="isError" class="text-red d-flex w-100 justify-center text-h6">An error has occurred.</div>
        </v-container>
      </v-card-text>

      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn variant="text" @click="showDialog = false"> Close </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script setup lang="ts">
import Issue from "@/types/Issue";
import issueResource from "@/resources/IssueResource";
import axios, { CancelTokenSource } from "axios";
import { ref, watch } from "vue";

const showDialog = ref(false);
let cancelToken: CancelTokenSource | undefined = undefined;
const loading = ref(false);
const data = ref<{ [key: string]: { [key: string]: number } }>({});
const totalDevicesAffected = ref(0);
const totalDevicesAnalysed = ref(0);
const isError = ref(false);

const props = withDefaults(defineProps<{ readonly issue: Issue | null }>(), { issue: null });

const getUnknownValue = (value: { [key: string]: number }) => {
  return totalDevicesAffected.value - Object.values(value).reduce((acc, item) => (acc += item), 0);
};

const getPercent = (quantity: number) => {
  if (!totalDevicesAffected.value) {
    return 0;
  }

  return Math.round((quantity / totalDevicesAffected.value) * 100 * 10) / 10;
};

const getData = () => {
  if (cancelToken) {
    cancelToken.cancel();
  }

  loading.value = true;
  isError.value = false;
  setTimeout(async () => {
    // Timeout is workaround for finally() being executed after request was canceled and new request already began
    cancelToken = axios.CancelToken.source();

    if (!props.issue?.issueId) return;
    issueResource
      .getDeviceStats(props.issue.issueId, cancelToken)
      .then((resp) => {
        data.value = resp.data.aggr;
        totalDevicesAffected.value = resp.data.totalDevicesAffected;
        totalDevicesAnalysed.value = resp.data.totalDevicesAnalysed;
      })
      .catch((e) => {
        issueResource.defaultErrorHandler(e);
        isError.value = true;
      })
      .finally(() => {
        loading.value = false;
        cancelToken = undefined;
      });
  }, 10);
};

watch(showDialog, function onChangeShowDialog() {
  if (showDialog.value) {
    return getData();
  }

  if (cancelToken) {
    cancelToken.cancel();
  }
});
</script>

<style scoped>
.quantity {
  min-width: 50px;
  text-align: right;
}
.string {
  border-bottom: 1px solid rgba(175, 175, 175, 0.15);
  position: relative;
}
.percent-bar {
  position: absolute;
  bottom: -1px;
  left: 0;
  background: rgb(var(--v-theme-secondary));
  height: 1px;
}
.v-theme--dark .percent-bar {
  background: rgb(var(--v-theme-primary));
}
.progress {
  width: 100%;
  padding: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.stats-label {
  margin-left: 2px;
  text-transform: none;
  font-size: 14px;
}
.dialog-content {
  background: #fbfbfb;
  height: 100%;
  overflow: auto;
  box-shadow: inset 0px 2px 6px rgba(0, 0, 0, 0.1);
}
.v-theme--dark .dialog-content {
  background: #1c1c1c;
}
</style>
