<template>
  <div>
    <side-sheet
      v-if="props.modelValue"
      :heading="dialogHeading"
      :modelValue="showDialog"
      @update:modelValue="close"
      @click-outside="close"
      :noClickAnimation="true"
    >
      <template v-slot:tabs>
        <v-tabs v-model="tab" grow color="primary" slider-color="primary">
          <v-tab value="advert">Advert</v-tab>
          <v-tab value="stats">Stats</v-tab>
        </v-tabs>
      </template>

      <v-window class="full-height-tabs-wrap tabs-w-100" v-model="tab" :touch="false">
        <!-- POI -->
        <v-tabs-window-item :reverse-transition="false" value="advert" transition="none">
          <v-form ref="advertForm" v-model="valid" lazy-validation>
            <PropEditor v-if="props.modelValue.advertId" name="Info">
              <div class="text-subtitle-2">
                <div><span class="info-label">Advert ID:</span> {{ props.modelValue.advertId }}</div>
                <div v-if="props.modelValue.createdDate">
                  <span class="info-label">Created:</span> {{ moment(props.modelValue.createdDate).format("lll") }}
                </div>
              </div>
            </PropEditor>
            <PropEditor name="Image">
              <img :src="imagePreview || undefined" alt="" class="ad-image" />
              <div class="img-field" v-if="canEditAds">
                <v-file-input
                  accept="image/png, image/bmp"
                  :rules="[fileInputValidation]"
                  prepend-icon="mdi-camera"
                  label="Upload new image"
                  :loading="imageLoading"
                  @update:modelValue="newImageSelected"
                ></v-file-input>
              </div>
            </PropEditor>
            <PropEditor name="Active" desc="">
              <v-switch v-model="props.modelValue.isActive" density="compact" :readonly="!canEditAds" class="my-0" />
            </PropEditor>
            <PropEditor name="Organisation ID" desc="">
              <v-text-field
                v-model="props.modelValue.organisationId"
                density="compact"
                :variant="canEditAds ? 'outlined' : 'solo'"
                :flat="!canEditAds"
                :readonly="!canEditAds"
              ></v-text-field>
            </PropEditor>
            <PropEditor name="Campaign ID" desc="">
              <v-text-field
                v-model="props.modelValue.campaignId"
                density="compact"
                :variant="canEditAds ? 'outlined' : 'solo'"
                :flat="!canEditAds"
                :readonly="!canEditAds"
              ></v-text-field>
            </PropEditor>
            <PropEditor name="Advert type">
              <v-select
                v-model="props.modelValue.advertType"
                :items="advertTypes"
                density="compact"
                single-line
                :variant="canEditAds ? 'outlined' : 'solo'"
                :flat="!canEditAds"
                :readonly="!canEditAds"
                attach
              ></v-select>
            </PropEditor>
            <PropEditor v-if="props.modelValue.advertType == 1" name="Area">
              <v-select
                v-model="props.modelValue.advertLocationId"
                :items="areas"
                density="compact"
                single-line
                :variant="canEditAds ? 'outlined' : 'solo'"
                :flat="!canEditAds"
                :readonly="!canEditAds"
                attach
              ></v-select>
            </PropEditor>
            <PropEditor v-else-if="props.modelValue.advertType == 2" name="Location" valign="top">
              <v-autocomplete
                v-model="geocodeSelectedItem"
                :items="geocodeItems"
                :loading="geocodeLoading"
                v-model:search="geocodeSearchTerm"
                density="compact"
                variant="outlined"
                clearable
                hide-no-data
                no-filter
                label="Address search"
                item-title="formatted_address"
                class="mb-2 map-search-field"
                :loader-height="4"
                return-object
              ></v-autocomplete>
              <div class="map-wrap">
                <l-map
                  v-if="showMap && props.modelValue"
                  class="map-container"
                  :zoom.sync="mapZoom"
                  :center="mapCenter"
                  @click="setMarker"
                  @contextmenu="() => false"
                >
                  <l-tile-layer :url="leaflet.url" :attribution="leaflet.attribution"></l-tile-layer>
                  <l-control-scale position="bottomleft" :imperial="false" :metric="true"></l-control-scale>
                  <!-- ACTIVE MARKER -->
                  <l-marker-rotate
                    v-if="mapIcon"
                    :lat-lng="[props.modelValue.advertLocation.latitude, props.modelValue.advertLocation.longitude]"
                    :draggable="canEditAds"
                    :rotationAngle="mapIcon.roatationOrigin ? props.modelValue.direction : 0"
                    :rotationOrigin="mapIcon.roatationOrigin ? mapIcon.roatationOrigin : null"
                    @dragend="markerPositionUpdated"
                    @click="() => false"
                  >
                    <l-icon
                      :icon-url="mapIcon.iconUrl"
                      :icon-size="mapIcon.iconSize"
                      :icon-anchor="mapIcon.iconAnchor"
                    ></l-icon>
                  </l-marker-rotate>
                </l-map>
              </div>
              <v-row class="mt-2">
                <v-col>
                  <v-text-field
                    v-model="latLng"
                    label="Advert coordinates (latitude, longitude)"
                    density="compact"
                    variant="outlined"
                    :flat="!canEditAds"
                    :readonly="!canEditAds"
                    clearable
                    @update:model-value="() => applyLatLngToAdvert()"
                    :rules="latLngRule"
                  />
                </v-col>
              </v-row>
            </PropEditor>
            <PropEditor name="Promo text" desc="">
              <v-text-field
                v-model="props.modelValue.promoText"
                density="compact"
                :variant="canEditAds ? 'outlined' : 'solo'"
                :flat="!canEditAds"
                :readonly="!canEditAds"
              ></v-text-field>
            </PropEditor>
            <PropEditor v-if="showDirection" name="Radius">
              <v-row class="align-center">
                <v-col>
                  <v-slider
                    min="0"
                    max="2000"
                    v-model="props.modelValue.activeRadius"
                    step="50"
                    hide-details
                    :readonly="!canEditAds"
                    class="my-0"
                  ></v-slider>
                </v-col>
                <v-col class="flex-grow-0">
                  <v-text-field
                    v-model="props.modelValue.activeRadius"
                    step="50"
                    type="number"
                    variant="outlined"
                    density="compact"
                    :readonly="!canEditAds"
                    style="width: 80px"
                    label="Meters"
                  />
                </v-col>
              </v-row>
            </PropEditor>
            <PropEditor v-if="showDirection" name="Direction">
              <v-row class="align-center">
                <v-col>
                  <v-slider
                    min="-1"
                    max="359"
                    v-model="props.modelValue.direction"
                    step="1"
                    hide-details
                    :readonly="!canEditAds"
                    class="my-0"
                  ></v-slider>
                </v-col>
                <v-col class="flex-grow-0">
                  <v-text-field
                    v-model="props.modelValue.direction"
                    type="number"
                    variant="outlined"
                    density="compact"
                    :readonly="!canEditAds"
                    style="width: 80px"
                    label="Degrees"
                  />
                </v-col>
              </v-row>
            </PropEditor>
            <PropEditor name="Maximum shows">
              <v-row class="align-center">
                <v-col>
                  <v-slider
                    min="0"
                    max="100"
                    v-model="props.modelValue.maximumShows"
                    step="1"
                    hide-details
                    :readonly="!canEditAds"
                    class="my-0"
                  ></v-slider>
                </v-col>
                <v-col class="flex-grow-0">
                  <v-text-field
                    v-model="props.modelValue.maximumShows"
                    type="number"
                    variant="outlined"
                    density="compact"
                    step="1"
                    min="0"
                    max="100"
                    :readonly="!canEditAds"
                    style="width: 80px"
                  />
                </v-col>
              </v-row>
            </PropEditor>
            <PropEditor name="Screen time">
              <div class="d-flex mb-2">
                <v-text-field
                  v-model="screenTime[0]"
                  class="flex-grow-0"
                  style="width: 140px"
                  type="number"
                  step="1"
                  label="Min screen time, sec"
                  variant="outlined"
                  density="compact"
                  :readonly="!canEditAds"
                  @change="(event: Event) => (screenTime[0] = Number((event.target as HTMLInputElement).value))"
                ></v-text-field>
                <v-spacer />
                <v-text-field
                  v-model="screenTime[1]"
                  class="flex-grow-0"
                  style="width: 140px"
                  type="number"
                  step="1"
                  label="Max screen time, sec"
                  variant="outlined"
                  density="compact"
                  :readonly="!canEditAds"
                  @change="(event: Event) => (screenTime[1] = Number((event.target as HTMLInputElement).value))"
                ></v-text-field>
              </div>
              <v-range-slider
                min="0"
                max="60"
                v-model="screenTime"
                :readonly="!canEditAds"
                step="1"
                hide-details
              ></v-range-slider>
            </PropEditor>
          </v-form>
        </v-tabs-window-item>

        <v-tabs-window-item :reverse-transition="false" value="stats" transition="none">
          <AdvertStatsView :advertId="statsAdvertId" />
        </v-tabs-window-item>
      </v-window>

      <template v-slot:actions>
        <v-btn
          v-if="canDeleteAds && props.modelValue.advertId"
          color="secondary"
          @click="deleteAdvertConfirm"
          :loading="deleting"
          :disabled="deleting"
          >Delete</v-btn
        >
        <v-spacer></v-spacer>
        <v-btn variant="text" @click="showDialog = false">Cancel</v-btn>
        <v-btn
          v-if="canEditAds"
          color="primary"
          class="ml-4"
          @click="submit"
          :loading="loading"
          :disabled="loading || disabledSubmitBtn"
          >Submit</v-btn
        >
      </template>
    </side-sheet>
  </div>
</template>

<script setup lang="ts">
import SideSheet from "@/components/layout/SideSheet.vue";
import PropEditor from "@/components/layout/PropEditor.vue";
import infoMessageService from "@/services/InfoMessageService";
import { InfoMessageType } from "@/types/InfoMessageType";
import userProfileService from "@/services/UserProfileService";
import moment from "moment";
import { UserPermissionType } from "@/types/UserPermissionType";
import L from "leaflet";
import { LMap, LTileLayer, LMarker, LControlScale, LIcon, LTooltip, LPopup, LControl } from "@vue-leaflet/vue-leaflet";
//@ts-ignore
import { LMarkerRotate } from "vue-leaflet-rotate-marker";
import MapHelper from "@/helpers/mapHelper";
import googleMapsResource from "@/resources/GoogleMapsResource";
import MapIcons from "@/types/MapIcons";
import AdvertHelper from "@/helpers/advertHelper";
import { AdvertType } from "@/types/AdvertType";
import advertResource from "@/resources/AdvertResource";
import Advert from "@/types/Advert";
import AdvertStatsView from "./AdvertStatsView.vue";
import ChangeManager from "@/services/ChangeManager";
import { VForm } from "vuetify/components";
import { ref, watch, computed } from "vue";
import { useConfirm } from "@/services/ConfirmService";
import { useComponentQuery } from "@/globalProperties";

const emit = defineEmits(["update:modelValue", "updated"]);
const props = withDefaults(
  defineProps<{
    modelValue: Advert | null;
    readonly advertInitTab: string | null;
  }>(),
  { modelValue: null }
);

const confirm = useConfirm();
const { setComponentQuery } = useComponentQuery();

const disabledSubmitBtn = computed(() => !ChangeManager.state().isChanged);

const statsAdvertId = ref<number | null>(null);

const tab = ref<string | null>(null);

const showDialog = computed({
  get() {
    return props.modelValue != null;
  },
  set(value: boolean) {
    emit("update:modelValue", null);
  },
});

const dialogHeading = computed(() => {
  let heading = "";
  if (props.modelValue) {
    heading = props.modelValue?.advertId ? `Advert ID: ${props.modelValue.advertId}` : "New Advert";
  }
  return heading;
});

const valid = ref(true);
const loading = ref(false);

const imageLoading = ref(false);
const imagePreview = ref<string | null>(null);

const advertTypes = [
  // { text: AdvertHelper.getAdvertTypeDisplayName(AdvertType.National), value: AdvertType.National },
  { title: AdvertHelper.getAdvertTypeDisplayName(AdvertType.Area), value: AdvertType.Area },
  { title: AdvertHelper.getAdvertTypeDisplayName(AdvertType.Spot), value: AdvertType.Spot },
];

const areas = [
  { title: "Namsos", value: 367 },
  { title: "Trondheim", value: 170 },
];

const screenTime = ref([0, 0]);

const showDirection = computed(() => props.modelValue && props.modelValue.advertType === AdvertType.Spot);

const showMap = ref(false);
const mapCenter = ref([0, 0]);
const mapZoom = ref(15);
const leaflet = {
  url: MapHelper.defaultMapTilesUrl,
  attribution: MapHelper.defaultMapAttr,
};

const mapIcon = MapIcons.default;

const canAddAds = computed(() => userProfileService.hasPermission(UserPermissionType.AddPoi));

const canEditAds = computed(() => {
  return (
    userProfileService.hasPermission(UserPermissionType.EditAds) ||
    (props.modelValue && props.modelValue.advertId === 0 && canAddAds.value)
  );
});

const canDeleteAds = computed(() => userProfileService.hasPermission(UserPermissionType.DeleteAds));

const deleting = ref(false);

const geocodeSearchTerm = ref("");
const geocodeLoading = ref(false);
const geocodeSelectedItem = ref<google.maps.GeocoderResult | null>(null);
const geocodeItems = ref<google.maps.GeocoderResult[]>([]);
let geocodeTimeout = 0;

const latLng = ref<string | undefined>("");
const latLngRule = [
  (v: string) => !!v || "Field is required",
  (v: string) =>
    /^[-+]?([1-8]?\d(\.\d+)?|90(\.0+)?),\s*[-+]?(180(\.0+)?|((1[0-7]\d)|([1-9]?\d))(\.\d+)?)$/.test(v) ||
    "Correct format is 'lat, lng', ex. 63.123456, 10.123456",
];

const advertForm = ref<InstanceType<typeof VForm> | null>(null);
const setMarker = (e: any) => {
  if (canEditAds.value && props.modelValue) {
    latLng.value = `${e.latlng.lat.toFixed(6)}, ${e.latlng.lng.toFixed(6)}`;
    applyLatLngToAdvert(false);
  }
};

const markerPositionUpdated = (e: any) => {
  if (canEditAds.value && props.modelValue) {
    const newLatLng = e.target.getLatLng();
    latLng.value = `${newLatLng.lat.toFixed(6)}, ${newLatLng.lng.toFixed(6)}`;
    applyLatLngToAdvert(false);
  }
};

const setLatLngFromAdvert = () => {
  latLng.value = `${props.modelValue!.advertLocation.latitude.toFixed(
    6
  )}, ${props.modelValue!.advertLocation.longitude.toFixed(6)}`;
};

const applyLatLngToAdvert = async (recenterMap: boolean = true) => {
  const { valid } = (await advertForm.value?.validate()) || {};

  if (!latLng.value || latLng.value === undefined || !valid) {
    return;
  }

  infoMessageService.clear();

  const latLngArray = latLng.value.split(",").map((elm) => parseFloat(elm.trim()));
  if (latLngArray.length === 2 && !latLngArray.some((elm) => isNaN(elm))) {
    props.modelValue!.advertLocation.latitude = parseFloat(latLngArray[0].toFixed(6));
    props.modelValue!.advertLocation.longitude = parseFloat(latLngArray[1].toFixed(6));

    if (recenterMap) {
      mapCenter.value = latLngArray;
    }
  } else {
    infoMessageService.show(InfoMessageType.Error, "Cannot parse latitude, longitude value.");
  }
};

const geocode = () => {
  geocodeLoading.value = true;
  googleMapsResource
    .geocodeAddress(geocodeSearchTerm.value)
    .then((resp) => {
      if (resp.results) {
        geocodeItems.value = resp.results;
      }
    })
    .catch(googleMapsResource.defaultErrorHandler)
    .finally(() => {
      geocodeLoading.value = false;
    });
};

const submit = async () => {
  if (props.modelValue === null) {
    return;
  }

  // Validate form
  const { valid } = (await advertForm.value?.validate()) || {};
  if (valid) {
    // Get value
    props.modelValue.minTimeOnScreen = screenTime.value[0];
    props.modelValue.maxTimeOnScreen = screenTime.value[1];

    if (props.modelValue.advertId) {
      // Update
      loading.value = true;
      advertResource
        .updateAdvert(props.modelValue)
        .then((resp) => {
          showDialog.value = false;
          emit("updated", props.modelValue);
        })
        .catch(advertResource.defaultErrorHandler)
        .finally(() => {
          loading.value = false;
        });
    } else {
      // New
      loading.value = true;
      advertResource
        .addAdvert(props.modelValue)
        .then((resp) => {
          infoMessageService.show(InfoMessageType.Success, `New Advert with ID ${resp.data} created`);
          showDialog.value = false;
          emit("updated", props.modelValue);
        })
        .catch(advertResource.defaultErrorHandler)
        .finally(() => {
          loading.value = false;
        });
    }
  }
};

const newImageSelected = (file: any) => {
  if (!file || typeof fileInputValidation([file]) === "string") {
    return;
  }

  const reader = new FileReader();
  reader.readAsDataURL(file);

  reader.onload = () => {
    const imgData = reader.result!.toString();

    imageLoading.value = true;
    advertResource
      .generateImagePreview(imgData)
      .then((resp) => {
        props.modelValue!.imageDataBase64 = imgData;
        imagePreview.value = resp.data;
      })
      .catch(advertResource.defaultErrorHandler)
      .finally(() => {
        imageLoading.value = false;
      });
  };

  reader.onerror = function (error) {
    console.log("Error: ", error);
  };
};

const deleteAdvertConfirm = () => {
  if (!canDeleteAds.value || props.modelValue == null) {
    return;
  }

  confirm.show(`Delete Advert ID '${props.modelValue.advertId}'?`).then((confirmed) => {
    if (confirmed) {
      deleteAdvert();
    }
  });
};

const deleteAdvert = () => {
  if (!canDeleteAds.value || props.modelValue == null) {
    return;
  }

  deleting.value = true;
  advertResource
    .deleteAdvert(props.modelValue.advertId)
    .then((resp) => {
      showDialog.value = false;
      emit("updated");
    })
    .catch(advertResource.defaultErrorHandler)
    .finally(() => {
      deleting.value = false;
    });
};

// begin change management
const changesControl = ref<ChangeManager | null>(null);
watch(
  () => props.modelValue,
  function setChangeManager(val: Advert | null, oldValue: Advert | null) {
    changesControl.value = ChangeManager.modalController({
      controller: changesControl.value,
      isNewValue: val && oldValue === null,
      isDestroy: oldValue && val === null,
      isUpdateValue: oldValue && val && oldValue.advertId !== val.advertId,
      data: { advert: val },
      message: "You have unsaved Advert changes.",
      target: `advert_${val?.advertId}`,
      onLeave: () => {
        showDialog.value = false;
      },
      onSave: submit,
    });
  }
);

watch(
  () => props.modelValue,
  function checkChangesStatus() {
    setChangesStatus();
  },
  { deep: true }
);

watch(
  screenTime,
  function onChangeScreenTime() {
    setChangesStatus();
  },
  { deep: true }
);

const fileInputValidation = ([value]: [File]) => {
  return !value || Boolean(["image/png", "image/bmp"].includes(value.type)) || "Only png and bmp files allowed";
};

const setChangesStatus = () => {
  if (!props.modelValue) {
    return;
  }

  const origAdvert = changesControl.value?.data?.origData?.advert;
  if (!changesControl.value || !origAdvert) return;

  if (
    !ChangeManager.isObjectEqual(origAdvert, props.modelValue || {}, {
      isOrigPartial: true,
      exclude: ["direction", "imageDataBase64"],
    })
  ) {
    changesControl.value?.activate();
    return;
  }

  if ((origAdvert.direction || -1) !== props.modelValue.direction) {
    changesControl.value?.activate();
    return;
  }

  if (!origAdvert.imageDataBase64 && Boolean(props.modelValue.imageDataBase64)) {
    changesControl.value?.activate();
    return;
  }

  if (
    origAdvert.imageDataBase64 &&
    props.modelValue.imageDataBase64 &&
    props.modelValue.imageDataBase64 !== origAdvert.imageDataBase64
  ) {
    changesControl.value?.activate();
    return;
  }

  if (props.modelValue.minTimeOnScreen !== screenTime.value[0] || props.modelValue.maxTimeOnScreen !== screenTime.value[1]) {
    changesControl.value?.activate();
    return;
  }

  changesControl.value?.deactivate();
};

const close = (value: boolean) => {
  if (!value && ChangeManager.state().isChanged) {
    ChangeManager.show();
    return;
  }

  showDialog.value = value;
};
// end change management

watch(
  () => props.modelValue,
  function onAdvertChange() {
    if (props.modelValue) {
      if (props.modelValue.advertType == AdvertType.Spot) {
        mapCenter.value = [props.modelValue.advertLocation.latitude, props.modelValue.advertLocation.longitude];
        setLatLngFromAdvert();
      }
      mapZoom.value = 15;
      screenTime.value = [props.modelValue.minTimeOnScreen, props.modelValue.maxTimeOnScreen];
      imagePreview.value = props.modelValue.imageDataBase64;
      props.modelValue.imageDataBase64 = null; // Reset image data. When updating image, this field should contain new image data.

      // delay map init
      setTimeout(() => {
        showMap.value = true;
      }, 100);

      if (props.advertInitTab) {
        tab.value = props.advertInitTab || null;
      }
    } else {
      setComponentQuery("advertTab", null);
      tab.value = null;
      showMap.value = false;
      mapCenter.value = [0, 0];
      geocodeSelectedItem.value = null;
      geocodeItems.value = [];
      statsAdvertId.value = null;
    }

    setComponentQuery("advertId", props.modelValue?.advertId ? props.modelValue.advertId : null);
  }
);

watch(tab, function onTabChange(val: string | null) {
  if (val === "stats") {
    statsAdvertId.value = props.modelValue!.advertId;
  }
  if (props.modelValue?.advertId) {
    setComponentQuery("advertTab", val);
  }
});

watch(geocodeSearchTerm, function onGeocodeSearch(term: string) {
  // Search threshold
  if (geocodeTimeout) {
    clearTimeout(geocodeTimeout);
    geocodeTimeout = 0;
  }

  if (!term) {
    geocodeSelectedItem.value = null;
    geocodeItems.value = [];
    geocodeLoading.value = false;
    return;
  }

  if (geocodeSelectedItem.value != null) return;

  geocodeItems.value = [];
  geocodeLoading.value = true;
  geocodeTimeout = setTimeout(() => {
    geocode();
  }, 2000);
});

watch(geocodeSelectedItem, function onGeocodeItemSelected() {
  if (geocodeSelectedItem.value) {
    mapZoom.value = 15;
    mapCenter.value = [geocodeSelectedItem.value.geometry.location.lat(), geocodeSelectedItem.value.geometry.location.lng()];
  }
});
</script>

<style scoped>
.map-wrap {
  width: 100%;
  height: 360px;
}
.ad-image {
  display: block;
  max-width: 320px;
  max-height: 180px;
  margin-bottom: 1rem;
}

.img-field {
  position: relative;
}
.img-field :deep(.v-input__details) {
  position: static;
  min-height: 0;
  margin-top: 5px;
}

.img-field :deep(.v-messages) {
  min-height: 0;
}
</style>
