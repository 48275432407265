<template>
  <form @submit.prevent="updatePassword" class="form-auth" method="post">
    <h3 class="form-auth-heading"><img src="/favicon.png" alt="Safedrive" />Safedrive</h3>
    <p>Please update your password.</p>
    <p class="mb-1">New password must be:</p>
    <ul class="mt-1 mb-4">
      <li>at least 8 characters long</li>
      <li>at least one upper case character</li>
      <li>at least one lower case character</li>
      <li>at least one digit</li>
      <li>at least one special character, or total password length more that 12 characters</li>
    </ul>
    <div class="input-field">
      <v-text-field v-model="currentPassword" type="password" label="Current password" required autocomplete="off" />
    </div>
    <div class="input-field">
      <v-text-field v-model="newPassword" type="password" label="New password" required autocomplete="off" />
    </div>
    <div class="input-field">
      <v-text-field v-model="passwordRepeat" type="password" label="Repeat new password" required autocomplete="off" />
    </div>

    <v-btn class="submit-button" type="submit" :loading="loading" :disabled="isSubmitDisable"> Continue </v-btn>
  </form>
</template>

<script setup lang="ts">
import authService from "@/services/AuthService";
import authResource from "@/resources/AuthResource";
import { ref, computed } from "vue";

const loading = ref(false);
const currentPassword = ref("");
const newPassword = ref("");
const passwordRepeat = ref("");

const isSubmitDisable = computed(() => {
  return loading.value || !passwordRepeat.value || !newPassword.value || !currentPassword.value;
});

const updatePassword = () => {
  loading.value = true;

  authResource
    .updatePassword(currentPassword.value, newPassword.value, passwordRepeat.value)
    .then(() => {
      authService.setRequirePasswordUpdate(false);
      authService.redirect();
    })
    .catch(authResource.defaultErrorHandler)
    .finally(() => (loading.value = false));
};
</script>

<style scoped>
.link-btn {
  color: #bebebe;
  text-decoration: underline;
}
ul li {
  list-style: disc !important;
  list-style-position: outside !important;
  margin-left: 1rem;
}
.mt-1 {
  margin-top: 0.25rem;
}
.mb-1 {
  margin-bottom: 0.25rem;
}
.mb-4 {
  margin-bottom: 2rem;
}
</style>
