<template>
  <div>
    <side-sheet
      v-if="props.modelValue"
      :modelValue="showDialog"
      @update:modelValue="close"
      @click-outside="close"
      :heading="dialogHeading"
      :noClickAnimation="true"
    >
      <template v-slot:tabs>
        <v-tabs v-model="tab" grow :show-arrows="false" color="primary" slider-color="primary">
          <v-tab value="key">Api key</v-tab>
          <v-tab value="stats">Stats</v-tab>
        </v-tabs>
      </template>

      <v-window v-model="tab" :touch="false" class="full-height-tabs-wrap tabs-w-100">
        <!-- API KEY -->
        <v-tabs-window-item :reverse-transition="false" value="key" transition="none">
          <v-form ref="apiKeyForm" v-model="valid" lazy-validation>
            <PropEditor v-if="props.modelValue.apiKeyId" name="Info">
              <div class="text-subtitle-2">
                <div><span class="info-label">Api key ID:</span> {{ props.modelValue.apiKeyId }}</div>
                <div v-if="props.modelValue.createdAt">
                  <span class="info-label">Created:</span> {{ moment(props.modelValue.createdAt).format("lll") }}
                </div>
                <div v-if="props.modelValue.createdBy">
                  <span class="info-label">Created by:</span> {{ props.modelValue.createdBy }}
                </div>
                <div v-if="props.modelValue.lastModifiedAt">
                  <span class="info-label">Updated:</span> {{ moment(props.modelValue.lastModifiedAt).format("lll") }}
                </div>
                <div v-if="props.modelValue.lastModifiedBy">
                  <span class="info-label">Updated by:</span> {{ props.modelValue.lastModifiedBy }}
                </div>
              </div>
            </PropEditor>

            <PropEditor name="Name" desc="Short description of the service provider or app that uses API key.">
              <v-text-field
                density="compact"
                variant="outlined"
                v-model="props.modelValue.name"
                :rules="nameRules"
              ></v-text-field>
            </PropEditor>
            <PropEditor
              name="Allowed IP addresses"
              desc="Allow-list of comma separated IP addresses or IP address ranges. Leave empty to allow any IP."
            >
              <v-text-field
                class="ip-filter"
                density="compact"
                variant="outlined"
                v-model="props.modelValue.ipFilter"
                autocomplete="off"
                hint="Example: 192.168.0.1, 192.168.0.2-192.168.0.10, 192.168.1.0/24"
                persistent-hint
              ></v-text-field>
            </PropEditor>
            <PropEditor
              name="Key"
              desc="Generated unique key that should be included the X-API-KEY header during API requests."
              v-if="props.modelValue.apiKeyId"
            >
              <!-- A hack (not sure it helps actually) to stop password managers to treat API key as password that needs to be stored. -->
              <input
                name="disable-pwd-mgr-1"
                type="password"
                id="disable-pwd-mgr-1"
                style="display: none"
                value="disable-pwd-mgr-1"
              />
              <input
                name="disable-pwd-mgr-2"
                type="password"
                id="disable-pwd-mgr-2"
                style="display: none"
                value="disable-pwd-mgr-2"
              />
              <input
                name="disable-pwd-mgr-3"
                type="password"
                id="disable-pwd-mgr-3"
                style="display: none"
                value="disable-pwd-mgr-3"
              />

              <div class="d-flex align-center">
                <v-text-field
                  density="compact"
                  variant="outlined"
                  v-model="props.modelValue.key"
                  @click:append="displayKey = !displayKey"
                  :append-icon="displayKey ? 'mdi-eye' : 'mdi-eye-off'"
                  :type="displayKey ? 'text' : 'password'"
                  readonly
                  autocomplete="off"
                  data-lpignore="true"
                  accept="numbers"
                ></v-text-field>
                <v-btn
                  class="ml-2"
                  variant="text"
                  density="compact"
                  icon
                  size="small"
                  title="Copy API key to clipboard"
                  @click="copyAPIToClipboard"
                >
                  <v-icon>mdi-content-copy</v-icon>
                </v-btn>
                <v-btn
                  size="small"
                  density="compact"
                  variant="text"
                  icon
                  class="ml-2 d-inline"
                  @click="confirmAPIKeyRegeneration"
                  :loading="regenerating"
                  title="Rotate key"
                >
                  <v-icon>mdi-reload</v-icon>
                </v-btn>
              </div>
            </PropEditor>
            <div class="mt-10">Permissions</div>
            <v-divider class="mt-2 mb-4" />
            <PropEditor v-for="pg in permissions" :key="pg.name" :name="pg.name" :valign="'top'">
              <v-switch
                v-for="p in pg.permissions"
                :key="p.name"
                class="my-0"
                v-model="p.active"
                @click="setChangesStatus"
                :label="p.name"
              />
            </PropEditor>
          </v-form>
        </v-tabs-window-item>

        <!-- STATS -->
        <v-tabs-window-item :reverse-transition="false" value="stats" transition="none">
          <ApiKeyStats v-if="tab === 'stats' && props.modelValue" v-model="props.modelValue" />
        </v-tabs-window-item>
      </v-window>

      <template v-slot:actions>
        <v-btn
          v-if="allowDelete && props.modelValue.apiKeyId"
          color="secondary"
          @click="deleteApiKeyConfirm"
          :loading="deleting"
          :disabled="deleting"
          >Delete</v-btn
        >
        <v-spacer></v-spacer>
        <v-btn variant="text" @click="showDialog = false">Cancel</v-btn>
        <v-btn color="primary" class="ml-4" @click="submit" :loading="loading" :disabled="loading || disabledSubmitBtn"
          >Submit</v-btn
        >
      </template>
    </side-sheet>

    <v-dialog scrim="rgba(33, 33, 33)" v-model="showNewApiKeyDialog" width="500">
      <v-card>
        <v-toolbar flat color="primary" :height="4"> </v-toolbar>
        <v-card-title> New API key created </v-card-title>

        <v-card-text>
          <p>You can also view and copy this key later.</p>
          <div class="d-flex align-center">
            <v-text-field hide-details density="compact" variant="outlined" v-model="newApiKey" readonly></v-text-field>

            <v-btn class="ml-2" icon size="small" title="Copy API key to clipboard" @click="copyNewAPIToClipboard">
              <v-icon>mdi-content-copy</v-icon>
            </v-btn>
          </div>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn variant="text" @click="newApiKey = null"> Close </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script setup lang="ts">
import SideSheet from "@/components/layout/SideSheet.vue";
import PropEditor from "@/components/layout/PropEditor.vue";
//@ts-ignore
import ApiKeyStats from "@/components/apiKeys/ApiKeyStats.vue";
import ApiKey from "@/types/ApiKey";
import { UserPermissionType } from "@/types/UserPermissionType";
import apiKeysResource from "@/resources/ApiKeysResource";
import infoMessageService from "@/services/InfoMessageService";
import { InfoMessageType } from "@/types/InfoMessageType";
import ChangeManager from "@/services/ChangeManager";
import moment from "moment";
import { VForm } from "vuetify/components";
import { ref, computed, watch } from "vue";
import { useComponentQuery } from "@/globalProperties";
import { useConfirm } from "@/services/ConfirmService";

const { setComponentQuery } = useComponentQuery();
const confirm = useConfirm();

interface Permission {
  name: string;
  type: UserPermissionType;
  active: boolean;
}

interface PermissionsGroup {
  name: string;
  permissions: Permission[];
}

const emit = defineEmits(["update:modelValue", "updated"]);

const props = withDefaults(defineProps<{ readonly initTab: string | null; modelValue: ApiKey | null }>(), {
  initTab: null,
  modelValue: null,
});

const tab = ref<string | null>(null);
watch(tab, (val: string | null) => {
  if (props.modelValue?.apiKeyId) {
    setComponentQuery("apiKeyTab", val);
  }
});

const permissions = ref<PermissionsGroup[]>([]);
const allPermissions = ref([
  {
    name: "Customers",
    permissions: [
      { name: "View customers", type: UserPermissionType.ViewCustomers },
      { name: "Add customers", type: UserPermissionType.AddCustomers },
      { name: "Edit customers", type: UserPermissionType.EditCustomers },
      { name: "Delete customers", type: UserPermissionType.DeleteCustomers },
    ],
  },
  {
    name: "Devices",
    permissions: [
      { name: "View devices", type: UserPermissionType.ViewDevices },
      { name: "View firmware update URL", type: UserPermissionType.ViewFirmwareUpdateUrl },
      { name: "View device location", type: UserPermissionType.ViewDeviceLocation },
      { name: "View device logs", type: UserPermissionType.ViewDeviceLogs },
      { name: "Edit devices", type: UserPermissionType.EditDevices },
      { name: "Modify Allow Updates (requires Edit devices)", type: UserPermissionType.EditAllowUpdateProperty },
      { name: "Delete devices", type: UserPermissionType.DeleteDevices },
    ],
  },
  {
    name: "POI",
    permissions: [
      { name: "View POI", type: UserPermissionType.ViewPoi },
      { name: "Add POI", type: UserPermissionType.AddPoi },
      { name: "Edit POI", type: UserPermissionType.EditPoi },
      { name: "Delete POI", type: UserPermissionType.DeletePoi },
    ],
  },
  {
    name: "Advertisement",
    permissions: [
      { name: "View adverts", type: UserPermissionType.ViewAds },
      { name: "Add adverts", type: UserPermissionType.AddAds },
      { name: "Edit adverts", type: UserPermissionType.EditAds },
      { name: "Delete adverts", type: UserPermissionType.DeleteAds },
      { name: "Manage advert settings", type: UserPermissionType.EditAdsSettings },
    ],
  },
  {
    name: "Issues",
    permissions: [
      { name: "View issues", type: UserPermissionType.ViewIssues },
      { name: "Manage issue devices", type: UserPermissionType.ManageIssueDevices },
      { name: "Add issues", type: UserPermissionType.AddIssues },
      { name: "Edit issues", type: UserPermissionType.EditIssues },
      { name: "Delete issues", type: UserPermissionType.DeleteIssues },
    ],
  },
  {
    name: "Repairs",
    permissions: [
      { name: "View repairs", type: UserPermissionType.ViewRepairs },
      { name: "Add repairs", type: UserPermissionType.AddRepairs },
      { name: "Edit repairs", type: UserPermissionType.EditRepairs },
      { name: "Delete repairs", type: UserPermissionType.DeleteRepairs },
    ],
  },

  {
    name: "Warranty",
    permissions: [{ name: "View manufacturer warranty", type: UserPermissionType.ViewManufacturerWarranty }],
  },
] as PermissionsGroup[]);

// begin change management
const changesControl = ref<ChangeManager | null>(null);
watch(
  () => props.modelValue,
  (val: ApiKey | null, oldValue: ApiKey | null) => {
    changesControl.value = ChangeManager.modalController({
      controller: changesControl.value,
      isNewValue: val && oldValue === null,
      isDestroy: oldValue && val === null,
      isUpdateValue: oldValue && val && oldValue.apiKeyId !== val.apiKeyId,
      data: { apiKey: val },
      message: "You have unsaved API key changes.",
      target: `apiKey_${val?.apiKeyId}`,
      onLeave: () => (showDialog.value = false),
      onSave: submit,
    });
  }
);

const setChangesStatus = () => {
  if (!props.modelValue) {
    return;
  }

  const origApiKey = { ...changesControl.value?.data?.origData?.apiKey };

  const origPermissions = changesControl.value?.data?.origData?.permissions;
  if (!changesControl.value || !origApiKey) return;

  //ipFilter can be null or an empty string. Set the same type
  const ipFilter = props.modelValue?.ipFilter;
  if (!origApiKey.ipFilter && !ipFilter && props.modelValue?.hasOwnProperty("ipFilter")) origApiKey.ipFilter = ipFilter;

  if (!ChangeManager.isObjectEqual(origApiKey, props.modelValue || {}, { isOrigPartial: true })) {
    changesControl.value?.activate();
    return;
  }

  const currentPermissions = JSON.parse(JSON.stringify(permissions.value)).map((item: PermissionsGroup) => {
    item.permissions = item.permissions.map((v) => {
      // @ts-ignore
      if (!v.active) delete v.active;
      return v;
    });
    return item;
  });

  if (!ChangeManager.isObjectEqual(origPermissions, currentPermissions, { isOrigPartial: true })) {
    changesControl.value?.activate();
    return;
  }

  changesControl.value?.deactivate();
};

watch(() => props.modelValue, setChangesStatus, { deep: true });

watch(permissions, setChangesStatus, { deep: true });
// end change management

const disabledSubmitBtn = computed(() => !ChangeManager.state().isChanged);

watch(
  () => props.modelValue,
  (newValue: ApiKey | null, oldValue: ApiKey | null) => {
    if (newValue != null) {
      populatePermissionsList();
      tab.value = props.initTab || null;
    } else {
      tab.value = null;
      setComponentQuery("apiKeyTab", null);
    }

    setComponentQuery("apiKeyId", props.modelValue?.apiKeyId ? props.modelValue.apiKeyId : null);
  }
);

const showDialog = computed({
  get() {
    return props.modelValue != null;
  },
  set(value) {
    displayKey.value = false;
    emit("update:modelValue", null);
  },
});

const dialogHeading = computed(() => {
  let heading = "";
  if (props.modelValue) {
    heading = props.modelValue?.apiKeyId ? `${props.modelValue.name} (ID: ${props.modelValue.apiKeyId})` : "New API key";
  }
  return heading;
});

const apiKeyForm = ref<InstanceType<typeof VForm> | null>(null);
const valid = ref(true);
const loading = ref(false);
const regenerating = ref(false);
const nameRules = [
  (v: any) => !!v || "Name is required",
  (v: any) => v.length > 2 || "Api key name must be at least 3 characters long",
  (v: any) => v.length < 250 || "Maximum API key character limit is 250",
];

const allowDelete = computed(() => true);
const deleting = ref(false);
const newApiKey = ref<string | null>(null);
const displayKey = ref(false);

const showNewApiKeyDialog = computed({
  get() {
    return newApiKey.value != null;
  },
  set(value: boolean) {
    newApiKey.value = null;
  },
});

const populatePermissionsList = () => {
  permissions.value = JSON.parse(JSON.stringify(allPermissions.value));
  if (props.modelValue !== null) {
    for (var permission of props.modelValue.apiKeyPermissions) {
      for (var pg of permissions.value) {
        for (var p of pg.permissions) {
          if (p.type === permission) {
            p.active = true;
          }
        }
      }
    }
  }
  changesControl.value?.addOrigData({ permissions: permissions.value });
};

const applyPermissionsFromList = () => {
  if (props.modelValue !== null) {
    var permToApply = [] as number[];
    for (var pg of permissions.value) {
      for (var p of pg.permissions) {
        if (p.active) {
          permToApply.push(p.type);
        }
      }
    }

    props.modelValue.apiKeyPermissions = permToApply;
  }
};

const submit = async () => {
  if (props.modelValue === null) {
    return;
  }

  // Validate form
  const { valid } = await (apiKeyForm.value as InstanceType<typeof VForm>).validate();

  if (valid) {
    applyPermissionsFromList();

    if (props.modelValue.apiKeyId) {
      // Update API key
      apiKeysResource
        .updateApiKey(props.modelValue)
        .then(() => {
          showDialog.value = false;
          emit("updated");
        })
        .catch(apiKeysResource.defaultErrorHandler)
        .finally(() => {
          loading.value = false;
        });
    } else {
      // New API key
      apiKeysResource
        .addApiKey(props.modelValue)
        .then((resp) => {
          infoMessageService.show(InfoMessageType.Success, "New API key created");
          showDialog.value = false;
          newApiKey.value = resp.data;
          emit("updated");
        })
        .catch(apiKeysResource.defaultErrorHandler)
        .finally(() => {
          loading.value = false;
        });
    }
  }
};

const deleteApiKeyConfirm = () => {
  if (!allowDelete.value || props.modelValue == null) {
    return;
  }

  confirm.show(`Delete API key '${props.modelValue.name}'?`).then((confirmed) => {
    if (confirmed) {
      deleteApiKey();
    }
  });
};

const deleteApiKey = () => {
  if (!allowDelete.value || props.modelValue == null) {
    return;
  }

  deleting.value = true;
  apiKeysResource
    .deleteApiKey(props.modelValue.apiKeyId)
    .then(() => {
      showDialog.value = false;
      emit("updated");
    })
    .catch(apiKeysResource.defaultErrorHandler)
    .finally(() => {
      deleting.value = false;
    });
};

const close = (value: boolean) => {
  if (!value && ChangeManager.state().isChanged) {
    ChangeManager.show();
    return;
  }

  showDialog.value = value;
};

const copyNewAPIToClipboard = () => {
  if (newApiKey.value) {
    navigator.clipboard
      .writeText(newApiKey.value)
      .then(() => infoMessageService.show(InfoMessageType.Success, "Copied to clipboard"))
      .catch(() => infoMessageService.show(InfoMessageType.Error, "Failed to copy content"));
  }
};

const copyAPIToClipboard = () => {
  if (props.modelValue) {
    navigator.clipboard
      .writeText(props.modelValue.key)
      .then(() => infoMessageService.show(InfoMessageType.Success, "Copied to clipboard"))
      .catch(() => infoMessageService.show(InfoMessageType.Error, "Failed to copy content"));
  }
};

const confirmAPIKeyRegeneration = () => {
  if (!allowDelete.value || props.modelValue == null) {
    return;
  }

  confirm
    .show(
      `
    <div>
      <h3 class='mb-3 mt-3'>WARNING</h3>
      <p>
        Rotating the API key will invalidate the previous key. 
        You can rotate your API key if you believe that the current key is no longer safe to use.
      </p>
    </div>
    `,
      {
        width: 600,
        color: "error",
      }
    )
    .then((confirmed) => {
      if (confirmed) {
        regenerateKey();
      }
    });
};

const regenerateKey = () => {
  if (props.modelValue == null) {
    return;
  }

  regenerating.value = true;
  apiKeysResource
    .regenerateApiKey(props.modelValue.apiKeyId)
    .then((resp) => {
      if (props.modelValue) {
        props.modelValue.key = resp.data;
      }
      changesControl.value?.addOrigData({ apiKey: { ...changesControl.value?.data?.origData?.apiKey, key: resp.data } });
      displayKey.value = true;
      emit("updated");
    })
    .catch(apiKeysResource.defaultErrorHandler)
    .finally(() => {
      regenerating.value = false;
    });
};
</script>

<style scoped>
.ip-filter :deep(.v-messages__message) {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 100vw;
  padding-right: 30px;
}
</style>
