<template>
  <v-container fluid>
    <v-card>
      <v-card-title class="d-block d-sm-flex">
        <div class="d-flex flex-grow-1">
          <v-text-field
            v-model="searchTerm"
            append-inner-icon="mdi-magnify"
            variant="underlined"
            color="primary"
            label="Search"
            hide-details
            clearable
          ></v-text-field>
          <v-btn
            size="x-small"
            variant="text"
            icon
            class="align-self-end ml-4"
            @click="reload"
            :disabled="loading"
            title="Refresh"
          >
            <v-icon size="24">mdi-reload</v-icon>
          </v-btn>
        </div>
        <v-spacer class="d-none d-sm-block"></v-spacer>
        <div class="text-right align-self-end mt-2 mt-sm-0">
          <v-btn size="small" color="primary" class="align-self-end" @click="() => newApiKey()" test-id="newApiKey">
            New API key
          </v-btn>
        </div>
      </v-card-title>

      <v-data-table
        density="compact"
        :headers="headers"
        :items="items"
        :row-props="rowClass"
        v-model:options="options"
        :loading="loading"
        :search="searchTerm"
        :disable-pagination="true"
        :hide-default-footer="true"
        items-per-page="-1"
        :mobile-breakpoint="0"
        @click:row="(event, { item }) => rowClick(item)"
        test-id="api-keys-table"
        :must-sort="true"
        hover
        :mobile="false"
      >
        <template v-slot:[`item.createdAt`]="{ item }">
          <span v-if="item.createdAt">
            {{ `${moment(item.createdAt).format("lll")} (${item.createdBy})` }}
          </span>
        </template>

        <template v-slot:[`item.lastModifiedAt`]="{ item }">
          <span v-if="item.lastModifiedAt">
            {{ `${moment(item.lastModifiedAt).format("lll")} (${item.lastModifiedBy})` }}
          </span>
        </template>
        <template #bottom></template>
      </v-data-table>

      <v-overlay contained :model-value="loading" persistent opacity="0" style="z-index: 999 !important" />
    </v-card>

    <EditAPIKey v-model="apiKeyToEdit" v-on:updated="reload" :initTab="editInitTab" />
  </v-container>
</template>

<script setup lang="ts">
import apiKeysResource from "@/resources/ApiKeysResource";
import axios, { CancelTokenSource } from "axios";
import moment from "moment";
import ApiKey from "@/types/ApiKey";
//@ts-ignore
import EditAPIKey from "@/components/apiKeys/EditApiKey.vue";
import userStorage from "@/services/UserStorageService";
import { ref, computed, watch, onMounted, onBeforeMount } from "vue";

const props = withDefaults(defineProps<{ initData: { apiKeyId: number; apiKeyTab: string } | null }>(), {
  initData: null,
});

const items = ref<ApiKey[]>([]);
const loading = ref(false);
let cancelToken: CancelTokenSource | undefined = undefined;

const searchTerm = ref<string>("");
const editInitTab = ref<string | null>(null);

const optionsStorageKey = "apiKeysTable";
const options = ref(
  userStorage.get(optionsStorageKey) ?? {
    sortBy: [],
    sortDesc: [false],
  }
);

watch(
  options,
  () => {
    userStorage.set(optionsStorageKey, options.value);
  },
  { deep: true }
);

const headers = ref([
  { title: "ID", align: "start", key: "apiKeyId" },
  { title: "Name", key: "name" },
  { title: "Created", key: "createdAt", sortable: false },
  { title: "Updated", key: "lastModifiedAt", sortable: false },
]);

onMounted(() => {
  if (props.initData?.apiKeyId) {
    getInitApiKeyById(props.initData?.apiKeyId);
  }
});

const getInitApiKeyById = (apiKeyId: number) => {
  apiKeysResource
    .getApiKeyById(apiKeyId)
    .then((resp) => {
      rowClick(resp.data);
      editInitTab.value = props.initData?.apiKeyTab || null;
    })
    .catch(apiKeysResource.defaultErrorHandler);
};

const apiKeyToEdit = ref<ApiKey | null>(null);

watch(apiKeyToEdit, () => {
  if (!apiKeyToEdit.value) {
    editInitTab.value = null;
  }
});

onBeforeMount(() => {
  getData();
});

const newApiKey = () => {
  apiKeyToEdit.value = {
    apiKeyId: 0,
    name: "",
    apiKeyPermissions: [] as number[],
  } as ApiKey;
};

const getData = () => {
  // Cancel existing request
  if (cancelToken) {
    cancelToken.cancel();
  }

  setTimeout(() => {
    // Timeout is workaround for finaly() being executed after request was canceled and new request already began
    loading.value = true;
    cancelToken = axios.CancelToken.source();

    apiKeysResource
      .getApiKeys(cancelToken)
      .then((resp) => {
        items.value = resp.data;
      })
      .catch(apiKeysResource.defaultErrorHandler)
      .finally(() => {
        loading.value = false;
        cancelToken = undefined;
      });
  }, 10);
};

const reload = () => {
  getData();
};

const rowClick = (item: ApiKey) => {
  apiKeyToEdit.value = Object.assign({}, item);
};

const rowClass = ({ item }: { item: ApiKey }) => {
  return { class: { "cursor-default": true } };
};
</script>
