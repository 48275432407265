<script setup lang="ts">
import moment from "moment";
import L from "leaflet";
import {
  LMap,
  LTileLayer,
  LControlScale,
  LControl,
  LRectangle,
  LTooltip,
  LPopup,
  LCircle,
  LCircleMarker,
  LFeatureGroup,
} from "@vue-leaflet/vue-leaflet";
import MapHelper from "@/helpers/mapHelper";
import geohashResource from "@/resources/GeohashResource";
import Geohash from "@/services/Geohash";
import { FeatureCollection } from "geojson";
import geojsonvt, { Feature } from "geojson-vt";
import "@/components/LeafletDraw/leaflet-geojson-vt";
import { pointInPolygon, Polygon } from "geojson-utils-ts";
import { Ref, ref, nextTick } from "vue";

let canvasLayer:any = null;

interface GeoRect {
  name: string;
  bounds: [number[], number[]];
}

const loading = ref(false);
const geohashLayer = ref<any | null>(null);
const geoJson = ref<FeatureCollection<Polygon, GeoRect>>({ type: "FeatureCollection", features: [] });


const circle = ref<{ center: [number, number]; radius: number } | undefined>(undefined);
const circleCenter = ref<{ center: [number, number]; radius: number } | undefined>(undefined);
const circleCenterLatInput = ref<number | undefined>(63.428);
const circleCenterLonInput = ref<number | undefined>(10.395);
const circleRadiusInput = ref(3000);

const autoPrecision = ref(true);
const precision = ref(5);

const compress = ref(true);

const mapCenter = ref([64, 19]);
const mapBounds = ref<{ _northEast: { lat: number; lng: number }; _southWest: { lat: number; lng: number } } | null>(null);
const mapZoom = ref(5);
const bounds = ref({}) as Ref<{
  ne: { lat: number; lng: number };
  sw: { lat: number; lng: number };
}>;
const leaflet = ref({
  url: MapHelper.defaultMapTilesUrl,
  attribution: MapHelper.defaultMapAttr,
});

const selectedGeohashAreaType = ref("radius");
const geohashAreaType = ref([
  { title: "Radius", value: "radius" },
  // { text: "Bounds", value: "bounds" },
]);

const mapRef = ref(null);
const geohashGroupRef = ref(null);
const getGeohashes = () => {
  if (selectedGeohashAreaType.value == "radius") {
    getGeohashesInRadius();
  }
};

const getGeohashesInRadius = () => {
  if (!circleCenterLatInput.value || !circleCenterLonInput.value) return;

  loading.value = true;

  geohashResource
    .getInRadius(
      circleCenterLatInput.value,
      circleCenterLonInput.value,
      circleRadiusInput.value,
      compress.value,
      autoPrecision.value ? undefined : precision.value
    )
    .then((resp) => {
      const newGeoJson: any = resp.data.geohashes.map((gh) => {
        const bnds = Geohash.bounds(gh);
        return {
          type: "Feature",
          geometry: {
            type: "Polygon",
            coordinates: [
              [
                [bnds.ne.lon, bnds.ne.lat],
                [bnds.ne.lon, bnds.sw.lat],
                [bnds.sw.lon, bnds.sw.lat],
                [bnds.sw.lon, bnds.ne.lat],
                [bnds.ne.lon, bnds.ne.lat],
              ],
            ],
          },
          properties: {
            name: gh,
            bounds: [
              [bnds.ne.lat, bnds.ne.lon],
              [bnds.sw.lat, bnds.sw.lon],
            ],
          },
        };
      });

      geoJson.value = {
        type: "FeatureCollection",
        features: [...newGeoJson],
      };

      circle.value = {
        center: [Number(circleCenterLatInput.value!), Number(circleCenterLonInput.value!)],
        radius: Number(circleRadiusInput.value),
      };
      circleCenter.value = {
        center: [resp.data.centerLat, resp.data.centerLon],
        radius: Number(circleRadiusInput.value),
      };
      precision.value = resp.data.precision;

      if (canvasLayer) canvasLayer.remove();

      const options = {
        maxZoom: 20,
        tolerance: 3,
        debug: 0,
        style: {
          fillColor: "#ddaa22",
          color: "#ddaa22",
          weight: 2,
          opacity: 1,
          fillOpacity: 0.2,
        },
      };
      //@ts-ignore
      const map = mapRef.value.leafletObject;
      canvasLayer = L.geoJson.vt(geoJson.value, options, geojsonvt).addTo(map);

      setTimeout(center, 250);
    })
    .catch(geohashResource.defaultErrorHandler)
    .finally(() => {
      loading.value = false;
    });
};

const center = () => {
  var group = geohashGroupRef.value as any;
  if (group) {
    mapBounds.value = group.leafletObject.getBounds();
  }
};
const findRectangleByCoordinates = (coordinates: [x: number, y: number]) => {
  return geoJson.value.features.find(
    (react: any) => !!pointInPolygon({ type: "Point", coordinates }, react.geometry as Polygon)
  );
};

const openPopup = (e: any) => {
  const map = mapRef.value as any;
  const x: number = e.latlng.lng;
  const y: number = e.latlng.lat;
  const foundRectangle = findRectangleByCoordinates([x, y]);

  if (!foundRectangle) return;

  const georect = foundRectangle.properties;
  L.popup(e.latlng, {
    content: `<p>
                  <b> ${georect.name}</b>
                  <br />
                  NE: ${georect.bounds[0][0]}, ${georect.bounds[0][1]}<br />
                  SW: ${georect.bounds[1][0]}, ${georect.bounds[1][1]}
                </p>`,
  }).openOn(map.leafletObject);
};
</script>

<style scoped>
.map-wrap {
  width: 100%;
  height: 100%;
}

.geo-rect {
  cursor: default;
}

.geohash-tools-container {
  width: 415px;
  max-width: calc(100vw - 100px);
}
</style>

<template>
  <v-theme-provider theme="light">
    <v-container class="pa-3" fluid style="min-height: 100%; height: 100%">
      <div class="d-flex flex-column" style="height: 100%">
        <v-card class="d-flex flex-grow-1" style="min-height: 400px">
          <div class="map-wrap">
            <l-map
              ref="mapRef"
              class="map-container"
              v-model:zoom="mapZoom"
              :center="mapCenter"
              v-model:bounds="mapBounds"
              :noBlockingAnimations="true"
              :maxZoom="20"
              @click="openPopup"
            >
              <l-tile-layer :url="leaflet.url" :attribution="leaflet.attribution"></l-tile-layer>
              <l-control-scale position="bottomleft" :imperial="false" :metric="true"></l-control-scale>
              <l-control position="topright">
                <div class="text-right geohash-tools-container">
                  <div class="d-flex no-wrap mt-2 justify-end">
                    <v-select
                      :items="geohashAreaType"
                      v-model="selectedGeohashAreaType"
                      label="Type"
                      density="compact"
                      filled
                      variant="outlined"
                      bg-color="rgba(0,0,0,.08)"
                      hide-details
                      attach
                      :menu-props="{ theme: 'dark' }"
                    ></v-select>
                  </div>
                  <!-- Radius -->
                  <div v-if="selectedGeohashAreaType == 'radius'">
                    <div class="d-flex no-wrap mt-2 justify-end">
                      <v-text-field
                        v-model="circleCenterLatInput"
                        label="Latitude"
                        density="compact"
                        variant="outlined"
                        bg-color="rgba(0,0,0,.08)"
                        hide-details
                        step="0.001"
                        type="number"
                      ></v-text-field>
                      <div class="ml-2"></div>
                      <v-text-field
                        v-model="circleCenterLonInput"
                        label="Longitude"
                        density="compact"
                        filled
                        variant="outlined"
                        bg-color="rgba(0,0,0,.08)"
                        hide-details
                        step="0.001"
                        type="number"
                      ></v-text-field>
                    </div>
                    <div class="d-flex no-wrap mt-2 justify-end">
                      <v-text-field
                        v-model="circleRadiusInput"
                        label="Radius (meters)"
                        density="compact"
                        bg-color="rgba(0,0,0,.08)"
                        variant="outlined"
                        hide-details
                        step="100"
                        type="number"
                      ></v-text-field>
                    </div>
                  </div>
                  <!-- Shared -->
                  <div class="d-flex no-wrap mt-2 align-center justify-end">
                    <v-switch
                      v-model="autoPrecision"
                      label="Auto"
                      class="mt-0 mr-3"
                      theme="light"
                      density="compact"
                      hide-details
                    ></v-switch>
                    <v-text-field
                      v-model="precision"
                      label="Precision (1-12)"
                      density="compact"
                      filled
                      variant="outlined"
                      light
                      hide-details
                      :disabled="autoPrecision"
                      max="12"
                      min="1"
                      step="1"
                      type="number"
                      bg-color="rgba(0,0,0,.08)"
                    ></v-text-field>
                  </div>
                  <div class="d-flex no-wrap mt-2 align-center justify-end">
                    <v-switch
                      v-model="compress"
                      label="Compress"
                      class="mt-0"
                      light
                      density="compact"
                      hide-details
                    ></v-switch>
                  </div>
                  <div class="mt-2">
                    <v-btn size="small" theme="dark" @click="getGeohashes()" :disabled="loading"><b>Show on map</b></v-btn>
                  </div>
                </div>
              </l-control>
              <div v-if="geoJson.features.length">
                <l-feature-group ref="geohashGroupRef">
                  <div v-if="selectedGeohashAreaType == 'radius' && circle && circleCenter">
                    <l-circle-marker
                      :lat-lng="circle.center"
                      :radius="4"
                      :weight="0"
                      fillColor="#003300"
                      :fillOpacity="0.7"
                      :interactive="false"
                    ></l-circle-marker>
                    <l-circle
                      :lat-lng="circle.center"
                      :radius="circle.radius"
                      :weight="1"
                      color="#003300"
                      :fillOpacity="0"
                      :opacity="0.4"
                      :interactive="false"
                    ></l-circle>
                    <l-circle-marker
                      :lat-lng="circleCenter.center"
                      :radius="4"
                      :weight="0"
                      fillColor="#ff0000"
                      :fillOpacity="0.7"
                      :interactive="false"
                    ></l-circle-marker>
                    <l-circle
                      :lat-lng="circleCenter.center"
                      :radius="circleCenter.radius"
                      :weight="1"
                      color="#ff0000"
                      :fillOpacity="0"
                      :interactive="false"
                    ></l-circle>
                  </div>
                </l-feature-group>
              </div>
            </l-map>
          </div>
        </v-card>
      </div>
    </v-container>
  </v-theme-provider>
</template>
