<template>
  <v-card :loading="loading" :disabled="loading" class="overflow-visible">
    <v-card-text class="overflow-visible">
      <v-sheet class="add-issue-comment">
        <RichText
          v-model="newContent"
          :disabled="loading"
          size="true"
          placeholder="Write your comment here..."
          toolbarType="minimal"
        />
      </v-sheet>
      <Attachments
        ref="attachmentsRef"
        :targetType="targetType"
        :targetId="commentId"
        v-on:updateLocalFiles="updateLocalFiles"
        :isActionByCommand="true"
      />
      <div class="text-right mt-4">
        <v-btn color="primary" @click="addComment" :loading="loading" :disabled="!isReadyForm || loading" size="small">
          Add comment
        </v-btn>
      </div>
    </v-card-text>
  </v-card>
</template>

<script setup lang="ts">
import { UserPermissionType } from "@/types/UserPermissionType";
import RichText from "@/components/common/RichText.vue";
import Attachments from "@/components/common/Attachments.vue";
import userProfileService from "@/services/UserProfileService";
import issueResource from "@/resources/IssueResource";
import { InfoMessageType } from "@/types/InfoMessageType";
import infoMessageService from "@/services/InfoMessageService";
import { AttachmentTargetType } from "@/types/AttachmentTargetType";
import ChangeManager from "@/services/ChangeManager";
import { ref, computed, watch, onMounted, onUnmounted } from "vue";
import { useRoute } from "vue-router";

const emit = defineEmits(["created"]);

const loading = ref(false);
const newContent = ref("");
const targetType = AttachmentTargetType.IssueComment;
const commentId = ref(0);
const localFiles = ref<File[]>([]);
const changesControl = ref(new ChangeManager());

const route = useRoute();

onMounted(() => {
  changesControl.value.init({
    target: `new_comment`,
    onSave: addComment,
    message: "Did you forget to submit new comment?",
    isChanged: false,
  });
});

onUnmounted(() => {
  changesControl.value.deactivate();
});

watch(newContent, function onChangeContent() {
  if (newContent.value) {
    changesControl.value.activate();
  }

  if (!newContent.value && !localFiles.value?.length) {
    changesControl.value.deactivate();
  }
});

const canAddComment = computed(() => userProfileService.hasPermission(UserPermissionType.ViewIssues));

const isReadyForm = computed(() => Boolean(newContent.value.trim().length));

const attachmentsRef = ref<typeof Attachments | null>(null);

const updateLocalFiles = (files: File[]) => {
  localFiles.value = files;
  if (files?.length) {
    changesControl.value.activate();
  }

  if (!newContent.value && !files?.length) {
    changesControl.value.deactivate();
  }
};

const addComment = () => {
  if (!canAddComment.value || !isReadyForm.value) {
    if (!newContent.value.trim().length) {
      infoMessageService.show(InfoMessageType.Info, "Add text to the new comment");
    }
    return;
  }

  loading.value = true;
  let data: any;
  issueResource
    .addIssueComment(Number(route.params.id), newContent.value)
    .then((resp) => {
      commentId.value = resp.data.commentId;
      data = resp.data;
    })
    .then(() => attachmentsRef.value?.uploadLocalFiles())
    .then((attachments) => {
      data.attachments = attachments;
      commentId.value = 0;
      localFiles.value = [];
      emit("created", data);
      newContent.value = "";
      changesControl.value.deactivate();
    })
    .catch(issueResource.defaultErrorHandler)
    .finally(() => {
      loading.value = false;
    });
};
</script>

<style scoped>
.add-issue-comment {
  position: relative;
}
</style>
