<template>
  <div class="mp-wrap d-block d-md-flex flex-column">
    <v-overlay contained :model-value="loading" class="progress" z-index="101" persistent>
      <v-progress-linear indeterminate position="absolute" />
    </v-overlay>
    <div class="mp-title">Active devices (top 2K, last minute)</div>
    <div id="leaflet" class="mp-container flex-grow-1">
      <div id="map"></div>
    </div>
  </div>
</template>

<script setup lang="ts">
import statsResource from "@/resources/StatsResource";
import axios, { CancelTokenSource } from "axios";
import L from "leaflet";
import MapHelper from "@/helpers/mapHelper";
import { useDisplay, useTheme } from "vuetify";
import { ref, onActivated, onMounted, onDeactivated } from "vue";

let cancelToken: CancelTokenSource | undefined = undefined;
const loading = ref(false);
const allowAutoCenter = ref(true);

const isTabletAndDown = useDisplay().mdAndDown;
const circleColor = useTheme().current.value.colors.primary;

const map = ref<any>(null);
const mapCenter = ref([64, 19]);
const markersGroup = ref<any | null>(null);
let timer = 0;
const leaflet = {
  url: MapHelper.defaultMapTilesUrl,
  attribution: MapHelper.defaultMapAttr,
};

onMounted(() => {
  // Init map
  map.value = L.map("map", {
    dragging: !isTabletAndDown.value,
    touchZoom: true,
    zoomControl: false,
    preferCanvas: true,
    zoom: 5,
    maxZoom: 18,
    minZoom: 3,
    center: mapCenter.value,
    worldCopyJump: true,
    renderer: L.canvas(),
  });

  L.tileLayer(MapHelper.defaultMapTilesUrl, {
    attribution: MapHelper.defaultMapAttr,
  }).addTo(map.value);
});

const center = () => {
  if (markersGroup.value && markersGroup.value.getBounds?.()?.isValid()) {
    map.value.fitBounds(markersGroup.value.getBounds());
  }
};

const getData = () => {
  // Cancel existing request
  if (cancelToken) {
    cancelToken.cancel();
  }

  setTimeout(() => {
    // Timeout is workaround for finaly() being executed after request was canceled and new request already began
    loading.value = true;
    cancelToken = axios.CancelToken.source();

    statsResource
      .getTopActiveDevices(cancelToken)
      .then((resp) => {
        // Create markers
        const opt = {
          stroke: true,
          color: "rgba(0,0,0,0.4)",
          weight: 1,
          fillColor: circleColor,
          fillOpacity: 0.6,
          radius: 3,
          interactive: false,
        };
        const markers = resp.data.map((item) => {
          return L.circleMarker([item.lat, item.lon], opt);
        });

        // Clear and add marker group with markers
        if (markersGroup.value) {
          map.value.removeLayer(markersGroup.value);
        }

        markersGroup.value = L.featureGroup(markers);
        map.value.addLayer(markersGroup.value);

        // Center map
        if (allowAutoCenter.value) {
          allowAutoCenter.value = false; // Only auto center on first time load
          setTimeout(() => center(), 10);
        }
      })
      .catch(statsResource.defaultErrorHandler)
      .finally(() => {
        loading.value = false;
        cancelToken = undefined;
      });
  }, 10);
};

onActivated(() => {
  getData();
  timer = setInterval(() => getData(), 60 * 1000);
  allowAutoCenter.value = true;
});

onDeactivated(() => {
  if (timer) {
    const cloneTimer = timer;
    clearInterval(cloneTimer);
    timer = 0;
  }
});
</script>

<style scoped>
.mp-wrap {
  height: 100%;
  position: relative;
}
.mp-container {
  position: relative;
  height: 100%;
}
.mp-container :deep(.leaflet-layer) {
  filter: grayscale(1);
}
.v-theme--dark .mp-container :deep(.leaflet-layer) {
  filter: invert(1) grayscale(1);
}
.mp-container :deep(.leaflet-container .leaflet-control-attribution) {
  background: rgba(255, 255, 255, 0.75);
}
.v-theme--dark .mp-container :deep(.leaflet-container .leaflet-control-attribution) {
  background: rgba(20, 20, 20, 0.75);
  color: #ccc !important;
}
.v-theme--dark .mp-container :deep(.leaflet-container .leaflet-control-attribution a) {
  color: #ccc !important;
}
.mp-container .leaflet-container {
  width: 100%;
  height: 100%;
  z-index: 0;
  background: transparent;
}
.mp-title {
  text-align: center;
  padding-bottom: 10px;
  font-size: 20px;
  font-weight: 400;
}

.progress {
  display: flex;
  align-items: center;
  justify-content: center;
}

.progress :deep(.v-overlay__content) {
  width: 25%;
}
</style>
