<template>
  <v-container fluid>
    <v-card>
      <v-card-title class="d-block d-sm-flex">
        <div class="d-flex flex-grow-1">
          <v-text-field
            v-model="searchTerm"
            append-inner-icon="mdi-magnify"
            color="primary"
            variant="underlined"
            label="Search"
            hide-details
            clearable
            @click:clear="search()"
            style="max-width"
            v-on:input="search()"
            v-on:keypress.enter="search(true)"
          ></v-text-field>
          <v-btn
            size="x-small"
            variant="text"
            icon
            class="align-self-end ml-4"
            @click="reload"
            :disabled="loading"
            title="Refresh"
          >
            <v-icon size="24">mdi-reload</v-icon>
          </v-btn>
          <TableConfiguration :allHeaders="headers" v-model="selectedHeaders" tableKey="advertsTableColumns" />
        </div>
        <v-spacer class="d-none d-sm-block"></v-spacer>
        <div class="text-right align-self-end mt-2 mt-sm-0">
          <v-btn v-if="canAddAdverts" size="small" color="primary" @click="newAdvert()"> New Ad </v-btn>
        </div>
      </v-card-title>

      <v-data-table-server
        density="compact"
        :row-props="rowClass"
        :headers="selectedHeaders"
        :items="items"
        :items-length="total"
        v-model:sort-by="sortBy"
        :must-sort="true"
        hover
        :mobile="false"
        :loading="loading"
        :mobile-breakpoint="0"
        @click:row="(event:any, { item }: any) => rowClick(item)"
        @contextmenu:row="openContenxMenu"
        class="no-wrap-table-sm"
      >
        <template v-slot:[`item.createdDate`]="{ item }">
          {{ moment(item.createdDate).format("lll") }}
        </template>
        <template v-slot:[`item.isActive`]="{ item }">
          <v-icon size="small" color="green" v-if="item.isActive">mdi-check</v-icon>
        </template>
        <template v-slot:bottom>
          <DataTableFooter
            v-model:page="page"
            :items="items"
            :itemsLength="total"
            v-model:itemsPerPage="itemsPerPage"
            :itemsPerPageOptions="[15, 25, 50]"
          />
        </template>
      </v-data-table-server>

      <v-overlay contained :model-value="loading" persistent opacity="0" :style="'z-index: 999 !important'" />
    </v-card>

    <EditAdvert v-model="advertToEdit" v-on:updated="reload" :advertInitTab="advertInitTab" />
    <DataTableContextMenu v-model="contextMenuEventItem" />
  </v-container>
</template>

<script setup lang="ts">
import EditAdvert from "@/components/adverts/EditAdvert.vue";
import AdvertListItem from "@/types/AdvertListItem";
import Advert from "@/types/Advert";
import advertResource from "@/resources/AdvertResource";
import axios, { CancelTokenSource } from "axios";
import moment from "moment";
import userProfileService from "@/services/UserProfileService";
import { UserPermissionType } from "@/types/UserPermissionType";
import DataTableContextMenu from "@/components/common/DataTableContextMenu.vue";
import TableConfiguration from "@/components/common/TableConfiguration.vue";
import userStorage from "@/services/UserStorageService";
import { AdvertType } from "@/types/AdvertType";
import AdvertLocation from "@/types/AdvertLocation";
import { nextTick, onMounted } from "vue";
import DataTableFooter from "@/components/common/DataTableFooter.vue";
import { ref, watch, computed, onActivated, onDeactivated } from "vue";

const props = withDefaults(defineProps<{ initData: { advertTab: string; advertId: number } | null }>(), { initData: null });

const total = ref(0);
const items = ref<AdvertListItem[]>([]);
const loading = ref(false);

const optionsStorageKey = "advertsTable";
const itemsPerPage = ref(userStorage.get(optionsStorageKey)?.itemsPerPage ?? 15);

const sortBy = ref<{ key: string; order: boolean | "asc" | "desc" }[]>(
  userStorage.get(optionsStorageKey)?.sortBy?.[0]?.key
    ? userStorage.get(optionsStorageKey)?.sortBy
    : [{ key: "advertId", order: true }]
);

const page = ref(userStorage.get(optionsStorageKey)?.page ?? 1);
const searchTermStorageKey = "advertsTableSearchTerm";
const searchTerm = ref(userStorage.get(searchTermStorageKey) ?? "");
let searchThrottleTimer = 0;
let cancelToken: CancelTokenSource | undefined = undefined;

const advertToEdit = ref<Advert | null>(null);
const advertInitTab = ref<string | null>(null);

const ignoreOptionsChange = ref(false);

const selectedHeaders = ref([]);
const headers = [
  { title: "ID", key: "advertId" },
  { title: "Org.ID", key: "organisationId" },
  { title: "Campaign ID", key: "campaignId" },
  { title: "Promo text", key: "promoText", sortable: false },
  { title: "Active", key: "isActive" },
  { title: "Created", key: "createdDate" },
];

const canAddAdverts = computed(() => userProfileService.hasPermission(UserPermissionType.AddAds));

let dataReloadTimeoutId: number | null = null;
const dataReloadIntervalSeconds = 180;
const componentActive = ref(false);
onActivated(() => {
  componentActive.value = true;

  // reload data (user haven't been on the page logner than dataReloadIntervalSeconds)
  if (dataReloadTimeoutId === 0 || dataReloadTimeoutId === null) {
    getData();
  }
});

onDeactivated(() => {
  componentActive.value = false;
});

onMounted(() => {
  if (props.initData?.advertId) {
    getInitAdvertById(props.initData?.advertId);
  }
});

const getInitAdvertById = (advertId: number) => {
  advertResource
    .getAdvertById(advertId)
    .then((resp) => {
      advertToEdit.value = resp.data;
      advertInitTab.value = props.initData?.advertTab || null;
    })
    .catch(advertResource.defaultErrorHandler);
};

const restartDataReloadTimeout = () => {
  if (dataReloadTimeoutId) {
    clearTimeout(dataReloadTimeoutId);
  }

  dataReloadTimeoutId = setTimeout(() => {
    dataReloadTimeoutId = 0;
    if (componentActive.value) {
      getData();
    }
  }, dataReloadIntervalSeconds * 1000);
};

const contextMenuEventItem = ref<any>(null);
const openContenxMenu = (e: any) => {
  contextMenuEventItem.value = e;
};

const newAdvert = () => {
  advertToEdit.value = {
    advertId: 0,
    organisationId: 0,
    campaignId: 0,
    isActive: true,
    minTimeOnScreen: 5,
    maxTimeOnScreen: 15,
    advertType: AdvertType.Spot,
    activeRadius: 300,
    maximumShows: 50,
    promoText: "",
    imageDataBase64: null,
    imageWidth: 0,
    imageHeight: 0,
    advertLocation: {
      latitude: 64.466956,
      longitude: 11.492757,
    } as AdvertLocation,
  } as Advert;
};

const getData = (resetPagination: boolean = false) => {
  // Cancel existing request
  if (cancelToken) {
    cancelToken.cancel();
  }

  // Reset pagination
  if (resetPagination) {
    ignoreOptionsChange.value = true;
    page.value = 1;
  }

  // Save sorting, filters and search terms
  userStorage.set(optionsStorageKey, {
    page: page.value,
    itemsPerPage: itemsPerPage.value,
    sortBy: sortBy.value,
  });
  userStorage.set(searchTermStorageKey, searchTerm.value);

  // Restart data reload timeout
  restartDataReloadTimeout();

  setTimeout(() => {
    // Timeout is workaround for finaly() being executed after request was canceled and new request already began
    loading.value = true;
    cancelToken = axios.CancelToken.source();
    const orderDesc =
      typeof sortBy.value[0].order === "boolean" ? sortBy.value[0].order : sortBy.value[0].order.toString() === "desc";

    advertResource
      .getAdvertsPaged(itemsPerPage.value, page.value, searchTerm.value, sortBy.value[0].key, orderDesc, cancelToken)
      .then((resp) => {
        items.value = resp.data.items;
        total.value = resp.data.totalItems;
      })
      .catch(advertResource.defaultErrorHandler)
      .finally(() => {
        loading.value = false;
        cancelToken = undefined;
        ignoreOptionsChange.value = false;
      });
  }, 10);
};

const search = (noTheshold: boolean = false) => {
  if (searchThrottleTimer) {
    clearTimeout(searchThrottleTimer);
    searchThrottleTimer = 0;
  }

  if (noTheshold || !searchTerm.value) {
    getData(true);
  } else {
    searchThrottleTimer = setTimeout(() => {
      getData(true);
    }, 1000);
  }
};

const reload = () => {
  getData();
};

const rowClick = (item: AdvertListItem) => {
  if (!contextMenuEventItem.value) {
    // load advert
    loading.value = true;
    advertResource
      .getAdvertById(item.advertId)
      .then((resp) => {
        advertToEdit.value = resp.data;
      })
      .catch(advertResource.defaultErrorHandler)
      .finally(() => {
        loading.value = false;
      });
  }
};

const rowClass = () => {
  return { class: { "cursor-default": true } };
};

watch([itemsPerPage, sortBy, page], async function onPropertyChanged() {
  if (!ignoreOptionsChange.value) {
    await nextTick();
    getData();
  }
});

watch(advertToEdit, function onChangeCustomerToEdit() {
  if (!advertToEdit.value) {
    advertInitTab.value = null;
  }
});
</script>

<style scoped></style>
