<template>
  <div>
    <v-snackbar v-model="show" location="top" timeout="-1" color="purple">
      Application update available! Refresh to get the latest features and improvements.
      <template v-slot:actions>
        <v-btn color="white" variant="text" @click="() => refresh()" :loading="refreshing">Refresh</v-btn>
        <v-btn icon size="small" color="white" @click="() => postpone()">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </template>
    </v-snackbar>
  </div>
</template>

<script setup lang="ts">
import appStatusService from "@/services/AppStatusService";
import { ref, computed } from "vue";

const updatePosponed = ref(false);
const refreshing = ref(false);

const show = computed(() => {
  return appStatusService.updateAvailable && updatePosponed.value === false;
});

const postpone = () => {
  updatePosponed.value = true;
};

const refresh = () => {
  refreshing.value = true;
  window.location.reload();
};
</script>
