<template>
  <form @submit.prevent="signIn" class="form-auth">
    <h3 class="form-auth-heading"><img src="/favicon.png" alt="Safedrive" />Safedrive</h3>
    <div class="input-field">
      <v-text-field v-model="username" type="text" label="Username" required autocomplete="off" test-id="username" />
    </div>
    <div class="input-field">
      <v-text-field v-model="password" type="password" label="Password" required autocomplete="off" test-id="password" />
    </div>
    <v-btn class="submit-button" type="submit" :loading="loading" :disabled="loading"> Sign in </v-btn>
  </form>
</template>

<script setup lang="ts">
import authService from "@/services/AuthService";
import authResource from "@/resources/AuthResource";
import router from "@/router/router";
import { ref, computed } from "vue";

const username = ref("");
const password = ref("");
const loading = ref(false);

const isFormReady = computed(() => Boolean(username.value && password.value));

const signIn = () => {
  if (!isFormReady.value) return;

  loading.value = true;
  authResource
    .signIn(username.value, password.value)
    .then((resp) => {
      authService.initAuth(username.value, resp.data.requirePasswordUpdate);

      if (resp.data.token) {
        // Sign in
        authService.signIn(resp.data.token, resp.data.expires!);
      } else {
        // MFA
        router.push({
          path: "/verification",
          query: { type: resp.data.mfaType?.toString() },
        });
      }
    })
    .catch(authResource.defaultErrorHandler)
    .finally(() => {
      loading.value = false;
      password.value = "";
    });
};
</script>
