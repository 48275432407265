<template>
  <div>
    <side-sheet
      v-if="props.modelValue"
      :modelValue="showDialog"
      @update:modelValue="close"
      @click-outside="close"
      :heading="dialogHeading"
      :noClickAnimation="true"
    >
      <template v-slot:tabs>
        <v-tabs v-model="tab" grow color="primary" slider-color="primary">
          <v-tab value="customer">Customer</v-tab>
          <v-tab value="devices">Devices</v-tab>
          <v-tab value="comments" v-if="props.modelValue?.customerId">
            <span
              >Comments
              <v-badge
                density="compact"
                :color="tab === 'comments' ? 'primary' : 'badge'"
                :content="commentCount"
                inline
                class="ml-1"
                v-if="commentCount"
              />
            </span>
          </v-tab>
          <v-tab v-if="props.modelValue?.customerId" value="history">History</v-tab>
        </v-tabs>
      </template>

      <v-window v-model="tab" :touch="false" class="full-height-tabs-wrap tabs-w-100">
        <!-- CUSTOMER -->
        <v-tabs-window-item :eager="true" :reverse-transition="false" value="customer" transition="none">
          <v-form ref="customerForm" v-model="customerFormValid" lazy-validation>
            <PropEditor v-if="props.modelValue?.customerId" name="Info">
              <div class="text-subtitle-2">
                <div><span class="info-label">Customer ID:</span> {{ props.modelValue?.customerId }}</div>
                <div v-if="props.modelValue?.createdDate">
                  <span class="info-label">Created:</span> {{ moment(props.modelValue?.createdDate).format("lll") }}
                </div>
                <div v-if="props.modelValue?.updatedDate">
                  <span class="info-label">Updated:</span> {{ moment(props.modelValue?.updatedDate).format("lll") }}
                </div>
              </div>
            </PropEditor>
            <PropEditor name="First name" desc="">
              <v-text-field
                v-model="props.modelValue.firstName"
                density="compact"
                :variant="canEditCustomer ? 'outlined' : 'solo'"
                :flat="!canEditCustomer"
                :readonly="!canEditCustomer"
                :rules="nameRules"
              ></v-text-field>
            </PropEditor>
            <PropEditor name="Last name" desc="">
              <v-text-field
                v-model="props.modelValue.lastName"
                density="compact"
                :variant="canEditCustomer ? 'outlined' : 'solo'"
                :flat="!canEditCustomer"
                :readonly="!canEditCustomer"
                :rules="nameRules"
              ></v-text-field>
            </PropEditor>
            <PropEditor name="Email">
              <v-text-field
                v-model="props.modelValue.email"
                density="compact"
                :variant="canEditCustomer ? 'outlined' : 'solo'"
                :flat="!canEditCustomer"
                :readonly="!canEditCustomer"
                :rules="emailRules"
              ></v-text-field>
            </PropEditor>
            <PropEditor
              name="Phone number"
              desc="Digits only, ex. 4745012345, 46123456789. Numbers should include country code."
            >
              <v-text-field
                v-model="props.modelValue.phoneNumber"
                density="compact"
                :variant="canEditCustomer ? 'outlined' : 'solo'"
                :flat="!canEditCustomer"
                :readonly="!canEditCustomer"
                :rules="phoneRules"
              ></v-text-field>
            </PropEditor>
            <PropEditor name="Customer type">
              <v-select
                v-model="props.modelValue.type"
                :items="customerTypes"
                density="compact"
                single-line
                :variant="canEditCustomer ? 'outlined' : 'solo'"
                :flat="!canEditCustomer"
                :readonly="!canEditCustomer"
                attach
              ></v-select>
            </PropEditor>
            <PropEditor name="Company name">
              <v-text-field
                v-model="props.modelValue.companyName"
                density="compact"
                :variant="canEditCustomer ? 'outlined' : 'solo'"
                :flat="!canEditCustomer"
                :readonly="!canEditCustomer"
              ></v-text-field>
            </PropEditor>
            <PropEditor name="Country">
              <v-autocomplete
                ref="countryAutocomplete"
                open-on-clear
                v-model="props.modelValue.countryIso2"
                @update:modelValue="checkMenuState"
                :items="countryCodes"
                v-model:search="countrySearch"
                density="compact"
                single-line
                :variant="canEditCustomer ? 'outlined' : 'solo'"
                :flat="!canEditCustomer"
                :readonly="!canEditCustomer"
                :custom-filter="countryFilter"
                attach
                :menu-props="{ maxHeight: '170' }"
              >
                <template v-slot:selection="{ item }">
                  <span> {{ item ? `${countryByCode(item.title)} (${item.title})` : "" }} </span>
                </template>
                <template v-slot:item="{ item, props }">
                  <v-list-item
                    v-bind="props"
                    :title="undefined"
                    :class="{ delimiterItem: hasDelimiter(item.raw) }"
                    class="ml-2"
                  >
                    {{ `${countryByCode(item.title)} (${item.title})` }}

                    <v-divider v-if="hasDelimiter(item.raw)" class="delimiter" />
                  </v-list-item>
                </template>
              </v-autocomplete>
            </PropEditor>
            <PropEditor name="Notes">
              <v-textarea
                v-model="props.modelValue.notes"
                density="compact"
                :variant="canEditCustomer ? 'outlined' : 'solo'"
                :flat="!canEditCustomer"
                :readonly="!canEditCustomer"
              ></v-textarea>
            </PropEditor>
          </v-form>
        </v-tabs-window-item>

        <!-- DEVICES -->
        <v-tabs-window-item :reverse-transition="false" value="devices" transition="none">
          <div class="text-subtitle-1 mb-1">Customer devices</div>
          <p class="text-caption mb-4">List of devices associated with this customer.</p>
          <div v-if="customerDevices && customerDevices.length">
            <v-divider />
            <v-table density="comfortable" hover>
              <template v-slot:default>
                <thead>
                  <tr>
                    <th scope="col" class="text-left">Device ID</th>
                    <th scope="col">Type</th>
                    <th scope="col">Blocked</th>
                    <th scope="col">Subscription end</th>
                    <th scope="col">Last seen</th>
                    <th scope="col"></th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-for="device in customerDevices" :key="device.deviceId">
                    <td class="text-left">
                      <div class="no-wrap">
                        {{ device.deviceId }}
                        <DeviceMenu v-model="device.deviceId" size="small" />
                      </div>
                    </td>
                    <td>{{ getDeviceTypeName(device.type) }}</td>
                    <td>
                      <v-icon size="small" color="green" v-if="device.isBlocked">mdi-check</v-icon>
                    </td>
                    <td class="no-wrap">
                      {{ device.subscriptionEndDate ? moment(device.subscriptionEndDate).format("lll") : "" }}
                    </td>
                    <td class="no-wrap">{{ device.lastSeenDate ? moment(device.lastSeenDate).format("lll") : "" }}</td>
                    <td class="text-right no-wrap">
                      <v-btn
                        v-if="canViewDevices"
                        @click="openDevice(device.deviceId)"
                        variant="text"
                        icon
                        density="compact"
                        title="Open"
                      >
                        <v-icon size="x-small">mdi-open-in-new</v-icon>
                      </v-btn>
                      <v-btn
                        v-if="canEditCustomer"
                        @click="removeCustomerDevice(device.deviceId)"
                        icon
                        title="Remove from list"
                        variant="text"
                        density="compact"
                      >
                        <v-icon size="x-small">mdi-close-thick</v-icon>
                      </v-btn>
                    </td>
                  </tr>
                </tbody>
              </template>
            </v-table>
            <v-divider />
          </div>
          <v-progress-linear v-if="loadingCustomerDevices" indeterminate></v-progress-linear>
          <v-row v-if="canEditCustomer" class="mt-2">
            <v-col>
              <v-text-field
                v-model="deviceIdToAdd"
                variant="outlined"
                density="compact"
                clearable
                :rules="[numberRule]"
                :error-messages="deviceToAddValidationErrors"
                @update:model-value="deviceToAddValidationErrors = []"
                @keypress.enter="addCustomerDevice()"
                placeholder="Device ID"
              />
            </v-col>
            <v-col>
              <v-btn variant="text" :loading="loadingAddCustomerDevice" @click="addCustomerDevice()">
                <v-icon start> mdi-plus </v-icon>
                Add device
              </v-btn>
            </v-col>
          </v-row>
        </v-tabs-window-item>

        <!-- COMMENTS -->
        <v-tabs-window-item
          :reverse-transition="false"
          value="comments"
          transition="none"
          v-if="props.modelValue.customerId"
        >
          <CustomerComments :customerId="props.modelValue?.customerId" @update="getCommentCount" />
        </v-tabs-window-item>

        <!-- HISTORY -->
        <v-tabs-window-item :reverse-transition="false" value="history" transition="none">
          <CustomerChangeHistory :customerId="changeHistoryCustomerId" />
        </v-tabs-window-item>
      </v-window>

      <template v-slot:actions>
        <v-btn
          v-if="canDeleteCustomer && props.modelValue.customerId"
          color="secondary"
          @click="deleteCustomerConfirm()"
          :loading="deleting"
          :disabled="deleting"
          >Delete</v-btn
        >
        <v-spacer></v-spacer>
        <v-btn variant="text" @click="showDialog = false">Cancel</v-btn>
        <v-btn
          v-if="canEditCustomer"
          color="primary"
          class="ml-4"
          @click="submit"
          :loading="loading"
          :disabled="loading || disabledSubmitBtn"
          >Submit</v-btn
        >
      </template>
    </side-sheet>
    <EditDevice
      :modelValue="props.modelValue && tab === 'devices' ? deviceToEdit : null"
      @update:modelValue="(value) => (deviceToEdit = value)"
      v-on:updated="deviceUpdated"
      :deviceInitTab="deviceInitTab"
    />
  </div>
</template>

<script setup lang="ts">
import SideSheet from "@/components/layout/SideSheet.vue";
import PropEditor from "@/components/layout/PropEditor.vue";
import Customer from "@/types/Customer";
import infoMessageService from "@/services/InfoMessageService";
import { InfoMessageType } from "@/types/InfoMessageType";
import userProfileService from "@/services/UserProfileService";
import moment from "moment";
import { UserPermissionType } from "@/types/UserPermissionType";
import { CustomerType } from "@/types/CustomerType";
import CustomerHelper from "@/helpers/customerHelper";
import customerResource from "@/resources/CustomerResource";
import deviceResource from "@/resources/DeviceResource";
import Device from "@/types/Device";
import { DeviceType } from "@/types/DeviceType";
import DeviceHelper from "@/helpers/deviceHelper";
import EditDevice from "@/components/devices/EditDevice.vue";
import ResourceBase from "@/resources/ResourceBase";
import DeviceMenu from "@/components/devices/DeviceMenu.vue";
import CustomerChangeHistory from "@/components/customers/CustomerChangeHistory.vue";
import ChangeManager from "@/services/ChangeManager";
import CustomerComments from "@/components/customers/CustomerComments/CustomerComments.vue";
import countries from "i18n-iso-countries";
countries.registerLocale(require("i18n-iso-countries/langs/en.json"));
import { VForm } from "vuetify/components";
import { ref, computed, watch, onActivated, nextTick } from "vue";
import { useComponentQuery } from "@/globalProperties";
import { useConfirm } from "@/services/ConfirmService";
import { VAutocomplete } from 'vuetify/components';

const { setComponentQuery } = useComponentQuery();
const confirm = useConfirm();

const emit = defineEmits(["update:modelValue", "updated"]);

const props = withDefaults(
  defineProps<{
    readonly modelValue: Customer | null;
    readonly initData: { customerId?: number; deviceId?: number; deviceTab?: string } | null;
    customerInitTab: string | null;
  }>(),
  {
    initData: null,
    customerInitTab: null,
    modelValue: null,
  }
);

const commentCount = ref(0);
const countrySearch = ref("");

const customerFormValid = ref(true);
const loading = ref(false);
const deleting = ref(false);

const nameRules = [(v: string) => !!v || "Field is required"];
const emailRules = [(v: string) => !v || /^\S+@\S+\.\S+$/.test(v) || "E-mail must be valid"];
const phoneRules = [
  (v: string) => !!v || "Phone number is required",
  (v: string) => /^\d+$/.test(v) || "Phone number should contain digits only",
];
const numberRule = (v: string | null) => !v || /^\d+$/.test(v) || "Field should contain a valid number";

const customerTypes = [
  { title: CustomerHelper.getCustomerTypeDisplayName(CustomerType.Unknown), value: CustomerType.Unknown },
  { title: CustomerHelper.getCustomerTypeDisplayName(CustomerType.Private), value: CustomerType.Private },
  { title: CustomerHelper.getCustomerTypeDisplayName(CustomerType.Professional), value: CustomerType.Professional },
  { title: CustomerHelper.getCustomerTypeDisplayName(CustomerType.Reseller), value: CustomerType.Reseller },
];

const canAddCustomer = computed(() => userProfileService.hasPermission(UserPermissionType.EditCustomers));

const canEditCustomer = computed(
  () =>
    userProfileService.hasPermission(UserPermissionType.EditCustomers) ||
    (props.modelValue && props.modelValue.customerId === 0 && canAddCustomer.value)
);

const canDeleteCustomer = computed(() => userProfileService.hasPermission(UserPermissionType.DeleteCustomers));

const canViewDevices = computed(() => userProfileService.hasPermission(UserPermissionType.ViewDevices));

const customerDevices = ref<Device[] | null>(null);
const loadingCustomerDevices = ref(false);
const loadingAddCustomerDevice = ref(false);
const deviceToEdit = ref<Device | null>(null);
const deviceIdToAdd = ref<number | null>(null);
const deviceToAddValidationErrors = ref<string[]>([]);
const deviceInitTab = ref<string | null>(null);
const customerForm = ref<InstanceType<typeof VForm> | null>(null);
const tab = ref<string | null>(null);
const changeHistoryCustomerId = ref<number | null>(null);

const showDialog = computed({
  get() {
    return props.modelValue != null;
  },
  set(value: boolean) {
    if (value) {
      emit("update:modelValue", value);
    } else {
      emit("update:modelValue", null);
    }
  },
});

const submitCustomerDevices = (isNewCustomer: boolean = false) => {
  // update customer devices list if devices list was loaded or changed
  if (props.modelValue && customerDevices.value) {
    loading.value = true;

    const deviceIds = customerDevices.value.map((item) => item.deviceId);

    customerResource
      .setCustomerDevices(props.modelValue.customerId, deviceIds)
      .then((resp) => {
        if (isNewCustomer) {
          infoMessageService.show(InfoMessageType.Success, "New customer created");
        } else {
          infoMessageService.show(InfoMessageType.Success, "Customer information updated");
        }

        showDialog.value = false;
        emit("updated");
      })
      .catch(customerResource.defaultErrorHandler)
      .finally(() => {
        loading.value = false;
      });
  } else {
    if (isNewCustomer) {
      infoMessageService.show(InfoMessageType.Success, "New customer created");
    } else {
      infoMessageService.show(InfoMessageType.Success, "Customer information updated");
    }
    showDialog.value = false;
    emit("updated");
  }
};

const submit = async () => {
  if (props.modelValue === null) return;

  // Validate form
  const { valid } = await (customerForm.value as InstanceType<typeof VForm>).validate();

  // Validate form
  if (!valid) {
    return;
  }
  // Submit in 2 stages:
  //   1) Submit customer data
  //   2) Submit customer device data
  try {
    loading.value = true;

    // Set email address to null if empty
    if (!props.modelValue.email) {
      props.modelValue.email = null;
    }

    if (props.modelValue.customerId) {
      // Update
      if (customerForm.value) {
        await customerResource.updateCustomer(props.modelValue);
      }
      submitCustomerDevices();
    } else {
      // New
      if (!customerForm.value) return;
      const resp = await customerResource.addCustomer(props.modelValue);

      if (props.modelValue != null) {
        props.modelValue.customerId = resp.data;
        submitCustomerDevices(true);
      }
    }
  } catch (e) {
    customerResource.defaultErrorHandler(e);
  }

  loading.value = false;
};

const countryAutocomplete = ref<VAutocomplete | null>(null);
const checkMenuState = async (newValue: string | null) => {

  if (!newValue && countryAutocomplete.value) {
    await nextTick();
    await nextTick();
      countryAutocomplete.value.focus();
      countryAutocomplete.value.menu = true
  }
};
// begin change management
const changesControl = ref<ChangeManager | null>(null);
watch(
  () => props.modelValue,
  (val: Customer | null, oldValue: Customer | null) => {
    changesControl.value = ChangeManager.modalController({
      controller: changesControl.value,
      isNewValue: val && oldValue === null,
      isDestroy: oldValue && val === null,
      isUpdateValue: oldValue && val && oldValue.customerId !== val.customerId,
      data: { customer: val, customerDevices: customerDevices.value },
      message: "You have unsaved Customer changes.",
      target: `customer_${val?.customerId}`,
      onLeave: () => {
        showDialog.value = false;
      },
      onSave: submit,
    });
  }
);

const setChangesStatus = () => {
  if (!props.modelValue) return;

  const origCustomer = changesControl?.value?.data?.origData?.customer;
  if (origCustomer && !ChangeManager.isObjectEqual(origCustomer, props.modelValue || {}, { isOrigPartial: true })) {
    changesControl.value?.activate();
    return;
  }

  const origCustomerDevice = changesControl.value?.data?.origData?.customerDevices;

  if (
    customerDevices.value &&
    origCustomerDevice &&
    !ChangeManager.isObjectEqual(customerDevices.value, origCustomerDevice)
  ) {
    changesControl.value?.activate();
    return;
  }

  changesControl.value?.deactivate();
};

watch(() => props.modelValue, setChangesStatus, { deep: true });

watch(customerDevices, setChangesStatus, { deep: true });

// end change management

const disabledSubmitBtn = computed(() => !ChangeManager.state().isChanged);

const countryCodes = computed(() =>
  Object.entries(countries.getNames("en", { select: "official" }))
    .sort((a, b) => {
      const mainCountries = ["DK", "SE", "NO"];
      const isSortByAlphabet = !mainCountries.includes(a[0]) && !mainCountries.includes(b[0]);
      if (isSortByAlphabet) return a[1] > b[1] ? 1 : -1;
      return mainCountries.indexOf(a[0]) > mainCountries.indexOf(b[0]) ? -1 : 1;
    })
    .map(([k]) => k)
);

const countryByCode = (code: string) => {
  return countries.getName(code, "en", { select: "official" });
};

const getCommentCount = () => {
  if (!props.modelValue?.customerId) {
    return;
  }
  customerResource
    .getCommentCount(props.modelValue.customerId)
    .then((resp) => {
      if (resp.data) {
        commentCount.value = resp.data.total;
      }
    })
    .catch(customerResource.defaultErrorHandler);
};

watch(
  () => props.modelValue,
  (val: Customer | null) => {
    deviceToAddValidationErrors.value = [];
    deviceIdToAdd.value = null;
    customerDevices.value = null;

    if (val != null) {
      tab.value = props.customerInitTab || null;
      getCommentCount();
    } else {
      tab.value = null;
      setComponentQuery("customerTab", null);
      changeHistoryCustomerId.value = null;
    }

    setComponentQuery("customerId", props.modelValue?.customerId ? props.modelValue?.customerId : null);
  }
);

const dialogHeading = computed(() => {
  let heading = "";
  if (props.modelValue) {
    heading = props.modelValue?.customerId
      ? `${props.modelValue.firstName} ${props.modelValue.lastName} (ID: ${props.modelValue.customerId})`
      : "New customer";
  }
  return heading;
});

const openDevice = (deviceId: number, isInitData = false) => {
  deviceResource
    .getDeviceById(deviceId)
    .then((resp) => {
      deviceToEdit.value = resp.data;
      if (isInitData) {
        deviceInitTab.value = props.initData?.deviceTab || null;
      }
    })
    .catch(deviceResource.defaultErrorHandler);
};

onActivated(() => {
  if (props.initData?.deviceId && props.initData?.customerId) {
    openDevice(props.initData?.deviceId, true);
  }
});

const getCustomerDevices = () => {
  if (props.modelValue === null) return;

  if (props.modelValue.customerId === 0) {
    customerDevices.value = [];
  }

  loadingCustomerDevices.value = true;
  customerResource
    .getDevicesByCustomerId(props.modelValue.customerId)
    .then((resp) => {
      customerDevices.value = resp.data;
      changesControl.value?.addOrigData({ customerDevices: resp.data });
    })
    .catch(customerResource.defaultErrorHandler)
    .finally(() => {
      loadingCustomerDevices.value = false;
    });
};

watch(tab, (val: string | null) => {
  if (val === "devices") {
    if (customerDevices.value === null) {
      getCustomerDevices();
    }
  } else if (val === "history") {
    changeHistoryCustomerId.value = props.modelValue?.customerId || null;
  }

  if (props.modelValue?.customerId) {
    setComponentQuery("customerTab", val);
  }
});

watch(deviceToEdit, () => {
  if (!deviceToEdit.value) {
    deviceInitTab.value = null;
  }
});

const deleteCustomer = () => {
  if (!canDeleteCustomer.value || props.modelValue == null) {
    return;
  }

  deleting.value = true;
  customerResource
    .deleteCustomer(props.modelValue.customerId)
    .then((resp) => {
      showDialog.value = false;
      emit("updated");
    })
    .catch(customerResource.defaultErrorHandler)
    .finally(() => {
      deleting.value = false;
    });
};

const deleteCustomerConfirm = () => {
  if (!canDeleteCustomer.value || props.modelValue == null) {
    return;
  }
  confirm.show(`Delete customer '${props.modelValue.firstName} ${props.modelValue.lastName}'?`).then((result) => {
    if (result) {
      deleteCustomer();
    }
  });
};

const getDeviceTypeName = (type: DeviceType) => {
  return DeviceHelper.getDeviceTypeDisplayName(type);
};

const deviceUpdated = (device: Device) => {
  if (!props.modelValue || !customerDevices.value) {
    return;
  }

  // filter out device if it has been just removed
  var filteredDeviceList = customerDevices.value.filter((item) => {
    if (device.deviceId === item.deviceId) {
      // if, after update, device no longer belongs to the customer, we remove it from the list
      if (item.customerId > 0 && Number(device.customerId) !== props.modelValue!.customerId) {
        return false;
      }
    }

    return true;
  });

  // update device object
  var updatedDeviceList: Device[] = filteredDeviceList.map((item) => {
    if (device.deviceId === item.deviceId) {
      return device;
    } else {
      return item;
    }
  });

  customerDevices.value = updatedDeviceList;
  changesControl.value?.addOrigData({ customerDevices: updatedDeviceList });
};

const addCustomerDevice = () => {
  const deviceId = Number(deviceIdToAdd.value);

  // check if not in the list already
  if (customerDevices.value?.some((item) => item.deviceId === deviceId)) {
    deviceToAddValidationErrors.value.push(`Device ID ${deviceIdToAdd.value} is already associated with this customer`);
    return;
  }

  if (props.modelValue && deviceId > 0) {
    loadingAddCustomerDevice.value = true;
    deviceResource
      .getDeviceById(deviceId)
      .then((resp) => {
        if (resp.data.customerId != 0 && resp.data.customerId != props.modelValue?.customerId) {
          confirm
            .show(
              `<p class="text-red">Device ID ${deviceIdToAdd.value} is already associated with Customer ID ${resp.data.customerId}.</p>Do you still want to associated this device with the current customer?`,
              { width: 400 }
            )
            .then((result) => {
              if (result) {
                // add device
                deviceIdToAdd.value = null;
                customerDevices.value?.push(resp.data);
              }
            });
        } else {
          // add device
          deviceIdToAdd.value = null;
          customerDevices.value?.push(resp.data);
        }
      })
      .catch((error) => {
        var errors = ResourceBase.readErrors(error);
        errors.forEach((msg) => {
          deviceToAddValidationErrors.value.push(msg);
        });
      })
      .finally(() => {
        loadingAddCustomerDevice.value = false;
      });
  }
};

const removeCustomerDevice = (deviceId: number) => {
  confirm
    .show(`Remove Device ID ${deviceId} from customer ${props.modelValue?.firstName} ${props.modelValue?.lastName}?`)
    .then((confirmed) => {
      if (confirmed) {
        if (!customerDevices.value) {
          return;
        }
        var filteredDeviceList = customerDevices.value.filter((item) => {
          return item.deviceId !== deviceId;
        });

        customerDevices.value = filteredDeviceList;
      }
    });
};

const close = (value: boolean) => {
  if (!value && ChangeManager.state().isChanged) {
    ChangeManager.show();
    return;
  }

  showDialog.value = value;
};

const countryFilter = (item: string, queryText: string) => {
  const searchText = queryText.toLowerCase();
  return item.toLowerCase().startsWith(searchText) || (countryByCode(item) || "").toLowerCase().startsWith(searchText);
};

const hasDelimiter = (item: string) => {
  return item === "DK" && !countrySearch.value;
};
</script>

<style scoped>
.delimiter {
  position: absolute;
  left: 5px;
  right: 5px;
  bottom: -5px;
}
.delimiterItem {
  position: relative;
  margin-bottom: 10px;
}
</style>
