<template>
  <v-dialog scrim="rgba(33, 33, 33)" v-if="canAddIssue" v-model="showNewIssueDialog" width="500">
    <template v-slot:activator="{ props }">
      <v-btn size="small" color="primary" class="align-self-end" v-bind="props"> New issue </v-btn>
    </template>
    <v-card>
      <v-toolbar flat color="primary" :height="4"> </v-toolbar>
      <v-card-title> New Issue </v-card-title>
      <v-card-text>
        <IssueSelect
          @create="createIssue"
          @change="onSelectIssue"
          :isLoading="loading"
          hint="Ensure that a similar issue doesn't already exist before you create a new one."
          label="Find existing issue or create a new one"
          :menuProps="{ maxWidth: '455' }"
          @update:title="(v:string) => (title = v)"
          :noFilter="true"
        />
      </v-card-text>

      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn variant="text" @click="cancel"> Cancel </v-btn>
        <v-btn color="primary" :disabled="!title" :loading="loading" @click="createIssue(null)"> Create </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script setup lang="ts">
import { UserPermissionType } from "@/types/UserPermissionType";
import axios, { CancelTokenSource } from "axios";
import issueResource from "@/resources/IssueResource";
import userProfileService from "@/services/UserProfileService";
import Issue from "@/types/Issue";
import IssueSelect from "@/components/common/IssueSelect.vue";
import { useRouter } from "vue-router";
import { ref, computed, watch, onMounted } from "vue";

let cancelToken: CancelTokenSource | undefined = undefined;
const loading = ref(false);
const showNewIssueDialog = ref(false);
const title = ref("");

const canAddIssue = computed(() => userProfileService.hasPermission(UserPermissionType.AddIssues));

const router = useRouter();

const cancel = () => {
  if (cancelToken) {
    cancelToken.cancel();
  }

  showNewIssueDialog.value = false;
};

const createIssue = (issueName: string | null) => {
  const name = issueName || title.value;
  if (!canAddIssue.value || !name) {
    return;
  }
  if (cancelToken) {
    cancelToken.cancel();
  }
  loading.value = true;
  cancelToken = axios.CancelToken.source();
  issueResource
    .addIssue(name, undefined, cancelToken)
    .then((resp) => {
      const newIssueId = resp.data.issueId;
      title.value = "";
      router.push(`/support/issues/${newIssueId}`);
      cancel();
    })
    .catch(issueResource.defaultErrorHandler)
    .finally(() => {
      loading.value = false;
      cancelToken = undefined;
    });
};

const onSelectIssue = (issue: Issue | null) => {
  if (!issue) return;
  router.push(`/support/issues/${issue.issueId}`);
};
</script>

<style scoped></style>
