<template>
  <div>
    <div class="d-flex">
      <div class="d-flex flex-wrap">
        <FileBox
          :key="file.attachmentId"
          v-for="(file, index) in props.files"
          :file="file"
          :index="index"
          :disabled="props.disabled"
          v-on:remove="onRemoveFile"
          v-on:open="openFile"
        />
        <FileBox
          :key="`${index}-local`"
          v-for="(file, index) in localFiles"
          :file="file"
          :index="index"
          :disabled="props.disabled"
          v-on:remove="removeLocalFile"
          v-on:open="openFile"
        />
        <FileInput
          v-if="!props.disabled"
          @upload="selectImages"
          :disabled="isUploading || isRemoving"
          :hint="props.maxSize"
          :small="!localFiles.length && !props.files.length"
        />
      </div>
    </div>
    <div v-if="!props.disabled" class="mt-3 font-weight-light text-caption text--disabled">Max file size is 100 MB</div>
    <div v-if="isUploading">
      <v-progress-linear class="my-1" height="2" :model-value="progress" />
      <div class="text-center text-primary">Uploading file {{ uploadMessage }} ...</div>
    </div>

    <FileViewer :openedFile="openedFile" :onClose="closeViewer" />
  </div>
</template>
<script setup lang="ts">
import AttachedFile from "@/types/AttachedFile";
import { AttachmentTargetType } from "@/types/AttachmentTargetType";
import fileResource from "@/resources/FileResource";
import infoMessageService from "@/services/InfoMessageService";
import { InfoMessageType } from "@/types/InfoMessageType";
import FileViewer from "./FileViewer.vue";
import FileBox from "./FileBox.vue";
import FileInput from "./FileInput.vue";
import { ref, computed} from "vue";

const emit = defineEmits(["updateLocalFiles", "update"]);
const props = withDefaults(
  defineProps<{
    readonly files?: AttachedFile[];
    readonly targetType: AttachmentTargetType;
    readonly targetId: number;
    readonly disabled?: boolean;
    readonly maxSize?: number;
    readonly isActionByCommand: boolean;
  }>(),
  { files: () => [], disabled: false, maxSize: 100, isActionByCommand: false }
);

const isUploading = ref(false);
const isRemoving = ref(false);
const uploadMessage = ref("");
const progress = ref(0);
const localFiles = ref<File[]>([]);
const removedFiles = ref<AttachedFile[]>([]);
const openedFile = ref<AttachedFile | File | null>(null);

const hint = computed(() => `Max file size is ${props.maxSize}MB`);

const allFiles = computed(() => (props.isActionByCommand ? [...props.files, ...localFiles.value] : props.files));

const closeViewer = () => {
  openedFile.value = null;
};

const uploadLocalFiles = () => {
  return uploadFiles(localFiles.value);
};

const onUploadProgress = ({ loaded, total }: { loaded: number; total: number }) => {
  progress.value = (loaded * 100) / total;
  if (progress.value >= 100) {
    progress.value = 0;
    if (isUploading.value) isUploading.value = false;
  }
};

const selectImages = (files: File[]) => {
  let newFiles = files.filter((f) => f instanceof File);
  if (props.maxSize) {
    newFiles = newFiles.filter((f) => {
      if (f.size > props.maxSize * 1048576) {
        infoMessageService.show(InfoMessageType.Error, `The ${f.name} file you are trying to upload is too big`);
        return false;
      }
      return true;
    });
  }

  if (!newFiles.length) {
    return;
  }

  if (props.isActionByCommand) {
    localFiles.value.push(...newFiles);
    emit("updateLocalFiles", localFiles.value);
    return;
  }

  uploadFiles(files);
};

const uploadFiles = async (newFiles: File[]) => {
  const uploadedFiles: any = [];
  const newUploadFiles = [...newFiles];
  localFiles.value = [];

  for (const file of newUploadFiles) {
    progress.value = 0;
    emit("update", [...props.files]);
    isUploading.value = true;
    progress.value = 1;
    uploadMessage.value = file.name;

    await fileResource
      .attachFile(props.targetType, props.targetId, [file], onUploadProgress)
      .then((resp) => {
        if (!props.isActionByCommand) {
          infoMessageService.show(InfoMessageType.Success, `File ${file.name} are uploaded`);
        }

        if (resp.data.errors) {
          resp.data.errors.forEach((message) => infoMessageService.show(InfoMessageType.Error, message));
        }

        uploadedFiles.push(...resp.data.uploaded);
        emit("update", [...props.files, ...resp.data.uploaded]);
        uploadMessage.value = "";
      })
      .catch((e) => {
        emit("update", [...props.files]);
        progress.value = 0;
        if (e.response && e.response.status == 413) {
          infoMessageService.show(InfoMessageType.Error, `The file you are trying to upload is too big`);
        } else {
          fileResource.defaultErrorHandler(e);
        }
      })
      .finally(() => {
        isUploading.value = false;
      });
  }

  if (props.isActionByCommand) {
    emit("updateLocalFiles", localFiles.value);
  }
  return uploadedFiles;
};

const openFile = (file: AttachedFile) => {
  openedFile.value = file;
};

const removeLocalFile = (index: number) => {
  localFiles.value.splice(index, 1);
  emit("updateLocalFiles", localFiles.value);
};

const removeFile = (index: number) => {
  isRemoving.value = true;
  const file = props.files[index];
  fileResource
    .deleteFile(file.attachmentId)
    .then(() => {
      emit(
        "update",
        props.files.filter((_, ind) => ind !== index)
      );
    })
    .catch(fileResource.defaultErrorHandler)
    .finally(() => {
      isRemoving.value = false;
    });
};

async function  removeFiles (newRemovedFiles: AttachedFile[] = removedFiles.value)  {
  const files = [...newRemovedFiles];
  removedFiles.value = [];

  for (const file of files) {
    progress.value = 0;
    isRemoving.value = false;

    await fileResource
      .deleteFile(file.attachmentId)
      .catch((e) => {
        emit("update", [...props.files, file]);
        progress.value = 0;
        if (e.response && e.response.status == 413) {
          infoMessageService.show(InfoMessageType.Error, `The file you are trying to upload is too big`);
        } else {
          fileResource.defaultErrorHandler(e);
        }
      })
      .finally(() => {
        isRemoving.value = false;
      });
  }
};

const onRemoveFile = (index: number) => {
  if (!props.isActionByCommand) {
    removeFile(index);
    return;
  }
  removedFiles.value.push(props.files[index]);
  emit(
    "update",
    props.files.filter((_, ind) => ind !== index)
  );
};

defineExpose({
  uploadLocalFiles,
  removeFiles
});
</script>

<style scoped></style>
