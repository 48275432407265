<template>
  <div>
    <SideSheet v-model="showDialog" heading="Export devices" contentClass="medium">
      <h4>Customer filter</h4>
      <v-select
        v-model="customerFilterSelectedItem"
        :items="customerFilterItems"
        density="compact"
        hide-details
        variant="outlined"
        class="filter-item"
        attach
      />

      <h4 class="mt-6">Device type filter</h4>
      <v-combobox
        attach
        v-model="deviceTypeFilterSelectedItems"
        :items="deviceTypeFilterItems"
        multiple
        variant="outlined"
        density="compact"
        hint="Leave empty to ignore this filter"
        persistent-hint
      ></v-combobox>

      <h4 class="mt-6">Firmware version filter</h4>
      <v-text-field
        v-model="fwVer"
        density="compact"
        hint="Leave empty to ignore this filter, otherwise enter comma separated firmware versions. Example: 4.19, 4.30"
        persistent-hint
        variant="outlined"
      />

      <h4 class="mt-6">Memory address filter</h4>
      <v-text-field
        v-model="memAddress"
        density="compact"
        hint="Leave empty to ignore this filter, otherwise enter comma separated memory addresses. Example: 0x404000, 0x484000, 0x0"
        persistent-hint
        variant="outlined"
      />

      <h4 class="mt-6">Bootloader version filter</h4>
      <v-text-field
        v-model="bootloaderVer"
        density="compact"
        hint="Leave empty to ignore this filter, otherwise enter comma separated bootloader versions. Example: 1.0, 2.0"
        persistent-hint
        variant="outlined"
      />

      <h4 class="mt-6">Include SIM status</h4>
      <v-switch
        class="mt-0"
        v-model="includeDeviceStatus"
        density="compact"
        persistent-hint
        hint="NB! Including SIM status noticeably increases export time."
        variant="outlined"
      />

      <h4 class="mt-6">Include firmware update URL</h4>
      <v-switch
        class="mt-0"
        v-model="includeFwUpdateUrl"
        density="compact"
        persistent-hint
        hint="NB! Including firmware update URL noticeably increases export time."
        variant="outlined"
      />

      <h4 class="mt-6">Export format</h4>
      <v-select
        v-model="exportFormat"
        :items="exportFormatItems"
        density="compact"
        hide-details
        variant="outlined"
        class="filter-item"
      />

      <div v-if="isBackgroundExport">
        <h4 class="mt-6">Email address</h4>
        <p class="text-caption text--secondary">
          Exporting devices based on the selected options may take a while. Once the export process is complete, we will
          deliver it to the specified email address below.
        </p>
        <v-text-field v-model="emailAddress" density="compact" hide-details variant="outlined" class="filter-item" />
      </div>

      <template v-slot:actions>
        <v-spacer />
        <v-btn @click="showDialog = false">Close</v-btn>
        <v-btn color="primary" class="ml-4" @click="exportDevices()">Export</v-btn>
      </template>
    </SideSheet>
  </div>
</template>

<script setup lang="ts">
import SideSheet from "@/components/layout/SideSheet.vue";
import { DeviceType } from "@/types/DeviceType";
import commonHelper from "@/helpers/commonHelper";
import userProfileService from "@/services/UserProfileService";
import deviceResource from "@/resources/DeviceResource";
import { ref, computed } from "vue";
import { useConfirm } from "@/services/ConfirmService";

const confirm = useConfirm();

const emit = defineEmits(["update:modelValue"]);

const props = withDefaults(
  defineProps<{
    readonly modelValue: boolean | null;
  }>(),
  {
    modelValue: null,
  }
);

const showDialog = computed({
  get() {
    return props.modelValue != null && props.modelValue;
  },
  set() {
    emit("update:modelValue", null);
  },
});

const customerFilterSelectedItem = ref("");
const customerFilterItems = [
  { title: "No filter", value: "" },
  { title: "Customer assigned", value: "true" },
  { title: "No customer assigned", value: "false" },
];

const deviceTypeFilterSelectedItems = ref<{ title: string; value: DeviceType }[]>([]);
const deviceTypeFilterItems = [
  { title: "Pro", value: DeviceType.GpsRadarPro },
  { title: "ProX", value: DeviceType.GpsRadarProX },
];

const fwVer = ref("");
const memAddress = ref("");
const bootloaderVer = ref("");
const includeDeviceStatus = ref(false);
const includeFwUpdateUrl = ref(false);

const exportFormat = ref("xls");
const exportFormatItems = [
  { title: "Excel (XLS)", value: "xls" },
  { title: "CSV", value: "csv" },
];

const emailAddress = ref("");

const isBackgroundExport = computed(() => {
  const isBackgroundExport = includeDeviceStatus.value || includeFwUpdateUrl.value;

  // TODO Prefill email with current user's email if not set. This should be done somewhere else, but for now it's here.
  if (isBackgroundExport && !emailAddress.value) {
    emailAddress.value = userProfileService.currentUser?.email || "";
  }

  return isBackgroundExport;
});

const exportDevices = () => {
  var queryParams: string[] = [];
  queryParams.push(`format=${exportFormat.value}`);

  // Optional export params
  if (customerFilterSelectedItem.value) {
    queryParams.push(`isCustomerAssigned=${customerFilterSelectedItem.value}`);
  }
  if (deviceTypeFilterSelectedItems.value.length) {
    queryParams.push(`deviceType=${deviceTypeFilterSelectedItems.value.map((el) => el.value).join(",")}`);
  }
  if (fwVer.value) {
    queryParams.push(`fw=${fwVer.value}`);
  }
  if (memAddress.value) {
    queryParams.push(`memAddress=${memAddress.value}`);
  }
  if (bootloaderVer.value) {
    queryParams.push(`bootloader=${bootloaderVer.value}`);
  }
  if (includeDeviceStatus.value) {
    queryParams.push("includeDeviceStatus=true");
  }
  if (includeFwUpdateUrl.value) {
    queryParams.push("includeFwUpdateUrl=true");
  }
  if (emailAddress.value) {
    queryParams.push(`recipientEmail=${emailAddress.value}`);
  }

  // Execute export
  if (isBackgroundExport.value) {
    // Background export
    deviceResource
      .exportDevices(queryParams)
      .then(() => {
        confirm.show(
          `Export started<br/><br/>An email will be sent to <b>${emailAddress.value}</b> once the export process is completed.`,
          {
            buttonTrueText: "OK",
            buttonFalseText: "",
            width: 500,
            persistent: true,
            overlayOpacity: 0.3,
          }
        );

        showDialog.value = false;
      })
      .catch(deviceResource.defaultErrorHandler);
  } else {
    // Direct export (browser download)
    const url = `${commonHelper.apiHost}/export/devices?${queryParams.join("&")}`;
    window.open(url, "_blank");
  }
};
</script>
