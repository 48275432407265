<template>
  <div
    v-bind:name="props.name"
    v-bind:desc="props.desc"
    v-bind:valign="props.valign"
    class="d-block d-sm-flex property-editor"
    :class="[valignClass, titleSizeClass]"
  >
    <div class="property-editor-header">
      <div class="text-subtitle-1">{{ props.name }}</div>
      <div v-if="desc" class="desc text-caption text-medium-emphasis text--secondary font-weight-light">
        {{ props.desc }}
      </div>
    </div>
    <div class="property-editor-content flex-grow-1">
      <slot />
    </div>
  </div>
</template>

<script setup lang="ts">
import { computed } from "vue";

const props = withDefaults(
  defineProps<{
    readonly name?: string;
    readonly desc?: string;
    readonly valign?: string;
    readonly titleSize?: "default" | "large";
  }>(),
  {
    name: "",
    desc: "",
    valign: "middle",
    titleSize: "default",
  }
);

const valignClass = computed(() => {
  if (props.valign === "top") {
    return "align-top";
  } else if (props.valign === "middle") {
    return "align-center";
  } else if (props.valign === "bottom") {
    return "align-bottom";
  }
});

const titleSizeClass = computed(() => {
  if (props.titleSize === "large") {
    return "large-title";
  }
  return null;
});
</script>
