<script setup lang="ts">
import moment from "moment";
import { CancelTokenSource } from "axios";
import L from "leaflet";
import { LMap, LTileLayer, LMarker, LControlScale, LIcon, LTooltip, LControl, LPolyline } from "@vue-leaflet/vue-leaflet";
//@ts-ignore
import { LMarkerRotate } from "vue-leaflet-rotate-marker";
import MapHelper from "@/helpers/mapHelper";
import deviceResource from "@/resources/DeviceResource";
import DeviceLocation from "@/types/DeviceLocation";
import MapIcons from "@/types/MapIcons";
import infoMessageService from "@/services/InfoMessageService";
import { InfoMessageType } from "@/types/InfoMessageType";
import { ref, watch, computed, onActivated, onDeactivated } from "vue";
import { useRoute } from "vue-router";

const loading = ref(false);
const cancelToken = ref<CancelTokenSource | undefined>(undefined);

const selectedDateRange = ref("lastHour");
const dateRangeOptions = [
  { title: "Last hour", value: "lastHour" },
  { title: "Last 3 hours", value: "last3Hours" },
  { title: "Last 6 hours", value: "last6Hours" },
  { title: "Last 12 hours", value: "last12Hours" },
  { title: "Last 24 hours", value: "last24Hours" },
  { title: "Define", value: "define" },
];

const showCalendar = ref(false);

watch(
  () => showCalendar,
  () => {
    if (showCalendar.value === false) {
      dateInd.value = 0;
    }
  }
);

const fromDate = ref<Date>(moment().add(-1, "days").startOf("day").toDate());
const toDate = ref<Date>(moment().startOf("day").toDate());
const dateInd = ref(0);

const locationMarkerIcon = MapIcons.smallArrow;
const locationMarkers = ref<DeviceLocation[]>([]);
const polyline = ref({
  latLngs: [] as number[][],
  color: "green",
});

const route = useRoute();
const deviceId = ref<number | null>(null);
const deviceIdRules = [(v: string) => !v || /^\d+$/.test(v) || "Device ID should be valid number."];

const dateRangeTextSeparator = ref(" ~ ");
const dateRangeText = ref(
  moment(fromDate.value).format("YYYY-MM-DD HH:mm") +
    dateRangeTextSeparator.value +
    moment(toDate.value).format("YYYY-MM-DD HH:mm")
);

onActivated(() => {
  // Prefill device id and trigger search
  if (route.query.deviceId) {
    deviceId.value = Number(route.query.deviceId);
    // Use timeout to give map object some time to init
    setTimeout(() => getLocationHistory(), 250);
  }

  setTimeout(() => {
    showMap.value = true;
  }, 10);
});

onDeactivated(() => {
  showMap.value = false;
});

const getRange = (startDate: string | undefined, endDate: string | undefined) => {
  let fromDate = startDate && moment(startDate, undefined, true).isValid() ? moment(startDate) : null;
  let toDate = startDate && moment(startDate, undefined, true).isValid() ? moment(endDate) : null;

  if (!fromDate || !toDate) {
    if (toDate) return [toDate.toDate()];
    if (fromDate) return [fromDate.toDate()];
    return [];
  }
  let diff = toDate.diff(fromDate, "days");
  let range = [fromDate.toDate()];
  for (let i = 0; i < diff; i++) {
    range.push(moment(startDate).add(i, "days").toDate());
  }
  range.push(toDate.toDate());
  return range;
};

const datesRange = computed({
  get() {
    var arr = dateRangeText.value.split(dateRangeTextSeparator.value);
    return getRange(arr[0], arr[1]);
  },
  set(val: Date[]) {
    if (dateInd.value == 0) {
      fromDate.value = moment(val[0]).startOf("day").toDate();
      toDate.value = moment(fromDate.value).add(1, "days").add(-1, "seconds").toDate();
      dateInd.value++;
    } else {
      toDate.value = moment(val[val.length - 1])
        .startOf("day")
        .add(1, "days")
        .add(-1, "seconds")
        .toDate();
      dateInd.value = 0;
    }

    dateRangeText.value =
      moment(fromDate.value).format("YYYY-MM-DD HH:mm") +
      dateRangeTextSeparator.value +
      moment(toDate.value).format("YYYY-MM-DD HH:mm");
  },
});
const showMap = ref(false);
const mapZoom = ref(5);
const mapCenter = ref([64, 19]);
const mapBounds = ref<any>(null);
const leaflet = ref({
  url: MapHelper.defaultMapTilesUrl,
  attribution: MapHelper.defaultMapAttr,
});


const getLocationHistory = () => {
  // Cancel existing request
  if (cancelToken.value) {
    cancelToken.value.cancel();
  }

  // Get date range
  var dateRange = getDateRange();

  // Validate id and dates
  if (!dateRange.length) {
    infoMessageService.show(InfoMessageType.Error, "Error parcing date range");
    return;
  }

  if (!deviceId.value) {
    infoMessageService.show(InfoMessageType.Error, "Missing device ID");
    return;
  }

  loading.value = true;
  locationMarkers.value = [];
  polyline.value.latLngs = [];

  setTimeout(() => {
    // Timeout is workaround for finaly() being executed after request was canceled and new request already began
    deviceResource
      .getDeviceLocationHistory(deviceId.value!, dateRange[0], dateRange[1])
      .then((resp) => {
        locationMarkers.value = resp.data;

        var lines: number[][] = [];
        locationMarkers.value.forEach((item) => {
          lines.push([item.latitude, item.longitude]);
        });

        polyline.value.latLngs = lines;

        setTimeout(() => {
          recenterMap();
        }, 10);

        // show location points limit count
        if (locationMarkers.value.length == 500) {
          infoMessageService.show(InfoMessageType.Warning, "The result was limited to 500 location points.");
        }
      })
      .catch(deviceResource.defaultErrorHandler)
      .finally(() => {
        loading.value = false;
        cancelToken.value = undefined;
      });
  }, 10);
};

const getDateRange = (): Date[] => {
  if (selectedDateRange.value == "lastHour") {
    return [moment().utc().add(-1, "hours").toDate(), moment().utc().toDate()];
  } else if (selectedDateRange.value == "last3Hours") {
    return [moment().utc().add(-3, "hours").toDate(), moment().utc().toDate()];
  } else if (selectedDateRange.value == "last6Hours") {
    return [moment().utc().add(-6, "hours").toDate(), moment().utc().toDate()];
  } else if (selectedDateRange.value == "last12Hours") {
    return [moment().utc().add(-12, "hours").toDate(), moment().utc().toDate()];
  } else if (selectedDateRange.value == "last24Hours") {
    return [moment().utc().add(-24, "hours").toDate(), moment().utc().toDate()];
  } else if (selectedDateRange.value == "define") {
    var arr = dateRangeText.value.split(dateRangeTextSeparator.value);
    return [moment(arr[0]).utc().toDate(), moment(arr[1]).utc().toDate()];
  }

  return [];
};

const getMarkerColor = (speed: number) => {
  if (speed > 120) {
    return "marker-color-red";
  } else if (speed > 80) {
    return "marker-color-orange";
  } else if (speed > 30) {
    return "marker-color-green";
  } else if (speed > 0) {
    return "marker-color-blue";
  } else {
    return "marker-color-gray";
  }
};

const recenterMap = () => {
  if (locationMarkers.value.length) {
    var southWest = [0, 0];
    var northEast = [0, 0];

    locationMarkers.value.forEach((item) => {
      if (southWest[0] == 0 || southWest[0] > item.latitude) {
        southWest[0] = item.latitude;
      }
      if (southWest[1] == 0 || southWest[1] > item.longitude) {
        southWest[1] = item.longitude;
      }
      if (northEast[0] == 0 || northEast[0] < item.latitude) {
        northEast[0] = item.latitude;
      }
      if (northEast[1] == 0 || northEast[1] < item.longitude) {
        northEast[1] = item.longitude;
      }
    });

    mapBounds.value = [southWest, northEast];
  }
};
</script>

<style scoped>
.map-top {
  z-index: 99;
}
.map-wrap {
  width: 100%;
  height: 100%;
}
.poi-filter {
  width: 180px;
  font-size: 14px;
}
.map-date-range-picker-wrap {
  display: flex;
  flex-direction: row;
  align-items: flex-end;
}
.map-top-inner {
  width: 100%;
}
</style>

<template>
  <v-container fluid class="pa-3" style="min-height: 100%; height: 100%">
    <div class="d-flex flex-column" style="height: 100%">
      <v-card class="d-flex flex-grow-0 mb-3 map-top">
        <v-card-title class="d-block d-sm-flex map-top-inner align-end">
          <v-text-field
            v-model="deviceId"
            label="Device ID"
            hide-details
            class="flex-grow-0 mt-md-0"
            style="width: 150px"
            :rules="deviceIdRules"
          />
          <v-select
            v-model="selectedDateRange"
            :items="dateRangeOptions"
            label="Date range"
            class="ml-0 ml-md-4 mt-2 mt-md-0 flex-grow-0"
            style="width: 150px"
            hide-details
            density="compact"
          />
          <div v-if="selectedDateRange == 'define'" class="map-date-range-picker-wrap">
            <v-text-field
              v-model="dateRangeText"
              label="From/to date"
              hide-details
              style="width: 280px"
              class="ml-0 ml-md-4 mt-2 mt-md-0"
            ></v-text-field>
            <v-menu
              v-model="showCalendar"
              :close-on-content-click="false"
              transition="scale-transition"
              offset-y
              nudge-bottom="5"
              max-width="290px"
              min-width="290px"
            >
              <template v-slot:activator="{ props }">
                <v-btn v-bind="props" icon density="compact" variant="text">
                  <v-icon>mdi-calendar</v-icon>
                </v-btn>
              </template>
              <v-date-picker
                v-model="datesRange"
                color="primary"
                :hide-header="true"
                no-title
                scrollable
                multiple="range"
                :show-current="moment().format('YYYY-MM-DD')"
              ></v-date-picker>
            </v-menu>
          </div>
          <v-btn
            size="small"
            color="primary"
            class="ml-0 ml-md-5 mt-2 mt-md-0"
            :loading="loading"
            @click="getLocationHistory()"
            >Search</v-btn
          >
        </v-card-title>
      </v-card>
      <v-card class="d-flex flex-grow-1" style="min-height: 400px">
        <div class="map-wrap">
          <l-map
            v-if="showMap"
            ref="map"
            class="map-container"
            v-model:zoom="mapZoom"
            v-model:center="mapCenter"
            v-model:bounds="mapBounds"
          >
            <l-tile-layer :url="leaflet.url" :attribution="leaflet.attribution"></l-tile-layer>
            <l-control-scale position="bottomleft" :imperial="false" :metric="true"></l-control-scale>
            <!-- LINE -->
            <l-polyline :lat-lngs="polyline.latLngs" :color="polyline.color" :weight="2" :smoothFactor="0"></l-polyline>
            <!-- LOCATION MARKERS -->
            <l-marker-rotate
              v-for="(marker, ind) in locationMarkers"
              :key="ind"
              :lat-lng="[marker.latitude, marker.longitude]"
              :rotationAngle="marker.heading"
              :rotationOrigin="locationMarkerIcon.roatationOrigin"
            >
              <l-icon
                :icon-url="locationMarkerIcon.iconUrl"
                :icon-size="locationMarkerIcon.iconSize"
                :icon-anchor="locationMarkerIcon.iconAnchor"
                :tooltipAnchor="[20, -10]"
                :className="getMarkerColor(marker.speed)"
              ></l-icon>
              <l-tooltip>
                <div class="poi-tooltip">
                  <h4>
                    {{ moment(marker.timestamp).local().format("lll") }}<br />
                    Speed: {{ marker.speed }} km/h
                  </h4>
                </div>
              </l-tooltip>
            </l-marker-rotate>
          </l-map>
        </div>
      </v-card>
    </div>
    <v-overlay position="absolute" :model-value="loading" opacity="0.1" z-index="999">
      <v-progress-linear indeterminate color="secondary" style="width: 200px" height="5px"></v-progress-linear>
      <!-- <v-progress-circular color="secondary" indeterminate size="32"></v-progress-circular> -->
    </v-overlay>
  </v-container>
</template>
