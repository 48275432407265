<template>
  <div class="api-key-resp-status-chart-wrap">
    <div v-if="showChart" class="api-key-resp-status-chart">
      <Overlay :show="loading" :loading="loading" />
      <v-chart
        :key="option.series && (option.series as []).length"
        ref="chart"
        :option="option"
        autoresize
        :class="{ 'opacity-50': loading }"
      />
    </div>
    <div v-else class="text-center text-body-2 text--disabled mt-2">No data available</div>
    <div class="api-key-chart-total text-caption mt-1">
      <div v-for="({ name, value }, index) in total" :key="index" class="mx-3 text-no-wrap">
        <v-icon size="small" class="mb-1" :color="getIconByStatus(name).color">{{ getIconByStatus(name).icon }}</v-icon>
        {{ `${name}: ` }} <span class="font-weight-light"> {{ value }}</span>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { use, ComposeOption } from "echarts/core";
import { CanvasRenderer } from "echarts/renderers";
import { LineChart, LineSeriesOption } from "echarts/charts";
import {
  TitleComponentOption,
  TitleComponent,
  TooltipComponent,
  TooltipComponentOption,
  LegendComponent,
  LegendComponentOption,
  GridComponent,
  GridComponentOption,
} from "echarts/components";
import VChart from "vue-echarts";
import axios, { CancelTokenSource } from "axios";
import apiKeysResource from "@/resources/ApiKeysResource";
import ApiKey from "@/types/ApiKey";
import moment from "moment";
import { useTheme } from "vuetify";
import { ref, watch, onMounted, onUnmounted } from "vue";
import Overlay from "@/components/common/Overlay.vue";

use([CanvasRenderer, LineChart, TitleComponent, TooltipComponent, LegendComponent, GridComponent]);
type ECOption = ComposeOption<
  LineSeriesOption | TitleComponentOption | TooltipComponentOption | LegendComponentOption | GridComponentOption
>;

const props = withDefaults(defineProps<{ readonly period: number; modelValue: ApiKey | null }>(), {
  modelValue: null,
});

let cancelToken: CancelTokenSource | undefined = undefined;
const loading = ref(false);

const total = ref<{ name: string; value: number }[]>([]);
const showChart = ref(true);

const colors = {
  dark: {
    textColor: "#fff",
    barTextColor: "#fedd10",
    secondaryColor: "#333",
  },
  light: {
    textColor: "#333",
    barTextColor: "#333",
    secondaryColor: "#ccc",
  },
};

const isDark = useTheme().global?.current?.value?.dark;

const option = ref<ECOption>({
  textStyle: {
    fontFamily: "Roboto, sans-serif",
  },

  grid: { top: 50, bottom: 30 },

  legend: {
    top: 20,
    textStyle: { color: colors[`${isDark ? "dark" : "light"}`].textColor },
    icon: "circle",
  },

  tooltip: {
    trigger: "axis",
    confine: true,
    formatter: (args: any) => {
      let tooltip = `<p>${moment(args[0].axisValue).format("D MMM, YYYY")}</p> `;
      let total = 0;

      args.forEach(({ marker, seriesName, value }: any) => {
        total += value[1] || 0;
        tooltip += `<div class="w-100 d-flex justify-space-between">
                        <span class='text-left'>${marker} ${seriesName}</span>
                        <b class="ml-5">${value[1] || 0}</b>
                      </div>`;
      });

      tooltip += `
          <div class="w-100 d-flex justify-space-between mt-4">
            <span class='text-left'>Total:</span>
            <b class="ml-5">${total}</b>
          </div>`;

      return tooltip;
    },
    axisPointer: {
      type: "line",
    },
  },

  xAxis: {
    type: "time",

    splitLine: {
      show: true,
      lineStyle: { color: colors[`${isDark ? "dark" : "light"}`].secondaryColor },
    },
  },

  yAxis: {
    type: "value",
    alignTicks: true,
    minInterval: 1,
    axisLabel: {
      hideOverlap: true,
      color: colors[`${isDark ? "dark" : "light"}`].textColor,
      formatter: (val: number) => (val > 10000 ? `${val / 1000}K` : val.toString()),
    },
    splitLine: {
      show: true,
      lineStyle: { color: colors[`${isDark ? "dark" : "light"}`].secondaryColor },
    },
  },

  series: [],
  animation: false,
});

watch(
  () => props.period,
  () => {
    if (props.period) getData();
  }
);

onMounted(() => getData());

onUnmounted(() => {
  // Cancel existing request
  if (cancelToken) {
    cancelToken.cancel();
  }
});

const getColorByStatus = (status?: string) => {
  if (!status) return;

  const type = status?.substring(0, 1);
  const subtype = Number(status?.substring(1));
  if (type === "2") {
    return `#91${(255 - 12 * subtype).toString(16)}75`;
  } else if (type === "4") {
    return `#fa${(255 - 15 * subtype).toString(16)}57`;
  } else if (type === "5") {
    return `#${(255 - 12 * subtype).toString(16)}6666`;
  }
  return;
};

const getIconByStatus = (status: string = "") => {
  const color = getColorByStatus(status);
  switch (status[0]) {
    case "2":
      return { icon: "mdi-check-circle", color: color };
    case "4":
      return { icon: "mdi-alert-outline", color: color };
    case "5":
      return { icon: "mdi-alert", color: color };
    default:
      return { icon: "mdi-sigma" };
  }
};

const getData = () => {
  // Cancel existing request
  if (cancelToken) {
    cancelToken.cancel();
  }

  setTimeout(() => {
    // Timeout is workaround for finaly() being executed after request was canceled and new request already began
    loading.value = true;
    cancelToken = axios.CancelToken.source();

    if (!props.modelValue) return;

    apiKeysResource
      .getDailyUsageByStatusCode(props.modelValue.apiKeyId, props.period, cancelToken)
      .then((resp) => {
        const data = resp.data;
        showChart.value = data.length > 0;

        const legend = [...new Set(data?.flatMap((item) => Object.keys(item.values)))].sort((a, b) => Number(a) - Number(b));

        const totalByItem: { [key: string]: number } = { sum: 0 };
        data.forEach((item) => {
          legend.forEach((v) => {
            totalByItem.sum += item.values[v] || 0;

            if (!totalByItem[v]) totalByItem[v] = 0;
            totalByItem[v] += item.values[v] || 0;
          });
        });

        total.value = [
          { name: "Total", value: totalByItem.sum },
          ...legend.map((v) => ({ name: v, value: totalByItem[v] })),
        ];
        option.value.legend = { ...(option.value.legend || {}), data: legend };

        option.value.series = legend.map((name) => ({
          name,
          type: "line",
          showSymbol: false,
          stack: "Total",
          sampling: "average",
          dimensions: [{ type: "time" }, { type: "number" }],
          areaStyle: { color: getColorByStatus(name) },
          lineStyle: { color: getColorByStatus(name) },
          itemStyle: { color: getColorByStatus(name) },
          data: data.map(({ date, values }) => [date, values[name]]),
        }));
      })
      .catch(apiKeysResource.defaultErrorHandler)
      .finally(() => {
        loading.value = false;
        cancelToken = undefined;
      });
  }, 10);
};
</script>

<style scoped>
.api-key-resp-status-chart-wrap {
  width: 100%;
  flex: 1;
}
.api-key-resp-status-chart {
  height: 500px;
  max-height: 70dvh;
  width: 100%;
  flex: 1;
  position: relative;
  align-items: center;
  justify-content: center;
}

.api-key-chart-total {
  display: flex;
  justify-content: center;
  border-top: 1px dashed #666;
  flex-wrap: wrap;
  padding-top: 5px;
}
</style>
