<template>
  <div class="chart">
    <v-overlay contained :model-value="loading" class="progress" z-index="101" persistent>
      <v-progress-linear indeterminate position="absolute" />
    </v-overlay>
    <v-chart :option="option" autoresize :class="{ 'opacity-50': loading }" />
  </div>
</template>

<script setup lang="ts">
import VChart from "vue-echarts";
import { use, graphic, ComposeOption } from "echarts/core";
import { CanvasRenderer } from "echarts/renderers";
import { BarChart, BarSeriesOption } from "echarts/charts";
import {
  TitleComponentOption,
  TitleComponent,
  TooltipComponentOption,
  TooltipComponent,
  GridComponent,
  GridComponentOption,
} from "echarts/components";

import statsResource from "@/resources/StatsResource";
import axios, { CancelTokenSource } from "axios";
import { useTheme } from "vuetify";
import { ref, onActivated, onDeactivated, computed, watch } from "vue";

use([CanvasRenderer, BarChart, TitleComponent, TooltipComponent, GridComponent]);
type ECOption = ComposeOption<BarSeriesOption | TitleComponentOption | TooltipComponentOption | GridComponentOption>;

const theme = useTheme();
const isDark = computed(() => theme?.global?.current?.value?.dark);

const yAxisMax = 100000;
const borderTopWidth = 0.02;
let timer = 0;
let cancelToken: CancelTokenSource | undefined = undefined;
const loading = ref(false);

const getData = () => {
  // Cancel existing request
  if (cancelToken) {
    cancelToken.cancel();
  }

  setTimeout(() => {
    // Timeout is workaround for finaly() being executed after request was canceled and new request already began
    loading.value = true;
    cancelToken = axios.CancelToken.source();

    statsResource
      .getActiveDevicesSummary(cancelToken)
      .then((resp) => {
        const data = resp.data;

        const maxValue = Math.max(...Object.values(data));
        const yMax = Math.min(yAxisMax, maxValue);
        const sortArr = [
          "lastMinute",
          "lastHour",
          "today",
          "lastSevenDays",
          "lastThirtyDays",
          "lastSixtyDays",
          "lastNinetyDays",
        ] as const;

        (option.value.series as BarSeriesOption).data = sortArr.map((key) => {
          const borderOffset = data[key] ? (yMax * borderTopWidth) / Math.min(yMax, data[key]) : 1;
          return {
            value: data[key],
            itemStyle: {
              color: new graphic.LinearGradient(0, 0, 0, 1, [
                { offset: 0, color: "#fedd10" },
                {
                  offset: borderOffset > 1 ? 1 : borderOffset,
                  color: "#fedd10",
                },
                {
                  offset: borderOffset > 1 ? 1 : borderOffset,
                  color: "rgba(254,222,16, 0.5)",
                },
                { offset: 1, color: "rgba(254,222,16, 0.5)" },
              ]),
            },
          };
        });
        if (!Array.isArray(option.value.yAxis) && option.value.yAxis?.max) {
          option.value.yAxis.max = yMax;
        }
      })
      .catch(statsResource.defaultErrorHandler)
      .finally(() => {
        loading.value = false;
        cancelToken = undefined;
      });
  }, 10);
};

onActivated(() => {
  getData();
  timer = setInterval(() => getData(), 60 * 1000);
});

onDeactivated(() => {
  if (timer) {
    const cloneTimer = timer;
    clearInterval(cloneTimer);
    timer = 0;
  }
});

const textColor = computed(() => (isDark.value ? "#fff" : "#333"));
const barTextColor = computed(() => (isDark.value ? "#fedd10" : "#333"));



const option = ref<ECOption>({
  textStyle: { fontFamily: "Roboto, sans-serif" },

  title: {
    text: "Active devices",
    left: "center",
    textStyle: {
      fontSize: 20,
      fontWeight: 400,
      color: textColor.value,
    },
  },

  yAxis: { show: false, max: yAxisMax },

  xAxis: {
    type: "category",
    data: ["Minute", "Hour", "Today", "Week", "30 days", "60 days", "90 days"],

    axisLabel: { color: isDark.value ? "#fff" : "#333" },
    axisLine: { show: false },
    axisTick: { show: false },
  },

  series: {
    type: "bar",
    itemStyle: {
      color: new graphic.LinearGradient(0, 0, 0, 1, [
        { offset: 0, color: "#fedd10" },
        {
          offset: (yAxisMax * borderTopWidth) / yAxisMax,
          color: "#fedd10",
        },
        {
          offset: (yAxisMax * borderTopWidth) / yAxisMax,
          color: "rgba(254,222,16, 0.5)",
        },
        { offset: 1, color: "rgba(254,222,16, 0.5)" },
      ]),
    },
    label: {
      show: true,
      position: "top",
      color: barTextColor.value,
    },

    barCategoryGap: "10%",
    data: [],
  },

  media: [
    {
      query: { maxWidth: 500 },
      option: {
        xAxis: {
          axisLabel: { fontSize: 10 },
          data: ["Min.", "Hour", "Today", "Week", "30d", "60d", "90d"],
        },
        series: [{ label: { fontSize: 10 } }],
      },
    },

    {
      query: { minWidth: 500 },
      option: {
        xAxis: { axisLabel: { fontSize: 14 } },
        series: [{ label: { fontSize: 14 } }],
      },
    },

    {
      query: { minWidth: 800 },
      option: {
        series: [{ label: { fontSize: 16 } }],
      },
    },
  ],
});

watch(isDark,
  () => {
    if (!Array.isArray(option.value.title) && option.value.title?.textStyle) {
      option.value.title.textStyle.color = textColor.value;
    }

    if (!Array.isArray(option.value.xAxis) && option.value.xAxis?.axisLabel) {
      option.value.xAxis.axisLabel.color = textColor.value;
    }

    if (!Array.isArray(option.value.series) && option.value.series?.label) {
      option.value.series.label.color = barTextColor.value;
    }
  },

);


</script>

<style scoped>
.chart {
  height: 100%;
  width: 100%;
  flex: 1;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
}

.progress {
  display: flex;
  align-items: center;
  justify-content: center;
}

.progress :deep(.v-overlay__content) {
  width: 25%;
  margin-bottom: 40px;
}
</style>
