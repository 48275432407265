<template>
  <div>
    <label>
      <input class="d-none" type="file" @change="onFileChanged" multiple />
      <div
        v-if="props.small"
        @drop.prevent="onDrop($event)"
        @dragover.prevent="dragover = true"
        @dragenter.prevent="dragover = true"
        @dragleave.prevent="dragover = false"
        class="small-upload mt-3"
      >
        <v-icon color="#f9be0b" elevation="25" class="mr-1 mb-1"> mdi-attachment </v-icon>
        Attach file
      </div>
      <v-card
        v-else
        @drop.prevent="onDrop($event)"
        @dragover.prevent="dragover = true"
        @dragenter.prevent="dragover = true"
        @dragleave.prevent="dragover = false"
        :class="{
          'mr-4 mt-4 preview-card active-card': true,
          drag: dragover,
        }"
        max-width="100"
      >
        <v-icon :color="dragover ? '#fedd10' : '#8e8f90'" size="40">mdi-file-plus</v-icon>
      </v-card>
    </label>
  </div>
</template>

<script setup lang="ts">
import { ref } from "vue";
const emit = defineEmits(["upload"]);
const props = withDefaults(
  defineProps<{
    readonly small: boolean;
  }>(),
  { small: false }
);

const dragover = ref(false);

const onFileChanged = (e: Event) => {
  const target = e.target as HTMLInputElement;
  if (target && target.files) {
    const files = target.files;
    emit("upload", Array.from(files));
  }
  target.value = "";
};

const onDrop = (e: DragEvent) => {
  dragover.value = false;
  if (e.dataTransfer) {
    const files = e.dataTransfer.files;
    emit("upload", Array.from(files));
  }
};
</script>

<style scoped>
.preview-card {
  width: 100px;
  height: 100px;
  display: flex;
  flex-grow: 1;
  align-items: center;
  justify-content: center;
}

.active-card {
  cursor: pointer;
}
.active-card:hover {
  border: 1px solid #fedd10 !important;
}
.active-card:hover i {
  color: #fedd10 !important;
  caret-color: #fedd10 !important;
}

.drag {
  border: 1px solid #fedd10 !important;
}

.preview-card {
  border: 1px solid rgb(var(--v-theme-dark-lighten3));
}

.small-upload {
  cursor: pointer;
  position: relative;
  margin-bottom: -0.6rem;
}
</style>
