<template>
  <div>
    <div v-if="canAddIssue || canManageIssueDevices">
      <AddDeviceIssue :deviceId="props.deviceId" @update="onAddIssue" />
    </div>
    <div class="text-subtitle-1">Related issues</div>
    <div class="d-block d-sm-flex mb-2">
      <div class="d-flex flex-grow-1">
        <v-text-field
          v-model="searchTerm"
          append-inner-icon="mdi-magnify"
          color="primary"
          variant="underlined"
          label="Search"
          hide-details
          clearable
          @click:clear="search()"
          v-on:input="search()"
          v-on:keypress.enter="search(true)"
        >
        </v-text-field>
        <v-btn density="compact" icon class="align-self-end ml-3" @click="reload" :disabled="loading" title="Refresh">
          <v-icon>mdi-reload</v-icon>
        </v-btn>
      </div>
    </div>

    <v-data-table-server
      class="issue-table"
      style="cursor: pointer"
      density="compact"
      :headers="headers"
      :items="items"
      :items-length="total"
      v-model:sort-by="sortBy"
      :must-sort="true"
      hover
      :mobile="false"
      :loading="loading"
      :mobile-breakpoint="0"
      :row-props="rowClass"
      @click:row="(_:any, { item }:any) => rowClick(item)"
    >
      <template v-slot:[`item.status`]="{ item }">
        <span :class="IssueHelper.getIssueStatusColor(item.status, true)">{{ getIssueStatusName(item.status) }}</span>
      </template>

      <template v-slot:[`item.priority`]="{ item }">
        <v-icon
          :color="IssueHelper.getIssuePriorityColor(item.priority)"
          :title="IssueHelper.getIssuePriorityName(item.priority)"
        >
          {{ IssueHelper.getIssuePriorityIcon(item.priority) }}
        </v-icon>
      </template>

      <template v-slot:[`item.tags`]="{ item }">
        <v-chip size="small" class="ma-1px" v-for="tag in item.tags" v-bind:key="tag.tagId">
          {{ tag.name }}
        </v-chip>
      </template>

      <template v-slot:[`item.deviceIssueStatus`]="{ item }">
        <div @click="$event.stopPropagation()">
          <v-select
            :loading="deviceStatusLoading.includes(item.issueId)"
            v-model="item.deviceIssueStatus"
            :items="issueDeviceStatuses"
            density="compact"
            flat
            background-color="transparent"
            hide-details
            class="status-select"
            :readonly="!item.isArchived && !canManageIssueDevices"
            :disabled="!item.isArchived && !canManageIssueDevices"
            @update:modelValue="(newStatus) => setDeviceIssueStatus(newStatus, item)"
            variant="solo"
          >
            <template v-slot:selection="{ item }">
              <span class="text-body-2" :class="item.raw.textColor">{{ item.title }}</span>
            </template>
            <template v-slot:item="{ props, item }">
              <v-list-item v-bind="props" class="text-body-2" :class="item.raw.textColor"> </v-list-item>
            </template>
          </v-select>
        </div>
      </template>

      <template v-slot:[`item.actions`]="{ item }">
        <div class="d-flex justify-end">
          <v-btn
            link
            :href="`/support/issues/${item.issueId}`"
            @click="$event.stopPropagation()"
            target="_blank"
            icon
            title="Open in new tab"
            density="compact"
            size="small"
            variant="text"
          >
            <v-icon size="small">mdi-open-in-new</v-icon>
          </v-btn>

          <v-btn
            v-if="item && !item.isArchived && canManageIssueDevices"
            @click.stop="() => deleteAssociatedIssueConfirm(item)"
            :loading="deletingIssueId.includes(item.issueId)"
            :disabled="deletingIssueId.includes(item.issueId)"
            icon
            density="compact"
            size="small"
            variant="text"
            class="ml-2"
          >
            <v-icon size="small">mdi-close-thick</v-icon>
          </v-btn>
        </div>
      </template>
      <template v-slot:bottom>
        <DataTableFooter
          v-model:page="page"
          :items="items"
          :itemsLength="total"
          v-model:itemsPerPage="itemsPerPage"
          :itemsPerPageOptions="[15, 25, 50]"
        />
      </template>
    </v-data-table-server>

    <v-overlay :scrim="false" contained :model-value="loading" persistent />
  </div>
</template>

<script setup lang="ts">
import Issue from "@/types/Issue";
import { IssueStatus } from "@/types/IssueStatus";
import issueResource from "@/resources/IssueResource";
import axios, { CancelTokenSource } from "axios";
import IssueHelper from "@/helpers/issueHelper";
import userProfileService from "@/services/UserProfileService";
import { UserPermissionType } from "@/types/UserPermissionType";
import AddDeviceIssue from "./AddDeviceIssue.vue";
import deviceResource from "@/resources/DeviceResource";
import { IssueDeviceStatus } from "@/types/IssueDeviceStatus";
import { useRoute, useRouter } from "vue-router";
import DataTableFooter from "@/components/common/DataTableFooter.vue";
import { ref, computed, watch, onMounted } from "vue";
import { useConfirm } from "@/services/ConfirmService";
import type { VDataTable } from "vuetify/components";
type ReadonlyHeaders = VDataTable["$props"]["headers"];

const emit = defineEmits(["countUpdate"]);
const props = withDefaults(defineProps<{ readonly deviceId: number | null }>(), { deviceId: null });

const confirm = useConfirm();

const total = ref(0);
const items = ref<(Issue & { deviceIssueStatus?: IssueDeviceStatus })[]>([]);
const loading = ref(false);
const deletingIssueId = ref<number[]>([]);
const deviceStatusLoading = ref<number[]>([]);

const itemsPerPage = ref(15);
const page = ref(1);
const sortBy = ref<{ key: string; order: boolean | "asc" | "desc" }[]>([{ key: "issueId", order: true }]);

const searchTerm = ref("");
let searchThrottleTimer = 0;
let cancelToken: CancelTokenSource | undefined = undefined;
let deviceIssueStatusToken: CancelTokenSource | undefined = undefined;
let cancelDeviceStatusToken: CancelTokenSource | undefined = undefined;

watch([itemsPerPage, sortBy, page], function onPropertyChanged() {
  getData();
});

watch(
  () => props.deviceId,
  function onDeviceChanged() {
    getData();
  }
);

const route = useRoute();
const router = useRouter();
onMounted(() => {
  getData();
});

const headers: ReadonlyHeaders = [
  { title: "ID", align: "start", key: "issueId" },
  { title: "Status", key: "status" },
  { title: "Priority", key: "priority" },
  { title: "Title", key: "name" },
  { title: "Tags", key: "tags" },
  { title: "Device status", key: "deviceIssueStatus", sortable: false },
  { title: "", key: "actions", sortable: false },
];

const canAddIssue = computed(() => userProfileService.hasPermission(UserPermissionType.AddIssues));

const canManageIssueDevices = computed(
  () =>
    userProfileService.hasPermission(UserPermissionType.EditIssues) ||
    userProfileService.hasPermission(UserPermissionType.ManageIssueDevices)
);

const canDeleteIssue = computed(() => userProfileService.hasPermission(UserPermissionType.DeleteIssues));

const issueDeviceStatuses = [
  {
    title: IssueHelper.getDeviceStatusDisplayName(IssueDeviceStatus.New),
    value: IssueDeviceStatus.New,
    textColor: IssueHelper.getDeviceStatusColor(IssueDeviceStatus.New),
  },
  {
    title: IssueHelper.getDeviceStatusDisplayName(IssueDeviceStatus.Active),
    value: IssueDeviceStatus.Active,
    textColor: IssueHelper.getDeviceStatusColor(IssueDeviceStatus.Active),
  },
  {
    title: IssueHelper.getDeviceStatusDisplayName(IssueDeviceStatus.Resolved),
    value: IssueDeviceStatus.Resolved,
    textColor: IssueHelper.getDeviceStatusColor(IssueDeviceStatus.Resolved),
  },
];

const getIssueStatusName = (status: number) => {
  return IssueHelper.getIssueStatusDisplayName(status);
};

const getData = () => {
  // Cancel existing request
  if (cancelToken) {
    cancelToken.cancel();
  }

  if (deviceIssueStatusToken) {
    deviceIssueStatusToken.cancel();
  }

  if (!props.deviceId) return;
  setTimeout(() => {
    // Timeout is workaround for finaly() being executed after request was canceled and new request already began
    loading.value = true;
    cancelToken = axios.CancelToken.source();
    deviceIssueStatusToken = axios.CancelToken.source();

    if (!props.deviceId) return;

    const orderDesc =
      typeof sortBy.value[0].order === "boolean" ? sortBy.value[0].order : sortBy.value[0].order.toString() === "desc";
    const promise = Promise.all([
      issueResource.getIssuesPaged(
        itemsPerPage.value,
        page.value,
        searchTerm.value,
        sortBy.value[0].key,
        orderDesc,
        undefined,
        undefined,
        undefined,
        undefined,
        props.deviceId,
        cancelToken
      ),
      deviceResource.getIssueStatusesByDeviceId(props.deviceId, deviceIssueStatusToken),
    ]);
    promise
      .then((resp) => {
        if (resp.some((v) => axios.isCancel(v))) return;
        const allDeviceIssueStatuses = resp[1].data;
        items.value = resp[0].data.items.map((v) => {
          const deviceIssueStatus = allDeviceIssueStatuses.find(({ issueId }) => issueId === v.issueId)?.status;
          return { ...v, deviceIssueStatus };
        });
        total.value = resp[0].data.totalItems;
      })
      .catch(issueResource.defaultErrorHandler)
      .finally(() => {
        loading.value = false;
        cancelToken = undefined;
      });
  }, 10);
};

const search = (noTheshold: boolean = false) => {
  if (searchThrottleTimer) {
    clearTimeout(searchThrottleTimer);
    searchThrottleTimer = 0;
  }

  if (noTheshold || !searchTerm.value) {
    getData();
  } else {
    searchThrottleTimer = setTimeout(() => {
      getData();
    }, 1000);
  }
};

const setDeviceIssueStatus = (status: IssueDeviceStatus, issueItem: Issue & { deviceIssueStatus?: IssueDeviceStatus }) => {
  const previousStatus = issueItem.deviceIssueStatus;
  // Cancel existing request
  if (cancelDeviceStatusToken) {
    cancelDeviceStatusToken.cancel();
  }

  if (!canManageIssueDevices || !issueItem) {
    return;
  }

  setTimeout(() => {
    // Timeout is workaround for finaly() being executed after request was canceled and new request already began

    if (!props.deviceId) return;
    cancelDeviceStatusToken = axios.CancelToken.source();
    deviceStatusLoading.value.push(issueItem.issueId);
    issueResource
      .updateIssueDeviceStatus(issueItem.issueId, props.deviceId, status, cancelDeviceStatusToken)
      .then(() => {
        emit("countUpdate");
      })
      .catch((e) => {
        issueResource.defaultErrorHandler(e);
        items.value = items.value.map((item) => {
          if (item.issueId === issueItem.issueId) {
            return { ...item, deviceIssueStatus: previousStatus };
          }
          return item;
        });
      })
      .finally(() => {
        cancelDeviceStatusToken = undefined;
        deviceStatusLoading.value = deviceStatusLoading.value.filter((v) => v !== issueItem.issueId);
      });
  }, 10);
};

const reload = () => {
  getData();
};

const rowClick = (item: Issue) => {
  if (route?.name === "Issue") {
    let route = router.resolve({ path: `/support/issues/${item.issueId}` });
    window.open(route.href, "_blank");
    return;
  }
  router.push(`/support/issues/${item.issueId}`).catch((e) => {
    if (e.message.includes("Navigation aborted")) return;
    if (e.message.includes("Avoided redundant navigation to current location")) {
      let route = router.resolve({ path: `/support/issues/${item.issueId}` });
      window.open(route.href, "_blank");
      return;
    }
    console.error(e);
  });
};

const rowClass = ({ item }: { item: Issue }) => {
  return { class: { "text-red": item.status === IssueStatus.Closed, "cursor-pointer": true } };
};

const onAddIssue = () => {
  itemsPerPage.value = 15;
  page.value = 1;
  sortBy.value = [{ key: "issueId", order: true }];
};

const deleteAssociatedIssueConfirm = (issue: Issue) => {
  if (!canManageIssueDevices.value || !props.deviceId) {
    return;
  }

  confirm
    .show(`Remove issue ID ${issue.issueId} from related issue list?`, {
      width: 360,
    })
    .then((confirmed) => {
      if (confirmed) {
        removeAssociatedIssue(issue);
      }
    });
};

const removeAssociatedIssue = (issue: Issue) => {
  if (!canManageIssueDevices.value || !props.deviceId) {
    return;
  }

  deletingIssueId.value.push(issue.issueId);
  issueResource
    .removeDevice(issue.issueId, props.deviceId)
    .then(() => reload())
    .catch(issueResource.defaultErrorHandler)
    .finally(() => {
      deletingIssueId.value = deletingIssueId.value.filter((v) => v !== issue.issueId);
    });
};
</script>

<style scoped>
.issue-table :deep(td),
.issue-table :deep(th) {
  padding-right: 14px !important;
  padding-left: 14px !important;
}

.status-select {
  width: 90px;
  padding: 0;
  margin: 0;
}

.status-select :deep(.v-input__control) > .v-field--active {
  padding: 0 !important;
  padding-inline-end: 0;
}

.status-select :deep(.v-input__control) > .v-field--active .v-field__input {
  padding: 0 !important;
  padding-inline-end: 0;
}
.issue-table :deep(.v-data-table-header th) {
  white-space: nowrap;
}
</style>
