<template>
  <div class="chart">
    <v-overlay contained :model-value="loading" class="progress" z-index="101" persistent>
      <v-progress-linear indeterminate position="absolute" />
    </v-overlay>
    <v-chart :option="option" autoresize :class="{ 'opacity-50': loading }" />
  </div>
</template>

<script setup lang="ts">
import { use, ComposeOption } from "echarts/core";
import { CanvasRenderer } from "echarts/renderers";
import { BarChart, BarSeriesOption } from "echarts/charts";
import {
  TitleComponentOption,
  TitleComponent,
  TooltipComponentOption,
  TooltipComponent,
  LegendComponent,
  GridComponent,
  GridComponentOption,
} from "echarts/components";
import VChart from "vue-echarts";
import statsResource from "@/resources/StatsResource";
import axios, { CancelTokenSource } from "axios";
import { useTheme } from "vuetify";
import { ref, watch, computed, onActivated, onDeactivated } from "vue";

use([CanvasRenderer, BarChart, TitleComponent, TooltipComponent, LegendComponent, GridComponent]);
type ECOption = ComposeOption<BarSeriesOption | TitleComponentOption | TooltipComponentOption | GridComponentOption>;

let timer = 0;
let cancelToken: CancelTokenSource | undefined = undefined;
const loading = ref(false);
const theme = useTheme();
const isDark = computed(() => theme?.global?.current?.value?.dark);

const textColor = computed(() => (isDark.value ? "#fff" : "#333"));
const secondaryColor = computed(() => (isDark.value ? "#333" : "#ccc"));

const option = ref<ECOption>({
  textStyle: { fontFamily: "Roboto, sans-serif" },
  grid: { bottom: 35 },

  legend: {
    top: 30,
    textStyle: { color: textColor.value },
  },

  title: {
    text: "Device firmware",
    left: "center",
    textStyle: {
      fontSize: 20,
      fontWeight: 400,
      color: textColor.value,
    },
  },

  xAxis: {
    type: "category",
    data: [],
    axisLine: { lineStyle: { color: secondaryColor.value } },
    axisLabel: { color: textColor.value },
  },

  yAxis: {
    axisLabel: {
      color: textColor.value,
      formatter: (val: number) => `${val / 1000}K`,
    },
    splitLine: { show: true, lineStyle: { color: secondaryColor.value } },
  },

  tooltip: {
    trigger: "axis",
    axisPointer: { type: "shadow" },
  },

  series: [
    {
      type: "bar",
      stack: "total",
      name: "ProX",
      itemStyle: { color: theme.current.value.colors.primary, opacity: 0.75 },
      data: [],
    },

    {
      type: "bar",
      stack: "total",
      name: "Pro",
      itemStyle: { color: "#f24911", opacity: 0.75 },
      data: [],
    },

    {
      type: "bar",
      stack: "total",
      name: "n/a",
      itemStyle: { color: "#999", opacity: 0.75 },
      data: [],
    },
  ],
});

const getData = () => {
  // Cancel existing request
  if (cancelToken) {
    cancelToken.cancel();
  }

  setTimeout(() => {
    // Timeout is workaround for finaly() being executed after request was canceled and new request already began
    loading.value = true;
    cancelToken = axios.CancelToken.source();

    statsResource
      .getDeviceTypesByVersion(cancelToken)
      .then((resp) => {
        const data = resp.data;

        (option.value.xAxis as any).data = data.map(({ ver }) => ver);
        const sortArr = ["ProX", "Pro", "n/a"] as const;
        const ProXData = data.map(({ perDeviceType }) => perDeviceType["ProX"] || 0);
        const ProData = data.map(({ perDeviceType }) => perDeviceType["Pro"] || 0);
        const naData = data.map(({ perDeviceType }) => perDeviceType["n/a"] || 0);
        const ProXSeries = (option.value.series as BarSeriesOption[]).find(({ name }) => name === "ProX");
        const ProSeries = (option.value.series as BarSeriesOption[]).find(({ name }) => name === "Pro");
        const naSeries = (option.value.series as BarSeriesOption[]).find(({ name }) => name === "n/a");
     
        option.value.series = [
          { ...ProXSeries, data: ProXData },
          { ...ProSeries, data: ProData },
          { ...naSeries, data: naData },
        ];
      })
      .catch(statsResource.defaultErrorHandler)
      .finally(() => {
        loading.value = false;
        cancelToken = undefined;
      });
  }, 10);
};

onActivated(() => {
  getData();
  timer = setInterval(() => getData(), 60 * 1000);
});

onDeactivated(() => {
  if (timer) {
    const cloneTimer = timer;
    clearInterval(cloneTimer);
    timer = 0;
  }
});

watch(isDark, () => {
  if (!Array.isArray(option.value.title) && option.value.title?.textStyle) {
    option.value.title.textStyle.color = textColor.value;
  }

  if (!Array.isArray(option.value.xAxis)) {
    if (option.value.xAxis?.axisLabel) {
      option.value.xAxis.axisLabel.color = textColor.value;
    }

    if (option.value.xAxis?.axisLine?.lineStyle) {
      option.value.xAxis.axisLine.lineStyle.color = secondaryColor.value;
    }
  }

  if (!Array.isArray(option.value.yAxis)) {
    if (option.value.yAxis?.axisLabel) {
      option.value.yAxis.axisLabel.color = textColor.value;
    }
    if (option.value.yAxis?.splitLine?.lineStyle) {
      option.value.yAxis.splitLine.lineStyle.color = secondaryColor.value;
    }
  }
});
</script>

<style scoped>
.chart {
  height: 100%;
  width: 100%;
  flex: 1;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
}

.progress {
  display: flex;
  align-items: center;
  justify-content: center;
}

.progress :deep(.v-overlay__content) {
  width: 25%;
}
</style>
