<template>
  <div v-if="loading" class="w-100 d-flex justify-center my-5">
    <v-progress-linear color="primary" class="w-25 my-5" indeterminate></v-progress-linear>
  </div>
  <div v-else-if="!error" class="html-source-container" v-html="html" />
  <div v-else>{{ error }}</div>
</template>

<script setup lang="ts">
import staticResource from "@/resources/StaticResource";
import { ref, onMounted } from "vue";

const props = defineProps<{ readonly src: string }>();

const loading = ref(false);
const error = ref("");
const html = ref("");

const getHTML = () => {
  if (!props.src) return;

  loading.value = true;

  staticResource
    .getHTML(props.src)
    .then((res) => (html.value = res.data))
    .catch(() => {
      staticResource.defaultErrorHandler;
      error.value = "No description available";
    })
    .finally(() => {
      loading.value = false;
    });
};

onMounted(() => {
  getHTML();
});
</script>
