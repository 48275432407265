<template>
  <v-card :loading="loading" :disabled="loading" flat class="overflow-visible">
    <v-card-text class="pa-0">
      <v-sheet class="add-device-comment">
        <RichText v-model="newContent" :disabled="loading" small="true" placeholder="Write your new comment here..." />
      </v-sheet>
      <div class="d-sm-flex justify-space-between align-end">
        <Attachments
          ref="attachmentsRef"
          :targetType="targetType"
          :targetId="commentId"
          v-on:updateLocalFiles="updateLocalFiles"
          :isActionByCommand="true"
        />
        <div class="text-right mt-4">
          <v-btn color="primary" @click="addComment" :loading="loading" :disabled="!isReadyForm || loading" size="small">
            Add comment
          </v-btn>
        </div>
      </div>
    </v-card-text>
  </v-card>
</template>

<script setup lang="ts">
import { UserPermissionType } from "@/types/UserPermissionType";
import RichText from "@/components/common/RichText.vue";
import Attachments from "@/components/common/Attachments.vue";
import userProfileService from "@/services/UserProfileService";
import deviceResource from "@/resources/DeviceResource";
import { InfoMessageType } from "@/types/InfoMessageType";
import infoMessageService from "@/services/InfoMessageService";
import { AttachmentTargetType } from "@/types/AttachmentTargetType";
import { ref, computed } from "vue";

const emit = defineEmits(["created"]);
const props = defineProps<{ readonly deviceId: number | undefined }>();

const attachmentsRef = ref<typeof Attachments | null>(null);
const loading = ref(false);
const newContent = ref("");
const targetType = AttachmentTargetType.DeviceComment;
const commentId = ref(0);
const localFiles = ref<File[]>([]);

const canAddComment = computed(() => userProfileService.hasPermission(UserPermissionType.ViewDevices));

const isReadyForm = computed(() => Boolean(newContent.value.trim().length));

const updateLocalFiles = (files: File[]) => {
  localFiles.value = files;
};

const addComment = () => {
  if (!canAddComment.value || !isReadyForm.value) {
    if (!newContent.value.trim().length) {
      infoMessageService.show(InfoMessageType.Info, "Add text to the new comment");
    }
    return;
  }
  if (props.deviceId === undefined) return;

  loading.value = true;
  let data: any;
  return deviceResource
    .addDeviceComment(props.deviceId, newContent.value)
    .then((resp) => {
      commentId.value = resp.data.commentId;
      data = resp.data;
    })
    .then(() => (attachmentsRef.value as typeof Attachments)?.uploadLocalFiles())
    .then((attachments) => {
      data.attachments = attachments;
      commentId.value = 0;
      localFiles.value = [];

      emit("created", data);
      newContent.value = "";
    })
    .catch(deviceResource.defaultErrorHandler)
    .finally(() => {
      loading.value = false;
    });
};
</script>

<style scoped>
.add-device-comment {
  position: relative;
}
</style>
