<template></template>

<script setup lang="ts">
import authResource from "@/resources/AuthResource";
import authService from "@/services/AuthService";
import { onMounted, onUnmounted } from "vue";

let interval = 0;

const refreshToken = () => {
  if (!authService.authInfo.refreshAfter) {
    return;
  }

  // Auth token refresh
  var now = Date.now();
  if (authService.authInfo.refreshAfter < now) {
    authResource
      .refreshToken()
      .then((resp) => {
        authService.setToken(resp.data.token, resp.data.expires);
      })
      .catch((e) => {
        console.error(e);
      });
  }
};

onMounted(() => {
  interval = setInterval(() => refreshToken(), 1000 * 60 * 5); // every 5 minutes
});

onUnmounted(() => {
  clearInterval(interval);
  interval = 0;
});
</script>

<style scoped></style>
