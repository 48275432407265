<template>
  <v-container fluid>
    <v-card>
      <v-card-title class="d-block d-sm-flex">
        <div class="d-flex flex-grow-1">
          <v-text-field
            v-model="searchTerm"
            append-inner-icon="mdi-magnify"
            color="primary"
            variant="underlined"
            label="Search"
            hide-details
            clearable
            test-id="search"
          ></v-text-field>
          <v-btn
            size="x-small"
            variant="text"
            icon
            class="align-self-end ml-4"
            @click="reload"
            :disabled="loading"
            title="Refresh"
          >
            <v-icon size="24">mdi-reload</v-icon>
          </v-btn>
          <TableConfiguration :allHeaders="headers" v-model="selectedHeaders" tableKey="userGroupsTableColumns" />
        </div>
        <v-spacer class="d-none d-sm-block"></v-spacer>
        <div class="text-right align-self-end mt-2 mt-sm-0">
          <v-btn size="small" color="primary" class="align-self-end" @click="newUserGroup()" test-id="new-user-group">
            New user group
          </v-btn>
        </div>
      </v-card-title>

      <v-data-table
        density="compact"
        :headers="selectedHeaders"
        :items="items"
        :row-props="rowClass"
        v-model:sort-by="sortBy"
        :must-sort="true"
        hover
        :mobile="false"
        :loading="loading"
        :search="searchTerm"
        :disable-pagination="true"
        :hide-default-footer="true"
        items-per-page="-1"
        :mobile-breakpoint="0"
        @click:row="(_, { item }) => rowClick(item)"
      >
        <template #bottom></template>
      </v-data-table>

      <v-overlay :model-value="loading" contained persistent opacity="0" style="z-index: 999 !important" />
    </v-card>

    <EditUserGroup v-model="userGroupToEdit" v-on:updated="reload" />
  </v-container>
</template>

<script setup lang="ts">
import userGroupResource from "@/resources/UserGroupResource";
import axios, { CancelTokenSource } from "axios";
import UserGroup from "@/types/UserGroup";
import userStorage from "@/services/UserStorageService";
//@ts-ignore
import EditUserGroup from "@/components/users/EditUserGroup.vue";
import TableConfiguration from "@/components/common/TableConfiguration.vue";
import { ref, watch, onMounted, onBeforeMount } from "vue";

const props = withDefaults(defineProps<{ initData: { userId: number; userGroupId: number; userTab: string } | null }>(), {
  initData: null,
});

const items = ref<UserGroup[]>([]);
const loading = ref(false);
let cancelToken: CancelTokenSource | undefined = undefined;

const searchTerm = ref<string>("");
const optionsStorageKey = "userGroupsTable";

const sortBy = ref<{ key: string; order: boolean | "asc" | "desc" }[]>(
  userStorage.get(optionsStorageKey)?.sortBy?.[0]?.key
    ? userStorage.get(optionsStorageKey)?.sortBy
    : [{ key: "userGroupId", order: true }]
);

watch(
  sortBy,
  () => {
    userStorage.set(optionsStorageKey, { sortBy: sortBy.value });
  },
  { deep: true }
);

const selectedHeaders = ref([]);
const headers = ref([
  { title: "ID", align: "start", key: "userGroupId" },
  { title: "Name", key: "name" },
]);

onMounted(() => {
  if (props.initData?.userGroupId) {
    getInitUserGroupById(props.initData?.userGroupId);
  }
});

const getInitUserGroupById = (userGroupId: number) => {
  userGroupResource
    .getUserGroupById(userGroupId)
    .then((resp) => {
      rowClick(resp.data);
    })
    .catch(userGroupResource.defaultErrorHandler);
};

const userGroupToEdit = ref<UserGroup | null>(null);

onBeforeMount(() => getData());

const newUserGroup = () => {
  userGroupToEdit.value = {
    userGroupId: 0,
    name: "",
    userGroupPermissions: [],
  } as UserGroup;
};

const getData = () => {
  // Cancel existing request
  if (cancelToken) {
    cancelToken.cancel();
  }

  setTimeout(() => {
    // Timeout is workaround for finaly() being executed after request was canceled and new request already began
    loading.value = true;
    cancelToken = axios.CancelToken.source();

    userGroupResource
      .getAllUserGroups(cancelToken)
      .then((resp) => {
        items.value = resp.data;
      })
      .catch(userGroupResource.defaultErrorHandler)
      .finally(() => {
        loading.value = false;
        cancelToken = undefined;
      });
  }, 10);
};

const reload = () => {
  getData();
};

const rowClick = (item: UserGroup) => {
  userGroupToEdit.value = Object.assign({}, item);
};

const rowClass = ({ item }: { item: UserGroup }) => {
  return { class: { "cursor-default": true } };
};
</script>
