<template>
  <div v-click-outside="onBlur" @click="onFocus" class="rich-text-container" :class="styles">
    <QuillEditor
      :key="`${showToolbar.toString()}-${props.disabled.toString()}`"
      :options="editorOption"
      v-model:content="inputValue"
      contentType="html"
      :readOnly="props.disabled"
      :class="styles"
      ref="quillEditor"
      :toolbar="props.toolbarType"
    />
  </div>
</template>

<script setup lang="ts">
import { ref, computed, watch, onMounted, nextTick } from "vue";
import { QuillEditor } from "@vueup/vue-quill";
import "@vueup/vue-quill/dist/vue-quill.snow.css";

const emit = defineEmits(["update:modelValue", "focus", "blur"]);
const props = withDefaults(
  defineProps<{
    readonly modelValue: string | null;
    readonly disabled?: boolean;
    readonly small?: any;
    readonly placeholder?: string;
    readonly alwaysFocus?: boolean;
    readonly toolbarType?: string;
  }>(),
  { modelValue: "", disabled: false, small: true, alwaysFocus: false, }
);

const showToolbar = ref(false);
const lastUpdate = ref("");

const quillEditor = ref(null);
watch(
  () => props.modelValue,
  function syncValue() {
    if (lastUpdate.value !== props.modelValue) {
      //@ts-ignore
      if (quillEditor.value?.setContents) {
        //@ts-ignore
        nextTick(() => quillEditor.value?.setContents?.(props.modelValue));
      }
    }
  }
);

const styles = computed(() => {
  return {
    "w-hover": true,
    "hide-toolbar": !showToolbar.value,
    activeRichText: showToolbar.value,
    "small-rich-text": props.small,
  };
});

const editorOption = computed(() => {
  return { placeholder: props.modelValue ? "" : props.placeholder, modules: { toolbar: true } };
});

const inputValue = computed({
  get: () => {
    return props.modelValue;
  },
  set: (newValue: any) => {
    const editorValue = newValue === "<p></p>" || newValue === "<p><br></p>" ? "" : newValue;
    lastUpdate.value = editorValue;

    emit("update:modelValue", editorValue);
  },
});

const onFocus = () => {
  if (!props.disabled && !showToolbar.value) {
    //@ts-ignore
    if (quillEditor.value?.focus && props.modelValue?.length) {
      nextTick(() => {
        setTimeout(() => {
          //@ts-ignore
          const quill = quillEditor.value?.getQuill();
          if (props.modelValue) {
            quill.setSelection(props.modelValue.length, 0, "user");
          }
        }, 0);
      });
    }

    showToolbar.value = true;
    emit("focus");
  }

  //@ts-ignore
  if (quillEditor.value?.focus) {
    //@ts-ignore
    nextTick(() => quillEditor.value?.focus?.());
  }
};

const onBlur = () => {
  if (props.alwaysFocus) return;

  if (showToolbar.value) {
    showToolbar.value = false;
    emit("blur");
  }
};

onMounted(() => {
  if (props.alwaysFocus) {
    onFocus();
  }
});
</script>

<style>
.rich-text-container {
  display: flex;
  flex-direction: column-reverse;
  border: none;
  border-radius: 4px;
  position: relative;
}

.rich-text-container .ql-toolbar,
.rich-text-container .ql-container {
  border: none;
}

.v-theme--dark .ql-tooltip.ql-editing {
  background-color: #2d2d2d;
  box-shadow: none;
  color: #fff;
}

.v-theme--dark .ql-snow .ql-picker-options {
  background-color: #2d2d2d;
}

.v-theme--dark .ql-snow .ql-tooltip {
  background-color: #2d2d2d;
  box-shadow: none;
  color: #fff;
}

.v-theme--dark .ql-tooltip.ql-editing input[type="text"] {
  background-color: #2d2d2d;
  box-shadow: none;
  color: #fff;
  outline: none;
}

.ql-tooltip.ql-editing {
  z-index: 99999;
  position: absolute;
  -webkit-transform: translateY(10px);
  transform: translateY(10px);
}

.v-theme--dark .ql-editor.ql-blank::before {
  color: #fff;
  opacity: 0.4;
}

.ql-snow.ql-toolbar button.ql-active .ql-stroke,
.ql-snow .ql-toolbar button.ql-active .ql-stroke,
.ql-snow.ql-toolbar .ql-picker-label.ql-active .ql-stroke,
.ql-snow .ql-toolbar .ql-picker-label.ql-active .ql-stroke,
.ql-snow.ql-toolbar .ql-picker-item.ql-selected .ql-stroke,
.ql-snow .ql-toolbar .ql-picker-item.ql-selected .ql-stroke,
.ql-snow.ql-toolbar button.ql-active .ql-stroke-miter,
.ql-snow .ql-toolbar button.ql-active .ql-stroke-miter,
.ql-snow.ql-toolbar .ql-picker-label.ql-active .ql-stroke-miter,
.ql-snow .ql-toolbar .ql-picker-label.ql-active .ql-stroke-miter,
.ql-snow.ql-toolbar .ql-picker-item.ql-selected .ql-stroke-miter,
.ql-snow .ql-toolbar .ql-picker-item.ql-selected .ql-stroke-miter,
.ql-snow.ql-toolbar button.ql-active,
.ql-snow .ql-toolbar button.ql-active,
.ql-snow.ql-toolbar .ql-picker-label.ql-active,
.ql-snow .ql-toolbar .ql-picker-label.ql-active,
.ql-snow.ql-toolbar .ql-picker-item.ql-selected,
.ql-snow .ql-toolbar .ql-picker-item.ql-selected {
  stroke: rgb(var(--v-theme-primary)) !important;
  color: rgb(var(--v-theme-primary)) !important;
}

.ql-snow.ql-toolbar button.ql-active .ql-fill,
.ql-snow .ql-toolbar button.ql-active .ql-fill,
.ql-snow.ql-toolbar .ql-picker-label.ql-active .ql-fill,
.ql-snow .ql-toolbar .ql-picker-label.ql-active .ql-fill,
.ql-snow.ql-toolbar .ql-picker-item.ql-selected .ql-fill,
.ql-snow .ql-toolbar .ql-picker-item.ql-selected .ql-fill,
.ql-snow.ql-toolbar button.ql-active .ql-stroke.ql-fill,
.ql-snow .ql-toolbar button.ql-active .ql-stroke.ql-fill,
.ql-snow.ql-toolbar .ql-picker-label.ql-active .ql-stroke.ql-fill,
.ql-snow .ql-toolbar .ql-picker-label.ql-active .ql-stroke.ql-fill,
.ql-snow.ql-toolbar .ql-picker-item.ql-selected .ql-stroke.ql-fill,
.ql-snow .ql-toolbar .ql-picker-item.ql-selected .ql-stroke.ql-fill {
  fill: rgb(var(--v-theme-primary)) !important;
}

.ql-snow.ql-toolbar button.ql-active,
.ql-snow .ql-toolbar button.ql-active,
.ql-snow.ql-toolbar .ql-picker-label.ql-active,
.ql-snow .ql-toolbar .ql-picker-label.ql-active,
.ql-snow.ql-toolbar .ql-picker-item.ql-selected,
.ql-snow .ql-toolbar .ql-picker-item.ql-selected {
  background: none;
}

:root[theme="dark"] .ql-toolbar .ql-stroke {
  stroke: #bbbbbb;
}

:root[theme="dark"] .ql-toolbar .ql-fill {
  fill: #bbbbbb;
}

:root[theme="dark"] .ql-toolbar .ql-picker {
  color: #bbbbbb;
}

.ql-snow.ql-toolbar button:hover,
.ql-snow .ql-toolbar button:hover,
.ql-snow.ql-toolbar button:focus,
.ql-snow .ql-toolbar button:focus,
.ql-snow.ql-toolbar .ql-picker-label:hover,
.ql-snow .ql-toolbar .ql-picker-label:hover,
.ql-snow.ql-toolbar .ql-picker-item:hover,
.ql-snow.ql-toolbar button:hover .ql-stroke,
.ql-snow.ql-toolbar .ql-picker-label:hover .ql-stroke {
  background: none;
  color: rgb(var(--v-theme-primary)) !important;
  stroke: rgb(var(--v-theme-primary)) !important;
}

:root[theme="dark"] .ql-snow .ql-picker-options {
  background: #2d2d2d;
}

.small-rich-text .ql-container.ql-snow {
  min-height: 0;
}

.ql-container.ql-snow {
  min-height: 150px;
  border: none;
  display: flex;
  flex-direction: column;
  flex: 1;
}

.hide-toolbar .ql-toolbar {
  padding: 0;
}

.hide-toolbar .ql-toolbar {
  display: none;
}

.ql-editor {
  font-family: Roboto, sans-serif !important;
  font-size: 0.875rem;
  padding: 5px 0;
}

.ql-editor.ql-blank::before {
  right: 0;
  left: 0;
}

.activeRichText .ql-editor.ql-blank::before {
  right: 15px;
  left: 15px;
}

.rich-text-container.activeRichText {
  border: 1px solid rgb(var(--v-theme-primary));
  box-shadow: inset 0px 0px 0px 1px rgb(var(--v-theme-primary));
}

.activeRichText .ql-editor {
  font-family: Roboto, sans-serif !important;
  font-size: 0.875rem;
  padding: 10px 15px;
}

.ql-snow a {
  color: rgb(var(--v-theme-primary)) !important;
}
</style>
