<template>
  <div v-if="canAddIssue || canManageIssueDevices">
    <IssueSelect
      :key="issueSelectKey"
      @create="addNewIssue"
      @change="(v) => (selectedIssue = v)"
      :isLoading="creating || issueUpdating"
      hint="Select an existing issue or create a new issue and automatically connect this device to the issue."
      label="Search existing or create new issue"
      :menuProps="{ maxWidth: '770' }"
      @update:title="(v) => (title = v)"
      :noFilter="true"
    />

    <div class="text-right mt-2 mb-2">
      <v-btn
        v-if="canAddIssue || canManageIssueDevices"
        size="small"
        color="primary"
        :disabled="!selectedIssue"
        :loading="issueUpdating"
        class="align-self-end"
        @click="addDeviceToIssue"
      >
        Confirm
      </v-btn>
    </div>
  </div>
</template>

<script setup lang="ts">
import Issue from "@/types/Issue";
import issueResource from "@/resources/IssueResource";
import userProfileService from "@/services/UserProfileService";
import { UserPermissionType } from "@/types/UserPermissionType";
import infoMessageService from "@/services/InfoMessageService";
import { InfoMessageType } from "@/types/InfoMessageType";
import IssueSelect from "@/components/common/IssueSelect.vue";
import { ref, computed } from "vue";

const emit = defineEmits(["update"]);
const props = defineProps<{ readonly deviceId: number | null }>();

const issueSelectKey = ref(new Date().getTime());
const title = ref("");
const selectedIssue = ref<Issue | null>(null);

const creating = ref(false);
const issueUpdating = ref(false);

const canAddIssue = computed(() => userProfileService.hasPermission(UserPermissionType.AddIssues));

const canManageIssueDevices = computed(
  () =>
    userProfileService.hasPermission(UserPermissionType.EditIssues) ||
    userProfileService.hasPermission(UserPermissionType.ManageIssueDevices)
);

const addDeviceToIssue = () => {
  if (!(canAddIssue.value || canManageIssueDevices.value) || !selectedIssue.value) {
    return;
  }
  issueUpdating.value = true;
  if (!props.deviceId) return;
  issueResource
    .addDevice(selectedIssue.value.issueId, props.deviceId)
    .then(() => {
      issueSelectKey.value = new Date().getTime();
      emit("update");
      selectedIssue.value = null;
      title.value = "";
    })
    .catch(issueResource.defaultErrorHandler)
    .finally(() => {
      issueUpdating.value = false;
    });
};

const addNewIssue = () => {
  if (!canAddIssue.value || !title.value) {
    return;
  }
  creating.value = true;
  if (!props.deviceId) return;
  issueResource
    .addIssue(title.value, props.deviceId ? [props.deviceId] : undefined)
    .then(() => {
      infoMessageService.show(InfoMessageType.Success, `New Issue with name ${title.value} created`);
      emit("update");
      issueSelectKey.value = new Date().getTime();
      selectedIssue.value = null;
      title.value = "";
    })
    .catch(issueResource.defaultErrorHandler)
    .finally(() => {
      creating.value = false;
    });
};
</script>

<style scoped></style>
