<template>
  <div>
    <div class="d-block mt-4">
      <v-select
        attach
        v-model="period"
        :items="periodOptions"
        variant="outlined"
        density="compact"
        class="flex-grow-0"
        style="min-width: 150px"
        hide-details
        label="Time window"
      />
    </div>
    <div class="d-block mt-2 pt-4">
      <div>API endpoint response status code</div>
      <ApiKeyRespStatusChart v-model="props.modelValue" :period="period" />
    </div>
    <div class="mt-5">
      <div class="mb-3">Top API endpoint calls</div>
      <v-data-table
        density="compact"
        :headers="headers"
        :items="topApiEndpointItems"
        :loading="topEndpointLoading"
        :disable-pagination="true"
        :hide-default-footer="true"
        items-per-page="-1"
        :mobile-breakpoint="0"
      >
        <template #bottom></template>
      </v-data-table>
    </div>
  </div>
</template>

<script setup lang="ts">
import ApiKeyRespStatusChart from "@/components/apiKeys/ApiKeyRespStatusChart.vue";
import ApiKey from "@/types/ApiKey";
import TopApiKeyEndpointsUsage from "@/types/TopApiKeyEndpointsUsage";
import axios, { CancelTokenSource } from "axios";
import apiKeysResource from "@/resources/ApiKeysResource";
import { ref, watch, onMounted } from "vue";
import type { VDataTable } from "vuetify/components";
type ReadonlyHeaders = VDataTable["$props"]["headers"];

const emit = defineEmits(["update:modelValue"]);
const props = withDefaults(defineProps<{ modelValue: ApiKey | null }>(), {
  modelValue: null,
});

let topEndpointCancelToken: CancelTokenSource | undefined = undefined;
const topEndpointLoading = ref(false);
const topApiEndpointItems = ref<TopApiKeyEndpointsUsage[]>([]);
const period = ref(30);

const periodOptions = [
  { title: "Last 7 days", value: 7 },
  { title: "Last 30 days", value: 30 },
  { title: "Last 3 months", value: 90 },
  { title: "Last year", value: 365 },
];

const headers: ReadonlyHeaders = [
  { title: "Endpoint", align: "start", key: "endpoint" },
  { title: "Number of calls", align: "end", key: "count" },
];

watch(period, () => {
  if (period.value) getTopEndpointsUsage();
});

onMounted(() => {
  getTopEndpointsUsage();
});

const getTopEndpointsUsage = () => {
  // Cancel existing request
  if (topEndpointCancelToken) {
    topEndpointCancelToken.cancel();
  }

  setTimeout(() => {
    // Timeout is workaround for finaly() being executed after request was canceled and new request already began
    topEndpointLoading.value = true;
    topEndpointCancelToken = axios.CancelToken.source();

    if (!props.modelValue) return;

    apiKeysResource
      .getTopEndpointsUsage(props.modelValue.apiKeyId, period.value, topEndpointCancelToken)
      .then((resp) => (topApiEndpointItems.value = resp.data))
      .catch(apiKeysResource.defaultErrorHandler)
      .finally(() => {
        topEndpointLoading.value = false;
        topEndpointCancelToken = undefined;
      });
  }, 10);
};
</script>
