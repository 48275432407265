<template>
  <div class="issue-current-devices-container">
    <div class="text-subtitle-2">Affected devices</div>
    <div class="d-flex flex-grow-1">
      <v-text-field
        v-model="searchTerm"
        append-inner-icon="mdi-magnify"
        color="primary"
        variant="underlined"
        label="Search"
        hide-details
        clearable
        @click:clear="search()"
        v-on:input="search()"
        v-on:keypress.enter="search(true)"
      ></v-text-field>
      <v-btn
        size="x-small"
        variant="text"
        icon
        class="align-self-end ml-4"
        @click="reload"
        :disabled="loading"
        title="Refresh"
      >
        <v-icon size="24">mdi-reload</v-icon>
      </v-btn>
    </div>
    <div class="mt-2 mb-3">
      <v-chip
        class="mr-2"
        v-for="item in issueDeviceStatuses"
        :key="item.value"
        :variant="!activeFilters.includes(item.value) ? 'outlined' : 'flat'"
        :color="(IssueHelper.getDeviceStatusColor(item.value, true) as string)"
        :text-color="isDark || activeFilters.includes(item.value) ? 'white' : 'secondary-darken3'"
        pill
        @click="changeFilter(item.value)"
        size="small"
      >
        {{ `${item.title}: ${getStatusCount(item.value)}` }}
      </v-chip>
    </div>
    <v-data-table-server
      class="device-table"
      item-key="deviceId"
      density="compact"
      :items-length="total"
      :headers="headers"
      :items="items"
      v-model:sort-by="sortBy"
      v-model:page="page"
      :must-sort="true"
      hover
      :mobile="false"
      :loading="loading"
      :mobile-breakpoint="0"
    >
      <template v-slot:[`item.deviceId`]="{ item }">
        <div class="no-wrap">
          {{ item.device.deviceId }}
          <DeviceMenu v-model="item.device.deviceId" size="small" />
        </div>
      </template>
      <template v-slot:[`item.type`]="{ item }">
        {{ getDeviceTypeName(item.device.type) }}
      </template>
      <template v-slot:[`item.status`]="{ item }">
        <v-select
          v-model="item.status"
          :items="issueDeviceStatuses"
          density="compact"
          flat
          background-color="transparent"
          hide-details
          class="status-select"
          :readonly="!canManageIssueDevices && !props.modelValue?.isArchived"
          :disabled="!canManageIssueDevices && !props.modelValue?.isArchived"
          @update:modelValue="(newStatus) => setDeviceStatus(newStatus, item)"
          variant="solo"
        >
          <template v-slot:selection="{ item }">
            <span class="device-status" :class="item.raw.textColor">{{ item.title }}</span>
          </template>
          <template v-slot:item="{ props, item }">
            <v-list-item density="compact" v-bind="props" class="text-body-2" color="red" :class="item.raw.textColor">
            </v-list-item>
          </template>
        </v-select>
      </template>

      <template v-slot:[`item.actions`]="{ item }">
        <v-btn
          class="mr-2"
          v-if="canViewDevices"
          @click="deviceToEdit = { ...item.device }"
          icon
          title="Open"
          density="compact"
          variant="text"
          size="small"
        >
          <v-icon>mdi-open-in-new</v-icon>
        </v-btn>
        <v-btn
          v-if="props.modelValue && !props.modelValue.isArchived && canManageIssueDevices"
          :loading="deletingDeviceId.includes(item.device.deviceId)"
          @click="removeDeviceConfirm(item.device)"
          icon
          density="compact"
          variant="text"
          title="Remove from affected devices"
          size="small"
        >
          <v-icon> mdi-close-thick </v-icon>
        </v-btn>
      </template>
      <template v-slot:bottom>
        <DataTableFooter
          v-model:page="page"
          :items="items"
          :itemsLength="total"
          v-model:itemsPerPage="itemsPerPage"
          :itemsPerPageOptions="[15, 25, 50]"
        />
      </template>
    </v-data-table-server>

    <EditDevice v-model="deviceToEdit" v-on:updated="reload" :hasUrlUpdate="false" />
  </div>
</template>

<script setup lang="ts">
import DeviceHelper from "@/helpers/deviceHelper";
import IssueDeviceListItem from "@/types/IssueDeviceListItem";
import { UserPermissionType } from "@/types/UserPermissionType";
import Issue from "@/types/Issue";
import { DeviceType } from "@/types/DeviceType";
import { IssueDeviceStatus } from "@/types/IssueDeviceStatus";
import Device from "@/types/Device";
import userProfileService from "@/services/UserProfileService";
import DeviceMenu from "@/components/devices/DeviceMenu.vue";
import EditDevice from "@/components/devices/EditDevice.vue";
import issueResource from "@/resources/IssueResource";
import axios, { CancelTokenSource } from "axios";
import IssueHelper from "@/helpers/issueHelper";
import DataTableFooter from "@/components/common/DataTableFooter.vue";
import { ref, computed, watch, onMounted } from "vue";
import { useConfirm } from "@/services/ConfirmService";
import { useTheme } from "vuetify";

const emit = defineEmits(["remove", "changeStatus"]);

const props = withDefaults(
  defineProps<{
    readonly statusSummary: { status: IssueDeviceStatus; count: number }[] | [];
    modelValue?: Issue;
    readonly forceReload: object;
  }>(),
  { statusSummary: () => [] }
);

const confirm = useConfirm();
const isDark = useTheme().global?.current?.value?.dark;

const total = ref(0);
const loading = ref(false);
const deletingDeviceId = ref<number[]>([]);

const itemsPerPage = ref(15);
const page = ref(1);
const sortBy = ref<{ key: string; order: boolean | "asc" | "desc" }[]>([]);

const searchTerm = ref("");
const items = ref<IssueDeviceListItem[]>([]);
let searchThrottleTimer = 0;
let cancelToken: CancelTokenSource | undefined = undefined;
const ignoreOptionsChange = ref<boolean>(false);
const deviceToEdit = ref<Device | null>(null);
let cancelDeviceStatusToken: CancelTokenSource | undefined = undefined;

const canEditIssues = computed(
  () => !props.modelValue?.isArchived && userProfileService.hasPermission(UserPermissionType.EditIssues)
);

const canViewDevices = computed(() => userProfileService.hasPermission(UserPermissionType.ViewDevices));

const canManageIssueDevices = computed(
  () =>
    !props.modelValue?.isArchived &&
    (userProfileService.hasPermission(UserPermissionType.EditIssues) ||
      userProfileService.hasPermission(UserPermissionType.ManageIssueDevices))
);

const headers = computed(() => {
  const mainHeaders: any = [
    { title: "ID", align: "start", key: "deviceId", sortable: true },
    { title: "Name", key: "device.deviceName", sortable: false },
    { title: "Type", key: "type", sortable: false },
    { title: "Status", key: "status", sortable: true },
  ];
  if (canManageIssueDevices.value || canViewDevices.value) {
    mainHeaders.push({ text: "", value: "actions", align: "end", cellProps: { class: "actions" }, sortable: false });
  }
  return mainHeaders;
});

const issueDeviceStatuses = [
  {
    title: IssueHelper.getDeviceStatusDisplayName(IssueDeviceStatus.New),
    value: IssueDeviceStatus.New,
    textColor: IssueHelper.getDeviceStatusColor(IssueDeviceStatus.New),
  },
  {
    title: IssueHelper.getDeviceStatusDisplayName(IssueDeviceStatus.Active),
    value: IssueDeviceStatus.Active,
    textColor: IssueHelper.getDeviceStatusColor(IssueDeviceStatus.Active),
  },
  {
    title: IssueHelper.getDeviceStatusDisplayName(IssueDeviceStatus.Resolved),
    value: IssueDeviceStatus.Resolved,
    textColor: IssueHelper.getDeviceStatusColor(IssueDeviceStatus.Resolved),
  },
];

const activeFilters = ref<IssueDeviceStatus[]>([]);
const getDeviceTypeName = (type: DeviceType) => {
  return DeviceHelper.getDeviceTypeDisplayName(type);
};

const search = (noTheshold: boolean = false) => {
  if (searchThrottleTimer) {
    clearTimeout(searchThrottleTimer);
    searchThrottleTimer = 0;
  }

  if (noTheshold || !searchTerm.value) {
    getIssueDevices(true);
  } else {
    searchThrottleTimer = setTimeout(() => {
      getIssueDevices(true);
    }, 1000);
  }
};

const changeFilter = (status: IssueDeviceStatus) => {
  const ind = activeFilters.value.findIndex((value) => value === status);
  if (ind !== -1) {
    activeFilters.value.splice(ind, 1);
  }
  if (ind === -1) {
    activeFilters.value = [status];
  }
  reload();
};

const reload = () => {
  getIssueDevices();
};

const getIssueDevices = (resetPagination: boolean = false) => {
  if (!props.modelValue) {
    return;
  }
  // Cancel existing request
  if (cancelToken) {
    cancelToken.cancel();
  }

  // Reset pagination
  if (resetPagination) {
    ignoreOptionsChange.value = true;
    page.value = 1;
  }

  setTimeout(() => {
    // Timeout is workaround for finaly() being executed after request was canceled and new request already began
    loading.value = true;
    cancelToken = axios.CancelToken.source();

    if (!props.modelValue) {
      return;
    }

    const orderDesc =
      typeof sortBy.value[0]?.order === "boolean" ? sortBy.value[0]?.order : sortBy.value[0]?.order.toString() === "desc";
    issueResource
      .getDevicesByIssuePaged(
        props.modelValue.issueId,
        itemsPerPage.value,
        page.value,
        searchTerm.value,
        sortBy.value[0]?.key,
        orderDesc,
        activeFilters.value,
        cancelToken
      )
      .then((resp) => {
        items.value = resp.data.items;
        total.value = resp.data.totalItems;
      })
      .catch(issueResource.defaultErrorHandler)
      .finally(() => {
        loading.value = false;
        cancelToken = undefined;
        ignoreOptionsChange.value = false;
      });
  }, 10);
};

const removeDeviceConfirm = (device: Device) => {
  if ((!canEditIssues.value && !canManageIssueDevices.value) || !device) {
    return;
  }

  confirm
    .show(`Remove device ID ${device.deviceId} from affected devices?`, {
      width: 360,
    })
    .then((confirmed) => {
      if (confirmed) {
        removeDevice(device);
      }
    });
};

const removeDevice = (device: Device) => {
  if ((!canEditIssues.value && !canManageIssueDevices.value) || props.modelValue == null) {
    return;
  }

  const deviceId = device.deviceId;
  deletingDeviceId.value.push(deviceId);
  issueResource
    .removeDevice(props.modelValue.issueId, deviceId)
    .then(() => {
      items.value = items.value.filter((v) => v.device.deviceId !== deviceId);
      emit("remove");
    })
    .catch(issueResource.defaultErrorHandler)
    .finally(() => {
      deletingDeviceId.value = deletingDeviceId.value.filter((v) => v !== deviceId);
    });
};

const setDeviceStatus = (status: IssueDeviceStatus, deviceItem: IssueDeviceListItem) => {
  const previousStatus = deviceItem.status;
  // Cancel existing request
  if (cancelDeviceStatusToken) {
    cancelDeviceStatusToken.cancel();
  }

  if (!canManageIssueDevices.value || !deviceItem) {
    return;
  }

  setTimeout(() => {
    if (!props.modelValue) return;
    // Timeout is workaround for finaly() being executed after request was canceled and new request already began
    cancelDeviceStatusToken = axios.CancelToken.source();
    issueResource
      .updateIssueDeviceStatus(props.modelValue?.issueId, deviceItem.device.deviceId, status, cancelDeviceStatusToken)
      .then(() => {
        emit("changeStatus");
      })
      .catch((e) => {
        issueResource.defaultErrorHandler(e);
        items.value = items.value.map((item) => {
          if (item.device.deviceId === deviceItem.device.deviceId) {
            return { ...item, status: previousStatus };
          }
          return item;
        });
      })
      .finally(() => {
        cancelDeviceStatusToken = undefined;
      });
  }, 10);
};

const getStatusCount = (status: IssueDeviceStatus) => {
  return props.statusSummary.find((v) => v.status === status)?.count || 0;
};

onMounted(() => {
  getIssueDevices();
});

watch([itemsPerPage, sortBy, page], function onPropertyChanged() {
  if (!ignoreOptionsChange.value) {
    getIssueDevices();
  }
});

watch(
  () => props.forceReload,
  function onForceReload() {
    getIssueDevices(true);
  }
);
</script>

<style scoped>
.status-select {
  width: 90px;
}

.device-status {
  font-size: 14px;
}

.issue-current-devices-container :deep(.actions) {
  white-space: nowrap;
}

.status-select :deep(.v-input__control) > .v-field--active .v-field__input {
  padding: 0 !important;
  padding-inline-end: 0;
}

.status-select :deep(.v-input__control) > .v-field--active {
  padding: 0 !important;
  padding-inline-end: 0;
}

.device-table :deep(.v-data-table-header th) {
  white-space: nowrap;
}
</style>
