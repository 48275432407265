<template>
  <div>
    <v-dialog scrim="rgba(33, 33, 33)" v-model="dialog" overlay-opacity="0.75" overlay-color="#111">
      <transition name="fade">
        <div :class="toolsStyles" v-if="url && isTools">
          <v-btn v-if="url && !localFileUrl" size="small" variant="text" class="ma-2 text-white" @click="download">
            Download
            <v-icon end dark> mdi-cloud-download </v-icon>
          </v-btn>
          <v-btn icon density="compact" class="ma-2 text-white" @click="dialog = false">
            <v-icon> mdi-close </v-icon>
          </v-btn>
        </div>
      </transition>
      <div class="wrap">
        <div v-if="isVideo && url" class="overflow-hidden flex-grow-1">
          <h4 class="text-center" v-if="isUnsupportedMediaErr">
            Video not supported. Download the video <a href="" @click.prevent="download">here</a>.
          </h4>
          <video
            v-if="!isUnsupportedMediaErr"
            controls
            autoplay
            class="video"
            :src="url"
            :type="mime"
            @error="mediaErrorHandler"
          >
            <source :src="url" :type="mime" />
            Your browser doesn't support HTML5 video tag.
          </video>
        </div>

        <div v-if="isAudio && url" class="overflow-hidden flex-grow-1">
          <audio controls autoplay class="audio">
            <source :src="url" :type="mime" />
          </audio>
        </div>

        <object v-if="isPdf && url" :data="url" type="application/pdf" frameborder="0" width="100%" height="100%">
          <embed :src="url" width="100%" height="100%" />
        </object>

        <div v-if="isImage && url" class="image" :style="imgStyle" />
      </div>
    </v-dialog>
  </div>
</template>

<script setup lang="ts">
import AttachedFile from "@/types/AttachedFile";
import FileHelper from "@/helpers/fileHelper";
import commonHelper from "@/helpers/commonHelper";
import { useDisplay } from "vuetify";
import { ref, computed, watch, onUnmounted } from "vue";

const props = defineProps<{
  readonly onClose: () => void;
  openedFile: AttachedFile | File | null;
}>();

// isTools is a workaround to remove tool button jumps while appear
const isTools = ref(false);
const dialog = ref(false);
const localFileUrl = ref("");
const isUnsupportedMediaErr = ref(false);

const isTabletAndDown = useDisplay().mdAndDown;

const name = computed(() => {
  return props.openedFile instanceof File ? props.openedFile.name : props.openedFile?.filename;
});

const mime = computed(() => (props.openedFile instanceof File ? props.openedFile.type : props.openedFile?.mime));

const url = computed(() => {
  return props.openedFile instanceof File ? localFileUrl.value : `${commonHelper.apiHost}${props.openedFile?.url}`;
});

const isAudio = computed(() => FileHelper.isAudio(mime.value));

const isVideo = computed(() => FileHelper.isVideo(mime.value));

const isImage = computed(() => FileHelper.isImage(mime.value));

const isPdf = computed(() => FileHelper.isPdf(mime.value));

const toolsStyles = computed(() => ({ tools: true, "laptop-tools": false }));

const imgStyle = computed(() => `background-image: url("${url.value}");`);

watch(dialog, function onChangeDailog() {
  if (!dialog.value) {
    isTools.value = false;

    if (props.onClose) props.onClose();

    if (localFileUrl.value) {
      URL.revokeObjectURL(localFileUrl.value);
      localFileUrl.value = "";
    }
  }
});

watch(isVideo, function onVideoChange() {
  if (!isVideo) isUnsupportedMediaErr.value = false;
});

watch(
  () => props.openedFile,
  function onChange() {
    if (props.openedFile instanceof File || props.openedFile?.url) {
      dialog.value = true;

      if (props.openedFile instanceof File) {
        localFileUrl.value = URL.createObjectURL(props.openedFile);
      }

      // isTools is a workaround to remove tool button jumps while appear
      setTimeout(() => (isTools.value = true), 500);
    }
  }
);

const mediaErrorHandler = (e: Event) => {
  const error = e as ErrorEvent;
  const target = error.target as HTMLVideoElement;
  if (target.error?.code === 4) {
    isUnsupportedMediaErr.value = true;
  }
};

const download = () => {
  if (props.openedFile instanceof File) return;

  const a = document.createElement("a");
  a.href = `${commonHelper.apiHost}${props.openedFile?.downloadUrl}`;
  document.body.appendChild(a);
  a.click();
  document.body.removeChild(a);
};
</script>

<style scoped>
object {
  width: 100%;
}
.wrap {
  height: calc(100vh - 150px);
  width: 100%;
  background-color: #000;
  display: flex;
  justify-content: center;
  align-items: center;
}

.tools {
  text-align: right;
  position: absolute;
  right: 0px;
  top: -50px;
  z-index: 100;
}

.laptop-tools {
  top: 75px;
  right: 25px;
}

.image {
  height: calc(100vh - 150px);
  width: 100%;
  background-size: contain;
  background-position: center;
}

.video {
  height: calc(100vh - 160px);
  width: 100%;
}

.audio {
  width: 100%;
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s;
}
.fade-enter,
.fade-leave-to {
  opacity: 0;
}
</style>
