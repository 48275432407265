<template>
  <div>
    <side-sheet
      v-if="props.modelValue"
      :modelValue="showDialog"
      @update:modelValue="close"
      @click-outside="close"
      :heading="dialogHeading"
      :noClickAnimation="true"
    >
      <v-form ref="userForm" v-model="valid" lazy-validation>
        <PropEditor name="Name" desc="">
          <v-text-field
            density="compact"
            variant="outlined"
            v-model="props.modelValue.name"
            :rules="nameRules"
          ></v-text-field>
        </PropEditor>
        <PropEditor v-for="pg in permissions" :key="pg.name" :name="pg.name" :valign="'top'">
          <div class="d-flex justify-start align-center" v-for="p in pg.permissions" :key="p.name">
            <v-switch class="my-0" v-model="p.active" @click="setChangesStatus" :label="p.name"></v-switch>
            <v-tooltip v-if="p.desc" location="bottom" max-width="400px" open-on-click>
              <template v-slot:activator="{ props }">
                <v-icon v-bind="props" size="small" class="ml-2 opacity-70">mdi-help-circle</v-icon>
              </template>
              <div>{{ p.desc }}</div>
            </v-tooltip>
          </div>
        </PropEditor>
      </v-form>

      <template v-slot:actions>
        <v-btn
          v-if="allowDelete && props.modelValue.userGroupId && isDeleteBtn"
          color="secondary"
          @click="deleteUserGroupConfirm"
          :loading="deleting"
          :disabled="deleting"
          >Delete</v-btn
        >
        <v-spacer></v-spacer>
        <v-btn variant="text" @click="showDialog = false">Cancel</v-btn>
        <v-btn color="primary" class="ml-4" @click="submit" :loading="loading" :disabled="loading || disabledSubmitBtn"
          >Submit</v-btn
        >
      </template>
    </side-sheet>
  </div>
</template>

<script setup lang="ts">
import SideSheet from "@/components/layout/SideSheet.vue";
import PropEditor from "@/components/layout/PropEditor.vue";
import UserGroup from "@/types/UserGroup";
import { UserPermissionType } from "@/types/UserPermissionType";
import userGroupResource from "@/resources/UserGroupResource";
import infoMessageService from "@/services/InfoMessageService";
import { InfoMessageType } from "@/types/InfoMessageType";
import UserGroupPermission from "@/types/UserGroupPermission";
import ChangeManager from "@/services/ChangeManager";
import { VForm } from "vuetify/components";
import { ref, computed, watch } from "vue";
import { useComponentQuery } from "@/globalProperties";
import { useConfirm } from "@/services/ConfirmService";

const { setComponentQuery } = useComponentQuery();

const confirm = useConfirm();

const emit = defineEmits(["update:modelValue", "updated"]);
const props = withDefaults(defineProps<{ readonly isDeleteBtn?: boolean; modelValue: UserGroup | null }>(), {
  modelValue: null,
  isDeleteBtn: true,
});

interface Permission {
  name: string;
  type: UserPermissionType;
  active: boolean;
  desc?: string;
}

interface PermissionsGroup {
  name: string;
  permissions: Permission[];
}

const permissions = ref<PermissionsGroup[]>([]);
const allPermissions = ref<PermissionsGroup[]>([
  {
    name: "Customers",
    permissions: [
      { name: "View customers", type: UserPermissionType.ViewCustomers },
      { name: "Add customers", type: UserPermissionType.AddCustomers },
      { name: "Edit customers", type: UserPermissionType.EditCustomers },
      { name: "Delete customers", type: UserPermissionType.DeleteCustomers },
    ],
  },
  {
    name: "Devices",
    permissions: [
      { name: "View devices", type: UserPermissionType.ViewDevices },
      { name: "View firmware update URL", type: UserPermissionType.ViewFirmwareUpdateUrl },
      { name: "View device location", type: UserPermissionType.ViewDeviceLocation },
      { name: "View device logs", type: UserPermissionType.ViewDeviceLogs },
      { name: "Edit devices", type: UserPermissionType.EditDevices },
      { name: "Modify Allow Updates (requires Edit devices)", type: UserPermissionType.EditAllowUpdateProperty },
      { name: "Delete devices", type: UserPermissionType.DeleteDevices },
    ],
  },
  {
    name: "POI",
    permissions: [
      { name: "View POI", type: UserPermissionType.ViewPoi },
      { name: "Add POI", type: UserPermissionType.AddPoi },
      { name: "Edit POI", type: UserPermissionType.EditPoi },
      { name: "Delete POI", type: UserPermissionType.DeletePoi },
    ],
  },
  {
    name: "Advertisement",
    permissions: [
      { name: "View adverts", type: UserPermissionType.ViewAds },
      { name: "Add adverts", type: UserPermissionType.AddAds },
      { name: "Edit adverts", type: UserPermissionType.EditAds },
      { name: "Delete adverts", type: UserPermissionType.DeleteAds },
      { name: "Manage advert settings", type: UserPermissionType.EditAdsSettings },
    ],
  },
  {
    name: "Issues",
    permissions: [
      { name: "View issues", type: UserPermissionType.ViewIssues },
      {
        name: "Manage issue devices",
        type: UserPermissionType.ManageIssueDevices,
        desc: "Allows adding and removing devices from issues without assigning Add Issues or Edit Issues permissions. Requires View Issues and View Devices permissions.",
      },
      { name: "Add issues", type: UserPermissionType.AddIssues },
      { name: "Edit issues", type: UserPermissionType.EditIssues },
      { name: "Delete issues", type: UserPermissionType.DeleteIssues },
    ],
  },

  {
    name: "Repairs",
    permissions: [
      { name: "View repairs", type: UserPermissionType.ViewRepairs },
      { name: "Add repairs", type: UserPermissionType.AddRepairs },
      { name: "Edit repairs", type: UserPermissionType.EditRepairs },
      { name: "Delete repairs", type: UserPermissionType.DeleteRepairs },
    ],
  },

  {
    name: "Warranty",
    permissions: [{ name: "View manufacturer warranty", type: UserPermissionType.ViewManufacturerWarranty }],
  },
] as PermissionsGroup[]);

// begin change management
const changesControl = ref<ChangeManager | null>(null);
watch(
  () => props.modelValue,
  (val: UserGroup | null, oldValue: UserGroup | null) => {
    changesControl.value = ChangeManager.modalController({
      controller: changesControl.value,
      isNewValue: val && oldValue === null,
      isDestroy: oldValue && val === null,
      isUpdateValue: oldValue && val && oldValue.userGroupId !== val.userGroupId,
      data: { userGroup: val },
      message: "You have unsaved Permissions changes.",
      target: `userGroup_${val?.userGroupId}`,
      onLeave: () => {
        showDialog.value = false;
      },
      onSave: submit,
    });
  }
);

const setChangesStatus = () => {
  if (!props.modelValue) {
    return;
  }

  const origUserGroup = changesControl.value?.data?.origData?.userGroup;
  const origPermissions = changesControl.value?.data?.origData?.permissions;
  if (!changesControl.value || !origUserGroup) return;

  if (!ChangeManager.isObjectEqual(origUserGroup, props.modelValue || {}, { isOrigPartial: true })) {
    changesControl.value?.activate();
    return;
  }

  const currentPermissions = JSON.parse(JSON.stringify(permissions.value)).map((item: PermissionsGroup) => {
    item.permissions = item.permissions.map((v) => {
      // @ts-ignore
      if (!v.active) delete v.active;
      return v;
    });
    return item;
  });

  if (!ChangeManager.isObjectEqual(origPermissions, currentPermissions, { isOrigPartial: true })) {
    changesControl.value?.activate();
    return;
  }

  changesControl.value?.deactivate();
};

watch(() => props.modelValue, setChangesStatus, { deep: true });

watch(() => permissions.value, setChangesStatus, { deep: true });

// end change management

const disabledSubmitBtn = computed(() => !ChangeManager.state().isChanged);

watch(
  () => props.modelValue,
  (newValue: UserGroup | null, oldValue: UserGroup | null) => {
    if (newValue != null) {
      populatePermissionsList();
    }

    setComponentQuery("userGroupId", props.modelValue?.userGroupId ? props.modelValue.userGroupId : null);
  }
);

const showDialog = computed({
  get() {
    return props.modelValue != null;
  },
  set(value) {
    emit("update:modelValue", null);
  },
});

const dialogHeading = computed(() => {
  let heading = "";
  if (props.modelValue) {
    heading = props.modelValue?.userGroupId
      ? `${props.modelValue.name} (ID: ${props.modelValue.userGroupId})`
      : "New user group";
  }
  return heading;
});

const userForm = ref<InstanceType<typeof VForm> | null>(null);
const valid = ref(true);
const loading = ref(false);

const nameRules = [
  (v: any) => !!v || "Name is required",
  (v: any) => v.length > 2 || "Username must be at least 3 characters long",
];

const allowDelete = computed(() => true);
const deleting = ref(false);

const populatePermissionsList = () => {
  permissions.value = JSON.parse(JSON.stringify(allPermissions.value));
  if (props.modelValue !== null) {
    for (const permission of props.modelValue.userGroupPermissions) {
      for (const pg of permissions.value) {
        for (const p of pg.permissions) {
          if (p.type === permission.userGroupPermissionType) {
            p.active = true;
          }
        }
      }
    }
  }
  changesControl.value?.addOrigData({ permissions: permissions.value });
};

const applyPermissionsFromList = () => {
  if (props.modelValue !== null) {
    var permToApply = [] as UserGroupPermission[];
    for (var pg of permissions.value) {
      for (var p of pg.permissions) {
        if (p.active) {
          permToApply.push({ userGroupPermissionType: p.type } as UserGroupPermission);
        }
      }
    }

    props.modelValue.userGroupPermissions = permToApply;
  }
};

const submit = async () => {
  if (props.modelValue === null) {
    return;
  }

  // Validate form
  const { valid } = await (userForm.value as InstanceType<typeof VForm>).validate();

  if (valid) {
    applyPermissionsFromList();

    if (props.modelValue.userGroupId) {
      // Update user group
      userGroupResource
        .updateUserGroup(props.modelValue)
        .then((resp) => {
          showDialog.value = false;
          emit("updated");
        })
        .catch(userGroupResource.defaultErrorHandler)
        .finally(() => {
          loading.value = false;
        });
    } else {
      // New user group
      userGroupResource
        .addUserGroup(props.modelValue)
        .then((resp) => {
          infoMessageService.show(InfoMessageType.Success, "New user group created");
          showDialog.value = false;
          emit("updated");
        })
        .catch(userGroupResource.defaultErrorHandler)
        .finally(() => {
          loading.value = false;
        });
    }
  }
};

const deleteUserGroupConfirm = () => {
  if (!allowDelete.value || props.modelValue == null) {
    return;
  }

  confirm.show(`Delete user group '${props.modelValue.name}'?`).then((confirmed) => {
    if (confirmed) {
      deleteUserGroup();
    }
  });
};

const deleteUserGroup = () => {
  if (!allowDelete.value || props.modelValue == null) {
    return;
  }

  deleting.value = true;
  userGroupResource
    .deleteUserGroup(props.modelValue.userGroupId)
    .then((resp) => {
      showDialog.value = false;
      emit("updated");
    })
    .catch(userGroupResource.defaultErrorHandler)
    .finally(() => {
      deleting.value = false;
    });
};

const close = (value: boolean) => {
  if (!value && ChangeManager.state().isChanged) {
    ChangeManager.show();
    return;
  }

  showDialog.value = value;
};
</script>
