<template>
  <div>
    <div class="text-subtitle-1 mb-1">Advert Stats</div>

    <v-progress-linear v-if="loading" indeterminate absolute></v-progress-linear>

    <div class="d-block d-sm-flex align-center text-right mt-5">
      <v-select
        attach
        v-model="selectedDateRangeOption"
        :items="dateRangeOptions"
        variant="outlined"
        density="compact"
        label="Date range"
        class="flex-grow-0"
        style="min-width: 150px"
        hide-details
      />
      <div v-if="selectedDateRangeOption === 'define'" class="d-block d-sm-flex align-center">
        <v-text-field
          v-model="from"
          variant="outlined"
          density="compact"
          label="From"
          class="flex-grow-0 ml-0 ml-md-4 mt-2 mt-md-0"
          style="min-width: 150px"
          hide-details
        />
        <span class="d-none d-md-inline px-1">-</span>
        <v-text-field
          v-model="to"
          variant="outlined"
          density="compact"
          label="To"
          class="flex-grow-0 mt-2 mt-md-0"
          style="min-width: 150px"
          hide-details
        />
      </div>
      <v-btn color="primary" class="ml-0 ml-md-4 mt-2 mt-md-0" :loading="loading" @click="getData()">Update</v-btn>
    </div>

    <table v-if="advertStats" class="text-subtitle-2 mt-4 info-table">
      <tr>
        <td class="text-grey">Unique views:</td>
        <td>{{ advertStats.uniqueViews }}</td>
      </tr>
      <tr>
        <td class="text-grey">Total views:</td>
        <td>{{ advertStats.totalViews }}</td>
      </tr>
    </table>
  </div>
</template>

<script setup lang="ts">
import moment from "moment";
import advertResource from "@/resources/AdvertResource";
import AdvertStats from "@/types/AdvertStats";
import { ref, watch } from "vue";

const props = defineProps<{ readonly advertId: number | null }>();

const loading = ref(false);

const advertStats = ref<null | AdvertStats>(null);
const from = ref(moment("2022-01-01").format("YYYY-MM-DD HH:mm"));
const to = ref(moment().add(1, "days").startOf("day").format("YYYY-MM-DD HH:mm"));

const selectedDateRangeOption = ref("all");
const dateRangeOptions = [
  { title: "From start", value: "all" },
  { title: "Define", value: "define" },
];

const getData = () => {
  if (!props.advertId) {
    return;
  }

  loading.value = true;

  let fromDate: Date | undefined = undefined;
  let toDate: Date | undefined = undefined;
  if (selectedDateRangeOption.value === "define") {
    if (from.value) {
      fromDate = moment(from.value).utc().toDate();
    }
    if (to.value) {
      toDate = moment(to.value).utc().toDate();
    }
  }

  advertResource
    .getAdvertStats(props.advertId, fromDate, toDate)
    .then((resp) => {
      advertStats.value = resp.data;
    })
    .catch(advertResource.defaultErrorHandler)
    .finally(() => {
      loading.value = false;
    });
};

watch(
  () => props.advertId,
  function onValueChanged() {
    if (props.advertId !== null) {
      getData();
    } else {
      advertStats.value = null;
    }
  },
  { immediate: true }
);
</script>

<style scoped>
.info-table td:first-child {
  padding-right: 2rem;
}
</style>
