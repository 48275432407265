<template>
  <v-container v-if="canViewLogs" fluid class="fill-height">
    <v-row style="height: 100%; margin: 0">
      <v-col cols="12" sm="12" md="12" style="flex: 1 1 auto; padding: 0">
        <iframe
          :src="`${host}/kibana/app/dashboards#/view/${dashboardId}?embed=true&isDark=false&show-query-input=true&show-time-filter=true&_g=(filters:!(),refreshInterval:(pause:!t,value:0),time:(from:now-1h,to:now))&_a=(expandedPanelId:'${panelId}',filters:!(),query:(language:kuery,query:'${queryParams}'))`"
          style="width: 100%; min-height: 100%"
          frameborder="0"
        ></iframe>
      </v-col>
    </v-row>
  </v-container>
</template>

<script setup lang="ts">
import userProfileService from "@/services/UserProfileService";
import { UserPermissionType } from "@/types/UserPermissionType";
import appConfigService from "@/services/AppConfigService";
import commonHelper from "@/helpers/commonHelper";
import { computed } from "vue";
import { useRoute } from "vue-router";

const canViewLogs = computed(() => userProfileService.hasPermission(UserPermissionType.ViewDeviceLogs));
const route = useRoute();

const host = computed(() => commonHelper.apiHost);

const dashboardId = computed(() => appConfigService.kibanaLogsDashboardId);

const panelId = computed(() => appConfigService.kibanaLogsPanelId);

const queryParams = computed(() => {
  var query = [] as string[];
  if (route.query.deviceId) {
    query.push(`ClientId : ${route.query.deviceId}`);
  }
  return query.join(",");
});
</script>

<style scoped></style>
