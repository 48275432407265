<template>
  <v-menu
    v-model="showContextMenu"
    position="absolute"
    offset-y
    z-index="999"
    light
    :style="{ left: `${contextMenuX}px`, top: `${contextMenuY}px` }"
  >
    <v-list density="compact" class="py-0">
      <v-list-item @click="contextMenuCopyText">
        <template v-slot:prepend>
          <v-icon size="x-small" class="mr-2">mdi-content-copy</v-icon>
        </template>
        <v-list-item-title>Copy</v-list-item-title>
      </v-list-item>
      <slot />
    </v-list>
  </v-menu>
</template>

<script setup lang="ts">
import { ref, watch } from "vue";
import infoMessageService from "@/services/InfoMessageService";
import { InfoMessageType } from "@/types/InfoMessageType";

const emit = defineEmits(["update:modelValue"]);
const props = withDefaults(
  defineProps<{modelValue: MouseEvent | null;}>(),
  { modelValue: null }
);

const showContextMenu = ref(false);
const contextMenuX = ref(0);
const contextMenuY = ref(0);

watch(
  () => props.modelValue,
  () => {
    if (props.modelValue) {
      props.modelValue.preventDefault();
      openContenxMenu();
    }
  }
);

watch(showContextMenu, () => {
  if (showContextMenu.value === false) {
    emit("update:modelValue", null);
  }
});

const openContenxMenu = () => {
  contextMenuX.value = props.modelValue!.clientX;
  contextMenuY.value = props.modelValue!.clientY;
  showContextMenu.value = true;
};

const contextMenuCopyText = () => {
  const elm = props.modelValue!.target as HTMLElement;
  if (elm && elm.textContent) {
    navigator.clipboard.writeText(elm.textContent).catch((e) => {
      infoMessageService.show(InfoMessageType.Error, "Failed to copy text");
    });
  }
};
</script>

<style scoped>
:deep() .v-list-item__spacer {
  width: 0px !important;
}
</style>
