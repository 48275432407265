// Types
import type { App } from "vue";
import moment from "moment";
import { Router, LocationQuery, RouteLocationNormalized, useRoute, useRouter } from "vue-router";
import userStorage from "@/services/UserStorageService";
import { nextTick } from "vue";

let isActiveSetQueryFunction = false;
let query: LocationQuery = {};

export function registerGlobalProperties(app: App, router: Router) {
    // Momment
    app.config.globalProperties.moment = moment;
    moment.locale("en-gb");

    // Router
    app.config.globalProperties.$setComponentQuery = function (key: string, value: any) {
        return setComponentQuery(key, value, this.$route, this.$router) ;
    };
    updateOptionsStorageKey();
}

// Remove it after December 2024.
function updateOptionsStorageKey() {
    ["advertsTable", "customersTable", "devicesTable", "issuesTable", "poiTable", "userGroupsTable", "usersTable"].forEach(
        (key) => {
            const options = userStorage.get(key);
            if (!options) return;
            const sortBy = options.sortBy;
            if (sortBy?.[0]?.key) return;
            if (sortBy) {
                userStorage.set(key, { ...options, sortBy: [{ key: sortBy[0], order: options?.sortDesc[0] ?? true }] });
            }
        }
    );
}

export async function setComponentQuery(key: string, value: any, route: RouteLocationNormalized, router: Router) {
    if (!isActiveSetQueryFunction) {
        query = { ...route.query };
    }

    if (value) {
        var newVal = encodeURIComponent(JSON.stringify(value));
        if (newVal !== query[key]) {
            query[key] = newVal;
        }
    } else if (query[key]) {
        delete query[key];
    }

    if (!isActiveSetQueryFunction) {
        isActiveSetQueryFunction = true;

        await nextTick();

        if (JSON.stringify(query) !== JSON.stringify(route.query)) {
            const res = await router.replace({ query });
        }

        isActiveSetQueryFunction = false;
        query = {};
    }
}

export function useComponentQuery() {
    const route = useRoute();
    const router = useRouter();
    const setCompQuery = (key: string, value: any) => setComponentQuery(key, value, route, router);
    return { setComponentQuery: setCompQuery };
}
