<template>
  <div>
    <div class="text-subtitle-1 mb-1">Customer Change History</div>
    <p class="text-caption">List of the last customer information changes.</p>

    <v-progress-linear v-if="loading" indeterminate></v-progress-linear>

    <div v-if="items && items.length">
      <v-divider />
      <v-data-table
        density="compact"
        :headers="headers"
        :items="items"
        :options.sync="options"
        :loading="loading"
        :disable-pagination="true"
        :hide-default-footer="true"
        items-per-page="-1"
        :mobile-breakpoint="0"
      >
        <template v-slot:[`item.valueOld`]="{ item }">
          {{ formatValue(item.valueOld) }}
        </template>
        <template v-slot:[`item.valueNew`]="{ item }">
          {{ formatValue(item.valueNew) }}
        </template>
        <template v-slot:[`item.modifiedDate`]="{ item }">
          {{ moment(item.modifiedDate).format("lll") }}
        </template>
      </v-data-table>
    </div>
    <div v-else-if="!loading">No changes found.</div>
  </div>
</template>

<script setup lang="ts">
import moment from "moment";
import EntityChangeRecord from "@/types/EntityChangeRecord";
import customerResource from "@/resources/CustomerResource";
import userStorage from "@/services/UserStorageService";
import { ref, watch } from "vue";
import type { VDataTable } from "vuetify/components";
type ReadonlyHeaders = VDataTable["$props"]["headers"];

const props = defineProps<{ customerId: number | null }>();

const loading = ref(false);
const items = ref<EntityChangeRecord[]>([]);
const optionsStorageKey = "customerHistoryTable";
const options = ref(
  userStorage.get(optionsStorageKey) ?? {
    sortBy: [],
    sortDesc: [false],
  }
);

const getData = () => {
  if (!props.customerId) return;

  loading.value = true;

  customerResource
    .getCustomerChangeHistory(props.customerId)
    .then((resp) => {
      items.value = resp.data;
    })
    .catch(customerResource.defaultErrorHandler)
    .finally(() => {
      loading.value = false;
    });
};

const formatValue = (val: any) => {
  let result = val;
  if (typeof val === "object") {
    result = "";
  } else if (val.toString().match(/^\d\d\d\d-\d\d-\d\d/)) {
    result = moment(val).format("lll");
  }

  return result;
};

watch(
  () => props.customerId,
  () => {
    items.value = [];
    getData();
  },
  { immediate: true }
);

const headers: ReadonlyHeaders = [
  { title: "Modified on", align: "start", key: "modifiedDate" },
  { title: "Modified by", key: "modifiedBy" },
  { title: "Field name", key: "fieldName" },
  { title: "Old value", key: "valueOld" },
  { title: "New value", key: "valueNew" },
];

watch(
  options,
  () => {
    userStorage.set(optionsStorageKey, options.value);
  },
  { deep: true }
);
</script>
