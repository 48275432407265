<template>
  <v-container fluid>
    <v-card>
      <v-card-title class="d-block d-sm-flex">
        <div class="text-right align-self-end mt-0 d-block d-sm-none">
          <CreateIssue />
        </div>
        <div class="d-flex flex-grow-1">
          <v-text-field
            v-model="searchTerm"
            prepend-inner-icon="mdi-magnify"
            label="Search"
            hide-details
            clearable
            @click:clear="search()"
            v-on:input="search()"
            v-on:keypress.enter="search(true)"
            variant="underlined"
            color="primary"
          ></v-text-field>

          <v-btn
            size="x-small"
            variant="text"
            icon
            class="align-self-end ml-4"
            @click="reload"
            :disabled="loading"
            title="Refresh"
          >
            <v-icon size="24">mdi-reload</v-icon>
          </v-btn>

          <TableConfiguration :allHeaders="headers" v-model="selectedHeaders" tableKey="issuesTableColumns" />

          <v-btn
            variant="text"
            size="x-small"
            icon
            class="align-self-end"
            @click="showFilter = !showFilter"
            :color="showFilter ? 'primary' : undefined"
            :disabled="loading"
            title="Filters"
          >
            <v-icon size="24">mdi-filter-variant</v-icon>
            <v-badge
              v-if="numberOfFilter"
              transition="v-fade-transition"
              dot
              bordered
              offset-x="-1"
              offset-y="-10"
              color="primary"
            />
          </v-btn>
        </div>
        <v-spacer class="d-none d-sm-block"></v-spacer>
        <div class="text-right align-self-end mt-2 d-none d-sm-block">
          <CreateIssue />
        </div>
      </v-card-title>

      <Filters :show="showFilter" :filter="filter" @close="showFilter = false" @update="updateFilter" :disabled="loading" />

      <v-data-table-server
        density="compact"
        :row-props="rowClass"
        :headers="selectedHeaders"
        :items="items"
        :items-length="total"
        v-model:sort-by="sortBy"
        :must-sort="true"
        hover
        :mobile="false"
        :loading="loading"
        :mobile-breakpoint="0"
        @click:row="(event: any, { item }: any) => rowClick(item)"
      >
        <template v-slot:[`item.status`]="{ item }">
          <span :class="IssueHelper.getIssueStatusColor(item.status, true)">{{ getIssueStatusName(item.status) }}</span>
        </template>

        <template v-slot:[`item.priority`]="{ item }">
          <v-icon
            :color="IssueHelper.getIssuePriorityColor(item.priority)"
            :title="IssueHelper.getIssuePriorityName(item.priority)"
          >
            {{ IssueHelper.getIssuePriorityIcon(item.priority) }}
          </v-icon>
        </template>

        <template v-slot:[`item.tags`]="{ item }">
          <v-chip size="small" class="ma-1px" v-for="tag in item.tags" v-bind:key="tag.tagId">
            {{ tag.name }}
          </v-chip>
        </template>

        <template v-slot:[`item.createdAt`]="{ item }">
          <span v-if="item.createdAt">{{ moment(item.createdAt).format("lll") }}</span>
        </template>
        <template v-slot:[`item.lastModifiedAt`]="{ item }">
          <span v-if="item.lastModifiedAt">{{ moment(item.lastModifiedAt).format("lll") }}</span>
        </template>

        <template v-slot:[`item.isArchived`]="{ item }">
          <div class="ml-2">
            <v-icon size="small" color="green" v-if="item.isArchived">mdi-check</v-icon>
          </div>
        </template>
        <template v-slot:bottom>
          <DataTableFooter
            v-model:page="page"
            :items="items"
            :itemsLength="total"
            v-model:itemsPerPage="itemsPerPage"
            :itemsPerPageOptions="[15, 25, 50]"
          />
        </template>
      </v-data-table-server>

      <v-overlay :model-value="loading" opacity="0" contained persistent />
    </v-card>
  </v-container>
</template>

<script setup lang="ts">
import Issue from "@/types/Issue";
import issueResource from "@/resources/IssueResource";
import axios, { CancelTokenSource } from "axios";
import moment from "moment";
import Filters from "@/components/common/Filters/Filters.vue";
import TableConfiguration from "@/components/common/TableConfiguration.vue";
import CreateIssue from "@/components/issues/CreateIssue.vue";
import userStorage from "@/services/UserStorageService";
import IssueHelper from "@/helpers/issueHelper";
import TableFilter from "@/types/TableFilter";
import { ArchivedIssueFilter } from "@/types/ArchivedIssueFilter";
import { IssueStatus } from "@/types/IssueStatus";
import tagResource from "@/resources/TagResource";
import { TagType } from "@/types/TagType";
import DataTableFooter from "@/components/common/DataTableFooter.vue";
import { ref, computed, watch, onMounted } from "vue";
import { useRouter } from "vue-router";

const props = withDefaults(
  defineProps<{
    readonly initFilter: { [key: string]: TableFilter["selected"] } | null;
  }>(),
  {
    initFilter: null,
  }
);

const router = useRouter();

const showFilter = ref(Boolean(Object.values(props.initFilter || {}).length));
const total = ref(0);
const items = ref<Issue[]>([]);
const loading = ref(false);

const optionsStorageKey = "issuesTable";
const itemsPerPage = ref(userStorage.get(optionsStorageKey)?.itemsPerPage ?? 15);

const sortBy = ref<{ key: string; order: boolean | "asc" | "desc" }[]>(
  userStorage.get(optionsStorageKey)?.sortBy?.[0]?.key
    ? userStorage.get(optionsStorageKey)?.sortBy
    : [{ key: "issueId", order: true }]
);

const page = ref(userStorage.get(optionsStorageKey)?.page ?? 1);

const searchTermStorageKey = "issuesTableSearchTerm";
const searchTerm = ref(userStorage.get(searchTermStorageKey) ?? "");
let searchThrottleTimer = 0;
let cancelToken: CancelTokenSource | undefined = undefined;

const selectedHeaders = ref([]);
const headers = [
  { title: "ID", align: "start", key: "issueId" },
  { title: "Status", key: "status" },
  { title: "Priority", key: "priority" },
  { title: "Title", key: "name" },
  { title: "Tags", key: "tags" },
  { title: "Affected devices", key: "numberOfAffectedDevices", sortable: false },
  { title: "Comments", key: "numberOfComments", sortable: false },
  { title: "Created", key: "createdAt" },
  { title: "Reporter", key: "createdBy" },
  { title: "Updated", key: "lastModifiedAt" },
  { title: "Updated by", key: "lastModifiedBy" },
  { title: "Archived", key: "isArchived" },
];

const getReporters = (search?: string, filterCancelToken?: CancelTokenSource) => {
  return issueResource
    .getReporters(search?.trim(), 5, filterCancelToken)
    .then((resp) => resp.data.map((user) => ({ text: user.username, value: user.userId })))
    .catch(issueResource.defaultErrorHandler);
};

const getTags = (search?: string, filterCancelToken?: CancelTokenSource) => {
  // Cancel existing request
  if (cancelToken) {
    cancelToken.cancel();
  }

  return tagResource
    .getTagsPaged(5, 1, search?.trim(), [TagType.Issue], "issue_count", true, filterCancelToken)
    .then((resp) => resp.data.items.map((tag) => ({ text: tag.name, value: tag.tagId })))
    .catch(tagResource.defaultErrorHandler);
};

const filter = ref<TableFilter[]>([
  {
    title: "Status",
    icon: "mdi-checkbox-multiple-marked",
    filterName: "status",
    searchable: false,
    selected: props.initFilter?.status || [],
    itemsCallback: (search?: string) => {
      if (!search) return IssueHelper.getIssueFilter();
      return IssueHelper.getIssueFilter().filter(({ text }) => text.toLowerCase().includes(search.toLowerCase()));
    },
  },

  {
    title: "Tags",
    icon: "mdi-tag-multiple",
    filterName: "tags",
    searchable: true,
    selected: props.initFilter?.tags || [],
    itemsCallback: getTags,
  },

  {
    title: "Reporters",
    icon: "mdi-account-multiple",
    filterName: "reporters",
    searchable: true,
    selected: props.initFilter?.reporters || [],
    itemsCallback: getReporters,
  },
  {
    title: "Archived issues",
    icon: "mdi-archive-outline",
    filterName: "archivedIssues",
    searchable: false,
    selected: props.initFilter?.archivedIssues || [],
    disableMultiple: true,
    itemsCallback: (search?: string) => {
      const data = [
        { text: "Include archived issues", value: ArchivedIssueFilter.includeArchivedIssue },
        { text: "Only archived issues", value: ArchivedIssueFilter.onlyArchivedIssue },
      ];
      if (!search) return data;
      return data.filter(({ text }) => text.toLowerCase().includes(search.toLowerCase()));
    },
  },
]);

const numberOfFilter = computed(() => {
  return Object.values(filter.value).reduce((acc, { selected }) => (acc += selected.length), 0);
});

const updateFilter = ({ newFilter, eventOptions }: { newFilter: TableFilter[]; eventOptions: any }) => {
  if (searchThrottleTimer) {
    clearTimeout(searchThrottleTimer);
    searchThrottleTimer = 0;
  }

  searchThrottleTimer = setTimeout(
    () => {
      filter.value = newFilter;
      if (page.value > 1) {
        page.value = 1;
        return;
      }
      getData();
    },
    eventOptions.byRemoveBtn ? 0 : 1000
  );
};

const getIssueStatusName = (status: number) => {
  return IssueHelper.getIssueStatusDisplayName(status);
};

const getData = () => {
  // Cancel existing request
  if (cancelToken) {
    cancelToken.cancel();
  }

  // Save sorting, filters and search terms
  userStorage.set(optionsStorageKey, {
    page: page.value,
    itemsPerPage: itemsPerPage.value,
    sortBy: sortBy.value,
  });
  userStorage.set(searchTermStorageKey, searchTerm.value);

  setTimeout(() => {
    // Timeout is workaround for finaly() being executed after request was canceled and new request already began
    loading.value = true;
    cancelToken = axios.CancelToken.source();

    const orderDesc =
      typeof sortBy.value[0].order === "boolean" ? sortBy.value[0].order : sortBy.value[0].order.toString() === "desc";

    issueResource
      .getIssuesPaged(
        itemsPerPage.value,
        page.value,
        searchTerm.value,
        sortBy.value[0].key,
        orderDesc,
        filter.value.find(({ filterName }) => filterName === "status")?.selected.map(({ value }) => value),
        filter.value.find(({ filterName }) => filterName === "reporters")?.selected.map(({ value }) => value),
        filter.value.find(({ filterName }) => filterName === "tags")?.selected.map(({ value }) => value),
        filter.value.find(({ filterName }) => filterName === "archivedIssues")?.selected.map(({ value }) => value)[0],
        undefined,
        cancelToken
      )
      .then((resp) => {
        items.value = resp.data.items;
        total.value = resp.data.totalItems;
        if (resp.data.totalPages < page.value) page.value = 1;
      })
      .catch(issueResource.defaultErrorHandler)
      .finally(() => {
        loading.value = false;
        cancelToken = undefined;
      });
  }, 10);
};

const search = (noTheshold: boolean = false) => {
  if (searchThrottleTimer) {
    clearTimeout(searchThrottleTimer);
    searchThrottleTimer = 0;
  }

  if (noTheshold || !searchTerm.value) {
    getData();
  } else {
    searchThrottleTimer = setTimeout(() => {
      getData();
    }, 1000);
  }
};

const reload = () => {
  getData();
};

const rowClick = (item: Issue) => {
  router.push(`/support/issues/${item.issueId}`);
};

const rowClass = ({ item }: { item: Issue }) => {
  return {
    class: { "cursor-default": true, "text-grey": item.status === IssueStatus.Closed || item.isArchived === true },
  };
};

onMounted(() => {
  getData();
});

watch([page, itemsPerPage], function onPropertyChanged() {
  getData();
});

watch(
  sortBy,
  function onPropertyChanged() {
    getData();
  },
  { deep: true }
);
</script>

<style scoped></style>
