<template>
  <v-dialog v-model="showDialog" scrim="rgba(33, 33, 33)" width="600">
    <template v-slot:activator="{ props: activatorProps }">
      <v-badge dot bordered :model-value="headersUpdated" class="align-self-end" color="primary" offset-x="3" offset-y="3">
        <v-btn variant="text" icon size="x-small" title="Table configuration" v-bind="activatorProps" @click="openDialog">
          <v-icon size="24">mdi-format-columns</v-icon>
        </v-btn>
      </v-badge>
    </template>
    <v-card>
      <v-toolbar flat color="primary" :height="4"> </v-toolbar>
      <v-card-title class="pt-4 pb-3"> Table configuration </v-card-title>

      <v-card-text
        :class="{
          'd-sm-flex list-container pb-1 pt-0': true,
          'mobile-list-container': breakpoint.smAndDown.value,
        }"
      >
        <div
          :class="{
            'column mr-sm-2 fill-sm-height': true,
            'mobile-column': breakpoint.smAndDown.value,
          }"
        >
          <v-list-subheader class="heading">Available columns</v-list-subheader>
          <v-card class="columns-card" tile>
            <v-list density="compact" class="items-list">
              <div v-if="availableColumns.length">
                <v-list-item
                  v-for="(item, i) in availableColumns"
                  :key="i"
                  :selectable="false"
                  :ripple="false"
                  :active="false"
                  :link="false"
                >
                  <v-list-item-title>{{ item.title }}</v-list-item-title>
                  <template v-slot:append>
                    <v-btn
                      variant="text"
                      size="small"
                      density="compact"
                      icon
                      @click="add(item)"
                      class="table-settings-remove-btn"
                    >
                      <v-icon size="small">mdi-arrow-right</v-icon>
                    </v-btn>
                  </template>
                </v-list-item>
              </div>
              <v-list-item v-else>
                <v-list-item-title class="text-grey">No more columns available</v-list-item-title>
              </v-list-item>
            </v-list>
          </v-card>
        </div>

        <div
          :class="{
            'column ml-sm-2 fill-sm-height': true,
            'mobile-column': breakpoint.smAndDown.value,
          }"
        >
          <v-list-subheader class="heading mt-4 mt-sm-auto">Selected columns</v-list-subheader>
          <v-card class="columns-card" tile>
            <v-list density="compact" class="items-list">
              <draggable
                v-if="selectedHeaders.length"
                v-model="draggableList"
                delay="200"
                touchStartThreshold="4"
                :delayOnTouchOnly="true"
                ghost-class="dragged-item-ghost"
                item-key="value"
              >
                <template #item="{ element, index }">
                  <v-list-item :selectable="false" :ripple="false" inactive>
                    <v-list-item-title class="drag">{{ element.title }}</v-list-item-title>
                    <template v-slot:append>
                      <v-btn
                        variant="text"
                        size="small"
                        density="compact"
                        :disabled="index === 0"
                        icon
                        @click="moveUp(element)"
                      >
                        <v-icon size="small"> mdi-arrow-up </v-icon>
                      </v-btn>

                      <v-btn
                        variant="text"
                        size="small"
                        density="compact"
                        :disabled="index === selectedHeaders.length - 1"
                        icon
                        @click="moveDown(element)"
                      >
                        <v-icon size="small"> mdi-arrow-down </v-icon>
                      </v-btn>
                      <v-btn variant="text" density="compact" size="small" icon @click="remove(element)">
                        <v-icon>mdi-close</v-icon>
                      </v-btn>
                    </template>
                  </v-list-item>
                </template>
              </draggable>
              <v-list-item v-else :disabled="true">
                <v-list-item-title class="text-grey">No columns selected</v-list-item-title>
              </v-list-item>
            </v-list>
          </v-card>
        </div>
      </v-card-text>

      <v-card-actions>
        <v-spacer></v-spacer>
        <v-btn variant="text" @click="close"> Close </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script setup lang="ts">
import userStorage from "@/services/UserStorageService";
import draggable from "vuedraggable";
import { useDisplay } from "vuetify";
import { ref, computed, watch, onMounted } from "vue";

const emit = defineEmits(["update:modelValue"]);

const props = withDefaults(
  defineProps<{
    allHeaders?: { title: string; key: any }[];
    tableKey: string;
    modelValue?: { title: string; key: any }[];
  }>(),
  {
    allHeaders: () => [],
    modelValue: () => [],
  }
);

const selectedHeaders = computed({
  get() {
    return props.modelValue || [];
  },
  set(newValue) {
    emit("update:modelValue", newValue);
  },
});

const breakpoint = useDisplay();

const showDialog = ref(false);
const headersUpdated = ref(false);

watch(
  () => selectedHeaders.value,
  () => {
    if (props.tableKey) {
      userStorage.set(
        props.tableKey,
        selectedHeaders.value.map((v) => v.key)
      );
    }
  }
);

const availableColumns = computed(() =>
  props.allHeaders?.filter((v) => !selectedHeaders.value?.find((s) => s.key === v.key))
);

const draggableList = computed({
  get() {
    return selectedHeaders.value;
  },
  set(newSelectedHeaders) {
    emit("update:modelValue", newSelectedHeaders);
  },
});

const headersUpdateHashStorageKey = computed(() => `${props.tableKey}Hash`);

const checkHeadersUpdate = (usedHeadings: string[] | undefined) => {
  const newHash = generateHeadersHash();
  const cachedHash = userStorage.get(headersUpdateHashStorageKey.value);

  if (!cachedHash) {
    // This is the first time user opens this table (or first time after heading update notification was implemented).
    // We check to see if user has any column in Available list. If user does, we show headers update notification.
    if (usedHeadings) {
      const hasColumnsAvailable = props.allHeaders.some((h) => {
        return usedHeadings.includes(h.key) === false;
      });

      // Show notification without saving hash. This will force notification to be visible until user opens dialog.
      if (hasColumnsAvailable) {
        headersUpdated.value = true;
        return;
      }
    }

    // Just save new hash
    userStorage.set(headersUpdateHashStorageKey.value, newHash);
    return;
  }

  headersUpdated.value = cachedHash !== newHash;
};

onMounted(() => {
  let usedHeadings = userStorage.get(props.tableKey);

  checkHeadersUpdate(usedHeadings);

  if (!usedHeadings && props.tableKey) {
    userStorage.set(
      props.tableKey,
      props.allHeaders.map((v) => v.key)
    );
    emit("update:modelValue", props.allHeaders);
    return;
  }

  if (props.allHeaders) {
    emit(
      "update:modelValue",
      usedHeadings.map((key: any) => props.allHeaders.find((s) => s.key === key)).filter((v: any) => v)
    );
  }
});

const openDialog = () => {
  // Store latest headers hash and reset isUpdate flag
  const newHash = generateHeadersHash();
  userStorage.set(headersUpdateHashStorageKey.value, newHash);
  headersUpdated.value = false;
};

const generateHeadersHash = () => {
  return hash(
    props.allHeaders
      .map((v) => v.key)
      .sort()
      .join("")
  );
};

const hash = (str: string) => {
  let hash = 0;
  for (let i = 0, len = str.length; i < len; i++) {
    let chr = str.charCodeAt(i);
    hash = (hash << 5) - hash + chr;
    hash |= 0; // Convert to 32bit integer
  }
  return hash;
};

const add = (item: { title: string; key: any }) => {
  emit("update:modelValue", selectedHeaders.value.concat(item));
};

const moveUp = (item: { title: string; key: any }) => {
  const itemInd = selectedHeaders.value.findIndex(({ key }) => item.key === key);
  const newSelectedHeaders = [...selectedHeaders.value];

  if (itemInd === -1 || itemInd === 0) return;
  newSelectedHeaders[itemInd - 1] = newSelectedHeaders.splice(itemInd, 1, newSelectedHeaders[itemInd - 1])[0];

  emit("update:modelValue", newSelectedHeaders);
};

const moveDown = (item: { title: string; key: any }) => {
  const itemInd = selectedHeaders.value.findIndex(({ key }) => item.key === key);
  const newSelectedHeaders = [...selectedHeaders.value];

  if (itemInd === -1 || itemInd === selectedHeaders.value.length - 1) return;
  newSelectedHeaders[itemInd + 1] = newSelectedHeaders.splice(itemInd, 1, newSelectedHeaders[itemInd + 1])[0];

  emit("update:modelValue", newSelectedHeaders);
};

const remove = (item: { title: string; key: any }) => {
  const newSelectedHeaders = selectedHeaders.value.filter(({ key }) => item.key !== key);
  emit("update:modelValue", newSelectedHeaders);
};

const close = () => {
  showDialog.value = false;
  // Workaround for open dialog icon btn not losing focus
  setTimeout(() => {
    if (document.activeElement instanceof HTMLElement) document.activeElement.blur();
  }, 50);
};
</script>

<style scoped>
.list-container {
  height: calc(80vh - 60px);
  max-height: 400px;
  min-height: 200px;
  padding-bottom: 0;
  overflow: hidden;
}

.mobile-list-container {
  max-height: calc(80vh - 60px);
}

.columns-card {
  height: calc(100% - 30px);
  overflow-y: auto;
}

.column {
  flex: 1;
}

.mobile-column {
  height: 50%;
}

.heading {
  height: auto;
  min-height: 10px !important;
  padding-bottom: 10px;
  white-space: nowrap;
  text-overflow: ellipsis;
  text-overflow: ellipsis;
  display: block;
  overflow: hidden;
}

.items-list {
  overflow: hidden;
  min-height: 100%;
}

.drag {
  cursor: move;
}

.dragged-item-ghost {
  background: rgba(0, 0, 0, 0.2);
}

.v-theme--dark .dragged-item-ghost {
  background: rgba(255, 255, 255, 0.2);
}
</style>
