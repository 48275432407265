<template>
  <v-container fluid>
    <v-card>
      <v-card-title class="d-block d-sm-flex">
        <div class="d-flex flex-grow-1 align-end">
          <div class="flex-grow-0 pr-2 select-auto-width">
            <v-select
              v-model="selectedSearchFields"
              :items="searchFields"
              label="Fields"
              hide-details
              @update:modelValue="searchTerm ? search(true) : false"
              variant="underlined"
              color="primary"
            ></v-select>
          </div>
          <v-text-field
            v-model="searchTerm"
            append-inner-icon="mdi-magnify"
            label="Search for"
            hide-details
            clearable
            prefix=":"
            style="max-width"
            @click:clear="search()"
            v-on:input="search()"
            v-on:keypress.enter="search(true)"
            variant="underlined"
            color="primary"
          ></v-text-field>
          <v-btn
            size="x-small"
            variant="text"
            icon
            class="align-self-end ml-4"
            @click="reload"
            :disabled="loading"
            title="Refresh"
          >
            <v-icon size="24">mdi-reload</v-icon>
          </v-btn>
          <TableConfiguration :allHeaders="headers" v-model="selectedHeaders" tableKey="customersTableColumns" />

          <v-tooltip location="bottom" :disabled="!searchTerm">
            <template v-slot:activator="{ props }">
              <span class="align-self-end ml-2 p-relative" v-bind="props">
                <v-btn
                  size="x-small"
                  variant="text"
                  icon
                  class="align-self-end"
                  @click="showFilter = !showFilter"
                  :color="showFilter ? 'primary' : undefined"
                  :disabled="loading || Boolean(searchTerm)"
                  title="Filters"
                >
                  <v-icon size="24" v-if="searchTerm">mdi-filter-off</v-icon>

                  <v-icon size="24" v-if="!searchTerm">mdi-filter-variant</v-icon>
                  <v-badge
                    color="primary"
                    v-if="numberOfFilter && !searchTerm"
                    transition="v-fade-transition"
                    dot
                    bordered
                    offset-x="-1"
                    offset-y="-10"
                  />
                </v-btn>
              </span>
            </template>
            Filters ignored during search
          </v-tooltip>
        </div>
        <v-spacer class="d-none d-sm-block"></v-spacer>
        <div class="text-right align-self-end mt-2 mt-sm-0">
          <v-btn v-if="allowAddCustomers" size="small" color="primary" @click="newCustomer()"> New customer </v-btn>

          <v-menu v-if="isAdmin" location="bottom" start>
            <template v-slot:activator="{ props }">
              <v-btn icon v-bind="props" class="ml-4" :ripple="false" variant="text" density="compact">
                <v-icon>mdi-dots-vertical</v-icon>
              </v-btn>
            </template>

            <v-list>
              <v-list-item @click="exportCustomers('xls')">
                <v-list-item-title class="text-wrap">Export customers with last used device id (XLS)</v-list-item-title>
              </v-list-item>
              <v-list-item @click="exportCustomers('csv')">
                <v-list-item-title class="text-wrap">Export customers with last used device id (CSV)</v-list-item-title>
              </v-list-item>
            </v-list>
          </v-menu>
        </div>
      </v-card-title>
      <Filters :show="showFilter" :filter="filter" @close="showFilter = false" @update="updateFilter" :disabled="loading" />
      <v-data-table-server
        density="compact"
        :row-props="rowClass"
        :headers="selectedHeaders"
        :items="items"
        :items-length="total"
        :loading="loading && 'primary'"
        :mobile-breakpoint="0"
        @click:row="(_: any, { item }:any) => rowClick(item)"
        @contextmenu:row="openContenxMenu"
        class="no-wrap-table-sm"
        v-model:sort-by="sortBy"
        :must-sort="true"
        :mobile="false"
        hover
      >
        <template v-slot:[`item.type`]="{ item }">
          {{ getCustomerTypeName(item.type) }}
        </template>
        <template v-slot:[`item.email`]="{ item }">
          <v-tooltip v-if="item.email && item.email.length > 30" location="bottom" color="secondary" max-width="500">
            <template v-slot:activator="{ props }">
              <div class="no-wrap" v-bind="props">{{ item.email.substring(0, 30) + "..." }}</div>
            </template>
            <span class="pre-wrap">{{ item.email }}</span>
          </v-tooltip>
          <span v-else>{{ item.email }}</span>
        </template>
        <template v-slot:[`item.created`]="{ item }">
          {{ moment(item.createdDate).format("lll") }}
        </template>
        <template v-slot:[`item.updated`]="{ item }">
          {{ item.updatedDate ? moment(item.updatedDate).format("lll") : "" }}
        </template>
        <template v-slot:[`item.countryIso2`]="{ item }">
          {{ item.countryIso2 ? `${countryByCode(item.countryIso2)}` : "" }}
        </template>
        <template v-slot:[`item.notes`]="{ item }">
          <v-tooltip v-if="item.notes && item.notes.length > 15" location="bottom" color="secondary" max-width="500">
            <template v-slot:activator="{ props }">
              <div class="no-wrap" v-bind="props">{{ item.notes.substring(0, 15) + "..." }}</div>
            </template>
            <span class="pre-wrap">{{ item.notes }}</span>
          </v-tooltip>
          <span v-else>{{ item.notes }}</span>
        </template>

        <template v-slot:bottom>
          <DataTableFooter
            v-model:page="page"
            :items="items"
            :itemsLength="total"
            v-model:itemsPerPage="itemsPerPage"
            :itemsPerPageOptions="[15, 25, 50]"
          />
        </template>
      </v-data-table-server>

      <v-overlay contained :model-value="loading" persistent opacity="0" style="z-index: 999 !important" />
    </v-card>

    <EditCustomer
      v-model="customerToEdit"
      v-on:updated="reload"
      :initData="props.initData"
      :customerInitTab="customerInitTab"
    />
    <DataTableContextMenu v-model="contextMenuEventItem" />
  </v-container>
</template>

<script setup lang="ts">
import EditCustomer from "@/components/customers/EditCustomer.vue";
import Customer from "@/types/Customer";
import TableConfiguration from "@/components/common/TableConfiguration.vue";
import { CustomerType } from "@/types/CustomerType";
import customerResource from "@/resources/CustomerResource";
import axios, { CancelTokenSource } from "axios";
import moment from "moment";
import userProfileService from "@/services/UserProfileService";
import { UserPermissionType } from "@/types/UserPermissionType";
import CustomerHelper from "@/helpers/customerHelper";
import DataTableContextMenu from "@/components/common/DataTableContextMenu.vue";
import userStorage from "@/services/UserStorageService";
import commonHelper from "@/helpers/commonHelper";
import TableFilter from "@/types/TableFilter";
import Filters from "@/components/common/Filters/Filters.vue";
import DataTableFooter from "@/components/common/DataTableFooter.vue";
import { ref, computed, watch, nextTick, onActivated, onDeactivated } from "vue";

import countries from "i18n-iso-countries";
countries.registerLocale(require("i18n-iso-countries/langs/en.json"));

const props = withDefaults(
  defineProps<{
    initFilter: { [key: string]: TableFilter["selected"] } | null;
    initData?: { customerId: number; customerTab: string; deviceId: number; deviceTab: string } | null;
  }>(),
  {
    initFilter: null,
    initData: null,
  }
);
const total = ref(0);
const items = ref<Customer[]>([]);
const loading = ref<boolean>(false);

const optionsStorageKey = "customersTable";

const itemsPerPage = ref<number>(userStorage.get(optionsStorageKey)?.itemsPerPage ?? 15);

const sortBy = ref<{ key: string; order: boolean | "asc" | "desc" }[]>(
  userStorage.get(optionsStorageKey)?.sortBy?.[0]?.key
    ? userStorage.get(optionsStorageKey)?.sortBy
    : [{ key: "customerId", order: true }]
);

const page = ref(userStorage.get(optionsStorageKey)?.page ?? 1);

const searchTermStorageKey = "customersTableSearchTerm";
const searchTerm = ref(userStorage.get(searchTermStorageKey) ?? "");
let searchThrottleTimer = 0;
let cancelToken: CancelTokenSource | undefined = undefined;

const searchFieldsStorageKey = "customersSelectedSearchField";
const selectedSearchFields = ref(userStorage.get(searchFieldsStorageKey) ?? "");
const searchFields = ref([
  { title: "*", value: "" },
  { title: "ID", value: "id" },
  { title: "Phone number", value: "phone" },
]);

const customerToEdit = ref<Customer | null>(null);
const customerInitTab = ref<string | null>(null);
const ignoreOptionsChange = ref(false);

let dataReloadTimeoutId: number | null = null;
const dataReloadIntervalSeconds = 180;
const componentActive = ref(false);
const showFilter = ref(Boolean(Object.values(props.initFilter || {}).length));
const filter = ref<TableFilter[]>([
  {
    title: "Country",
    icon: "mdi-map-marker",
    filterName: "countryIso2",
    searchable: false,
    selected: props.initFilter?.countryIso2 || [],
    disableMultiple: true,
    dividerValues: ["DK"],
    itemsCallback: () => {
      const countryArr = Object.entries(countries.getNames("en", { select: "official" }))
        .sort((a, b) => {
          const mainCountries = ["DK", "SE", "NO"];
          const isSortByAlphabet = !mainCountries.includes(a[0]) && !mainCountries.includes(b[0]);
          if (isSortByAlphabet) return a[1] > b[1] ? 1 : -1;
          return mainCountries.indexOf(a[0]) > mainCountries.indexOf(b[0]) ? -1 : 1;
        })
        .map(([k, v]) => ({
          text: `${v} (${k})`,
          value: k,
        }));
      return countryArr;
    },
  },
]);

const numberOfFilter = computed(() =>
  Object.values(filter.value).reduce((acc, { selected }) => (acc += selected.length), 0)
);

const contextMenuEventItem = ref<any>(null);

const restartDataReloadTimeout = () => {
  if (dataReloadTimeoutId) {
    clearTimeout(dataReloadTimeoutId);
  }

  dataReloadTimeoutId = setTimeout(() => {
    dataReloadTimeoutId = 0;
    if (componentActive.value) {
      getData();
    }
  }, dataReloadIntervalSeconds * 1000);
};
const getData = (resetPagination: boolean = false) => {
  // Cancel existing request
  if (cancelToken) {
    cancelToken.cancel();
  }

  // Reset pagination
  if (resetPagination) {
    ignoreOptionsChange.value = true;
    page.value = 1;
  }

  // Save sorting, filters and search terms
  userStorage.set(optionsStorageKey, {
    page: page.value,
    itemsPerPage: itemsPerPage.value,
    sortBy: sortBy.value,
  });
  userStorage.set(searchFieldsStorageKey, selectedSearchFields.value);
  userStorage.set(searchTermStorageKey, searchTerm.value);

  // Restart data reload timeout
  restartDataReloadTimeout();

  setTimeout(() => {
    // Timeout is workaround for finaly() being executed after request was canceled and new request already began
    loading.value = true;
    cancelToken = axios.CancelToken.source();

    let countryFilter = undefined;

    if (!searchTerm.value) {
      countryFilter = filter.value
        .find(({ filterName }) => filterName === "countryIso2")
        ?.selected.map(({ value }) => value)[0];
    }

    let searchTermValue;
    if (selectedSearchFields.value && searchTerm.value) {
      searchTermValue = selectedSearchFields.value + ":" + searchTerm.value;
    } else {
      searchTermValue = searchTerm.value;
    }
    const orderDesc =
      typeof sortBy.value[0].order === "boolean" ? sortBy.value[0].order : sortBy.value[0].order.toString() === "desc";

    customerResource
      .getCustomersPaged(
        itemsPerPage.value,
        page.value,
        searchTermValue,
        sortBy.value[0].key,
        orderDesc,
        countryFilter,
        cancelToken
      )
      .then((resp) => {
        items.value = resp.data.items;
        total.value = resp.data.totalItems;
      })
      .catch(customerResource.defaultErrorHandler)
      .finally(() => {
        loading.value = false;
        cancelToken = undefined;
        ignoreOptionsChange.value = false;
      });
  }, 10);
};

watch(customerToEdit, () => {
  if (!customerToEdit.value) {
    customerInitTab.value = null;
  }
});

watch( [itemsPerPage, sortBy, page], async () => {
    if (!ignoreOptionsChange.value) {
      await nextTick();
      getData();
    }
  }
);

const selectedHeaders = ref([]);
const headers = [
  { title: "ID", key: "customerId" },
  { title: "First name", key: "firstName" },
  { title: "Last name", key: "lastName" },
  { title: "Email", key: "email" },
  { title: "Phone number", key: "phoneNumber" },
  { title: "Type", key: "type" },
  { title: "Company name", key: "companyName" },
  { title: "Notes", key: "notes", sortable: false },
  { title: "Created", key: "created" },
  { title: "Updated", key: "updated" },
  { title: "Country", key: "countryIso2" },
];

const allowAddCustomers = computed(() => userProfileService.hasPermission(UserPermissionType.AddCustomers));
const isAdmin = computed(() => userProfileService.currentUser?.isAdministrator);

const getCustomerById = (customerId: number, isInitData = false) => {
  if (customerId) {
    loading.value = true;

    customerResource
      .getCustomerById(customerId)
      .then((resp) => {
        customerToEdit.value = resp.data;
        if (isInitData) {
          customerInitTab.value = props.initData?.customerTab || null;
        }
      })
      .catch(customerResource.defaultErrorHandler)
      .finally(() => {
        loading.value = false;
      });
  }
};

onActivated(() => {
  componentActive.value = true;

  if (props.initData?.customerId) {
    getCustomerById(props.initData?.customerId, true);
  }

  // reload data (user haven't been on the page logner than dataReloadIntervalSeconds)
  if (dataReloadTimeoutId === 0 || dataReloadTimeoutId === null) {
    getData();
  }
});

onDeactivated(() => {
  componentActive.value = false;
});

const openContenxMenu = (e: any) => {
  contextMenuEventItem.value = e;
};

const getCustomerTypeName = (type: CustomerType) => {
  return CustomerHelper.getCustomerTypeDisplayName(type);
};

const newCustomer = () => {
  customerToEdit.value = {
    customerId: 0,
    firstName: "",
    lastName: "",
    email: "",
    type: CustomerType.Unknown,
    companyName: "",
    createdDate: new Date(),
  } as Customer;
};

const countryByCode = (code: string) => {
  return countries.getName(code, "en", { select: "official" });
};

const search = (noTheshold: boolean = false) => {
  if (searchThrottleTimer) {
    clearTimeout(searchThrottleTimer);
    searchThrottleTimer = 0;
  }

  if (noTheshold || !searchTerm.value) {
    getData(true);
  } else {
    searchThrottleTimer = setTimeout(() => {
      getData(true);
    }, 1000);
  }
};

const reload = () => {
  getData();
};

const rowClick = (item: Customer) => {
  if (!contextMenuEventItem.value) {
    customerToEdit.value = Object.assign({}, item);
  }
};

const rowClass = ({ item }: { item: Customer }) => {
  return { class: { "cursor-default": true } };
};

const exportCustomers = (format: string) => {
  window.open(`${commonHelper.apiHost}/export/customers-and-last-used-device-id?format=${format}`, "_blank");
};

const updateFilter = ({ newFilter }: { newFilter: TableFilter[] }) => {
  filter.value = newFilter;
  if (page.value > 1) {
    page.value = 1;
    return;
  }

  getData();
};
</script>
