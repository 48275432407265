<template>
  <div class="wrap">
    <v-expand-transition class="transition">
      <div v-show="show" class="filter-container">
        <div class="filter mb-2">
          <template v-for="section in filter" :key="section.filterName">
            <FilterSelect :data="section" :isDropListFrozen="disabled" />

            <div class="label" v-for="item in section.selected" :key="`${section.filterName}-${item.value}`">
              <v-expand-x-transition appear>
                <div class="d-flex align-center">
                  <div class="label-text">{{ item.text }}</div>
                  <v-btn
                    size="x-small"
                    density="compact"
                    variant="text"
                    icon
                    class="close-btn"
                    @click="removeItem(section, item.value)"
                  >
                    <v-icon size="13">mdi-close</v-icon>
                  </v-btn>
                </div>
              </v-expand-x-transition>
            </div>

            <span :class="{ 'mr-lg-6 mr-md-4 mr-sm-2': section.selected.length }" />
            <div class="d-block d-sm-none mobile-divider" />
          </template>

          <v-divider v-if="!breakpoint.xs.value" vertical class="my-2 mx-1" />
          <!--  Hide -->
          <v-btn
            variant="text"
            size="small"
            @click="closeFilter"
            class="text-capitalize"
            :class="{ 'mobile-action-btn': breakpoint.xs.value }"
          >
            Hide
          </v-btn>

          <!-- CLEAR -->
          <v-btn
            variant="text"
            size="small"
            @click="clearFilter"
            class="text-capitalize"
            :class="{ 'mobile-action-btn': breakpoint.xs.value }"
          >
            Clear
          </v-btn>
        </div>
      </div>
    </v-expand-transition>

    <div transition="fade-transition" v-show="show" class="table-divider" />
    <v-overlay :contained="true" :model-value="disabled" opacity="0.4" persistent> </v-overlay>
  </div>
</template>

<script setup lang="ts">
import FilterSelect from "./FilterSelect.vue";
import TableFilter from "@/types/TableFilter";
import { useDisplay } from "vuetify";
import { useComponentQuery } from "@/globalProperties";
import { ref, computed, watch } from "vue";

const { setComponentQuery } = useComponentQuery();
const emit = defineEmits(["close", "update"]);

const props = withDefaults(
  defineProps<{ readonly show: boolean; readonly disabled: boolean; readonly filter: TableFilter[] }>(),
  {
    show: false,
    disabled: false,
    filter: () => [],
  }
);

const eventOptions = ref<{ byRemoveBtn?: boolean }>({});

const filterQuery = computed(() => {
  return props.filter.reduce((acc: { [key: string]: any[] }, item) => {
    if (item.selected.length) {
      acc[item.filterName] = item.selected;
    }
    return acc;
  }, {});
});

watch(
  () => props.filter,
  function onChangeFilter() {
    setComponentQuery("filter", Object.values(filterQuery.value).length ? filterQuery.value : null);

    emit("update", { newFilter: props.filter, eventOptions: { ...eventOptions.value } });
    eventOptions.value = {};
  },
  { deep: true }
);

const breakpoint = useDisplay();

const removeItem = (section: TableFilter, itemId: number) => {
  const sectionInd = props.filter.findIndex(({ filterName }) => filterName === section.filterName);

  if (sectionInd == -1) return;

  eventOptions.value = { byRemoveBtn: true };
  props.filter[sectionInd].selected = props.filter[sectionInd].selected.filter(({ value }) => itemId !== value);
};

const closeFilter = () => emit("close");

const clearFilter = () => {
  eventOptions.value = { byRemoveBtn: true };
  props.filter.forEach((item) => (item.selected = []));
};
</script>

<style scoped>
.transition {
  max-width: 100%;
  width: 100%;
}
.wrap {
  position: relative;
}
.filter-container {
  width: 100%;
  overflow: hidden;
}

.filter {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  align-items: center;
  padding-left: 10px;
  padding-right: 10px;
  box-sizing: border-box;
}

.table-divider {
  border-bottom: thin solid rgba(0, 0, 0, 0.12);
}

:root[theme="dark"] .table-divider {
  border-bottom: thin solid rgba(255, 255, 255, 0.12);
}

.label {
  font-size: 13px;
  font-weight: 500;
  margin: 5px;
  white-space: nowrap;
  max-width: 100%;
}

.label-text {
  display: inline-block;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.mobile-divider {
  width: 100%;
  margin-bottom: 3px;
  opacity: 0.7;
}

.close-btn {
  width: 16px;
  height: 16px;
  margin-left: 0.3em;
}

.mobile-action-btn {
  width: 50%;
  margin-top: 5px;
}
</style>
