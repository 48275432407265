<template>
  <v-container fluid>
    <v-card>
      <v-card-title class="d-block d-sm-flex">
        <div class="d-flex flex-grow-1">
          <v-text-field
            v-model="searchTerm"
            variant="underlined"
            color="primary"
            label="Search"
            hide-details
            clearable
            @click:clear="search()"
            style="max-width"
            v-on:input="search()"
            v-on:keypress.enter="search(true)"
          >
            <template v-slot:append-inner>
              <v-tooltip location="bottom" z-index="99999">
                <template v-slot:activator="{ props }">
                  <v-icon
                    v-bind="props"
                    v-model="isActiveFilter"
                    @click="isActiveFilter = !isActiveFilter"
                    class="mr-1"
                    tooltip="Ignore filters"
                    >{{ isActiveFilter ? "mdi-filter-check" : "mdi-filter-remove" }}</v-icon
                  >
                </template>
                {{ isActiveFilter ? "Using filters when searching" : "Ignoring filters when searching" }}
              </v-tooltip>
              <v-icon @click="search(true)">mdi-magnify</v-icon>
            </template>
          </v-text-field>
          <v-btn
            size="x-small"
            variant="text"
            icon
            class="align-self-end ml-4"
            @click="() => reload()"
            :disabled="loading"
            title="Refresh"
          >
            <v-icon size="24">mdi-reload</v-icon>
          </v-btn>
          <TableConfiguration :allHeaders="headers" v-model="selectedHeaders" tableKey="poiTableColumns" />
          <span class="align-self-end ml-0 p-relative">
            <v-btn
              size="small"
              variant="text"
              @click="() => showFiltersMenu()"
              :color="showFilters || filterPresetsSelected !== 'all' ? 'primary' : ''"
              title="Show filters"
              :disabled="Boolean(searchTerm && !isActiveFilter)"
              :ripple="false"
              flat
              class="px-1 filter"
            >
              <template v-slot:prepend>
                <v-icon size="24" class="mr-n2" v-if="searchTerm && !isActiveFilter">mdi-filter-off</v-icon>
                <v-icon size="24" class="mr-n2" v-if="!searchTerm || isActiveFilter">mdi-filter</v-icon>
              </template>

              <span
                v-if="!searchTerm"
                class="d-none d-sm-block filter-preset-label"
                :title="getSelectedFilterPresetText()"
                >{{ getSelectedFilterPresetText() }}</span
              >
            </v-btn>
          </span>
        </div>
        <v-spacer class="d-none d-sm-block"></v-spacer>
        <div class="text-right align-self-end mt-2 mt-sm-0">
          <v-btn
            v-if="canAddPoi"
            size="small"
            color="primary"
            @click="newPoi()"
            :disabled="currentLocationLoading"
            :loading="currentLocationLoading"
          >
            New POI
          </v-btn>
        </div>
      </v-card-title>

      <v-data-table-server
        density="compact"
        :row-props="rowClass"
        :headers="selectedHeaders"
        :items="items"
        :items-length="total"
        v-model:sort-by="sortBy"
        :must-sort="true"
        hover
        :mobile="false"
        :loading="loading"
        :mobile-breakpoint="0"
        @click:row="(event:any, { item }:any) => rowClick(item)"
        @contextmenu:row="openContextMenu"
      >
        <template v-slot:[`item.isActive`]="{ item }">
          <v-icon size="small" color="green" v-if="item.isActive">mdi-check</v-icon>
        </template>
        <template v-slot:[`item.type`]="{ item }">
          {{ getPoiTypeDisplayName(item.type) }}
        </template>
        <template v-slot:[`item.timeToLive`]="{ item }">
          <span :title="item.timeToLive ? moment(item.timeToLive).format('lll') : ''">{{
            getTtlMinutes(item.timeToLive)
          }}</span>
        </template>
        <template v-slot:[`item.createdDate`]="{ item }">
          {{ moment(item.createdDate).format("lll") }}
        </template>
        <template v-slot:[`item.updatedDate`]="{ item }">
          {{ item.updatedDate ? moment(item.updatedDate).format("lll") : "" }}
        </template>

        <template v-slot:bottom>
          <DataTableFooter
            v-model:page="page"
            :items="items"
            :itemsLength="total"
            v-model:itemsPerPage="itemsPerPage"
            :itemsPerPageOptions="[15, 25, 50]"
          />
        </template>
      </v-data-table-server>

      <v-overlay :model-value="loading" opacity="0" contained persistent :style="'z-index: 999 !important'" />
    </v-card>

    <EditPoi v-model="poiToEdit" v-on:updated="() => reload()" @edit-another-poi="editPoi" :poiInitTab="poiInitTab" />

    <SideSheet v-model="showFilters" heading="Filter POI" contentClass="filters">
      <h4>Filter presets</h4>
      <v-select
        attach
        v-model="c_filterPresetsSelected"
        :items="filterPresetsItems"
        density="compact"
        hide-details
        @update:modelValue="applyFilterPreset()"
      />

      <h4 class="mt-6">POI Types</h4>
      <div>
        <v-btn size="x-small" link @click="selectAllPoiTypes()">Select all</v-btn> /
        <v-btn size="x-small" link @click="deselectAllPoiTypes()">Deselect all</v-btn>
      </div>
      <div v-for="type in typeItems" :key="type.value">
        <v-checkbox
          v-model="c_typeSelected"
          :label="type.text"
          :value="type.value"
          color="primary"
          density="compact"
          class="type"
          hide-details
          @click="setCustomFilterPreset()"
        />
      </div>

      <h4 class="mt-6">Active status</h4>
      <v-select
        v-model="c_activeSelected"
        :items="activeItems"
        density="compact"
        hide-details
        class="filter-item"
        @update:model-value="setCustomFilterPreset()"
      />

      <template v-slot:actions>
        <v-spacer />
        <v-btn variant="text" @click="resetFilters()">Reset</v-btn>
        <v-btn color="primary" class="ml-4" @click="applyFilters(true)">Apply</v-btn>
      </template>
    </SideSheet>

    <DataTableContextMenu v-model="contextMenuEventItem" />
  </v-container>
</template>

<script setup lang="ts">
import userProfileService from "@/services/UserProfileService";
import { UserPermissionType } from "@/types/UserPermissionType";
import moment from "moment";
import axios, { CancelTokenSource } from "axios";
import Poi from "@/types/Poi";
import { PoiType } from "@/types/PoiType";
import poiResource from "@/resources/PoiResource";
import PoiHelper from "@/helpers/poiHelper";
import EditPoi from "@/components/poi/EditPoi.vue";
import SideSheet from "@/components/layout/SideSheet.vue";
import DataTableContextMenu from "@/components/common/DataTableContextMenu.vue";
import userStorage from "@/services/UserStorageService";
import TableConfiguration from "@/components/common/TableConfiguration.vue";
import DataTableFooter from "@/components/common/DataTableFooter.vue";
import { ref, computed, watch, onActivated, onDeactivated, onMounted, nextTick } from "vue";

const props = withDefaults(
  defineProps<{
    readonly initData: { poiTab: string; poiId: number } | null;
  }>(),
  {
    initData: null,
  }
);

const total = ref(0);
const items = ref<Poi[]>([]);
const loading = ref(false);
const currentLocationLoading = ref(false);
const optionsStorageKey = "poiTable";
const itemsPerPage = ref(userStorage.get(optionsStorageKey)?.itemsPerPage ?? 15);

const sortBy = ref<{ key: string; order: boolean | "asc" | "desc" }[]>(
  userStorage.get(optionsStorageKey)?.sortBy?.[0]?.key
    ? userStorage.get(optionsStorageKey)?.sortBy
    : [{ key: "poiId", order: true }]
);

const page = ref(userStorage.get(optionsStorageKey)?.page ?? 1);

const searchFiltersActiveStorageKey = "poiTableSearchFiltersActive";
const isActiveFilter = ref(userStorage.get(searchFiltersActiveStorageKey) ?? false);

const searchTermStorageKey = "poiTableSearchTerm";
const searchTerm = ref(userStorage.get(searchTermStorageKey) ?? "");
let searchThrottleTimer = 0;
let cancelToken: CancelTokenSource | undefined = undefined;

const poiToEdit = ref<Poi | null>(null);
const poiInitTab = ref<string | null>(null);
const showFilters = ref(false);

const filterPresetStorageKey = "poiTableFilterPreset";
const filterPresetsSelected = ref(userStorage.get(filterPresetStorageKey) ?? "active");
const c_filterPresetsSelected = ref(filterPresetsSelected.value);
const filterPresetsItems = [
  { title: "All POI", value: "all" },
  { title: "Active non-static POI only", value: "active" },
  { title: "Static POI only", value: "static" },
  { title: "Custom", value: "custom" },
];

const typeSelectedStorageKey = "poiTableTypeSelected";
const typeSelected = ref((userStorage.get(typeSelectedStorageKey) ?? []) as PoiType[]);
const c_typeSelected = ref(typeSelected.value);
const getPoiTypeDisplayName = (type: PoiType) => {
  return PoiHelper.getPoiTypeDisplayName(type);
};

const typeItems = [
  { text: getPoiTypeDisplayName(PoiType.Checkpoint), value: PoiType.Checkpoint },
  { text: getPoiTypeDisplayName(PoiType.SpeedControl), value: PoiType.SpeedControl },
  { text: getPoiTypeDisplayName(PoiType.SeatBeltControl), value: PoiType.SeatBeltControl },
  { text: getPoiTypeDisplayName(PoiType.TechnicalControl), value: PoiType.TechnicalControl },
  { text: getPoiTypeDisplayName(PoiType.CustomsControl), value: PoiType.CustomsControl },
  { text: getPoiTypeDisplayName(PoiType.RoadCondition), value: PoiType.RoadCondition },
  { text: getPoiTypeDisplayName(PoiType.Animal), value: PoiType.Animal },
  { text: getPoiTypeDisplayName(PoiType.SpeedCamera), value: PoiType.SpeedCamera },
  { text: getPoiTypeDisplayName(PoiType.AverageCamera), value: PoiType.AverageCamera },
  { text: getPoiTypeDisplayName(PoiType.AverageCameraEnd), value: PoiType.AverageCameraEnd },
];

const activeSelectedStorageKey = "poiTableActiveSelected";
const activeSelected = ref(userStorage.get(activeSelectedStorageKey) ?? "any");
const c_activeSelected = ref(activeSelected.value);
const activeItems = [
  { title: "Any", value: "any" },
  { title: "Active only", value: "active" },
  { title: "Inactive only", value: "inactive" },
];

const ignoreOptionsChange = ref(false);

const selectedHeaders = ref([]);
const headers = [
  { title: "ID", key: "poiId" },
  { title: "Type", key: "type" },
  { title: "Area info", key: "areaInfo" },
  { title: "Search tags", key: "searchTags" },
  { title: "Active", key: "isActive" },
  { title: "TTL", key: "timeToLive" },
  { title: "Device ID", key: "deviceId" },
  { title: "Negated", key: "numberOfNegativeReports" },
  { title: "Created", key: "createdDate" },
  { title: "Updated", key: "updatedDate" },
];

const canAddPoi = computed(() => userProfileService.hasPermission(UserPermissionType.AddPoi));

onMounted(() => {
  applyFilterPreset();
  applyFilters(false);
  if (props.initData?.poiId) {
    getInitPoiById(props.initData?.poiId);
  }
});

let dataReloadTimeoutId: number | null = null;
let dataReloadIntervalSeconds = 60;
const componentActive = ref(false);

onActivated(() => {
  componentActive.value = true;
  // reload data (user haven't been on the page logner than dataReloadIntervalSeconds)
  if (dataReloadTimeoutId === 0 || dataReloadTimeoutId === null) {
    getData();
  }
});

onDeactivated(() => {
  componentActive.value = false;
});

const restartDataReloadTimeout = () => {
  if (dataReloadTimeoutId) {
    clearTimeout(dataReloadTimeoutId);
  }

  dataReloadTimeoutId = setTimeout(() => {
    dataReloadTimeoutId = 0;
    if (componentActive.value) {
      getData();
    }
  }, dataReloadIntervalSeconds * 1000);
};

const contextMenuEventItem = ref<any>(null);
const openContextMenu = (e: any) => {
  contextMenuEventItem.value = e;
};

const getInitPoiById = (poiId: number) => {
  poiResource
    .getPoiById(poiId)
    .then((resp) => {
      rowClick(resp.data);
      poiInitTab.value = props.initData?.poiTab || null;
    })
    .catch(poiResource.defaultErrorHandler);
};

const editPoi = (poi: Poi) => {
  poiToEdit.value = null;
  // use timeout to ensure poi is reset
  setTimeout(() => {
    poiToEdit.value = Object.assign({}, poi);
  }, 100);
};

const getTtlMinutes = (ttl: Date | undefined): string => {
  const ttlMin = PoiHelper.getTtlMinutes(ttl);
  if (ttlMin <= 0) {
    return "-";
  } else if (ttlMin > 1000) {
    return "~";
  } else {
    return `${ttlMin.toFixed(0)} min`;
  }
};

const newPoi = async () => {
  if ("permissions" in navigator) {
    currentLocationLoading.value = true;
    try {
      const permissionStatus = await navigator.permissions.query({ name: "geolocation" });
      let position: GeolocationPosition | null = null;
      if (permissionStatus.state === "granted") {
        position = await new Promise((resolve, reject) => {
          navigator.geolocation.getCurrentPosition(resolve, reject);
        });
      }

      const latitude = Number(position?.coords?.latitude?.toFixed(6));
      const longitude = Number(position?.coords?.longitude?.toFixed(6));

      editPoi(PoiHelper.getNewPoiTemplate(latitude, longitude));
    } catch (error: any) {
      console.error(error.message || "Error in PoiList newPoi function");
    }
    currentLocationLoading.value = false;
  } else {
    editPoi(PoiHelper.getNewPoiTemplate());
  }
};

const getData = (resetPagination: boolean = false) => {
  // Cancel existing request
  if (cancelToken) {
    cancelToken.cancel();
  }

  // Reset pagination
  if (resetPagination) {
    ignoreOptionsChange.value = true;
    page.value = 1;
  }

  // Save sorting, filters and search terms
  userStorage.set(optionsStorageKey, {
    page: page.value,
    itemsPerPage: itemsPerPage.value,
    sortBy: sortBy.value,
  });
  userStorage.set(searchTermStorageKey, searchTerm.value);
  userStorage.set(searchFiltersActiveStorageKey, isActiveFilter.value);
  userStorage.set(filterPresetStorageKey, filterPresetsSelected.value);
  userStorage.set(typeSelectedStorageKey, typeSelected.value);
  userStorage.set(activeSelectedStorageKey, activeSelected.value);

  // Restart data reload timeout
  restartDataReloadTimeout();

  setTimeout(() => {
    // Timeout is workaround for finaly() being executed after request was canceled and new request already began
    loading.value = true;
    cancelToken = axios.CancelToken.source();

    // Filters
    var isActive: boolean | undefined = undefined;
    var typeOf: PoiType[] | undefined = undefined;

    if (!searchTerm.value || isActiveFilter.value) {
      // isActive
      if (activeSelected.value === "active") {
        isActive = true;
      } else if (activeSelected.value === "inactive") {
        isActive = false;
      }
      // typeOf
      if (filterPresetsSelected.value !== "all") {
        typeOf = typeSelected.value;
      }
    }

    const orderDesc =
      typeof sortBy.value[0].order === "boolean" ? sortBy.value[0].order : sortBy.value[0].order.toString() === "desc";
    poiResource
      .getPoiPaged(
        itemsPerPage.value,
        page.value,
        isActive,
        typeOf,
        searchTerm.value,
        sortBy.value[0].key,
        orderDesc,
        cancelToken
      )
      .then((resp) => {
        items.value = resp.data.items;
        total.value = resp.data.totalItems;
      })
      .catch(poiResource.defaultErrorHandler)
      .finally(() => {
        loading.value = false;
        cancelToken = undefined;
        ignoreOptionsChange.value = false;
      });
  }, 10);
};

const search = (noTheshold: boolean = false) => {
  if (searchThrottleTimer) {
    clearTimeout(searchThrottleTimer);
    searchThrottleTimer = 0;
  }

  if (noTheshold || !searchTerm.value) {
    getData(true);
  } else {
    searchThrottleTimer = setTimeout(() => {
      getData(true);
    }, 1000);
  }
};

const showFiltersMenu = () => {
  c_filterPresetsSelected.value = filterPresetsSelected.value;
  c_typeSelected.value = [...typeSelected.value];
  c_activeSelected.value = activeSelected.value;

  showFilters.value = true;
};

const resetFilters = () => {
  c_filterPresetsSelected.value = "active";
  applyFilterPreset();
  applyFilters(true);
};

const applyFilters = (reloadPoi: boolean) => {
  filterPresetsSelected.value = c_filterPresetsSelected.value;
  typeSelected.value = [...c_typeSelected.value];
  activeSelected.value = c_activeSelected.value;

  if (reloadPoi) {
    showFilters.value = false;
    reload(reloadPoi);
  }
};

const setCustomFilterPreset = () => {
  c_filterPresetsSelected.value = "custom";
};

const selectAllPoiTypes = () => {
  c_typeSelected.value = typeItems.map((el) => el.value);
  setCustomFilterPreset();
};

const deselectAllPoiTypes = () => {
  c_typeSelected.value = [];
  setCustomFilterPreset();
};

const applyFilterPreset = () => {
  if (c_filterPresetsSelected.value === "all") {
    c_typeSelected.value = typeItems.map((el) => el.value);
    c_activeSelected.value = "any";
  } else if (c_filterPresetsSelected.value === "active") {
    c_typeSelected.value = PoiHelper.getNonPermanentPoiTypes();
    c_activeSelected.value = "active";
  } else if (c_filterPresetsSelected.value === "static") {
    c_typeSelected.value = PoiHelper.getPermanentPoiTypes();
    c_activeSelected.value = "any";
  }
};

const getSelectedFilterPresetText = () => {
  return filterPresetsItems.find((item) => item.value === filterPresetsSelected.value)?.title;
};

const reload = (reloadPoi?: boolean) => {
  getData(reloadPoi);
};

const rowClick = (item: Poi) => {
  if (!contextMenuEventItem.value) {
    editPoi(item);
  }
};

const rowClass = ({ item }: { item: Poi }) => {
  return { class: { "cursor-default": true } };
};

watch([itemsPerPage, sortBy, page], async function onPropertyChanged() {
  if (!ignoreOptionsChange.value) {
    await nextTick();
    getData();
  }
});

watch(isActiveFilter, function onIsActiveFilterChanged() {
  if (searchTerm.value) {
    getData();
  }
});

watch(poiToEdit, function onChangeCustomerToEdit() {
  if (!poiToEdit.value) {
    poiInitTab.value = null;
  }
});
</script>

<style scoped>
.filter-preset-label {
  position: "absolute";
  left: 100%;
  margin-left: 4px;
  display: inline-block;
  max-width: 100px;
  overflow: hidden;
  text-overflow: ellipsis;
  text-transform: none;
  text-align: left;
}

.filter {
  margin-bottom: 2px;
}

.type {
  font-size: 13px;
}
</style>
