<template>
  <div>
    <div class="text-subtitle-1 mb-1">Device Change History</div>
    <p class="text-caption">List of the last device information changes.</p>

    <v-progress-linear v-if="loading" indeterminate></v-progress-linear>

    <div v-if="items && items.length">
      <v-divider />
      <v-data-table
        density="compact"
        :headers="headers"
        :items="items"
        :options.sync="options"
        :loading="loading"
        :disable-pagination="true"
        :hide-default-footer="true"
        items-per-page="-1"
        :mobile-breakpoint="0"
      >
        <template v-slot:[`item.valueOld`]="{ item }">
          {{ formatValue(item.valueOld) }}
        </template>
        <template v-slot:[`item.valueNew`]="{ item }">
          {{ formatValue(item.valueNew) }}
        </template>
        <template v-slot:[`item.modifiedDate`]="{ item }">
          {{ moment(item.modifiedDate).format("lll") }}
        </template>
      </v-data-table>
    </div>
    <div v-else-if="!loading">No changes found.</div>
  </div>
</template>

<script setup lang="ts">
import moment from "moment";
import EntityChangeRecord from "@/types/EntityChangeRecord";
import deviceResource from "@/resources/DeviceResource";
import userStorage from "@/services/UserStorageService";
import { ref, computed, watch, onActivated, onDeactivated } from "vue";
import type { VDataTable } from "vuetify/components";
type ReadonlyHeaders = VDataTable["$props"]["headers"];

const props = withDefaults(defineProps<{ readonly deviceId: number | null }>(), { deviceId: null });

const loading = ref(false);
const items = ref<EntityChangeRecord[]>([]);

const getData = () => {
  if (!props.deviceId) {
    return;
  }

  loading.value = true;

  deviceResource
    .getDeviceChangeHistory(props.deviceId)
    .then((resp) => {
      items.value = resp.data;
    })
    .catch(deviceResource.defaultErrorHandler)
    .finally(() => {
      loading.value = false;
    });
};

watch(
  () => props.deviceId,
  function onValueChanged() {
    items.value = [];
    getData();
  },
  { immediate: true }
);

const headers: ReadonlyHeaders = [
  { title: "Modified on", align: "start", key: "modifiedDate" },
  { title: "Modified by", key: "modifiedBy" },
  { title: "Field name", key: "fieldName" },
  { title: "Old value", key: "valueOld" },
  { title: "New value", key: "valueNew" },
];

const optionsStorageKey = "deviceHistoryTable";
const options = ref(
  userStorage.get(optionsStorageKey) ?? {
    sortBy: [],
    sortDesc: [false],
  }
);

watch(
  options,
  function onPropertyChanged() {
    userStorage.set(optionsStorageKey, options.value);
  },
  { deep: true }
);

const formatValue = (val: any) => {
  let result = val;
  if (typeof val === "object") {
    result = "";
  } else if (val.toString().match(/^\d\d\d\d-\d\d-\d\d/)) {
    result = moment(val).format("lll");
  }

  return result;
};
</script>
