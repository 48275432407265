<template>
  <div>
    <side-sheet v-if="props.modelValue" v-model="showDialog" heading="Affected devices">
      <div v-if="(canAddIssues || canEditIssues || canManageIssueDevices) && canViewDevices" class="text-right">
        <v-autocomplete
          v-if="!isMultipleMode"
          v-model="newSelectedItem"
          :items="newDeviceItems"
          :loading="loading"
          v-model:search="newDeviceSearchTerm"
          clearable
          hide-no-data
          no-filter
          label="Add new affected device"
          return-object
          item-value="deviceId"
          persistent-hint
          hint="Search by device ID, IMEI or name"
        >
          <template v-slot:selection="{ item }">
            {{ `${item.raw.deviceId} - ${item.raw.deviceName}` }}
          </template>
          <template v-slot:item="{ props, item }">
            <v-list-item v-bind="{ ...props, title: '' }">
              {{ `${item.raw.deviceId} - ${item.raw.deviceName}` }}
            </v-list-item>
          </template>
        </v-autocomplete>
        <v-textarea
          v-if="isMultipleMode"
          label="Enter multiple device IDs"
          hint="Comma, space or new-line separated device IDs"
          persistent-hint
          auto-grow
          rows="1"
          v-model="newIDsTherm"
          variant="underlined"
        ></v-textarea>

        <div class="d-flex align-center justify-end">
          <v-switch
            class="mr-6 mt-0 new-device-mode-switch"
            density="compact"
            hide-details
            label="Add multiple"
            v-model="isMultipleMode"
          />
          <v-btn color="primary" v-if="isMultipleMode" size="small" @click="addDevices" :disabled="!newIDsTherm">
            Add
          </v-btn>
          <v-btn color="primary" v-if="!isMultipleMode" size="small" @click="addDevice" :disabled="!newSelectedItem">
            Add
          </v-btn>
        </div>
      </div>

      <IssueCurrentDevices
        v-model="props.issue"
        :forceReload="reloadCurrentDevices"
        @remove="removeDevice"
        @changeStatus="emit('changeStatus')"
        :statusSummary="props.statusSummary"
      />

      <template v-slot:actions>
        <v-spacer></v-spacer>
        <v-btn :disabled="loading" variant="text" @click="showDialog = false">Close</v-btn>
      </template>
    </side-sheet>
  </div>
</template>

<script setup lang="ts">
import IssueCurrentDevices from "@/components/issues/IssueCurrentDevices.vue";
import SideSheet from "@/components/layout/SideSheet.vue";
import userProfileService from "@/services/UserProfileService";
import { UserPermissionType } from "@/types/UserPermissionType";
import Device from "@/types/Device";
import { IssueDeviceStatus } from "@/types/IssueDeviceStatus";
import issueResource from "@/resources/IssueResource";
import deviceResource from "@/resources/DeviceResource";
import axios, { CancelTokenSource } from "axios";
import Issue from "@/types/Issue";
import infoMessageService from "@/services/InfoMessageService";
import { InfoMessageType } from "@/types/InfoMessageType";
import { ref, watch, computed } from "vue";
import { useConfirm } from "@/services/ConfirmService";

const emit = defineEmits(["changeStatus", "update:modelValue", "changeTotalDevices"]);

const props = withDefaults(
  defineProps<{
    readonly statusSummary: { status: IssueDeviceStatus; count: number }[] | [];
    modelValue: boolean;
    readonly issue?: Issue;
  }>(),
  { statusSummary: () => [], modelValue: false }
);

const confirm = useConfirm();

const loading = ref(false);
let cancelToken: CancelTokenSource | undefined = undefined;
const newSelectedItem = ref<Device | null>(null);
const newDeviceItems = ref<Device[]>([]);
const newDeviceSearchTerm = ref<string | undefined>(undefined);
let searchThrottleTimer = 0;
const reloadCurrentDevices = ref({});
const newIDsTherm = ref("");
const isMultipleMode = ref(false);

watch(newDeviceSearchTerm, function onDeviceSearchTermChanged(oldValue: any, newValue: any) {
  if (props.issue && oldValue !== newValue) {
    if (searchThrottleTimer) {
      clearTimeout(searchThrottleTimer);
      searchThrottleTimer = 0;
    }
    searchThrottleTimer = setTimeout(() => {
      getIssueDevices();
    }, 1000);
  }
});

const showDialog = computed({
  get() {
    return props.modelValue;
  },

  set(value: boolean) {
    emit("update:modelValue", value);
  },
});

const canViewDevices = computed(() => userProfileService.hasPermission(UserPermissionType.ViewDevices));

const canAddIssues = computed(
  () => props.issue?.isArchived && userProfileService.hasPermission(UserPermissionType.AddIssues)
);

const canEditIssues = computed(
  () => props.issue?.isArchived && userProfileService.hasPermission(UserPermissionType.EditIssues)
);

const canManageIssueDevices = computed(() => {
  return (
    !props.issue?.isArchived &&
    (userProfileService.hasPermission(UserPermissionType.EditIssues) ||
      userProfileService.hasPermission(UserPermissionType.ManageIssueDevices))
  );
});

const removeDevice = () => {
  emit("changeTotalDevices", -1);
};

const addDevices = () => {
  const idData = newIDsTherm.value
    .split(/\s+|,/g)
    .filter((v) => v.trim())
    .filter((v: string, ind: number, arr: string[]) => arr.indexOf(v) === ind);

  const iDs = idData.map((v) => Number(v.trim())).filter((v) => !isNaN(v));

  if (idData.length !== iDs.length) {
    const invalidIds = idData.filter((id) => !iDs.find((v) => String(v) === id));
    return infoMessageService.show(
      InfoMessageType.Error,
      `The following values are not device IDs: ${invalidIds.join(", ")}`
    );
  }

  if (iDs?.length > 300) {
    return infoMessageService.show(InfoMessageType.Error, "Too many davices. You can only add 300 devices at a time.");
  }

  if (!props.issue || !iDs.length) {
    return;
  }

  loading.value = true;

  const promise = Promise.all(
    iDs.map((id) => {
      return issueResource.addDevice((props.issue as Issue)?.issueId, id).catch((err) => ({ err, isError: true, id }));
    })
  );

  promise
    .then((resp) => {
      const errors = resp.filter((v: any) => v?.isError);
      if (errors.length) {
        confirm.show(
          `One or more devices were not linked to the issue. Either device is already linked, or device does not exist.<br/><br/>${errors
            .map((v: any) => v.id)
            .join(", ")}`,
          {
            color: "red",
            width: 500,
            persistent: true,
            buttonTrueText: "Close",
            buttonFalseText: null,
          }
        );
      }
      newIDsTherm.value = "";
      reloadCurrentDevices.value = {};

      if (iDs.length - errors.length > 0) {
        emit("changeTotalDevices", iDs.length - errors.length);
      }
    })
    .catch(issueResource.defaultErrorHandler)
    .finally(() => {
      loading.value = false;
    });
};

const addDevice = () => {
  if (!props.issue || !newSelectedItem.value) {
    return;
  }

  loading.value = true;

  issueResource
    .addDevice(props.issue.issueId, newSelectedItem.value.deviceId)
    .then(() => {
      newSelectedItem.value = null;
      reloadCurrentDevices.value = {};
      emit("changeTotalDevices", 1);
    })
    .catch(issueResource.defaultErrorHandler)
    .finally(() => {
      loading.value = false;
    });
};

const getIssueDevices = () => {
  if (!props.issue) {
    return;
  }
  // Cancel existing request
  if (cancelToken) {
    cancelToken.cancel();
  }

  setTimeout(() => {
    // Timeout is a workaround for finaly() being executed after request was canceled and new request already began
    loading.value = true;
    cancelToken = axios.CancelToken.source();

    if (!props.issue) {
      return;
    }
    deviceResource
      .getDevicesPaged(
        5,
        1,
        newDeviceSearchTerm.value ?? undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        undefined,
        cancelToken
      )
      .then((resp) => (newDeviceItems.value = resp.data.items))
      .catch(issueResource.defaultErrorHandler)
      .finally(() => {
        loading.value = false;
        cancelToken = undefined;
      });
  }, 10);
};
</script>

<style scoped>
.new-device-mode-switch {
  text-transform: unset !important;
}

.new-device-mode-switch :deep(.v-label) {
  font-size: 0.85rem;
}
</style>
