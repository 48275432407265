<template>
  <div>
    <div class="text-subtitle-1 mb-1">POI Change History</div>
    <p class="text-caption">List of the last POI information changes.</p>

    <v-progress-linear v-if="loading" indeterminate></v-progress-linear>

    <div v-if="poiChanges && poiChanges.length">
      <v-divider />
      <v-table density="compact" hover>
        <template v-slot:default>
          <thead>
            <tr>
              <th scope="col">Modified on</th>
              <th scope="col">Modified by</th>
              <th scope="col">Field name</th>
              <th scope="col">Old value</th>
              <th scope="col">New value</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(rec, ind) in poiChanges" :key="ind">
              <td class="text-caption no-wrap">{{ moment(rec.modifiedDate).format("lll") }}</td>
              <td class="text-caption no-wrap">{{ rec.modifiedBy }}</td>
              <td class="text-caption no-wrap">{{ rec.fieldName }}</td>
              <td class="text-caption no-wrap">{{ formatValue(rec.valueOld) }}</td>
              <td class="text-caption no-wrap">{{ formatValue(rec.valueNew) }}</td>
            </tr>
          </tbody>
        </template>
      </v-table>
    </div>
    <div v-else-if="!loading">No changes found.</div>
  </div>
</template>

<script setup lang="ts">
import { ref, watch } from "vue";
import moment from "moment";
import EntityChangeRecord from "@/types/EntityChangeRecord";
import poiResource from "@/resources/PoiResource";

const props = defineProps<{ readonly poiId: number | null }>();

const loading = ref(false);
const poiChanges = ref<EntityChangeRecord[]>([]);

const getData = () => {
  if (!props.poiId) {
    return;
  }

  loading.value = true;

  poiResource
    .getPoiChangeHistory(props.poiId)
    .then((resp) => {
      poiChanges.value = resp.data;
    })
    .catch(poiResource.defaultErrorHandler)
    .finally(() => {
      loading.value = false;
    });
};

const formatValue = (val: any) => {
  let result = val;
  if (typeof val === "object") {
    result = "";
  } else if (val.toString().match(/^\d\d\d\d-\d\d-\d\d/)) {
    result = moment(val).format("lll");
  }

  return result;
};

watch(
  () => props.poiId,
  function onValueChanged() {
    poiChanges.value = [];
    getData();
  },
  { immediate: true }
);
</script>
