<template>
  <div style="height: 100%; position: relative">
    <iframe :src="logsUrl" style="width: 100%; height: 100%" frameborder="0"></iframe>
  </div>
</template>

<script setup lang="ts">
import commonHelper from "@/helpers/commonHelper";
import appConfigService from "@/services/AppConfigService";
import { ref, computed, watch } from "vue";

const props = withDefaults(defineProps<{ readonly deviceId: number | null }>(), { deviceId: null });

const logsUrl = ref("");

const dashboardId = computed(() => appConfigService.kibanaLogsDashboardId);

const panelId = computed(() => appConfigService.kibanaLogsPanelId);

watch(
  () => props.deviceId,
  function onValueChanged() {
    if (props.deviceId) {
      logsUrl.value = `${commonHelper.apiHost}/kibana/app/dashboards#/view/${dashboardId.value}?embed=true&show-query-input=true&show-time-filter=true&_g=(filters:!(),refreshInterval:(pause:!t,value:0),time:(from:now-1h,to:now))&_a=(expandedPanelId:'${panelId.value}',filters:!(),query:(language:kuery,query:'ClientId : ${props.deviceId}'))`;
    } else {
      logsUrl.value = "";
    }
  },
  { immediate: true }
);
</script>
