<template>
  <v-card class="my-2" :loading="loading" :disabled="loading">
    <v-card-text>
      <div class="issue-comment-container">
        <div class="d-flex align-center text-truncate mb-2">
          <div class="author">
            <div class="d-flex align-center text-truncate">
              <span class="text-medium-emphasis">{{ data.createdBy }}</span>
              <div class="created-date ml-1">
                commented on
                {{ moment(data.createdAt).format("lll") }}
              </div>
            </div>
            <div v-if="data.lastModifiedBy" class="d-flex align-center text-truncate">
              <div class="secondary-font mr-1">edited by</div>
              <span class="text-medium-emphasis">{{ data.lastModifiedBy }}</span>
              <div class="created-date ml-1">on {{ moment(data.lastModifiedAt).format("lll") }}</div>
            </div>
          </div>
          <v-btn
            density="compact"
            size="small"
            v-if="canEditComment"
            @click="editMode = true"
            :disabled="loading || editMode"
            icon
            title="Edit"
            variant="text"
          >
            <v-icon> mdi-pencil </v-icon>
          </v-btn>
          <v-btn
            v-if="canEditComment"
            @click="deleteCommentConfirm"
            :disabled="loading || editMode"
            icon
            size="small"
            density="compact"
            title="Delete"
            class="ml-2"
            variant="text"
          >
            <v-icon> mdi-trash-can-outline </v-icon>
          </v-btn>
        </div>

        <v-sheet>
          <RichText
            v-if="editMode"
            v-model="data.content"
            @update:modelValue="() => changesControl.activate()"
            :alwaysFocus="true"
            :small="true"
          />
          <div v-else class="rte-content-view ql-editor" v-html="data.content"></div>
        </v-sheet>

        <div v-if="data.attachments || editMode">
          <Attachments
            ref="attachmentsRef"
            :files="data.attachments"
            :targetType="targetType"
            :targetId="data.commentId"
            v-on:update="updateFiles"
            v-on:updateLocalFiles="updateLocalFiles"
            :disabled="!editMode || !canEditComment"
            :isActionByCommand="true"
          />
        </div>

        <div v-if="editMode" class="text-right mt-4">
          <v-btn variant="text" @click="cancelContent" size="small"> Cancel </v-btn>
          <v-btn
            color="primary"
            class="ml-4"
            @click="updateComment"
            :loading="loading"
            :disabled="loading || !isReadyForm"
            size="small"
          >
            Save
          </v-btn>
        </div>
      </div>
    </v-card-text>
  </v-card>
</template>

<script setup lang="ts">
import Comment from "@/types/Comment";
import AttachedFile from "@/types/AttachedFile";
import { AttachmentTargetType } from "@/types/AttachmentTargetType";
import moment from "moment";
import userProfileService from "@/services/UserProfileService";
import RichText from "@/components/common/RichText.vue";
import Attachments from "@/components/common/Attachments.vue";
import issueResource from "@/resources/IssueResource";
import ChangeManager from "@/services/ChangeManager";
import { useRoute } from "vue-router";
import { ref, computed, watch, onMounted, onUnmounted } from "vue";
import { useConfirm } from "@/services/ConfirmService";

const emit = defineEmits(["delete", "update"]);
const props = defineProps<{ readonly comment: Comment }>();

const confirm = useConfirm();
const route = useRoute();

const attachmentsRef = ref<typeof Attachments | null>(null);

const targetType = AttachmentTargetType.IssueComment;
const loading = ref(false);
const data = ref<Comment>({ ...props.comment });
const localFiles = ref<File[]>([]);
const editMode = ref(false);
const changesControl = ref(new ChangeManager());

onMounted(() => {
  changesControl.value.init({
    target: `comment_${props.comment.commentId}`,
    onSave: updateComment,
    message: `You have unsaved comment changes.`,
    isChanged: false,
  });
});

onUnmounted(() => {
  changesControl.value.clear();
});

watch(
  () => props.comment,
  function onChangeComment() {
    data.value = { ...props.comment };
  }
);

const canEditComment = computed(
  () =>
    userProfileService.currentUser?.isAdministrator || props.comment?.createdById === userProfileService.currentUser?.userId
);

const isReadyForm = computed(() => Boolean(data.value?.content.trim().length));

const updateFiles = (files: AttachedFile[]) => {
  changesControl.value.activate();
  if (!data.value) return;
  data.value.attachments = files;
};

const updateLocalFiles = (files: File[]) => {
  changesControl.value.activate();
  localFiles.value = files;
};

const deleteCommentConfirm = () => {
  if (!canEditComment.value || props.comment == null) {
    return;
  }
  confirm
    .show(
      `Delete ${props.comment.createdBy}'s comment dated ${moment(
        props.comment.lastModifiedAt || props.comment.createdAt
      ).format("lll")}?`
    )
    .then((confirmed) => {
      if (confirmed) {
        deleteComment();
      }
    });
};

const deleteComment = () => {
  if (!canEditComment.value || props.comment == null) {
    return;
  }

  loading.value = true;
  issueResource
    .deleteComment(Number(route.params.id), props.comment.commentId)
    .then(() => {
      emit("delete", props.comment.commentId);
    })
    .catch(issueResource.defaultErrorHandler)
    .finally(() => {
      loading.value = false;
    });
};

const cancelContent = () => {
  data.value = { ...props.comment };
  localFiles.value = [];
  editMode.value = false;
  changesControl.value.deactivate();
};

const updateComment = () => {
  if (!isReadyForm.value || !data.value) {
    return;
  }
  loading.value = true;
  issueResource
    .updateIssueComment(Number(route.params.id), data.value)
    .then(() => attachmentsRef.value?.uploadLocalFiles())
    .then(() => attachmentsRef.value?.removeFiles())
    .then(() => {
      if (data.value) {
        data.value.lastModifiedAt = new Date().toISOString();
        data.value.lastModifiedBy = userProfileService.currentUser?.username;
        data.value.lastModifiedById = userProfileService.currentUser?.userId;
      }

      emit("update", { ...data.value });
      changesControl.value.deactivate();
    })
    .catch(issueResource.defaultErrorHandler)
    .finally(() => {
      loading.value = false;
      editMode.value = false;
    });
};
</script>

<style scoped>
.issue-comment-container {
  position: relative;
}
.created-date {
  font-weight: 400;
  color: grey;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  flex: 1;
}
.secondary-font {
  font-weight: 400;
  color: grey;
}
.author {
  max-width: calc(100% - 55px);
  flex: 1;
  font-size: 12px;
  line-height: 1.2;
}
</style>
