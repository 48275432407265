<template>
  <v-container fluid class="container">
    <v-card v-for="(data, i) in versionList.slice(0, lastLoadInd)" :key="i" class="mb-5">
      <v-card-title>
        v{{ data.version }}
      </v-card-title>
      <v-card-subtitle>
        {{ moment(data.date).format("MMMM DD, YYYY") }}
      </v-card-subtitle>
      <v-divider/>
      <v-card-text class="ml-6 ma-4">
        <HtmlSource :src="`/releases/${data.version}.html`" />
      </v-card-text>
    </v-card>

    <div class="d-flex justify-center">
      <v-btn v-if="!loading && versionList.length > lastLoadInd" class="my-2" variant="text" size="small" @click="lastLoadInd += limit">
        <v-icon start>mdi-arrow-down-bold</v-icon>
        Show more
      </v-btn>
    </div>
    <v-overlay contained :model-value="loading" opacity="0.46" z-index="99999" persistent class="align-center justify-center">
      <v-progress-circular indeterminate color="primary" size="64"></v-progress-circular>
    </v-overlay>
  </v-container>
</template>

<script setup lang="ts">

import staticResource from "@/resources/StaticResource";
import ReleaseData from "@/types/ReleaseData";
import HtmlSource from "@/components/common/HtmlSource.vue";
import moment from "moment";
import { ref, onMounted } from "vue";


const loading = ref(false);
const versionList = ref<ReleaseData[]>([]);
const lastLoadInd = ref(0);
const limit = ref(5);


onMounted(() => {
    getVersionList();
  })

  const getVersionList = () => {
    loading.value = true;

    staticResource
      .getReleases()
      .then((res) => {
        versionList.value = res.data.sort((a, b) => (new Date(a.date) < new Date(b.date) ? 1 : -1));
        lastLoadInd.value += limit.value;
      })
      .catch(staticResource.defaultErrorHandler)
      .finally(() => {
        loading.value = false;
      });
  }
</script>
 
<style scoped>
.container {
  position: relative;
  min-height: 100%;
}
.what-is-new-badge {
  position: absolute;
  top: 4px;
  left: 24px;
}
</style>