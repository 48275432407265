<template>
  <div>
    <v-dialog
      eager
      @update:model-value="cancel"
      :model-value="state.showWarnings"
      scrim="rgba(33, 33, 33)"
      max-width="500"
      :transition="false"
      @keydown.esc="cancel"
      opacity="0"
      content-class="changesWarning-for-test"
      test-id="changesWarning-dialog-test"
    >
      <v-card tile>
        <v-toolbar flat color="primary" :height="4"> </v-toolbar>
        <v-card-title>{{ target.message || state.title }}</v-card-title>
        <v-card-text class="text-subtitle-1 text-body-1 text-medium-emphasis py-3"> {{ state.message }}</v-card-text>
        <v-card-actions>
          <v-spacer />
          <v-btn size="small" variant="tonal" @click="cancel" test-id="cancel-btn"> Cancel </v-btn>
          <v-btn size="small" variant="tonal" @click="leave" test-id="leave-btn"> Leave </v-btn>
          <v-btn size="small" variant="flat" color="primary" @click="save" v-if="target.onSave" test-id="save-btn">
            Save & Close
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>

<script setup lang="ts">
import ChangeManager from "@/services/ChangeManager";
import { ref, computed } from "vue";

const currentKey = ref("");
const state = ref(ChangeManager.state());

const target = computed(() => {
  const key = getActiveKey();

  if (!key) return {};
  currentKey.value = key;
  const data = state.value.targets[key];
  return data;
});

const getActiveKey = (): void | string => {
  const key = Object.keys(state.value.targets).find((key) => state.value.targets[key]?.isChanged);
  return key;
};

const cancel = () => {
  ChangeManager.hide({ isCancel: true });
};

const leave = () => {
  ChangeManager.leave();
};

const isErrorAfterSave = (targetKey: string) => {
  return getActiveKey() === targetKey;
};

const isStackChanged = (stackLength: number) => {
  return stackLength !== ChangeManager.stack.length;
};

const save = async () => {
  const key = currentKey.value;
  const stackLength = ChangeManager.stack?.length;
  ChangeManager.hide();
  await target.value.onSave();

  setTimeout(() => {
    if (!isErrorAfterSave(key) && (!isStackChanged(stackLength) || state.value.location)) {
      ChangeManager.show();
    }
  }, 500);
};
</script>
